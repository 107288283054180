import React, { Component } from 'react'
import GoogleLogin from 'react-google-login'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import {
  login,
  getProfile,
} from '../../redux/api/actions'
import './Login.css'

class Login extends Component {
  hasLoaded = () => {
    return this.props.profile.loaded
  }

  componentWillMount() {
    if (!this.hasLoaded()) {
      this.props.getProfile()
    }
  }

  onLoginSuccess = async data => {
    await this.props.login(data.accessToken)
    this.props.getProfile()
  }

  onLoginFailure = error => {
    console.log(error)
  }

  render() {
    if (!this.hasLoaded()) {
      return false
    }

    if (this.props.profile.data) {
      return (
        <Redirect to="/dashboard" />
      )
    }

    return (
      <div className="login">
        <div className="login__content">
          <div className="login__title">
            Potluck Admin
          </div>
          <div className="login__subtitle">
            Please log in
          </div>
          <GoogleLogin
            clientId="314038964592-os2r8tsl1iuiiaa62fesfoia054e0kci.apps.googleusercontent.com"
            buttonText="Login"
            onSuccess={this.onLoginSuccess}
            onFailure={this.onLoginFailure}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  profile: state.api.profile.default,
})

const mapDispatchToProps = {
  login,
  getProfile,
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
