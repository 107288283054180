import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Field,
  reduxForm,
} from 'redux-form'
import {
  Link,
  withRouter,
} from 'react-router-dom'
import Autocomplete from 'react-autocomplete'
import classNames from 'classnames'
import {
  getProfile,
  getShop,
  getMeal,
  getTags,
  tagMeal,
  createMeal,
  uploadImage,
  addMediaToMeal,
} from '../../redux/api/actions'
import BigButton from '../../components/BigButton/BigButton'
import FormField from '../../components/FormField/FormField'
import FormFieldTextarea from '../../components/FormFieldTextarea/FormFieldTextarea'
import validate from '../../utils/validators/mealValidator'
import getUrlForImage from '../../utils/getUrlForImage'
import './NewMeal.css'

class NewMeal extends Component {
  state = {
    addTag: '',
    tags: [],
    images: [],
  }

  hasLoaded = () => {
    return this.props.profile.loaded
      && this.props.shop && this.props.shop.loaded
      && this.props.tags.loaded
  }

  onUploadFile = async event => {
    for (let file of event.target.files) {
      const upload = await this.props.uploadImage(file)

      this.setState({
        images: [...this.state.images, ...upload.map(item => item.uuid)],
      })
    }
  }

  onChangeAddTag = event => {
    this.setState({
      addTag: event.currentTarget.value,
    })
  }

  onSelectAddTag = name => {
    const newTag = this.props.tags.data.find(tag => tag.name === name)

    if (!this.state.tags.find(tag => tag.name === newTag.name)) {
      this.setState({
        tags: [...this.state.tags, newTag],
      })
    }
  }

  onClickSubmit = async values => {
    const meal = await this.props.createMeal({
      shopId: this.props.match.params.shopId,
      ...values,
    })

    debugger

    await Promise.all(this.state.images.map(image => this.props.addMediaToMeal({
      mealId: meal.id,
      path: `https://potluck-media.s3-ap-northeast-1.amazonaws.com/${image}/original.jpg`,
      uuid: image,
    })))

    await Promise.all(this.state.tags.map(tag => this.props.tagMeal({
      mealId: meal.id,
      tagId: tag.id,
    })))

    this.props.history.push(`/meals/${meal.id}`)
  }

  async componentWillMount() {
    if (!this.hasLoaded()) {
      this.props.getProfile()
      this.props.getTags()
      this.props.getShop(this.props.match.params.shopId)
    }
  }

  render() {
    if (!this.hasLoaded()) {
      return false
    }

    const {
      handleSubmit,
      pristine,
    } = this.props

    return (
      <div className="new-meal">
        <div className="new-meal__content">
          <form>
            <div className="new-meal__form">
              <Field
                name="name"
                type="text"
                component={FormField}
                label="Name" />
              <div className="meal__item">
                <div className="meal__item-key">
                  Shop
                </div>
                <Link to={`/shops/${this.props.shop.data.id}`}>
                  <div className="meal__item-value">
                    {this.props.shop.data.name}
                  </div>
                </Link>
              </div>
              <Field
                name="price"
                type="number"
                component={FormField}
                label="Price (¥)" />
              <Field
                name="dailyReservationLimit"
                type="number"
                component={FormField}
                label="Number of Offers" />
              <div className="new-meal__item">
              <div className="meal__item">
                <div className="meal__item-key">
                  Images
                </div>
                <div className="meal__item-value">
                  <input
                    multiple
                    label='upload file'
                    type='file'
                    onChange={this.onUploadFile} />
                  {this.state.images.map(image => (
                    <div
                      key={image}
                      className="meal__image"
                      style={{
                        backgroundImage: `url(${getUrlForImage(image)})`,
                      }} />
                  ))}
                </div>
              </div>
              <div className="new-meal__item-key">
                Tags
              </div>
              <div className="new-meal__item-value">
                <div className="new-meal__tags-input-wrapper">
                  <Autocomplete
                    value={this.state.addTag}
                    inputProps={{
                      className: 'new-meal__tags-input',
                      placeholder: 'add tags',
                    }}
                    items={this.props.tags.data}
                    getItemValue={tag => tag.name}
                    shouldItemRender={(tag, value) => tag.name.toLowerCase().includes(value.toLowerCase())}
                    renderItem={(item, isHighlighted) => (
                      <div
                        key={item.name}
                        className={classNames('new-meal__tags-input-option', {
                        'new-meal__tags-input-option--highlighted': isHighlighted,
                      })}>
                        {item.name}
                      </div>
                    )}
                    onChange={this.onChangeAddTag}
                    onSelect={this.onSelectAddTag} />
                </div>
                <div className="new-meal__tags">
                  {this.state.tags.map(tag => (
                    <div
                      key={tag.name}
                      className="new-meal__tag">
                      {tag.name}
                    </div>
                  ))}
                </div>
              </div>
              <Field
                name="details"
                type="text"
                component={FormFieldTextarea}
                label="Details" />
              <Field
                name="ingredients"
                type="text"
                component={FormFieldTextarea}
                label="Ingredients" />
              <Field
                name="pickupInstructions"
                type="text"
                component={FormFieldTextarea}
                label="Pickup Instructions" />
              </div>
              <BigButton
                text="登録する"
                inactive={pristine}
                loading={this.props.meal.loading || this.props.createMealState.loading || this.props.tagMealState.loading || this.props.addMediaToMealState.loading}
                onClick={handleSubmit(this.onClickSubmit)} />
            </div>
          </form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  profile: state.api.profile.default,
  tags: state.api.tags.default,
  shop: state.api.shop[ownProps.match.params.shopId],
  meal: state.api.meal[state.api.meal.lastRequestedKey],
  createMealState: state.api.createMeal.default,
  tagMealState: state.api.tagMeal[state.api.tagMeal.lastRequestedKey],
  addMediaToMealState: state.api.addMediaToMeal[state.api.addMediaToMeal.lastRequestedKey],
})

const mapDispatchToProps = {
  getProfile,
  getShop,
  getMeal,
  getTags,
  tagMeal,
  createMeal,
  uploadImage,
  addMediaToMeal,
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'new-meal',
  validate,
  enableReinitialize: true,
  initialValues: {
    price: 500,
    ticketQuantity: 1,
    dailyReservationLimit: 10,
    pickupInstructions: '1時間以上遅れた場合、キャンセルとみなし基本的に破棄させていただきます。1時間以上遅れる場合は、必ず事前にお店にお問い合わせください。また、お店の閉店時間をすぎるとお渡しができないことがあります。',
  },
})(withRouter(NewMeal)))
