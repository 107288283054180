import React, { Component } from 'react'
import moment from 'moment-timezone'
import { withStyles } from '@material-ui/core/styles'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  Field,
  reduxForm,
  reset,
} from 'redux-form'
import {
  withRouter,
} from 'react-router-dom'
import {
  injectIntl,
  defineMessages,
} from 'react-intl'
import Paper from '@material-ui/core/Paper'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import { TextField } from 'redux-form-material-ui'
import {
  getOpenRequests,
  getSideById,
  editSide,
  acceptRequest,
  deleteRequest,
} from '../redux/api/actions'
import getUrlForImage from '../utils/getUrlForImage'
import validate from '../utils/validators/sideValidator'

const FORM_NAME = 'side'

const messages = defineMessages({
  changeName: {
    id: 'side.change-name',
    defaultMessage: 'Change the name from "{previousValue}"" to "{newValue}"',
  },
  changeCategory: {
    id: 'side.change-category',
    defaultMessage: 'Change the category from "{previousValue}"" to "{newValue}"',
  },
  changePrice: {
    id: 'side.change-price',
    defaultMessage: 'Change the price from {previousValue}円 to {newValue}円',
  },
  changeDailyReservationLimit: {
    id: 'side.daily-reservation-limit',
    defaultMessage: 'Change the daily limit from {previousValue} to {newValue}',
  },
  changeImages: {
    id: 'side.change-images',
    defaultMessage: 'Change the images to:',
  },
})

const OPERATIONS_TO_MESSAGES = {
  'CHANGE_SIDE_NAME': messages.changeName,
  'CHANGE_SIDE_CATEGORY': messages.changeCategory,
  'CHANGE_SIDE_PRICE': messages.changePrice,
  'CHANGE_SIDE_DAILY_RESERVATION_LIMIT': messages.changeDailyReservationLimit,
  'CHANGE_SIDE_IMAGES': messages.changeImages,
}

const styles = theme => ({
  container: {
    margin: theme.spacing(3),
  },
  request: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  requestTitle: {
    fontWeight: 'bold',
  },
  requestImages: {
    display: 'flex',
    flexDirection: 'row',
    margin: theme.spacing(0, -1),
  },
  requestImage: {
    width: 200,
    height: 140,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repear',
    backgroundPosition: 'center',
    borderRadius: 4,
    margin: theme.spacing(1),
  },
  requestCta: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  paper: {
    marginBottom: theme.spacing(3),
  },
  title: {
    flex: 1,
  },
  actions: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  ctaProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  content: {
    padding: theme.spacing(3),
  },
  formField: {
    marginBottom: theme.spacing.unit * 3,
  },
  formFieldSmall: {
    width: 110,
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  formFieldMedium: {
    width: 180,
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  formFieldGrow: {
    flex: 1,
  },
  formFieldBlue: {
    '& input': {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
    },
  },
  formFieldsHorizontal: {
    display: 'flex',
    flexDirection: 'row',
    '& > :not(:first-child)': {
      marginLeft: theme.spacing(3),
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      '& > :not(:first-child)': {
        marginLeft: 0,
      },
    },
  },
  images: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: theme.spacing(0, -1)
  },
  image: {
    minWidth: 220,
    maxWidth: 220,
    margin: theme.spacing(1)
  },
  noneText: {
    paddingLeft: theme.spacing(1),
  },
})

class Side extends Component {
  state = {
    denyRequestLoading: false,
    acceptRequestLoading: false,
    loading: false,
  }

  onClickDenySideChangeRequest = async requestId => {
    this.setState({ denyRequestLoading: true })

    await this.props.deleteRequest(requestId)
    await Promise.all([
      this.props.getOpenRequests(),
      this.props.getSideById(this.props.match.params.sideId),
    ])

    this.props.dispatch(reset(FORM_NAME))

    this.setState({ denyRequestLoading: false })
  }

  onClickAcceptSideChangeRequest = async requestId => {
    this.setState({ acceptRequestLoading: true })

    await this.props.acceptRequest(requestId)
    await Promise.all([
      this.props.getOpenRequests(),
      this.props.getSideById(this.props.match.params.sideId),
    ])

    this.props.dispatch(reset(FORM_NAME))

    this.setState({ acceptRequestLoading: false })
  }

  onClickSubmit = async values => {
    this.setState({ loading: true })

    await this.props.editSide(this.props.match.params.sideId, values)
    await this.props.getSideById(this.props.match.params.sideId)

    this.setState({ loading: false })
  }

  componentWillMount() {
    this.props.getOpenRequests()
    this.props.getSideById(this.props.match.params.sideId)
  }

  render() {
    const {
      handleSubmit,
      pristine,
      invalid,
    } = this.props

    let images = []

    if (this.props.side && this.props.side.images) {
      images = this.props.side.images
    }

    return (
      <div className={this.props.classes.container}>
        {this.props.openRequestForSide && (
          <Paper className={this.props.classes.request}>
            <Typography
              variant="h6"
              className={this.props.classes.requestTitle}>
              Needs a review ({moment.tz(this.props.openRequestForSide.createdAt, 'Asia/Tokyo').format('MMMM Do kk:mm')})
            </Typography>
            <Typography variant="body1">
              {this.props.openRequestForSide.requesterName} wants to edit this meal:
            </Typography>
            <ul>
              {this.props.openRequestForSide.operations.map(operation => (
                <li key={operation.type}>
                  {this.props.intl.formatMessage(OPERATIONS_TO_MESSAGES[operation.type], {
                    previousValue: operation.previousValue,
                    newValue: operation.newValue,
                  })}
                  {operation.type === 'CHANGE_SIDE_IMAGES' && (
                    <div className={this.props.classes.requestImages}>
                      {operation.newValue.map(image => (
                        <div
                          key={image}
                          className={this.props.classes.requestImage}
                          style={{
                            backgroundImage: `url(${getUrlForImage(image)})`,
                          }} />
                      ))}
                    </div>
                  )}
                </li>
              ))}
            </ul>
            <div className={this.props.classes.requestCta}>
              <Button
                variant="text"
                disabled={this.state.denyRequestLoading || this.state.acceptRequestLoading}
                className={this.props.classes.button}
                onClick={() => this.onClickDenySideChangeRequest(this.props.openRequestForSide.id)}>
                {this.state.denyRequestLoading && (
                  <CircularProgress
                    size={24}
                    color="primary"
                    className={this.props.classes.ctaProgress} />
                )}
                Deny
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={this.state.denyRequestLoading || this.state.acceptRequestLoading}
                className={this.props.classes.button}
                onClick={() => this.onClickAcceptSideChangeRequest(this.props.openRequestForSide.id)}>
                {this.state.acceptRequestLoading && (
                  <CircularProgress
                    size={24}
                    color="primary"
                    className={this.props.classes.ctaProgress} />
                )}
                Accept
              </Button>
            </div>
          </Paper>
        )}
        <Paper className={this.props.classes.paper}>
          <form>
            <Toolbar>
              <Typography
                variant="h4"
                className={this.props.classes.title}>
                {this.props.side.name}
              </Typography>
              <Button
                color="primary"
                variant="contained"
                disabled={this.state.loading || invalid || pristine || this.props.openRequestForSide}
                onClick={handleSubmit(this.onClickSubmit)}>
                {this.state.loading && (
                  <CircularProgress
                    size={24}
                    color="primary"
                    className={this.props.classes.ctaProgress} />
                )}
                Save
              </Button>
            </Toolbar>
            <div className={this.props.classes.content}>
              <Field
                disabled={this.props.openRequestForSide}
                name="name"
                label="Name"
                variant="outlined"
                fullWidth
                autoComplete="off"
                component={TextField}
                className={this.props.classes.formField} />
              <div className={this.props.classes.formFieldsHorizontal}>
                <Field
                  disabled={this.props.openRequestForSide}
                  name="category"
                  label="Category"
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                  component={TextField}
                  className={this.props.classes.formField} />
                <Field
                  disabled={this.props.openRequestForSide}
                  name="price"
                  label="Price"
                  variant="outlined"
                  fullWidth
                  type="number"
                  component={TextField}
                  className={this.props.classes.formField} />
                <Field
                  disabled={this.props.openRequestForSide}
                  name="dailyReservationLimit"
                  label="Daily limit"
                  variant="outlined"
                  fullWidth
                  type="number"
                  component={TextField}
                  className={this.props.classes.formField} />
              </div>
              <Typography variant="body1">
                Images
              </Typography>
              <div className={this.props.classes.images}>
                {images.map(image => (
                  <Card
                    key={image}
                    className={this.props.classes.image}>
                    <CardMedia
                      component="img"
                      alt="Side image"
                      height="140"
                      image={getUrlForImage(image)} />
                  </Card>
                ))}
                {images.length === 0 && (
                  <div className={this.props.classes.noneText}>
                    <Typography variant="caption">
                      None
                    </Typography>
                  </div>
                )}
              </div>
            </div>
          </form>
        </Paper>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let sideId

  if (ownProps.match.params.sideId === 'new') {
    sideId = 'new'
  } else {
    sideId = parseInt(ownProps.match.params.sideId, 10)
  }

  let side = {}
  let initialValues = {}
  let openRequestForSide

  if (state.api.side[sideId] && state.api.side[sideId].data) {
    side = state.api.side[sideId].data
    initialValues = {
      name: side.name,
      category: side.category,
      price: side.price,
      dailyReservationLimit: side.dailyReservationLimit,
    }
  }

  if (state.api.openRequests.default.loaded) {
    openRequestForSide = state.api.openRequests.default.data.find(openRequest => openRequest.operations.some(operation => operation.sideId === sideId))
  }

  return {
    side,
    initialValues,
    openRequestForSide,
  }
}

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    getOpenRequests,
    getSideById,
    editSide,
    acceptRequest,
    deleteRequest,
  }, dispatch),
  dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: FORM_NAME,
  validate,
  enableReinitialize: true,
})(withRouter(injectIntl(withStyles(styles)(Side)))))
