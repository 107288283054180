import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { debounce } from 'throttle-debounce'
import {
  Field,
  reduxForm,
  formValueSelector,
  SubmissionError,
  reset,
} from 'redux-form'
import {
  withRouter,
} from 'react-router-dom'
import {
  TextField,
} from 'redux-form-material-ui'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Checkbox from '@material-ui/core/Checkbox'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import {
  createCompany,
  getCompany,
  getShopsForCompany,
  editCompany,
  searchCompanies,
  getShopUsersForCompany,
} from '../redux/api/actions'
import validate from '../utils/validators/shopUserValidator'

const FORM_NAME = 'company'

const styles = theme => ({
  container: {
    margin: theme.spacing(3),
  },
  paper: {
    marginBottom: theme.spacing(3),
    width: '100%',
  },
  toolbar: {
    display: 'flex',
    justiftContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  title: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing.unit * 3,
  },
  content: {
    padding: theme.spacing.unit * 3,
  },
  errorText: {
    color: 'red',
    marginBottom: theme.spacing.unit * 3,
  },
  formField: {
    marginBottom: theme.spacing.unit * 3,
  },
  button: {
    margin: theme.spacing.unit,
    marginTop: theme.spacing.unit * 2,
  },
  progress: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    zIndex: 10,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  cellSmall: {
    width: 150,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  },
  cellMedium: {
    width: 300,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  },
  cellLarge: {
    maxWidth: 450,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  },
  noneCell: {
    textAlign: 'center',
  },
})

class Company extends Component {
  constructor(props) {
    super(props)

    props.getCompany(props.match.params.companyId)
    props.getShopsForCompany(props.match.params.companyId)
    props.getShopUsersForCompany(props.match.params.companyId)

    const state = {
      loading: false,
      shopsResults: [],
      shopsResultsLoading: false,
      shopUsersResults: [],
      shopUsersResultsLoading: false,
    }
    this.state = state
  }

  hasLoaded = () => this.props.company && this.props.company.loaded

  searchCompanies = debounce(750, async text => {
    let results

    try {
      results = await this.props.searchCompanies({
        text,
        page: 0,
      })
    } catch (exception) {
      // swallow
    }

    this.setState({
      shopResultsLoading: false,
      shopResults: results.shops,
    })
  })

  onChangeShops = (event, shops) => {
    this.setState({
      shops,
    })
  }

  onChangeShopSearch = event => {
    this.setState({ shopResultsLoading: true })
    this.searchCompanies(event.target.value)
  }

  onClickUser = user => {
    this.props.history.push(`/shop-users/${user.id}`)
  }

  onClickShop = shop => {
    this.props.history.push(`/shops/${shop.id}`)
  }

  onClickSubmit = async values => {
    this.setState({ loading: true })

    if (this.props.isNew) {
      const result = await this.props.createCompany(values)

      if (result && result.id) {
        window.location = `/company/${result.id}`
      } else if (result && result.error) {
        this.setState({ loading: false })

        throw new SubmissionError({
          _error: result.error,
        })
      }
    } else {
      const promises = [this.props.editCompany(this.props.match.params.companyId, values)]

      await Promise.all(promises)

      await Promise.all([
        this.props.getCompany(this.props.match.params.companyId),
      ])

      this.setState({ loading: false })
      this.props.dispatch(reset(FORM_NAME))
    }
  }

  render() {
    if (!this.hasLoaded()) {
      return false
    }

    const {
      handleSubmit,
      pristine,
      invalid,
      error,
    } = this.props

    return (
      <div className={this.props.classes.container}>
        <Paper className={this.props.classes.paper}>
          <Toolbar className={this.props.classes.toolbar}>
            <div className={this.props.classes.title}>
              <Typography variant="h4">
                {this.props.name}
              </Typography>
            </div>
            <div className={this.props.classes.actions}>
              <Button
                variant="contained"
                color="primary"
                className={this.props.classes.button}
                disabled={(pristine || invalid || this.state.loading)}
                onClick={handleSubmit(this.onClickSubmit)}>
                登録する
              </Button>
            </div>
          </Toolbar>
          <form className={this.props.classes.content}>
            {error && (
              <Typography
                variant="body2"
                className={this.props.classes.errorText}>
                {error}
              </Typography>
            )}
            <Field
              name="name"
              label="Name"
              variant="outlined"
              autoComplete="off"
              fullWidth
              component={TextField}
              className={this.props.classes.formField} />
            {!this.props.isNew
              && this.props.shopsForShopUser
              && this.props.shopsForShopUser.data && (
              <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                ChipProps={{
                  color: 'primary',
                }}
                disableCloseOnSelect
                options={this.state.shopResults}
                defaultValue={this.props.companies.data}
                className={this.props.classes.formField}
                getOptionLabel={option => option.name}
                getOptionSelected={(option, value) => option.id === value.id}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      color="primary"
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Add company"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {this.state.shopResultsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                      onChange: this.onChangeShopSearch,
                    }}
                  />
                )}
                onChange={this.onChangeShops} />
            )}
          </form>
        </Paper>
        <Paper className={this.props.classes.paper}>
          <Toolbar className={this.props.classes.toolbar}>
            <div className={this.props.classes.title}>
              <Typography variant="h6">
                Shop Users
              </Typography>
            </div>
          </Toolbar>
          <div className={this.props.classes.tableWrapper}>
          <Table className={this.props.classes.table}>
            <TableHead>
              <TableRow>
                <TableCell classes={{ root: this.props.classes.cellSmall }}>id</TableCell>
                <TableCell classes={{ root: this.props.classes.cellLarge }}>name</TableCell>
                <TableCell classes={{ root: this.props.classes.cellMedium }}>email</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!this.state.shopUsersResultsLoading && this.props.shopUsers.data && this.props.shopUsers.data.map(shopUser => (
                <TableRow
                  key={shopUser.id}
                  hover
                  onClick={() => this.onClickUser(shopUser)}>
                  <TableCell classes={{ root: this.props.classes.cellSmall }}>{shopUser.id}</TableCell>
                  <TableCell classes={{ root: this.props.classes.cellLarge }}>{shopUser.name}</TableCell>
                  <TableCell classes={{ root: this.props.classes.cellMedium }}>{shopUser.email}</TableCell>
                </TableRow>
              ))}
              {!this.state.shopUsersResultsLoading && this.props.shopUsers.data && this.props.shopUsers.data.length === 0 && (
                <TableRow>
                  <TableCell className={this.props.classes.noneCell} colSpan={3}>none</TableCell>
                </TableRow>
                )}
            </TableBody>
          </Table>
          {this.state.shopUsersResultsLoading && (
            <div className={this.props.classes.progress}>
              <CircularProgress />
            </div>
          )}
        </div>
        </Paper>
        <Paper className={this.props.classes.paper}>
          <Toolbar className={this.props.classes.toolbar}>
            <div className={this.props.classes.title}>
              <Typography variant="h6">
                Shops
              </Typography>
            </div>
          </Toolbar>
          <div className={this.props.classes.tableWrapper}>
          <Table className={this.props.classes.table}>
            <TableHead>
              <TableRow>
                <TableCell classes={{ root: this.props.classes.cellSmall }}>id</TableCell>
                <TableCell classes={{ root: this.props.classes.cellLarge }}>name</TableCell>
                <TableCell classes={{ root: this.props.classes.cellMedium }}>descriptor</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!this.state.shopUsersResultsLoading && this.props.shops.data && this.props.shops.data.map(shop => (
                <TableRow
                  key={shop.id}
                  hover
                  onClick={() => this.onClickShop(shop)}>
                  <TableCell classes={{ root: this.props.classes.cellSmall }}>{shop.id}</TableCell>
                  <TableCell classes={{ root: this.props.classes.cellLarge }}>{shop.name}</TableCell>
                  <TableCell classes={{ root: this.props.classes.cellMedium }}>{shop.descriptor}</TableCell>
                </TableRow>
              ))}
              {!this.state.shopUsersResultsLoading && this.props.shops.data && this.props.shops.data.length === 0 && (
                <TableRow>
                  <TableCell className={this.props.classes.noneCell} colSpan={3}>none</TableCell>
                </TableRow>
                )}
            </TableBody>
          </Table>
          {this.state.shopUsersResultsLoading && (
            <div className={this.props.classes.progress}>
              <CircularProgress />
            </div>
          )}
        </div>
        </Paper>
      </div>
    )
  }
}

const selector = formValueSelector(FORM_NAME)

const mapStateToProps = (state, ownProps) => {
  const props = {
    isNew: ownProps.match.params.companyId === 'new',
    name: selector(state, 'name'),
    company: state.api.company[ownProps.match.params.companyId],
    shopUsers: state.api.shopUsersForCompany[state.api.shopUsersForCompany.lastReceivedKey],
    shops: state.api.shopsForCompany[state.api.shopsForCompany.lastReceivedKey],
  }

  if (props.company && props.company.data) {
    props.initialValues = {
      name: props.company.data.name,
      deleted: props.company.data.deleted,
    }
  }

  return props
}

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    createCompany,
    getCompany,
    getShopsForCompany,
    editCompany,
    searchCompanies,
    getShopUsersForCompany,
  }, dispatch),
  dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: FORM_NAME,
  validate,
  enableReinitialize: true,
})(withRouter(withStyles(styles)(Company))))
