import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Redirect,
  withRouter,
} from 'react-router-dom'
import moment from 'moment-timezone'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import AddIcon from '@material-ui/icons/Add'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TablePagination from '@material-ui/core/TablePagination'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'
import {
  getHolidays,
  getHolidayDialogs,
} from '../redux/api/actions'

const PAGE_SIZE = 10

const TablePaginationActions = withStyles(theme => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing.unit * 2.5,
  },
}))(props => {
  const classes = props.classes
  const { count, page, rowsPerPage, onChangePage } = props

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0)
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1)
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1)
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page">
        <FirstPageIcon />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page">
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page">
        <LastPageIcon />
      </IconButton>
    </div>
  )
})

const styles = theme => ({
  root: {
    flex: '1 0 auto',
  },
  content: {
    margin: theme.spacing.unit * 3,
  },
  toolbar: {
    display: 'flex',
  },
  actions: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  pagination: {
    borderBottom: 'none',
  },
  title: {
    flex: '1',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
})

class Holidays extends Component {
  constructor(props) {
    super(props)

    this.state = {
      holidaysPage: 0,
      holidayDialogsPage: 0,
    }
  }

  onChangeHolidaysPage = (event, page) => {
    this.setState({
      holidaysPage: page,
    })
  }

  onChangeHolidayDialogsPage = (event, page) => {
    this.setState({
      holidayDialogsPage: page,
    })
  }

  onClickHoliday = holiday => {
    this.props.history.push(`/holidays/${holiday.id}`)
  }

  onClickAddHoliday = () => {
    this.props.history.push('/holidays/new')
  }

  onClickHolidayDialog = holidayDialog => {
    this.props.history.push(`/holidays/dialogs/${holidayDialog.id}`)
  }

  onClickAddHolidayDialog = () => {
    this.props.history.push('/holidays/dialogs/new')
  }

  componentWillMount() {
    this.props.getHolidays()
    this.props.getHolidayDialogs()
  }

  render() {
    if (this.props.profile.loaded && !this.props.profile.data) {
      return (
        <Redirect to="/login" />
      )
    }

    let holidays = []
    let holidaysLength = 0

    if (this.props.holidays.loaded) {
      holidays = this.props.holidays.data.slice(this.state.holidaysPage * PAGE_SIZE, (this.state.holidaysPage + 1) * PAGE_SIZE)
      holidaysLength = this.props.holidays.data.length
    }

    let holidayDialogs = []
    let holidayDialogsLength = 0

    if (this.props.holidayDialogs.loaded) {
      holidayDialogs = this.props.holidayDialogs.data.slice(this.state.holidayDialogsPage * PAGE_SIZE, (this.state.holidayDialogsPage + 1) * PAGE_SIZE)
      holidayDialogsLength = this.props.holidayDialogs.data.length
    }

    return (
      <div className={this.props.classes.root}>
        <Paper className={this.props.classes.content}>
          <Toolbar className={this.props.classes.toolbar}>
            <div className={this.props.classes.title}>
              <Typography variant="h6">
                Holidays
              </Typography>
            </div>
            <TablePagination
              count={holidaysLength}
              page={this.state.holidaysPage}
              rowsPerPage={10}
              rowsPerPageOptions={[]}
              ActionsComponent={TablePaginationActions}
              classes={{ root: this.props.classes.pagination }}
              onChangePage={this.onChangeHolidaysPage} />
            <Tooltip title="Add">
              <IconButton
                aria-label="Add"
                color="primary"
                onClick={this.onClickAddHoliday}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          </Toolbar>
          <div className={this.props.classes.tableWrapper}>
            <Table className={this.props.classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!this.state.loading && holidays.map(holiday => (
                  <TableRow
                    key={holiday.id}
                    hover
                    onClick={() => this.onClickHoliday(holiday)}>
                    <TableCell>{holiday.id}</TableCell>
                    <TableCell>{holiday.name}</TableCell>
                    <TableCell>{moment.tz(holiday.startDate, 'Asia/Tokyo').format('MMMM Do YYYY')}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {this.state.loading && (
              <div className={this.props.classes.progress}>
                <CircularProgress />
              </div>
            )}
          </div>
        </Paper>
        <Paper className={this.props.classes.content}>
          <Toolbar className={this.props.classes.toolbar}>
            <div className={this.props.classes.title}>
              <Typography variant="h6">
                Holiday Dialogs
              </Typography>
            </div>
            <TablePagination
              count={holidayDialogsLength}
              page={this.state.holidayDialogsPage}
              rowsPerPage={10}
              rowsPerPageOptions={[]}
              ActionsComponent={TablePaginationActions}
              classes={{ root: this.props.classes.pagination }}
              onChangePage={this.onChangeHolidayDialogsPage} />
            <Tooltip title="Add">
              <IconButton
                aria-label="Add"
                color="primary"
                onClick={this.onClickAddHolidayDialog}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          </Toolbar>
          <div className={this.props.classes.tableWrapper}>
            <Table className={this.props.classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!this.state.loading && holidayDialogs.map(holidayDialog => (
                  <TableRow
                    key={holidayDialog.id}
                    hover
                    onClick={() => this.onClickHolidayDialog(holidayDialog)}>
                    <TableCell>{holidayDialog.id}</TableCell>
                    <TableCell>{holidayDialog.name}</TableCell>
                    <TableCell>{moment(holidayDialog.periodStart, 'YYYYMMDD', 'Asia/Tokyo').format('MMMM Do YYYY')} - {moment(holidayDialog.periodEnd, 'YYYYMMDD', 'Asia/Tokyo').format('MMMM Do YYYY')}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {this.state.loading && (
              <div className={this.props.classes.progress}>
                <CircularProgress />
              </div>
            )}
          </div>
        </Paper>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  profile: state.api.profile.default,
  holidays: state.api.holidays.default,
  holidayDialogs: state.api.holidayDialogs.default,
})

const mapDispatchToProps = {
  getHolidays,
  getHolidayDialogs,
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(Holidays)))
