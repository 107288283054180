import React from 'react'
import './FormFieldTextarea.css'

export default ({
  input,
  disabled,
  placeholder,
  label,
  type,
  loading,
  meta: {
    touched,
    error,
  },
}) => (
  <div className="form-field-textarea__item">
    <div className="form-field-textarea__item-key">
      {label}
    </div>
    <div className="form-field-textarea__item-value">
      <textarea
        {...input}
        disabled={disabled}
        placeholder={placeholder}
        type={type} />
      {loading && (<div className="form-field-textarea__loading" />)}
      {touched && (error && (
        <span className="form-field-textarea__item-error">
          {error}
        </span>
      ))}
    </div>
  </div>
)
