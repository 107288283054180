import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Field,
  reduxForm,
} from 'redux-form'
import {
  Link,
  Redirect,
  withRouter,
} from 'react-router-dom'
import {
  injectIntl,
  FormattedMessage,
} from 'react-intl'
import moment from 'moment-timezone'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import {
  getProfile,
  getOpenRequests,
  deleteRequest,
  acceptRequest,
} from '../../redux/api/actions'
import getUrlForImage from '../../utils/getUrlForImage'
import FormField from '../../components/FormField/FormField'
import FormFieldTextarea from '../../components/FormFieldTextarea/FormFieldTextarea'
import './MealRequest.css'

const styles = theme => ({
  optionsName: {
    fontWeight: 'bold',
  },
  button: {
    margin: theme.spacing.unit,
    marginTop: theme.spacing.unit * 2,
  },
  input: {
    display: 'none',
  },
})

class MealRequest extends Component {
  state = {
    addTag: '',
    tags: [],
    mediaLoading: false,
  }

  hasLoaded = () => {
    return this.props.profile.loaded
      && this.props.openRequests.loaded
  }

  onClickAcceptMealChangeRequest = async requestId => {
    const { insertId } = await this.props.acceptRequest(requestId)
    this.props.getOpenRequests()

    this.props.history.push(`/meals/${insertId}`)
  }

  onClickDenyMealRequest = async requestId => {
    await this.props.deleteRequest(requestId)
    this.props.getOpenRequests()
    this.props.history.push('/shops')
  }

  async componentWillMount() {
    if (!this.hasLoaded()) {
      this.props.getProfile()
      this.props.getOpenRequests()
    }
  }

  render() {
    if (!this.hasLoaded()) {
      return false
    }

    if (!this.props.request) {
      return (<Redirect to="/shops" />)
    }

    return (
      <div className="meal">
        <div className="meal__content">
          <div className="meal__change-request">
            <div className="meal__change-request-top">
              <div className="meal__change-request-icon" />
              <div className="meal__change-request-title">
                Needs a review ({moment(this.props.request.createdAt).format('MMMM Do')})
              </div>
            </div>
            <div className="meal__change-request-text">
              <FormattedMessage
                id="meal.request-title"
                defaultMessage="{requesterName} wants to add this new meal to their shop:"
                values={{
                  requesterName: this.props.request.requesterName,
                }} />
            </div>
            <Button
              variant="contained"
              color="primary"
              className={this.props.classes.button}
              disabled={this.props.openRequests.loading || this.props.acceptRequestState.loading || this.props.deleteRequestState.loading}
              onClick={() => this.onClickAcceptMealChangeRequest(this.props.request.id)}>
              Accept
            </Button>
            <Button
              variant="contained"
              color="secondary"
              className={this.props.classes.button}
              disabled={this.props.openRequests.loading || this.props.acceptRequestState.loading || this.props.deleteRequestState.loading}
              onClick={() => this.onClickDenyMealRequest(this.props.request.id)}>
              Deny
            </Button>
          </div>
          <form>
            <div className="meal__form">
              <Field
                disabled
                name="name"
                type="text"
                component={FormField}
                label="Name" />
              <div className="meal__item">
                <div className="meal__item-key">
                  Shop
                </div>
                <Link to={`/shops/${this.props.request.shop.id}`}>
                  <div className="meal__item-value">
                    {this.props.request.shop.name}
                  </div>
                </Link>
              </div>
              <div className="meal__horizontal-field">
                <Field
                  disabled
                  name="price"
                  type="number"
                  component={FormField}
                  label="Price (¥)" />
                <Field
                  disabled
                  name="dailyReservationLimit"
                  type="text"
                  component={FormField}
                  placeholder="unlimited"
                  label="Reservation Limit (daily)" />
              </div>
              <div className="meal__item">
                <div className="meal__item-key">
                  Images
                </div>
                <div className="meal__item-value">
                  {this.props.images && this.props.images.map(image => (
                    <div
                      key={image}
                      className="meal__image"
                      style={{
                        backgroundImage: `url(${getUrlForImage(image)})`,
                      }} />
                  ))}
                </div>
              </div>
              <div className="meal__item">
                <div className="meal__item-key">
                  Options
                </div>
                <div className="meal__item-value">
                  <ul>
                  {this.props.options && this.props.options.map(option => (
                    <li key={option.id}>
                      <Typography
                        variant="body"
                        className={this.props.classes.optionsName}>
                        {option.name}
                      </Typography>
                      <ul>
                        {option.values.map(value => (
                          <li>
                            <Typography variant="body">
                              {value.name} ({value.cost > 0 ? `¥${value.cost}` : 'free'})
                            </Typography>
                          </li>
                        ))}
                      </ul>
                    </li>
                  ))}
                  </ul>
                </div>
              </div>
              <Field
                disabled
                name="details"
                type="text"
                component={FormFieldTextarea}
                label="Details" />
              <Field
                disabled
                name="ingredients"
                type="text"
                component={FormFieldTextarea}
                label="Ingredients" />
              <Field
                disabled
                name="pickupInstructions"
                type="text"
                component={FormFieldTextarea}
                label="Pickup Instructions" />
            </div>
          </form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const props = {
    profile: state.api.profile.default,
    openRequests: state.api.openRequests.default,
    acceptRequestState: state.api.acceptRequest.default,
    deleteRequestState: state.api.deleteRequest.default,
  }

  const requestId = parseInt(ownProps.match.params.requestId, 10)

  if (props.openRequests.data) {
    props.request = props.openRequests.data.find(openRequest => openRequest.id === requestId)

    if (props.request) {
      props.initialValues = props.request.operations[0].values
      props.images = props.request.operations[0].values.images
      props.options = props.request.operations[0].values.options
    }
  }
  return props
}

const mapDispatchToProps = {
  getProfile,
  getOpenRequests,
  deleteRequest,
  acceptRequest,
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'meal-request',
  enableReinitialize: true,
})(withRouter(injectIntl(withStyles(styles)(MealRequest)))))
