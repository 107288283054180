import Home from './containers/Home/Home'
import Dashboard from './containers/Dashboard/Dashboard'
import KPIs from './containers/KPIs'
import Areas from './containers/Areas'
import Area from './containers/Area'
import Users from './containers/Users/Users'
import User from './containers/User/User'
import ShopUsers from './containers/ShopUsers'
import ShopUser from './containers/ShopUser'
import ShopUserResetPassword from './containers/ShopUserResetPassword'
import Grant from './containers/Grant'
import OffersAndProducts from './containers/OffersAndProducts'
import Offer from './containers/Offer'
import Product from './containers/Product'
import Subscriptions from './containers/Subscriptions/Subscriptions'
import ResetPassword from './containers/ResetPassword/ResetPassword'
import Organizations from './containers/Organizations/Organizations'
import Organization from './containers/Organization/Organization'
import Shops from './containers/Shops/Shops'
import Shop from './containers/Shop/Shop'
import Meals from './containers/Meals/Meals'
import Meal from './containers/Meal/Meal'
import NewMeal from './containers/NewMeal/NewMeal'
import MealRequest from './containers/MealRequest/MealRequest'
import Side from './containers/Side'
import SideRequest from './containers/SideRequest'
import Tags from './containers/Tags/Tags'
import Orders from './containers/Orders'
import Order from './containers/Order'
import Deliveries from './containers/Deliveries'
import DeliveryZones from './containers/DeliveryZones'
import PaymentsNew from './containers/Payments'
import Payments from './containers/Payments/Payments'
import Holidays from './containers/Holidays'
import Holiday from './containers/Holiday'
import HolidayDialog from './containers/HolidayDialog'
import AppVersions from './containers/AppVersions'
import Companies from './containers/Companies'
import Company from './containers/Company'
// import AppVersion from './containers/AppVersion'

export default [{
  path: '/',
  exact: true,
  component: Home,
}, {
  path: '/dashboard',
  exact: true,
  component: Dashboard,
  requireLoggedIn: true,
}, {
  path: '/kpis',
  exact: true,
  component: KPIs,
  requireLoggedIn: true,
}, {
  path: '/areas',
  exact: true,
  component: Areas,
  requireLoggedIn: true,
}, {
  path: '/areas/:areaId',
  exact: true,
  component: Area,
  requireLoggedIn: true,
}, {
  path: '/users',
  exact: true,
  component: Users,
  requireLoggedIn: true,
}, {
  path: '/users/:userId',
  exact: true,
  component: User,
  requireLoggedIn: true,
}, {
  path: '/shop-users',
  exact: true,
  component: ShopUsers,
  requireLoggedIn: true,
}, {
  path: '/shop-users/:shopUserId',
  exact: true,
  component: ShopUser,
  requireLoggedIn: true,
}, {
  path: '/shop-users/:shopUserId/reset-password',
  exact: true,
  component: ShopUserResetPassword,
  requireLoggedIn: true,
}, {
  path: '/grants/:grantId',
  exact: true,
  component: Grant,
  requireLoggedIn: true,
}, {
  path: '/offers-and-products',
  exact: true,
  component: OffersAndProducts,
  requireLoggedIn: true,
}, {
  path: '/offers/:offerId',
  exact: true,
  component: Offer,
  requireLoggedIn: true,
}, {
  path: '/products/:productId',
  exact: true,
  component: Product,
  requireLoggedIn: true,
}, {
  path: '/subscriptions',
  exact: true,
  component: Subscriptions,
  requireLoggedIn: true,
}, {
  path: '/users/:userId/reset-password',
  exact: true,
  component: ResetPassword,
  requireLoggedIn: true,
}, {
  path: '/organizations',
  exact: true,
  component: Organizations,
  requireLoggedIn: true,
}, {
  path: '/organizations/:organizationId',
  exact: true,
  component: Organization,
  requireLoggedIn: true,
}, {
  path: '/shops',
  exact: true,
  component: Shops,
  requireLoggedIn: true,
}, {
  path: '/shops/:shopId',
  exact: true,
  component: Shop,
  requireLoggedIn: true,
}, {
  path: '/shops/:shopId/meals/new',
  component: NewMeal,
  requireLoggedIn: true,
}, {
  path: '/tags',
  exact: true,
  component: Tags,
  requireLoggedIn: true,
}, {
  path: '/meals',
  exact: true,
  component: Meals,
  requireLoggedIn: true,
}, {
  path: '/meals/:mealId',
  component: Meal,
  requireLoggedIn: true,
}, {
  path: '/meal-requests/:requestId',
  component: MealRequest,
  requireLoggedIn: true,
}, {
  path: '/sides/:sideId',
  component: Side,
  requireLoggedIn: true,
}, {
  path: '/side-requests/:requestId',
  component: SideRequest,
  requireLoggedIn: true,
}, {
  path: '/orders/:date?',
  exact: true,
  component: Orders,
  requireLoggedIn: true,
}, {
  path: '/order/:id',
  exact: true,
  component: Order,
  requireLoggedIn: true,
}, {
  path: '/deliveries/:date?',
  exact: true,
  component: Deliveries,
  requireLoggedIn: true,
}, {
  path: '/delivery-zones',
  exact: true,
  component: DeliveryZones,
  requireLoggedIn: true,
}, {
  path: '/payments-new/:date?',
  exact: true,
  component: PaymentsNew,
  requireLoggedIn: true,
}, {
  path: '/payments/:date?',
  exact: true,
  component: Payments,
  requireLoggedIn: true,
}, {
  path: '/holidays',
  exact: true,
  component: Holidays,
  requireLoggedIn: true,
}, {
  path: '/holidays/:holidayId',
  exact: true,
  component: Holiday,
  requireLoggedIn: true,
}, {
  path: '/holidays/dialogs/:holidayDialogId',
  exact: true,
  component: HolidayDialog,
  requireLoggedIn: true,
}, {
  path: '/app-versions',
  exact: true,
  component: AppVersions,
  requireLoggedIn: true,
}, {
  path: '/companies',
  exact: true,
  component: Companies,
  requireLoggedIn: true,
}, {
  path: '/companies/:companyId',
  exact: true,
  component: Company,
  requireLoggedIn: true,
}]
