import React, { Component } from 'react'
import moment from 'moment-timezone'
import { connect } from 'react-redux'
import {
  withRouter,
} from 'react-router-dom'
import {
  Field,
  formValueSelector,
  reduxForm,
} from 'redux-form'
import {
  TextField,
  Switch,
} from 'redux-form-material-ui'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import FormControl from '@material-ui/core/FormControl'
import CircularProgress from '@material-ui/core/CircularProgress'
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded'
import {
  KeyboardDatePicker,
} from '@material-ui/pickers'
import {
  getProfile,
  getHolidays,
  createHoliday,
  editHoliday,
  deleteHoliday,
  getHolidayStatus,
} from '../redux/api/actions'
import validate from '../utils/validators/holidayValidator'
import promiseMap from '../utils/promiseMap'

const FORM_NAME = 'holiday'

const generateCode = (name, date) => {
  const baseName = name.toLowerCase().replaceAll(' ', '-')
  const year = date.format('YYYY')

  return `${baseName}-${year}`
}

const styles = theme => ({
  root: {
    flex: '1 0 auto',
  },
  container: {
    margin: theme.spacing.unit * 3,
  },
  content: {
    padding: theme.spacing.unit * 3,
  },
  toolbar: {
    display: 'flex',
    justiftContent: 'space-between',
  },
  title: {
    flex: 1,
  },
  cta: {
    marginLeft: theme.spacing(2),
  },
  ctaProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  ctaIcon: {
    marginRight: theme.spacing(),
  },
  formField: {
    flex: 1,
    marginBottom: theme.spacing.unit * 3,
  },
  formFieldDate: {
    flex: 1,
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit * 3,
  },
  formFieldsHorizontal: {
    display: 'flex',
    flexDirection: 'row',
    '& > :not(:first-child)': {
      marginLeft: theme.spacing(3),
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      '& > :not(:first-child)': {
        marginLeft: 0,
      },
    },
  },
  toggle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    marginLeft: theme.spacing(-1),
  },
  greenText: {
    color: 'green',
  },
  redText: {
    color: 'red',
  },
})

class Holiday extends Component {
  constructor(props) {
    super(props)

    const state = {
      loading: false,
      deleteLoading: false,
    }

    if (props.isNew) {
      state.date = moment.tz('Asia/Tokyo')
    } else if (props.holiday) {
      state.date = moment.tz(props.holiday.startDate, 'Asia/Tokyo')
    }

    this.state = state
  }

  hasLoaded = () => this.props.holidays.loaded && this.props.holidayStatus && this.props.holidayStatus.loaded

  onDateChange = date => {
    this.setState({
      date: moment(date),
    })
  }

  onClickDelete = async () => {
    this.setState({ deleteLoading: true })

    await this.props.deleteHoliday(this.props.match.params.holidayId)
    await this.props.getHolidays()

    this.props.history.push('/holidays')
  }

  onClickShopStatus = shopStatuses => {
    this.props.history.push(`/shops/${shopStatuses.id}`)
  }

  onClickSave = async values => {
    this.setState({ loading: true })

    if (this.props.isNew) {
      const code = generateCode(values.name, this.state.date)

      const newHoliday = await this.props.createHoliday({
        ...values,
        code,
        date: this.state.date.format('YYYYMMDD'),
      })

      await this.props.getHolidays()

      window.location = `/holidays/${newHoliday.id}`
    } else {
      await this.props.editHoliday(this.props.holidayId, {
        ...values,
        date: this.state.date.format('YYYYMMDD'),
      })

      await this.props.getHolidays()
      this.setState({ loading: false })
    }
  }

  async componentWillMount() {
    const {
      holidays
    } = await promiseMap({
      profile: this.props.getProfile(),
      holidays: this.props.getHolidays(),
    })

    if (!this.props.isNew) {
      this.props.getHolidayStatus(this.props.holidayId)

      const holiday = holidays.find(({ id }) => id === parseInt(this.props.holidayId, 10))

      this.setState({
        date: moment.tz(holiday.startDate, 'Asia/Tokyo')
      })
    }
  }

  render() {
    let shopStatuses = []

    if (this.props.holidayStatus && this.props.holidayStatus.data) {
      shopStatuses = this.props.holidayStatus.data.shops
    }

    return (
      <div className={this.props.classes.root}>
        <Paper className={this.props.classes.container}>
          <Toolbar className={this.props.classes.toolbar}>
            <div className={this.props.classes.title}>
              <Typography variant="h4">
                {this.props.name}
              </Typography>
            </div>
            {!this.props.isNew && (
              <Button
                variant="contained"
                color="secondary"
                disabled={this.state.loading || this.state.deleteLoading}
                onClick={this.onClickDelete}>
                {this.state.deleteLoading && (
                  <CircularProgress
                    size={24}
                    color="secondary"
                    className={this.props.classes.ctaProgress} />
                )}
                <DeleteRoundedIcon className={this.props.classes.ctaIcon} />
                Delete
              </Button>
            )}
            <Button
              variant="contained"
              color="primary"
              disabled={this.props.pristine || this.props.invalid || this.state.loading || this.state.deleteLoading}
              className={this.props.classes.cta}
              onClick={this.props.handleSubmit(this.onClickSave)}>
              {this.state.loading && (
                <CircularProgress
                  size={24}
                  color="primary"
                  className={this.props.classes.ctaProgress} />
              )}
              Save
            </Button>
          </Toolbar>
          <div className={this.props.classes.content}>
            <form>
              <div className={this.props.classes.formFieldsHorizontal}>
                <Field
                  name="name"
                  label="Name"
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                  component={TextField}
                  className={this.props.classes.formField} />
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  label="Date"
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  value={this.state.date}
                  className={this.props.classes.formFieldDate}
                  onChange={this.onDateChange} />
              </div>
              <FormControl className={this.props.classes.toggle}>
                <Field
                  name="disableDelivery"
                  color="primary"
                  component={Switch} />
                <Typography variant="caption">
                  Disable delivery
                </Typography>
              </FormControl>
            </form>
          </div>
        </Paper>
        {!this.props.isNew && (
          <Paper className={this.props.classes.container}>
            <Toolbar className={this.props.classes.toolbar}>
              <div className={this.props.classes.title}>
                <Typography variant="h4">
                  Responses
                </Typography>
              </div>
            </Toolbar>
            <div className={this.props.classes.tableWrapper}>
              <Table className={this.props.classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>Id</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Has responded</TableCell>
                    <TableCell>Has holiday</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {shopStatuses.map(shopStatus => (
                    <TableRow
                      key={shopStatus.id}
                      hover
                      onClick={() => this.onClickShopStatus(shopStatus)}>
                      <TableCell>{shopStatus.id}</TableCell>
                      <TableCell>{shopStatus.name}</TableCell>
                      <TableCell>{shopStatus.seenDialog ? (<span className={this.props.classes.greenText}>✓</span>) : (<span className={this.props.classes.redText}>✗</span>)}</TableCell>
                      <TableCell>{shopStatus.hasHoliday ? (<span className={this.props.classes.greenText}>✓</span>) : (<span className={this.props.classes.redText}>✗</span>)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </Paper>
        )}
      </div>
    )
  }
}

const selector = formValueSelector(FORM_NAME)

const mapStateToProps = (state, ownProps) => {
  let initialValues
  let holiday
  let holidayStatus
  const holidayId = ownProps.match && ownProps.match.params.holidayId

  if (holidayId && state.api.holidays.default.data) {
    holiday = state.api.holidays.default.data.find(({ id }) => id === parseInt(holidayId, 10))

    if (holiday) {
      initialValues = {
        name: holiday.name,
        code: holiday.code,
        disableDelivery: holiday.disableDelivery,
      }
    }

    holidayStatus = state.api.holidayStatus[holidayId]
  }

  return {
    isNew: ownProps.match && holidayId === 'new',
    holidays: state.api.holidays.default,
    holiday,
    holidayId,
    holidayStatus,
    initialValues,
    name: selector(state, 'name'),
  }
}

const mapDispatchToProps = {
  getProfile,
  getHolidays,
  createHoliday,
  editHoliday,
  deleteHoliday,
  getHolidayStatus,
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: FORM_NAME,
  validate,
  enableReinitialize: true,
})(withRouter(withStyles(styles)(Holiday))))


