import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import classNames from 'classnames'
import {
  Field,
  reduxForm,
  formValueSelector,
  SubmissionError,
  reset,
} from 'redux-form'
import {
  withRouter,
} from 'react-router-dom'
import ReactMapGL, {
  Source,
  Layer,
  FlyToInterpolator,
} from 'react-map-gl'
import {
  TextField,
  Select,
} from 'redux-form-material-ui'
import Paper from '@material-ui/core/Paper'
import Toolbar from '@material-ui/core/Toolbar'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import DeleteIcon from '@material-ui/icons/Delete'
import {
  // createOrder,
  getOrder,
  editOrder,
  deleteOrder,
} from '../redux/api/actions'
import OrderItem from '../components/OrderItem'
import formatNumberWithCommas from '../utils/formatNumberWithCommas'
import getViewportForCoordinates from '../utils/getViewportForCoordinates'
import validate from '../utils/validators/orderValidator'
import {
  orderTypes,
  deliveryProviders,
} from '../utils/Constants'
import Colors from '../utils/Colors'

const FORM_NAME = 'order'

const DELIVERY_PROVIDERS = [{
  id: deliveryProviders.TODOKU,
  label: 'Todocu',
}, {
  id: deliveryProviders.ANYCARRY,
  label: 'AnyCarry',
}]

const getLineItemValueText = lineItem => {
  if (lineItem.type === 'ticket') {
    return `${formatNumberWithCommas(lineItem.value)}枚`
  } else if (['yen', 'grand-total-yen'].includes(lineItem.type)) {
    return `￥${formatNumberWithCommas(lineItem.value)}`
  }

  return formatNumberWithCommas(lineItem.value)
}

const ConfirmationDialog = withStyles(theme => ({
  ctaProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}))(({ classes, loading, onClose, onConfirm, ...rest }) => (
  <Dialog
    {...rest}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    onClose={onClose}>
    <DialogTitle id="alert-dialog-title">
      Are you sure?
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        This will delete the order and refund the user their tickets and money.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button
        disabled={loading}
        onClick={onClose}>Cancel</Button>
      <Button
        disabled={loading}
        color="primary"
        onClick={onConfirm}>
        {loading && (
          <CircularProgress
            size={24}
            color="primary"
            className={classes.ctaProgress} />
        )}
        OK
      </Button>
    </DialogActions>
  </Dialog>
))

const styles = theme => ({
  container: {
    margin: theme.spacing(3),
  },
  paper: {
    marginBottom: theme.spacing(3),
    maxWidth: 500,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit,
    },
  },
  button: {
    marginRight: theme.spacing.unit,
  },
  title: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingTop: theme.spacing.unit * 3,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  orderId: {
    marginLeft: theme.spacing.unit / 2,
    fontWeight: 'bold',
  },
  orderStatus: {
    color: 'red',
    fontWeight: 'bold',
  },
  actions: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  subtitle: {
    marginBottom: theme.spacing.unit,
  },
  content: {
    padding: theme.spacing.unit * 3,
  },
  errorText: {
    color: 'red',
    marginBottom: theme.spacing.unit * 3,
  },
  formField: {
    marginBottom: theme.spacing.unit * 3,
  },
  formFieldSelect: {
    minWidth: 180,
  },
  receipt: {
    marginBottom: theme.spacing.unit * 3,
  },
  lineItems: {
    backgroundColor: Colors.backgroundGray,
    padding: theme.spacing.unit * 2,
  },
  lineItem: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
  },
  lineItemSeparator: {
    borderBottom: `1px solid ${Colors.borderGray}`,
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  lineItemLabel: {
    lineHeight: '21px',
    fontSize: '14px',
    fontWeight: '700',
    color: Colors.primaryCharcoal,
  },
  lineItemValue: {
    lineHeight: '21px',
    fontSize: '14px',
    color: Colors.primaryCharcoal,
  },
  lineItemValueLinkTag: {
    textDecoration: 'none',
  },
  lineItemValueLink: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  }
})

class User extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      deleteLoading: false,
      showDeleteConfirmationDialog: false,
    }

    if (!props.isNew) {
      props.getOrder(props.match.params.id)
    }
  }

  recenterMap = () => {
    const map = this.mapRef && this.mapRef.getMap()

    if (!map) {
      return
    }

    let coordinates = [{
      latitude: this.props.order.data.shop.location.lat,
      longitude: this.props.order.data.shop.location.lon,
    }]

    if (this.props.order.data.orderType === orderTypes.DELIVERY && this.props.order.data.delivery.location) {
      coordinates.push({
        latitude: this.props.order.data.delivery.location.latitude,
        longitude: this.props.order.data.delivery.location.longitude,
      })
    }

    const mapViewport = getViewportForCoordinates({
      coordinates,
      height: 400,
      paddingBottomPx: 0,
    })

    this.setState({
      mapViewport: {
        ...this.state.mapViewport,
        ...mapViewport,
      },
    })
  }

  onViewportChange = mapViewport => {
    this.setState({
      mapViewport,
    })
  }

  onClickSubmit = async values => {
    this.setState({ loading: true })

    if (this.props.isNew) {
      const result = await this.props.createOrder(values)

      if (result && result.id) {
        window.location = `/orders/${result.id}`
      } else if (result && result.error) {
        this.setState({ loading: false })

        throw new SubmissionError({
          _error: result.error,
        })
      }
    } else {
      await this.props.editOrder(this.props.match.params.id, values)

      await this.props.getOrder(this.props.match.params.id)

      this.setState({ loading: false })
      this.props.dispatch(reset(FORM_NAME))
    }
  }

  onClickDelete = async () => {
    this.setState({
      showDeleteConfirmationDialog: true,
    })
  }

  onClickConfirmDelete = async () => {
    this.setState({
      deleteLoading: true,
    })

    await this.props.deleteOrder(this.props.match.params.id)

    this.props.history.goBack()
  }

  onClickCloseDeleteConfirmationDialog = () => {
    this.setState({
      showDeleteConfirmationDialog: false,
    })
  }

  componentDidUpdate(prevProps) {
    if (!(prevProps.order && prevProps.order.data) && (this.props.order && this.props.order.data)) {
      this.setState({
        mapViewport: {
          zoom: 15,
          latitude: this.props.order.data.shop.location.lat,
          longitude: this.props.order.data.shop.location.lon,
          transitionDuration: 500,
          transitionInterpolator: new FlyToInterpolator(),
        }
      }, this.recenterMap)
    }
  }

  render() {
    if (!this.props.order || this.props.order.loading) {
      return (
        <div className={this.props.classes.container}>
          Loading...
        </div>
      )
    }

    const {
      handleSubmit,
      pristine,
      invalid,
      error,
    } = this.props

    let topLineParts = []

    if (this.props.order && this.props.order.data) {
      topLineParts = [(
        <Typography
          key="order-number"
          variant="h4">
          Order #{this.props.order.data.number}
        </Typography>
      ), (
        <Typography
          key="id"
          variant="body1"
          className={this.props.classes.orderId}>
          ID: {this.props.order.data.id}
        </Typography>
      )]

      if (this.props.order.data.canceled) {
        topLineParts.push(
          <Typography
            key="id"
            variant="body1"
            className={this.props.classes.orderStatus}>
            Canceled late
          </Typography>
        )
      }
    }

    return (
      <div className={this.props.classes.container}>
        <ConfirmationDialog
          open={this.state.showDeleteConfirmationDialog}
          loading={this.state.deleteLoading}
          onClose={this.onClickCloseDeleteConfirmationDialog}
          onConfirm={this.onClickConfirmDelete} />
        <Paper className={this.props.classes.paper}>
          <Toolbar className={this.props.classes.toolbar}>
            <div className={this.props.classes.title}>
              {topLineParts}
            </div>
            <div className={this.props.classes.actions}>
              <Button
                variant="contained"
                color="primary"
                className={this.props.classes.button}
                disabled={pristine || invalid || this.state.loading}
                onClick={handleSubmit(this.onClickSubmit)}>
                登録する
              </Button>
              {!this.props.isNew && (
                <IconButton
                  color="primary"
                  disabled={this.props.order && this.props.order.data && this.props.order.data.canceled}
                  onClick={this.onClickDelete}>
                  <DeleteIcon />
                </IconButton>
              )}
            </div>
          </Toolbar>
          <form className={this.props.classes.content}>
            {error && (
              <Typography
                variant="body2"
                className={this.props.classes.errorText}>
                {error}
              </Typography>
            )}
            <Field
              disabled
              name="shop.name"
              label="Shop"
              variant="outlined"
              autoComplete="off"
              fullWidth
              component={TextField}
              className={this.props.classes.formField} />
            <Field
              disabled
              name="userName"
              label="User"
              variant="outlined"
              autoComplete="off"
              fullWidth
              component={TextField}
              className={this.props.classes.formField} />
            {this.props.order && this.props.order.data && this.props.order.data.receipt && [(
              <div
                key="food-items"
                className={this.props.classes.receipt}>
                <Typography
                  variant="h6"
                  className={this.props.classes.subtitle}>
                  Food items
                </Typography>
                {this.props.order.data.receipt.foodItems.map((foodItem, index) => (
                  <OrderItem
                    key={index}
                    item={foodItem}
                    className={{
                      [this.props.classes.orderItemMarginBottom]: index < this.props.order.data.receipt.foodItems.length - 1,
                    }} />
                ))}
              </div>
            ), (
              <div
                key="receipt"
                className={this.props.classes.receipt}>
                <Typography
                  variant="h6"
                  className={this.props.classes.subtitle}>
                  Receipt
                </Typography>
                <div className={this.props.classes.lineItems}>
                  {this.props.order.data.receipt.lineItems.map((lineItem, index) => {
                    const parts = []
                    const isGrandTotal = lineItem.type === 'grand-total-yen'

                    if (isGrandTotal) {
                      parts.push(
                        <div
                          key={`${index}-separator`}
                          className={this.props.classes.lineItemSeparator} />
                      )
                    }

                    parts.push(
                      <div
                        key={`${index}-item`}
                        className={this.props.classes.lineItem}>
                        <div className={this.props.classes.lineItemLabel}>
                          {lineItem.label}
                        </div>
                        {(isGrandTotal && this.props.order.data.receipt.charge) ? (
                          <a
                            href={`https://pay.jp/d/charges/${this.props.order.data.receipt.charge.id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={this.props.classes.lineItemValueLinkTag}>
                            <div className={classNames([this.props.classes.lineItemValue, this.props.classes.lineItemValueLink])}>
                              {getLineItemValueText(lineItem)}
                            </div>
                          </a>
                        ) : (
                          <div className={this.props.classes.lineItemValue}>
                            {getLineItemValueText(lineItem)}
                          </div>
                        )}
                      </div>
                    )

                    return parts
                  })}
                </div>
              </div>
            )]}
            {this.props.order && this.props.order.data && this.props.order.data.orderType === orderTypes.DELIVERY && (
              <div className={this.props.classes.delivery}>
                <Typography
                  variant="h6"
                  className={this.props.classes.subtitle}>
                  Delivery
                </Typography>
                <FormControl variant="outlined">
                  <InputLabel htmlFor="delivery.provider">
                    Delivery Partner
                  </InputLabel>
                  <Field
                    name="delivery.provider"
                    variant="outlined"
                    component={Select}
                    className={classNames([this.props.classes.formField, this.props.classes.formFieldSelect])}>
                    {DELIVERY_PROVIDERS.map(deliveryProvider => (
                      <MenuItem
                        key={deliveryProvider.id}
                        value={deliveryProvider.id}>
                        {deliveryProvider.label}
                      </MenuItem>
                    ))}
                  </Field>
                </FormControl>
                {this.state.mapViewport && (
                  <ReactMapGL
                    {...this.state.mapViewport}
                    ref={ref => this.mapRef = ref}
                    mapboxApiAccessToken="pk.eyJ1IjoicG90bHVja2hxIiwiYSI6ImNqeW03OHc3bjBnNTkzbHJvNHVtemhjNW4ifQ._9iIJ5jy_RzZTeks86lQBw"
                    mapStyle="mapbox://styles/potluckhq/cjym7i4e00vom1clbjz7at3an"
                    width="100%"
                    height="400px"
                    className={this.props.classes.map}
                    onViewportChange={this.onViewportChange}>
                    {this.props.order && this.props.order.data && this.props.order.data.deliveryRoute && (
                      <Source
                        id="route"
                        type="geojson"
                        data={{
                          type: 'FeatureCollection',
                          features: [{
                            id: 'route',
                            type: 'Feature',
                            geometry: {
                              type: 'LineString',
                              coordinates: this.props.order.data.deliveryRoute,
                            },
                          }]
                        }}>
                        <Layer
                          id="route-layer"
                          type="line"
                          layout={{
                            'line-join': 'round',
                            'line-cap': 'round'
                          }}
                          paint={{
                            'line-color': Colors.primaryPink,
                            'line-width': 4
                          }} />
                      </Source>
                    )}
                    <Source
                      id="shop-location"
                      type="geojson"
                      data={{
                        type: 'FeatureCollection',
                        features: [{
                          id: this.props.order.data.shop.id,
                          type: 'Feature',
                          geometry: {
                            type: 'Point',
                            coordinates: [this.props.order.data.shop.location.lon, this.props.order.data.shop.location.lat],
                          },
                        }]
                      }}>
                      <Layer
                        id="shop-location-layer"
                        type="symbol"
                        layout={{
                          'icon-image': 'shop-marker-red',
                          'icon-anchor': 'bottom',
                        }} />
                    </Source>
                    <Source
                      id="user-location"
                      type="geojson"
                      data={{
                        type: 'FeatureCollection',
                        features: [{
                          id: this.props.order.data.userId,
                          type: 'Feature',
                          geometry: {
                            type: 'Point',
                            coordinates: [this.props.order.data.delivery.location.longitude, this.props.order.data.delivery.location.latitude],
                          },
                        }]
                      }}>
                      <Layer
                        id="user-location-layer"
                        type="symbol"
                        layout={{
                          'icon-image': 'user-location',
                          'icon-anchor': 'bottom',
                        }} />
                    </Source>
                  </ReactMapGL>
                )}
              </div>
            )}
          </form>
        </Paper>
      </div>
    )
  }
}

const selector = formValueSelector(FORM_NAME)

const mapStateToProps = (state, ownProps) => {
  const props = {
    isNew: ownProps.match.params.id === 'new',
    name: selector(state, 'name'),
    order: state.api.order[ownProps.match.params.id],
  }

  if (!props.isNew && props.order && props.order.data) {
    props.initialValues = {
      ...props.order.data
    }

    if (props.order.data.delivery && !props.order.data.delivery.provider) {
      props.initialValues.delivery.provider = deliveryProviders.TODOKU
    }
  }

  return props
}

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    // createOrder,
    getOrder,
    editOrder,
    deleteOrder,
  }, dispatch),
  dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: FORM_NAME,
  validate,
  enableReinitialize: true,
})(withRouter(withStyles(styles)(User))))
