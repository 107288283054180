import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { debounce } from 'throttle-debounce'
import { connect } from 'react-redux'
import clone from 'clone'
import {
  Field,
  reduxForm,
  formValueSelector,
  SubmissionError,
  reset,
} from 'redux-form'
import {
  withRouter,
} from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { TextField } from 'redux-form-material-ui'
import Paper from '@material-ui/core/Paper'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Input from '@material-ui/core/Input'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import LaunchRoundedIcon from '@material-ui/icons/LaunchRounded'
import AddIcon from '@material-ui/icons/Add'
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded'
import {
  createOffer,
  getOffer,
  editOffer,
  deleteOffer,
  searchProducts,
} from '../redux/api/actions'
import validate from '../utils/validators/offerValidator'
import EditablePlanPackage from '../components/EditablePlanPackage'

const FORM_NAME = 'offer'

const DEFAULT_NEW_PACKAGE = {
  copy: {
    title: 'Title',
    subtitle: 'Subtitle',
    cta: '購入に進む',
  },
  products: [],
  disclaimers: [
    '※ Disclaimer',
  ],
}

const DeleteConfirmationDialog = withStyles({
})(({ classes, open, onClose, onClickYes }) => (
  <Dialog
    open={open}
    keepMounted
    onClose={onClose}>
    <DialogTitle>Are you sure?</DialogTitle>
    <DialogContent>
      <DialogContentText>
        This will make the offer unavailable to new customers. It will not affect any existing customers.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        No
      </Button>
      <Button onClick={onClickYes} color="primary">
        Yes
      </Button>
    </DialogActions>
  </Dialog>
))

const ProductDialog = withStyles(theme => ({
  intput: {
    width: '100%',
  },
  inline: {
    display: 'inline',
  },
}))(({ open, loading, products, classes, handleSearch, handleSelect, handleClose }) => (
  <Dialog
    open={open}
    onClose={handleClose}
    fullWidth
    maxWidth="sm"
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description">
    <DialogContent>
      <Input
        autoFocus
        fullWidth
        placeholder="Search products"
        className={classes.input}
        onChange={handleSearch} />
      <List>
        {products.map(product => (
          <ListItem
            key={product.id}
            button
            onClick={() => handleSelect(product)}>
            <ListItemText
              primary={product.name}
              secondary={
                 <React.Fragment>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.inline}
                    color="textPrimary">
                    {product.type}
                  </Typography>
                  {` - ${product.code}`}
                </React.Fragment>
              } />
          </ListItem>
        ))}
      </List>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="primary">
        Cancel
      </Button>
    </DialogActions>
  </Dialog>
))

const styles = theme => ({
  container: {
    margin: theme.spacing(3),
  },
  paper: {
    marginBottom: theme.spacing(3),
  },
  toolbar: {
    display: 'flex',
    justiftContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  title: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing.unit * 3,
  },
  actions: {

  },
  content: {
    padding: theme.spacing.unit * 3,
  },
  errorText: {
    color: 'red',
    marginBottom: theme.spacing.unit * 3,
  },
  formField: {
    marginBottom: theme.spacing.unit * 3,
  },
  formFieldSmall: {
    width: 110,
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  formFieldsHorizontal: {
    display: 'flex',
    flexDirection: 'row',
    '& > :not(:first-child)': {
      marginLeft: theme.spacing(3),
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      '& > :not(:first-child)': {
        marginLeft: 0,
      },
    },
  },
  formFieldsPassword: {
    display: 'flex',
    flexDirection: 'row',
    '& > :not(:first-child)': {
      marginLeft: theme.spacing(3),
    },
  },
  titleLink: {
    marginLeft: theme.spacing.unit,
    cursor: 'pointer',
  },
  button: {
    margin: theme.spacing.unit,
    marginTop: theme.spacing.unit * 2,
  },
  progress: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    zIndex: 10,
  },
  toggle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    marginLeft: theme.spacing(-1),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  noneText: {
    margin: theme.spacing(2, 0),
    textAlign: 'center',
  },
  greenText: {
    color: 'green',
  },
  redText: {
    color: 'red',
  },
  ctaSmall: {
    padding: 0,
  },
  addPackageButton: {
    maxWidth: 340,
    margin: '0 auto',
    padding: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit * 8,
  },
})

class Offer extends Component {
  state = {
    showDeleteConfirmationDialog: false,
    editingPackageIndex: null,
    productsText: null,
    productDialogOpen: false,
    loading: false,
    packages: [],
  }

  hasLoaded = () => {
    return this.props.isNew || (this.props.offer && this.props.offer.loaded)
  }

  onClickLaunch = () => {
    const isDev = window.location.href.includes('dev.') || window.location.href.includes('localhost')
    
    if (isDev) {
      window.open(`https://dev.pot-luck.jp/signup/offer/${this.props.offer.data.code}`, '_blank')
    } else {
      window.open(`https://www.pot-luck.jp/signup/offer/${this.props.offer.data.code}`, '_blank')
    }
  }

  onClickDelete = () => {
    this.setState({
      showDeleteConfirmationDialog: true,
    })
  }

  onClickCloseDeleteConfirmationDialog = () => {
    this.setState({
      showDeleteConfirmationDialog: false,
    })
  }

  onClickConfirmDelete = async () => {
    this.setState({
      saving: true,
      showDeleteConfirmationDialog: false,
    })

    await this.props.deleteOffer(this.props.match.params.offerId)
    this.props.history.push('/offers-and-products')
  }

  onClickOpenProductDialog = editingPackageIndex => {
    this.setState({
      editingPackageIndex,
      productDialogOpen: true,
    })
  }

  onClickCloseProductDialog = () => {
    this.setState({
      productDialogOpen: false,
    })
  }

  refreshProductData = debounce(750, () => {
    this.props.searchProducts({
      text: this.state.productsText,
      page: 0,
    })
  })

  onChangeProductSearch = event => {
    let productsText = event.currentTarget.value

    if (productsText === '') {
      productsText = null
    }

    this.setState({
      productsText,
    })

    this.refreshProductData()
  }

  onAddProduct = product => {
    if (this.state.packages[this.state.editingPackageIndex]) {
      const packageToEdit = this.state.packages[this.state.editingPackageIndex]

      if(!packageToEdit.products) {
        packageToEdit.products = []
      }
      packageToEdit.products.push(product)

      this.setState({
        editingPackageIndex: null,
        productDialogOpen: false,
        packages: [...this.state.packages.slice(0, this.state.editingPackageIndex), packageToEdit, ...this.state.packages.slice(this.state.editingPackageIndex + 1, this.state.packages.length)]
      })
    }
  }

  onClickCloseProductDialog = () => {
    this.setState({
      editingPackageIndex: null,
      productDialogOpen: false,
    })
  }

  onClickAddPackage = () => {
    this.setState({
      packages: [...this.state.packages, DEFAULT_NEW_PACKAGE],
    })
  }

  onEditPackage = (value, index) => {
    this.setState({
      packages: [...this.state.packages.slice(0, index), value, ...this.state.packages.slice(index + 1, this.state.packages.length)]
    })
  }

  onClickSubmit = async values => {
    this.setState({ loading: true })

    if (this.props.isNew) {
      const result = await this.props.createOffer({
        ...values,
        packages: this.state.packages.map(({ copy, disclaimers, products }) => ({
          copy,
          disclaimers,
          products: products.map(product => product.code),
        })),
      })

      if (result && result.id) {
        window.location = `/offers/${result.id}`
      } else if (result && result.error) {
        this.setState({ loading: false })

        throw new SubmissionError({
          _error: result.error,
        })
      }
    } else {
      await this.props.editOffer(this.props.match.params.offerId, {
        ...values,
        packages: this.state.packages.map(({ copy, disclaimers, products }) => ({
          copy,
          disclaimers,
          products: products.map(product => product.code),
        })),
      })

      const offer = await this.props.getOffer(this.props.match.params.offerId)

      this.setState({
        loading: false,
        packages: clone(offer.packages),
      })

      this.props.dispatch(reset(FORM_NAME))
    }
  }

  async componentWillMount() {
    this.props.searchProducts({
      text: this.state.productsText,
      page: 0,
    })

    if (this.props.isNew) {
      this.setState({
        packages: [clone(DEFAULT_NEW_PACKAGE)],
      })
    } else {
      const offer = await this.props.getOffer(this.props.match.params.offerId)

      if (offer) {
        this.setState({
          packages: clone(offer.packages),
        })
      }
    }
  }

  render() {
    if (!this.hasLoaded()) {
      return false
    }

    const {
      handleSubmit,
      pristine,
      invalid,
      error,
    } = this.props

    let productsData = {
      products: [],
      page: 0,
      numProducts: 0,
      numPages: 1,
    }

    if (this.props.productsSearch && this.props.productsSearch.data) {
      productsData = this.props.productsSearch.data
    }

    const currentProductsKey = `text:${this.state.productsText}-page:${this.state.productsPage}`
    let productsLoading = (this.props.productsSearch && this.props.productsSearch.loading) || currentProductsKey !== this.props.productsSearchKey

    let isPackagesDirty = false

    if (this.props.isNew || (this.props.offer.data && JSON.stringify(this.state.packages) !== JSON.stringify(this.props.offer.data.packages))) {
      isPackagesDirty = true
    }

    return (
      <div className={this.props.classes.container}>
        <DeleteConfirmationDialog
          open={this.state.showDeleteConfirmationDialog}
          onClose={this.onClickCloseDeleteConfirmationDialog}
          onClickYes={this.onClickConfirmDelete} />
        <ProductDialog
          open={this.state.productDialogOpen}
          loading={productsLoading}
          products={productsData.products}
          handleSearch={this.onChangeProductSearch}
          handleSelect={this.onAddProduct}
          handleClose={this.onClickCloseProductDialog} />
        <Paper className={this.props.classes.paper}>
          <Toolbar className={this.props.classes.toolbar}>
            <div className={this.props.classes.title}>
              <Typography variant="h4">
                {this.props.isNew ? 'New Offer' : this.props.code}
                {!this.props.isNew && (
                  <IconButton
                    color="primary"
                    aria-label="View"
                    onClick={this.onClickLaunch}>
                    <LaunchRoundedIcon />
                  </IconButton>
                )}
              </Typography>
            </div>
            <div className={this.props.classes.actions}>
              {!this.props.isNew && (
                <IconButton
                  color="primary"
                  onClick={this.onClickDelete}>
                  <DeleteRoundedIcon />
                </IconButton>
              )}
              <Button
                variant="contained"
                color="primary"
                className={this.props.classes.button}
                disabled={invalid || this.state.loading || (pristine && !isPackagesDirty)}
                onClick={handleSubmit(this.onClickSubmit)}>
                登録する
              </Button>
            </div>
          </Toolbar>
          <form className={this.props.classes.content}>
            {error && (
              <Typography
                variant="body2"
                className={this.props.classes.errorText}>
                {error}
              </Typography>
            )}
            <Field
              name="code"
              label="Code"
              variant="outlined"
              autoComplete="off"
              fullWidth
              component={TextField}
              className={this.props.classes.formField} />
            {this.state.packages.map((offerPackage, index) => (
              <EditablePlanPackage
                key={index}
                offerPackage={offerPackage}
                onEdit={value => this.onEditPackage(value, index)}
                onClickAddProduct={() => this.onClickOpenProductDialog(index)} />
            ))}
            <div className={this.props.classes.addPackageButton}>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                onClick={this.onClickAddPackage}>
                <AddIcon />
              </Button>
            </div>
          </form>
        </Paper>
      </div>
    )
  }
}

const selector = formValueSelector(FORM_NAME)

const mapStateToProps = (state, ownProps) => {
  const props = {
    isNew: ownProps.match.params.offerId === 'new',
    code: selector(state, 'code'),
    offer: state.api.offer[ownProps.match.params.offerId],
    productsSearch: state.api.productsSearch[state.api.productsSearch.lastReceivedKey],
    productsSearchKey: state.api.productsSearch.lastReceivedKey,
  }

  if (props.offer && props.offer.data) {
    props.initialValues = {
      code: props.offer.data.code,
      deleted: props.offer.data.deleted,
    }
  }

  return props
}

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    createOffer,
    getOffer,
    editOffer,
    deleteOffer,
    searchProducts,
  }, dispatch),
  dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: FORM_NAME,
  validate,
  enableReinitialize: true,
})(withRouter(withStyles(styles)(Offer))))
