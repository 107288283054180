import {
  SET_FLAG,
  SET_REDIRECT_TO,
} from './actions'

const getInitialState = () => ({
  flags: {
    googlePlacesLoaded: false,
  },
})

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case SET_FLAG: {
      return {
        ...state,
        flags: {
          ...state.flags,
          [action.name]: action.value,
        }
      }
    }
    case SET_REDIRECT_TO: {
      return {
        ...state,
        redirectTo: action.redirectTo,
      }
    }
    default:
      return state
  }
}
