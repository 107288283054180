import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import {
  Field,
  reduxForm,
  SubmissionError,
} from 'redux-form'
import {
  TextField,
} from 'redux-form-material-ui'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import {
  resetPasswordShopUser,
} from '../redux/api/actions'
import validate from '../utils/validators/resetPasswordValidator'

const styles = theme => ({
  container: {
    margin: theme.spacing(3),
  },
  paper: {
    marginBottom: theme.spacing(3),
  },
  content: {
    padding: theme.spacing.unit * 3,
  },
  toolbar: {
    display: 'flex',
    justiftContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  title: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing.unit * 3,
  },
  formField: {
    marginBottom: theme.spacing.unit * 3,
  },
  button: {
    margin: theme.spacing.unit,
    marginTop: theme.spacing.unit * 2,
  },
  redText: {
    color: 'red',
  },
})

class ShopUserResetPassword extends Component {
  state = {
    loading: false,
  }

  onClickSubmit = async values => {
    this.setState({ loading: true })

    const reset = await this.props.resetPasswordShopUser(this.props.match.params.shopUserId, values.password)

    this.setState({ loading: true })

    if (reset && reset.reset) {
      throw new SubmissionError({
        _error: 'Something went wrong',
      })
    } else {
      this.props.history.push(`/shop-users/${this.props.match.params.shopUserId}`)
    }
  }

  render() {
    const {
      handleSubmit,
      pristine,
      invalid,
      error,
    } = this.props

    return (
      <div className={this.props.classes.container}>
        <Paper className={this.props.classes.paper}>
          <Toolbar className={this.props.classes.toolbar}>
            <div className={this.props.classes.title}>
              <Typography
                variant="h4"
                className={this.props.classes.title}>
                Reset Password
              </Typography>
            </div>
            <div className={this.props.classes.actions}>
              <Button
                variant="contained"
                color="primary"
                className={this.props.classes.button}
                disabled={(pristine || invalid || this.state.loading)}
                onClick={handleSubmit(this.onClickSubmit)}>
                登録する
              </Button>
            </div>
          </Toolbar>
          <form className={this.props.classes.content}>
            {error && (
              <Typography
                variant="body2"
                className={this.props.classes.redText}>
                {error}
              </Typography>
            )}
            <Field
              name="password"
              type="password"
              variant="outlined"
              autoComplete="off"
              label="New password"
              fullWidth
              component={TextField}
              className={this.props.classes.formField} />
            <Field
              name="confirmPassword"
              type="password"
              variant="outlined"
              autoComplete="off"
              label="Confirm new password"
              fullWidth
              component={TextField}
              className={this.props.classes.formField} />
          </form>
        </Paper>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = {
  resetPasswordShopUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'shop-user-reset-password',
  validate,
})(withRouter(withStyles(styles)(ShopUserResetPassword))))
