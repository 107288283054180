import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  withRouter,
} from 'react-router-dom'
import moment from 'moment-timezone'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import CircularProgress from '@material-ui/core/CircularProgress'
import SaveRoundedIcon from '@material-ui/icons/SaveRounded'
import {
  getDeliveryZones,
  createDeliveryZone,
  editDeliveryZone,
  deleteDeliveryZone,
} from '../redux/api/actions'
import canUseDOM from '../utils/canUseDOM'

let ReactMapboxGl
let DrawControl

let MapBoxMap

if (canUseDOM()) {
  const MapBox = require('react-mapbox-gl')

  ReactMapboxGl = MapBox.default

  const DrawControlImport = require('react-mapbox-gl-draw')
  DrawControl = DrawControlImport.default

  MapBoxMap = ReactMapboxGl({
    accessToken: 'pk.eyJ1IjoicG90bHVja2hxIiwiYSI6ImNqeW03OHc3bjBnNTkzbHJvNHVtemhjNW4ifQ._9iIJ5jy_RzZTeks86lQBw',
    injectCSS: false,
  })
}

const styles = theme => ({
  root: {
    flex: '1 0 auto',
  },
  content: {
    margin: theme.spacing.unit * 3,
    position: 'relative',
  },
  toolbar: {
    display: 'flex',
  },
  title: {
    flex: '1',
  },
  actions: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  map: {
    height: 400,
    width: '100%'
  },
  table: {
    marginTop: theme.spacing(3),
  },
  progressCell: {
    height: 140,
    position: 'relative',
  },
  progress: {
    position: 'absolute',
    left: 'calc(50% - 20px)',
    top: 'calc(50% - 20px)',
  },
  noneCell: {
    textAlign: 'center',
  },
})

class DeliveryZones extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      isDirty: false,
      lastEditedFeature: null,
      initialDeliveryZones: [],
    }

    this.loadData()
  }

  loadData = async () => {
    const deliveryZones = await this.props.getDeliveryZones()

    this.setState({
      loading: false,
      initialDeliveryZones: deliveryZones,
    })
  }

  _setDrawRef = ref => {
    this.drawRef = ref
    this.onDrawReady()
  }

  onDrawReady() {
    const featureIdMap = {}

    if (this.drawRef){
      for (let deliveryZone of this.state.initialDeliveryZones) {
        const ids = this.drawRef.draw.add({
          type: 'Polygon',
          coordinates: [deliveryZone.coordinates.map(coordinate => ([coordinate.longitude, coordinate.latitude]))],
        })

        featureIdMap[deliveryZone.id] = ids[0]
      }

      this.setState({
        initialDeliveryZones: this.state.initialDeliveryZones.map(deliveryZone => ({
          ...deliveryZone,
          featureId: featureIdMap[deliveryZone.id],
        }))
      })
    }
  }

  onDrawSelectionChange = () => {
    const selected = this.drawRef.draw.getSelected()

    if (selected && selected.features && selected.features.length === 0) {
      this.setState({
        isDirty: true,
      })
    }
  }

  onDrawDelete = () => {
    this.setState({
      isDirty: true,
    })
  }

  onClickSave = async () => {
    const promises = []

    const {
      features,
    } = this.drawRef.draw.getAll()

    const deliveryZones = features.map(feature => ({
      featureId: feature.id,
      coordinates: feature.geometry.coordinates[0].map(coordinate => ({
        latitude: coordinate[1],
        longitude: coordinate[0],
      }))
    }))

    for (let deliveryZone of deliveryZones) {
      const existingZone = this.state.initialDeliveryZones.find(({ featureId }) => featureId === deliveryZone.featureId)
      if (!existingZone) {
        promises.push(this.props.createDeliveryZone({
          name: 'New zone',
          coordinates: deliveryZone.coordinates,
        }))
      } else if (JSON.stringify(deliveryZone.coordinates) !== JSON.stringify(existingZone.coordinates)) {
        promises.push(this.props.editDeliveryZone({
          id: existingZone.id,
          coordinates: deliveryZone.coordinates,
        }))
      }
    }

    for (let deliveryZone of this.state.initialDeliveryZones) {
      if (!deliveryZones.some(({ featureId }) => featureId === deliveryZone.featureId)) {
        promises.push(this.props.deleteDeliveryZone(deliveryZone.id))
      }
    }

    await Promise.all(promises)

    const initialDeliveryZones = await this.props.getDeliveryZones()

    this.setState({
      isDirty: false,
      initialDeliveryZones,
    })
  }

  render() {
    let deliveryZones = this.props.deliveryZones.data || []

    return (
      <div className={this.props.classes.root}>
        <Paper className={this.props.classes.content}>
          <Toolbar className={this.props.classes.toolbar}>
            <div className={this.props.classes.title}>
              <Typography variant="h6">
                Delivery zones
              </Typography>
            </div>
            <div className={this.props.classes.actions}>
              <Tooltip title="Save">
                <IconButton
                  aria-label="Save"
                  color="primary"
                  disabled={!this.state.isDirty}
                  onClick={this.onClickSave}>
                  <SaveRoundedIcon />
                </IconButton>
              </Tooltip>
            </div>
          </Toolbar>
          <div className={this.props.classes.map}>
            {canUseDOM() && !this.state.loading && (
              <MapBoxMap
                // eslint-disable-next-line react/style-prop-object
                style="mapbox://styles/mapbox/streets-v9"
                containerStyle={{
                  height: '100%',
                  width: '100%',
                }}
                center={[139.7016360, 35.6580340]}
                zoom={[13]}>
                <DrawControl
                  ref={this._setDrawRef}
                  controls={{
                    polygon: true,
                    trash: true,
                    point: false,
                    line_string: false,
                    combine_features: false,
                    uncombine_features: false,
                  }}
                  onDrawSelectionChange={this.onDrawSelectionChange}
                  onDrawDelete={this.onDrawDelete} />
              </MapBoxMap>
            )}
          </div>
          <div className={this.props.classes.table}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Created</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.loading && (
                  <TableRow>
                    <TableCell
                      colSpan={3}
                      className={this.props.classes.progressCell}>
                      <CircularProgress className={this.props.classes.progress} />
                    </TableCell>
                  </TableRow>
                )}
                {!this.state.loading && deliveryZones.length === 0 && (
                  <TableRow>
                    <TableCell
                      colSpan={3}
                      className={this.props.classes.noneCell}>
                      None
                    </TableCell>
                  </TableRow>
                )}
                {!this.state.loading && deliveryZones.length > 0 && deliveryZones.map((deliveryZone, index) => (
                  <TableRow key={deliveryZone.id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{deliveryZone.name}</TableCell>
                    <TableCell>{moment.tz(deliveryZone.createdAt, 'Asia/Tokyo').format('MMMM Do YYYY')}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </Paper>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  deliveryZones: state.api.deliveryZones.default,
})

const mapDispatchToProps = {
  getDeliveryZones,
  createDeliveryZone,
  editDeliveryZone,
  deleteDeliveryZone,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(DeliveryZones)))
