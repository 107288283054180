import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import {
  getProfile,
  getTags,
  createTag,
} from '../../redux/api/actions'
import './Tags.css'

class Tags extends Component {
  state = {
    tagsByCategory: {},
    search: '',
  }

  hasLoaded = () => {
    return this.props.profile.loaded && this.props.tags.loaded
  }

  onChangeSearch = event => {
    this.setState({
      tagsByCategory: this.getTagsByCategory(this.props.tags.data, event.currentTarget.value),
      search: event.currentTarget.value,
    })
  }

  onKeyPressSearch = event => {
    const shouldShowAddTag = this.state.search && Object.keys(this.state.tagsByCategory).length === 0

    if (event.key === 'Enter' && shouldShowAddTag) {
      this.onClickCreateTag()
    }
  }

  onClickCreateTag = async () => {
    const name = this.state.search

    await this.props.createTag({ name })
    await this.props.getTags()

    this.setState({
      tagsByCategory: this.getTagsByCategory(this.props.tags.data),
      search: '',
    })
  }

  getTagsByCategory = (tags, search) => {
    return tags.reduce((memo, tag) => {
      if (search && tag.name.toLowerCase().indexOf(search.toLowerCase()) === -1) {
        return memo
      }

      if (memo[tag.category]) {
        memo[tag.category].push(tag)
        return memo
      }

      return {
        ...memo,
        [tag.category]: [tag],
      }
    }, {})
  }

  async componentWillMount() {
    if (!this.hasLoaded()) {
      await Promise.all([
        this.props.getProfile(),
        this.props.getTags(),
      ])
    }

    if (this.props.tags.data) {
      this.setState({
        tagsByCategory: this.getTagsByCategory(this.props.tags.data),
      })
    }
  }

  render() {
    if (!this.hasLoaded()) {
      return false
    }

    if (!this.props.profile.data) {
      return (
        <Redirect to="/login" />
      )
    }

    const shouldShowAddTag = this.state.search && Object.keys(this.state.tagsByCategory).length === 0

    return (
      <div className="tags">
        {(this.props.tags.loading || this.props.createTagState.loading) && (
          <div className="tags__loading" />
        )}
        <div className="tags__content">
          <div className="tags__top-bar">
            <div className="tags__title">
              Tags
            </div>
            <input
              className="tags__input"
              placeholder="search or add tags"
              value={this.state.search}
              onChange={this.onChangeSearch}
              onKeyPress={this.onKeyPressSearch} />
          </div>
          {Object.keys(this.state.tagsByCategory).map(category => (
            <div
              key={category}
              className="tags__content">
              <div className="tags__section-title">
                {category}
              </div>
              <div className="tags__section-values">
                {this.state.tagsByCategory[category].map(tag => (
                  <div
                    key={tag.id}
                    className="tags__section-value">
                    {tag.name}
                  </div>
                ))}
              </div>
            </div>
          ))}
          {shouldShowAddTag && (
            <div className="tags__add-tag">
              <div className="tags__add-tag-button-wrapper">
                <div
                  className="tags__add-tag-button"
                  onClick={this.onClickCreateTag}>
                  {this.state.search}
                </div>
                <div className="tags__add-tag-label">
                  Click to create
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  profile: state.api.profile.default,
  tags: state.api.tags.default,
  createTagState: state.api.createTag.default,
})

const mapDispatchToProps = {
  getProfile,
  getTags,
  createTag,
}

export default connect(mapStateToProps, mapDispatchToProps)(Tags)
