const REQUIRED_VALUES = [
  'name',
  'city',
  'latitude',
  'longitude',
]

export default (values, props) => {
  const errors = {
    hours: {},
  }

  for (let requiredValue of REQUIRED_VALUES) {
    if (!values[requiredValue]) {
      errors[requiredValue] = 'Required'
    }
  }

  return errors
}
