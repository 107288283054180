import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment-timezone'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Chip from '@material-ui/core/Chip'
import MotorcycleIcon from '@material-ui/icons/Motorcycle'
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import {
  getOrdersForDate,
} from '../redux/api/actions'
import {
  orderTypes,
  deliveryProviders,
} from '../utils/Constants'

const getSummaryTextForOrder = order => order.receipt.foodItems.map(foodItem => {
  let base = foodItem.name

  if (foodItem.quantity > 1) {
    base = `${base} (x${foodItem.quantity})`
  }

  return base
}).join(', ')

const getTypeChipForOrder = order => {
  if (order.delivery && (!order.delivery.provider || order.delivery.provider === deliveryProviders.TODOKU)) {
    return (
      <Chip
        icon={<MotorcycleIcon />}
        label="Delivery (Todocu)"
        color="secondary" />
    )
  }

  if (order.delivery && order.delivery.provider === deliveryProviders.ANYCARRY) {
    return (
      <Chip
        icon={<MotorcycleIcon />}
        label="Delivery (AnyCarry)"
        color="secondary" />
    )
  }

  if (order.type === orderTypes.NOW) {
    return (
      <Chip
        icon={<DirectionsWalkIcon />}
        label="Potluck NOW"
        color="primary" />
    )
  }

  return (
    <Chip
      icon={<DirectionsWalkIcon />}
      label="Pickup"
      color="primary" />
  )
}

const styles = theme => ({
  root: {
    flex: '1 0 auto',
  },
  content: {
    margin: theme.spacing.unit * 3,
  },
  toolbar: {
    display: 'flex',
    justiftContent: 'space-between',
  },
  title: {
    flex: 1,
  },
  date: {

  },
  tableWrapper: {
    overflowX: 'auto',
  },
  table: {
  },
  searchBox: {
    flex: 1,
    boxSizing: 'border-box',
    width: '100%',
    height: 40,
    fontSize: '12px',
    lineHeight: '16px',
    border: '1px solid #e4e4e4',
    borderRadius: 4,
    outline: 'none',
    appearance: 'none',
    paddingLeft: theme.spacing.unit,
    marginLeft: theme.spacing.unit * 2,
  },
  pagination: {
    borderBottom: 'none',
  },
  cell: {
    textOverflow: 'ellipses',
  },
  cellMedium: {
    maxWidth: 120,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  },
  cellLarge: {
    maxWidth: 260,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  },
  progress: {
    margin: `${theme.spacing.unit * 16}px auto`,
    display: 'flex',
    justifyContent: 'center',
  },
})

class Orders extends Component {
  constructor(props) {
    super(props)

    let date = props.match.params.date

    if (!date) {
      date = moment.tz('Asia/Tokyo').format('YYYY-MM-DD')

      this.props.history.replace({
        pathname: `/orders/${date}`
      })
    }

    this.props.getOrdersForDate(date)

    this.state = {
      date,
    }
  }

  onChangeDate = event => {
    const date = event.nativeEvent.target.value

    this.setState({
      date,
    }, () => {
      this.props.history.replace({
        pathname: `/orders/${date}`
      })

      this.props.getOrdersForDate(date)
    })
  }

  onClickRow = order => {
    this.props.history.push(`/order/${order.id}`)
  }

  onClickUser = (event, order) => {
    event.stopPropagation()

    this.props.history.push(`/users/${order.userId}`)
  }

  render() {
    const orders = this.props.orders && this.props.orders[this.state.date]

    let title = 'Orders'

    if (orders && orders.data) {
      title += ` (${orders.data.length})`
    }

    return (
      <div className={this.props.classes.root}>
        <Paper className={this.props.classes.content}>
          <Toolbar className={this.props.classes.toolbar}>
            <Typography
              variant="h6"
              className={this.props.classes.title}>
              {title}
            </Typography>
            <TextField
              id="date"
              type="date"
              value={this.state.date}
              className={this.props.classes.date}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={this.onChangeDate} />
          </Toolbar>
          <div className={this.props.classes.tableWrapper}>
            <Table className={this.props.classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell classes={{ root: this.props.classes.cellMedium }}>#</TableCell>
                  <TableCell classes={{ root: this.props.classes.cellMedium }}>type</TableCell>
                  <TableCell classes={{ root: this.props.classes.cellMedium }}>shop</TableCell>
                  <TableCell classes={{ root: this.props.classes.cellMedium }}>items</TableCell>
                  <TableCell classes={{ root: this.props.classes.cellMedium }}>user</TableCell>
                  <TableCell>pickup</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!(orders && orders.loading) && orders && orders.data && orders.data.map(order => (
                  <TableRow
                    key={order.id}
                    hover
                    onClick={() => this.onClickRow(order)}>
                    <TableCell classes={{ root: this.props.classes.cellMedium }}>{order.number}</TableCell>
                    <TableCell classes={{ root: this.props.classes.cellMedium }}>{getTypeChipForOrder(order)}</TableCell>
                    <TableCell classes={{ root: this.props.classes.cellLarge }}>{order.shop.name}</TableCell>
                    <TableCell classes={{ root: this.props.classes.cellLarge }}>{getSummaryTextForOrder(order)}</TableCell>
                    <TableCell classes={{ root: this.props.classes.cellMedium }}>
                      <Chip
                        label={order.userName}
                        color="primary"
                        variant="outlined"
                        onClick={event => this.onClickUser(event, order)} />
                    </TableCell>
                    <TableCell>{moment.tz(order.pickupStartsAt, 'Asia/Tokyo').format('kk:mm')}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {orders && orders.loading && (
              <div className={this.props.classes.progress}>
                <CircularProgress />
              </div>
            )}
          </div>
        </Paper>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  orders: state.api.ordersForDate,
})

const mapDispatchToProps = {
  getOrdersForDate,
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(Orders)))
