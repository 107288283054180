import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import classNames from 'classnames'
import {
  Field,
  reduxForm,
  reset,
} from 'redux-form'
import {
  withRouter,
} from 'react-router-dom'
import moment from 'moment-timezone'
import {
  TextField,
  Select,
  Switch,
} from 'redux-form-material-ui'
import Paper from '@material-ui/core/Paper'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Avatar from '@material-ui/core/Avatar'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import LaunchRoundedIcon from '@material-ui/icons/LaunchRounded'
import FlashOnIcon from '@material-ui/icons/FlashOn'
import AddIcon from '@material-ui/icons/Add'
import JSONPretty from 'react-json-pretty'
import {
  getUser,
  getUserSubscription,
  getUserCharges,
  getNotificationLogsForUser,
  editUser,
  getNeighborhoods,
  getReservationsForUser,
  createLoginLink,
  deleteUserForever,
  getGrantsForUser,
} from '../../redux/api/actions'
import validate from '../../utils/validators/shopValidator'
import getUrlForImage from '../../utils/getUrlForImage'

const DATE_FORMAT = 'YYYY-MM-DD hh:mm'

const NOTIFICATION_TYPE_LABELS = {
  'push-notification': 'Push notification',
  'email': 'Email',
}

const NotificationDataModal = withStyles(theme => ({
  title: {
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  },
  content: {
    padding: theme.spacing(3)
  },
  actions: {
    borderTop: '1px solid rgba(224, 224, 224, 1)',
  },
}))(({ classes, notificatitonLog, onClose, ...rest }) => (
  <Dialog
    {...rest}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    fullWidth
    maxWidth="lg"
    onClose={onClose}>
    {notificatitonLog && [(
      <DialogTitle
        key="title"
        id="alert-dialog-title"
        className={classes.title}>
        {NOTIFICATION_TYPE_LABELS[notificatitonLog.type]} sent on {moment.tz(notificatitonLog.createdAt, 'Asia/Tokyo').format('MMMM Do YYYY')} at {moment.tz(notificatitonLog.createdAt, 'Asia/Tokyo').format('kk:mm')}
      </DialogTitle>
    ), (
      <DialogContent
        key="body"
        className={classes.content}>
        <DialogContentText id="alert-dialog-description">
          Contents:
        </DialogContentText>
        <JSONPretty
          id="json-pretty"
          data={notificatitonLog.data}>
        </JSONPretty>
      </DialogContent>
    )]}
    <DialogActions className={classes.actions}>
      <Button
        color="primary"
        onClick={onClose}>
        Close
      </Button>
    </DialogActions>
  </Dialog>
))

const styles = theme => ({
  container: {
    margin: theme.spacing(3),
  },
  paper: {
    marginBottom: theme.spacing(3),
  },
  content: {
    padding: theme.spacing.unit * 3,
  },
  toolbar: {
    display: 'flex',
    justiftContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  avatar: {
    width: theme.spacing.unit * 16,
    height: theme.spacing.unit * 16,
    marginRight: theme.spacing.unit * 4,
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing.unit * 8,
      height: theme.spacing.unit * 8,
      marginRight: theme.spacing.unit * 2,
    },
  },
  title: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing.unit * 3,
  },
  actions: {

  },
  formField: {
    marginBottom: theme.spacing.unit * 3,
  },
  formFieldSmall: {
    width: 110,
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  formFieldsHorizontal: {
    display: 'flex',
    flexDirection: 'row',
    '& > :not(:first-child)': {
      marginLeft: theme.spacing(3),
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      '& > :not(:first-child)': {
        marginLeft: 0,
      },
    },
  },
  formFieldsPassword: {
    display: 'flex',
    flexDirection: 'row',
    '& > :not(:first-child)': {
      marginLeft: theme.spacing(3),
    },
  },
  titleLink: {
    marginLeft: theme.spacing.unit,
    cursor: 'pointer',
  },
  button: {
    margin: theme.spacing.unit,
    marginTop: theme.spacing.unit * 2,
  },
  progress: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    zIndex: 10,
  },
  toggle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    marginLeft: theme.spacing(-1),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  noneText: {
    margin: theme.spacing(2, 0),
    textAlign: 'center',
  },
  greenText: {
    color: 'green',
  },
  redText: {
    color: 'red',
  },
  ctaSmall: {
    padding: 0,
  },
})

class User extends Component {
  state = {
    open: false,
    notificatitonLogToView: null,
  }

  isLoading = () => {
    return this.props.user.loading
      || this.props.createLoginLinkState.loading
      || (this.props.editUserState && this.props.editUserState.loading)
  }

  hasLoaded = () => {
    return this.props.user && this.props.user.loaded
      && this.props.userSubscription && this.props.userSubscription.loaded
      && this.props.userCharges && this.props.userCharges.loaded
      && this.props.notificationLogsForUser && this.props.notificationLogsForUser.loaded
      && this.props.neighborhoods.loaded
      && this.props.reservationsForUser.loaded
      && this.props.grantsForUser.loaded
  }

  onClickLoginLink = () => {
    if (!this.props.createLoginLinkState.loading) {
      const windowReference = window.open()
      this.props.createLoginLink(this.props.user.data.id).then(data => {
        windowReference.location = data.loginLink
      })
    }
  }

  onClickSubmit = async values => {
    const promises = [this.props.editUser(this.props.match.params.userId, values)]

    await Promise.all(promises)

    await this.props.getUser(this.props.match.params.userId)

    this.props.dispatch(reset('user'))
  }

  onClickDeleteForever = () => {
    this.setState({
      open: true,
    })
  }

  onCloseDialog = () => {
    if (!this.props.deleteUserForeverState.loading) {
      this.setState({
        open: false,
      })
    }
  }

  onClickConfirmDeleteForever = async () => {
    await this.props.deleteUserForever(this.props.match.params.userId)
    await Promise.all([
      this.props.getUser(this.props.match.params.userId),
      this.props.getUserSubscription(this.props.match.params.userId),
    ])

    this.setState({
      open: false,
    })
  }

  onClickChangePassword = () => {
    this.props.history.push(`/users/${this.props.user.data.id}/reset-password`)
  }

  onClickChargeLink = chargeId => {
    const windowReference = window.open()
    windowReference.location = `https://pay.jp/d/charges/${chargeId}`
  }

  onClickAddGrant = () => {
    this.props.history.push('/grants/new', {
      user: this.props.user.data,
    })
  }

  onClickGrant = grant => {
    this.props.history.push(`/grants/${grant.id}`)
  }

  onClickViewNotificationData = notificatitonLogToView => {
    this.setState({
      notificatitonLogToView,
    })
  }

  onClickCloseViewNotificationData = () => {
    this.setState({
      notificatitonLogToView: null,
    })
  }

  componentWillMount() {
    this.props.getUser(this.props.match.params.userId)
    this.props.getUserSubscription(this.props.match.params.userId)
    this.props.getUserCharges(this.props.match.params.userId)
    this.props.getNotificationLogsForUser(this.props.match.params.userId)
    this.props.getNeighborhoods()
    this.props.getReservationsForUser(this.props.match.params.userId)
    this.props.getGrantsForUser(this.props.match.params.userId)
  }

  render() {
    if (!this.hasLoaded()) {
      return false
    }

    const {
      handleSubmit,
      pristine,
    } = this.props

    this.props.reservationsForUser.data.sort((first, second) => {
      const firstMoment = moment.tz(`${first.pickup.date} ${first.pickup.time.split('-')[0]}`, 'YYYYMMDD HH:mm:ss', 'Asia/Tokyo')
      const secondMoment = moment.tz(`${second.pickup.date} ${second.pickup.time.split('-')[0]}`, 'YYYYMMDD HH:mm:ss', 'Asia/Tokyo')

      return firstMoment.isBefore(secondMoment) ? 1 : -1
    })

    let grants = []

    if (this.hasLoaded()) {
      grants = this.props.grantsForUser.data
    }

    return (
      <div className={this.props.classes.container}>
        <Paper className={this.props.classes.paper}>
          <Dialog
            open={this.state.open}
            onClose={this.onCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                This will delete the users name and email, stop mail, cancel subscriptions and delete all credit card information.
              </DialogContentText>
              {this.props.deleteUserForeverState.loading && (
                <CircularProgress className={this.props.classes.progress} />
              )}
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                disabled={this.props.deleteUserForeverState.loading}
                onClick={this.onCloseDialog}>
                No
              </Button>
              <Button
                color="primary"
                autoFocus
                disabled={this.props.deleteUserForeverState.loading}
                onClick={this.onClickConfirmDeleteForever}>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
          <NotificationDataModal
            notificatitonLog={this.state.notificatitonLogToView}
            open={this.state.notificatitonLogToView}
            onClose={this.onClickCloseViewNotificationData} />
          <Toolbar className={this.props.classes.toolbar}>
            <div className={this.props.classes.title}>
              <Avatar
                src={getUrlForImage(this.props.user.data.photo.uuid)}
                className={this.props.classes.avatar} />
              <Typography variant="h4">
                {this.props.user.data.name}
                <IconButton
                  color="primary"
                  aria-label="Login"
                  className={this.props.classes.loginButton}
                  onClick={this.onClickLoginLink}>
                  <LaunchRoundedIcon />
                </IconButton>
              </Typography>
            </div>
            <div className={this.props.classes.actions}>
              <Button
                variant="contained"
                color="secondary"
                className={this.props.classes.button}
                disabled={this.isLoading()}
                onClick={this.onClickDeleteForever}>
                <FlashOnIcon />
                Delete forever
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={this.props.classes.button}
                disabled={(pristine || this.isLoading())}
                onClick={handleSubmit(this.onClickSubmit)}>
                登録する
              </Button>
            </div>
          </Toolbar>
          <form className={this.props.classes.content}>
            <div className={this.props.classes.formFieldsHorizontal}>
              <Field
                name="name"
                label="Name"
                variant="outlined"
                autoComplete="off"
                fullWidth
                component={TextField}
                className={this.props.classes.formField} />
              <Field
                disabled
                name="referralCode"
                label="Referral code"
                variant="outlined"
                autoComplete="off"
                component={TextField}
                className={this.props.classes.formField} />
            </div>
            <div className={this.props.classes.formFieldsHorizontal}>
              <Field
                name="email"
                type="email"
                label="Email"
                variant="outlined"
                autoComplete="off"
                fullWidth
                component={TextField}
                className={this.props.classes.formField} />
              <Field
                name="phone"
                type="tel"
                label="Phone"
                variant="outlined"
                autoComplete="off"
                fullWidth
                component={TextField}
                className={this.props.classes.formField} />
            </div>
            <div className={this.props.classes.formFieldsPassword}>
              <Field
                disabled
                name="password"
                type="password"
                label="Password"
                variant="outlined"
                autoComplete="off"
                fullWidth
                component={TextField}
                className={this.props.classes.formField} />
              <Button
                color="primary"
                variant="text"
                className={this.props.classes.formField}
                onClick={this.onClickChangePassword}>
                Change
              </Button>
            </div>
            <FormControl variant="outlined">
              <InputLabel htmlFor="city">
                City
              </InputLabel>
              <Field
                name="neighborhoodId"
                label="City"
                variant="outlined"
                fullWidth
                component={Select}
                className={classNames(this.props.classes.formField, this.props.classes.formFieldSmall)}>
                {this.props.neighborhoods.data.map(neighborhood => (
                  <MenuItem
                    key={neighborhood.id}
                    value={neighborhood.id}>
                    {neighborhood.name}
                  </MenuItem>
                ))}
              </Field>
            </FormControl>
            <div>
              <Typography
                variant="caption"
                color="textSecondary">
                Plan (this month)
              </Typography>
              <Typography
                variant="body2"
                gutterBottom>
                {(this.props.userSubscription.data && this.props.userSubscription.data.plan) ? this.props.userSubscription.data.plan.name : 'None'}
              </Typography>
              <Typography
                variant="caption"
                color="textSecondary">
                Plan (next month)
              </Typography>
              <Typography
                variant="body2"
                gutterBottom>
                {(this.props.userSubscription.data && this.props.userSubscription.data.nextPlan) ? this.props.userSubscription.data.nextPlan.name : 'None'}
              </Typography>
              <Typography
                variant="caption"
                color="textSecondary">
                Payjp User
              </Typography>
              <Typography
                variant="body2"
                gutterBottom>
                {!this.props.user.data.payjpid && (
                  <span>None</span>
                )}
                {this.props.user.data.payjpid && (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://pay.jp/d/customers/${this.props.user.data.payjpid}`}>
                    {`https://pay.jp/d/customers/${this.props.user.data.payjpid}`}
                  </a>
                )}
              </Typography>
            </div>
            <div>
              <FormControl className={this.props.classes.toggle}>
                <Field
                  name="accountVerified"
                  color="primary"
                  component={Switch} />
                <Typography variant="caption">
                  Email verified
                </Typography>
              </FormControl>
            </div>
            <div>
              <FormControl className={this.props.classes.toggle}>
                <Field
                  name="receiveDailyReminders"
                  color="primary"
                  component={Switch} />
                <Typography variant="caption">
                  Receive mail
                </Typography>
              </FormControl>
            </div>
            <div>
              <FormControl className={this.props.classes.toggle}>
                <Field
                  name="deleted"
                  color="primary"
                  component={Switch} />
                <Typography variant="caption">
                  Deleted
                </Typography>
              </FormControl>
            </div>
          </form>
        </Paper>
        <Paper className={this.props.classes.paper}>
          <Toolbar className={this.props.classes.toolbar}>
            <div className={this.props.classes.title}>
              <Typography variant="h6">
                Charges
              </Typography>
            </div>
          </Toolbar>
          <div className={this.props.classes.tableWrapper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Refund</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Link</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.userCharges.data.length === 0 && (
                  <TableRow>
                    <TableCell
                      colSpan={5}
                      className={this.props.classes.noneText}>
                      <Typography variant="caption">
                        None
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {this.props.userCharges.data.map((charge, index) => (
                  <TableRow
                    key={charge.id}
                    hover>
                    <TableCell>{this.props.userCharges.data.length - index}</TableCell>
                    <TableCell>{moment(charge.created).tz('Asia/Tokyo').format(DATE_FORMAT)}</TableCell>
                    <TableCell>{charge.amount}円</TableCell>
                    <TableCell>{charge.amount_refunded !== 0 ? `${charge.amount_refunded}円` : ''}</TableCell>
                    <TableCell>{charge.paid ? (<span className={this.props.classes.greenText}>✓</span>) : (<span className={this.props.classes.redText}>✗</span>)}</TableCell>
                    <TableCell>
                      <IconButton
                        color="primary"
                        aria-label="View in Payjp"
                        size="small"
                        onClick={() => this.onClickChargeLink(charge.id)}>
                        <LaunchRoundedIcon size="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </Paper>
        <Paper className={this.props.classes.paper}>
          <Toolbar className={this.props.classes.toolbar}>
            <div className={this.props.classes.title}>
              <Typography variant="h6">
                Grants
              </Typography>
            </div>
            <div className={this.props.classes.actions}>
              <Tooltip title="Add">
                <IconButton
                  aria-label="Add"
                  color="primary"
                  onClick={this.onClickAddGrant}>
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </div>
          </Toolbar>
          <div className={this.props.classes.tableWrapper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Product</TableCell>
                  <TableCell>Tickets</TableCell>
                  <TableCell>Start</TableCell>
                  <TableCell>End</TableCell>
                  <TableCell>Charge</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {grants.length === 0 && (
                  <TableRow>
                    <TableCell
                      colSpan={5}
                      className={this.props.classes.noneText}>
                      <Typography variant="caption">
                        None
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {grants.map((grant, index) => (
                  <TableRow
                    key={grant.id}
                    hover
                    onClick={() => this.onClickGrant(grant)}>
                    <TableCell>{grants.length - index}</TableCell>
                    <TableCell>{grant.productType}</TableCell>
                    <TableCell>{grant.productCode}</TableCell>
                    <TableCell>{grant.ticketQuantity}</TableCell>
                    <TableCell>{grant.startsAt && moment(grant.startsAt).tz('Asia/Tokyo').format(DATE_FORMAT)}</TableCell>
                    <TableCell>{grant.endsAt && moment(grant.endsAt).tz('Asia/Tokyo').format(DATE_FORMAT)}</TableCell>
                    <TableCell>
                      <IconButton
                        disabled={!grant.chargeId}
                        color="primary"
                        aria-label="View in Payjp"
                        size="small"
                        onClick={() => this.onClickChargeLink(grant.chargeId)}>
                        <LaunchRoundedIcon size="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </Paper>
        <Paper className={this.props.classes.paper}>
          <Toolbar className={this.props.classes.toolbar}>
            <div className={this.props.classes.title}>
              <Typography variant="h6">
                Reservations
              </Typography>
            </div>
          </Toolbar>
          <div className={this.props.classes.tableWrapper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Time</TableCell>
                  <TableCell>Shop</TableCell>
                  <TableCell>Meal</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.notificationLogsForUser.data.length === 0 && (
                  <TableRow>
                    <TableCell
                      colSpan={5}
                      className={this.props.classes.noneText}>
                      <Typography variant="caption">
                        None
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {this.props.reservationsForUser.data.map((reservation, index) => (
                  <TableRow
                    key={reservation.id}
                    hover>
                    <TableCell>{this.props.reservationsForUser.data.length - index}</TableCell>
                    <TableCell>{moment.tz(reservation.pickup.date, 'YYYYMMDD', 'Asia/Tokyo').format(DATE_FORMAT)}</TableCell>
                    <TableCell>{reservation.pickup.time}</TableCell>
                    <TableCell>{reservation.shop.name}</TableCell>
                    <TableCell>{reservation.meal.name}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </Paper>
        <Paper className={this.props.classes.paper}>
          <Toolbar className={this.props.classes.toolbar}>
            <div className={this.props.classes.title}>
              <Typography variant="h6">
                Notifications
              </Typography>
            </div>
          </Toolbar>
          <div className={this.props.classes.tableWrapper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Type</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>Data</TableCell>
                  <TableCell>Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.notificationLogsForUser.data.length === 0 && (
                  <TableRow>
                    <TableCell
                      colSpan={5}
                      className={this.props.classes.noneText}>
                      <Typography variant="caption">
                        None
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {this.props.notificationLogsForUser.data.map(notificatitonLog => (
                  <TableRow
                    key={notificatitonLog.id}
                    hover>
                    <TableCell>{NOTIFICATION_TYPE_LABELS[notificatitonLog.type]}</TableCell>
                    <TableCell>{notificatitonLog.name || 'none'}</TableCell>
                    <TableCell>{notificatitonLog.category || 'none'}</TableCell>
                    <TableCell>
                      <Button
                        color="primary"
                        className={this.props.classes.ctaSmall}
                        onClick={() => this.onClickViewNotificationData(notificatitonLog)}>
                        View
                      </Button>
                    </TableCell>
                    <TableCell>{moment.tz(notificatitonLog.createdAt, 'Asia/Tokyo').format(DATE_FORMAT)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </Paper>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const props = {
    user: state.api.user[ownProps.match.params.userId],
    userSubscription: state.api.userSubscription[ownProps.match.params.userId],
    userCharges: state.api.userCharges[ownProps.match.params.userId],
    grantsForUser: state.api.grantsForUser[ownProps.match.params.userId],
    notificationLogsForUser: state.api.notificationLogsForUser[ownProps.match.params.userId],
    editUserState: state.api.editUser[ownProps.match.params.userId],
    neighborhoods: state.api.neighborhoods.default,
    reservationsForUser: state.api.reservationsForUser[ownProps.match.params.userId],
    createLoginLinkState: state.api.createLoginLink.default,
    deleteUserForeverState: state.api.deleteUserForever.default,
  }

  if (props.user && props.user.data) {
    props.initialValues = {
      name: props.user.data.name,
      email: props.user.data.email,
      phone: props.user.data.phone,
      password: '••••••••••',
      referralCode: props.user.data.referralCode,
      neighborhoodId: props.user.data.neighborhood.id,
      accountVerified: props.user.data.accountVerified,
      receiveDailyReminders: props.user.data.receiveDailyReminders,
      deleted: props.user.data.deleted,
    }
  }

  return props
}

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    getUser,
    getUserSubscription,
    getUserCharges,
    getNotificationLogsForUser,
    editUser,
    getNeighborhoods,
    getReservationsForUser,
    createLoginLink,
    deleteUserForever,
    getGrantsForUser,
  }, dispatch),
  dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'user',
  validate,
  enableReinitialize: true,
})(withRouter(withStyles(styles)(User))))
