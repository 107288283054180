import React, { Component } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { debounce } from 'throttle-debounce'
import {
  withRouter,
  Link,
} from 'react-router-dom'
import {
  Field,
  formValueSelector,
  reduxForm,
  change,
  initialize,
  reset,
} from 'redux-form'
import {
  TextField,
  Select,
} from 'redux-form-material-ui'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import ListSubheader from '@material-ui/core/ListSubheader'
import MenuItem from '@material-ui/core/MenuItem'
import Avatar from '@material-ui/core/Avatar'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { KeyboardDateTimePicker } from "@material-ui/pickers"
import CircularProgress from '@material-ui/core/CircularProgress'
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded'
import {
  getGrantById,
  getGrantsForUser,
  createGrant,
  editGrant,
  deleteGrant,
  getProducts,
  getUser,
  searchUsers,
  getReservationsForGrant,
} from '../redux/api/actions'
import getUrlForImage from '../utils/getUrlForImage'
import {
  productTypes,
  grantStatuses,
} from '../utils/Constants'
import promiseMap from '../utils/promiseMap'
import validate from '../utils/validators/grantValidator'
import ReservationTable from '../components/ReservationTable'

const FORM_NAME = 'grant'

const sortedProductTypes = [
  productTypes.REWARD,
  productTypes.PLAN,
  productTypes.PROMO,
  productTypes.BUNDLE,
  productTypes.ADDON,
]

const DeleteConfirmationDialog = withStyles({
})(({ classes, open, onClose, onClickYes }) => (
  <Dialog
    open={open}
    keepMounted
    onClose={onClose}>
    <DialogTitle>Are you sure?</DialogTitle>
    <DialogContent>
      <DialogContentText>
        This will remove the tickets/plan from the user's account. You will still need to cancel the subscriptions and process refunds in Payjp manually.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        No
      </Button>
      <Button onClick={onClickYes} color="primary">
        Yes
      </Button>
    </DialogActions>
  </Dialog>
))

const KeyboardDateTimePickerField = ({
  timezone,
  showErrorsInline,
  input: { onChange, value, name },
  meta: { touched, error, form },
  dispatch,
  ...other
}) => (
  <KeyboardDateTimePicker
    autoOk
    name={name}
    error={touched && Boolean(error)}
    helperText={touched && error}
    value={value}
    onChange={onChange}
    format="yyyy/MM/dd HH:mm"
    {...other}
  />
)

const styles = theme => ({
  root: {
    flex: '1 0 auto',
  },
  container: {
    margin: theme.spacing.unit * 3,
  },
  content: {
    padding: theme.spacing.unit * 3,
  },
  toolbar: {
    display: 'flex',
    justiftContent: 'space-between',
  },
  title: {
    flex: '1',
  },
  userLink: {
    textDecoration: 'none',
  },
  userName: {
    fontSize: 12,
    color: theme.palette.primary.main,
    fontWeight: 600,
    cursor: 'pointer',
    marginTop: theme.spacing.unit,
  },
  ctaProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  formField: {
    marginBottom: theme.spacing.unit * 3,
  },
  formFieldHorizontalContainer: {
    display: 'flex',
    marginLeft: theme.spacing.unit * -1.5,
    marginRight: theme.spacing.unit * -1.5,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  formFieldHorizontal: {
    flex: 1,
    marginLeft: theme.spacing.unit * 1.5,
    marginRight: theme.spacing.unit * 1.5,
  },
  formFieldSelect: {
    minWidth: 180,
  },
  formFieldSmall: {
    width: 110,
    marginLeft: theme.spacing.unit * 1.5,
    marginRight: theme.spacing.unit * 1.5,
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  result: {
    display: 'flex',
  },
  resultAvatar: {
    width: theme.spacing.unit * 3,
    height: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 1,
  },
  resultText: {
    fontSize: 14,
  },
  listSubheader: {
    backgroundColor: '#ffffff',
  }
})

class Grant extends Component {
  state = {
    loading: true,
    saving: false,
    showDeleteConfirmationDialog: false,
    autoCompleteLoading: false,
    autoCompleteOpen: false,
    productsByType: null,
  }

  loadProductsIfNeeded = async () => {
    const productsByType = {}
    let products = this.props.products.data

    if (!products) {
      products = await this.props.getProducts()
    }

    for (let productType of Object.values(productTypes)) {
      const productsOfType = products.filter(product => product.type === productType)
      productsOfType.sort((first, second) => first.code > second.code ? -1 : 1)
      productsByType[productType] = productsOfType
    }

    this.setState({
      productsByType,
    })
  }

  refreshData = debounce(750, async text => {
    await this.props.searchUsers({
      text,
      page: 0,
    })

    this.setState({ autoCompleteLoading: false })
  })

  onChangeUser = (event, user)  => {
    this.props.change('userId', user ? user.id : null)

    this.setState({
      user,
    })
  }

  onChangeUserText = (event, value) => {
    this.setState({ autoCompleteLoading: true })

    this.refreshData(event.target.value)
  }

  onClickDelete = () => {
    this.setState({
      showDeleteConfirmationDialog: true,
    })
  }

  onClickCloseDeleteConfirmationDialog = () => {
    this.setState({
      showDeleteConfirmationDialog: false,
    })
  }

  onClickConfirmDelete = async () => {
    this.setState({
      saving: true,
      showDeleteConfirmationDialog: false,
    })

    await this.props.deleteGrant(this.props.grantId)
    await this.props.getGrantsForUser(this.state.user.id)
    this.props.history.push(`/users/${this.state.user.id}`)
  }

  onPressSave = async values => {
    this.setState({ saving: true })

    const grant = {
      ticketQuantity: values.ticketQuantity,
    }

    if (values.productType === productTypes.REWARD) {
      grant.data = {
        label: values.label,
        description: values.description,
      }
    } else if ([productTypes.PLAN, productTypes.ADDON].includes(values.productType)) {
      grant.productCode = values.productCode
      grant.nextProductCode = values.nextProductCode
      grant.startsAt = values.startsAt
      grant.endsAt = values.endsAt
      grant.pausedAt = values.pausedAt
    } else if (values.productType === productTypes.PROMO) {
      grant.productCode = values.productCode
      grant.nextProductCode = values.nextProductCode
      grant.startsAt = values.startsAt
      grant.endsAt = values.endsAt
    } else if (values.productType === productTypes.BUNDLE) {
      grant.productCode = values.productCode
    }

    if (this.props.isNew) {
      grant.userId = values.userId
      const newGrant = await this.props.createGrant(grant)

      await this.props.getGrantsForUser(grant.userId)

      window.location = `/grants/${newGrant.id}`
    } else {
      await this.props.editGrant(this.props.grantId, grant)
      await Promise.all([
        this.props.getGrantById(this.props.grantId),
        this.props.getGrantsForUser(grant.userId),
      ])

      this.setState({ saving: false })
    }
  }

  async componentWillMount() {
    let user

    if (this.props.isNew) {
      await this.loadProductsIfNeeded()

      user = this.props.location.state && this.props.location.state.user
    } else {
      const {
        grant,
      } = await promiseMap({
        products: this.loadProductsIfNeeded(),
        grant: this.props.getGrantById(this.props.grantId),
        reservations: this.props.getReservationsForGrant(this.props.grantId),
      })

      if (grant) {
        user = await this.props.getUser(grant.userId)
      }
    }

    if (user) {
      this.setState({ user }, () => {
        this.props.searchUsers({
          text: user.name,
          page: 0,
        })
      })

      this.props.change('userId', user.id)
    }

    this.setState({ loading: false })
  }

  render() {
    if (this.state.loading) {
      return false
    }

    const productsByType = this.state.productsByType || {}
    const searchResults = (this.props.usersSearch && this.props.usersSearch.data) ? this.props.usersSearch.data.users : []

    let reservations = []

    if (this.props.reservations && this.props.reservations.data) {
      reservations = this.props.reservations.data
    }

    return (
      <div className={this.props.classes.root}>
        <DeleteConfirmationDialog
          open={this.state.showDeleteConfirmationDialog}
          onClose={this.onClickCloseDeleteConfirmationDialog}
          onClickYes={this.onClickConfirmDelete} />
        <Paper className={this.props.classes.container}>
          <Toolbar className={this.props.classes.toolbar}>
            <div className={this.props.classes.title}>
              {this.state.user && (
                <Link
                  to={`/users/${this.state.user.id}`}
                  className={this.props.classes.userLink}>
                  <Typography
                    variant="body2"
                    className={this.props.classes.userName}>
                    ⤺{this.state.user.name}
                  </Typography>
                </Link>
              )}
              <Typography variant="h4">
                {this.props.isNew ? 'New Grant' : `Grant #${this.props.grantId}`}
              </Typography>
            </div>
            {!this.props.isNew && (
              <IconButton
                color="primary"
                disabled={reservations.length > 0}
                onClick={this.onClickDelete}>
                <DeleteRoundedIcon />
              </IconButton>
            )}
            <Button
              variant="contained"
              color="primary"
              disabled={this.props.pristine || this.props.invalid || this.state.saving}
              className={this.props.classes.button}
              onClick={this.props.handleSubmit(this.onPressSave)}>
              {this.state.saving && (
                <CircularProgress
                  size={24}
                  color="primary"
                  className={this.props.classes.ctaProgress} />
              )}
              Save
            </Button>
          </Toolbar>
          <div className={this.props.classes.content}>
            <form>
              <div className={this.props.classes.formFieldHorizontalContainer}>
                <Field
                  name="ticketQuantity"
                  label="Tickets"
                  variant="outlined"
                  autoComplete="off"
                  type="number"
                  InputProps={{
                    inputProps: {
                      min: 0,
                    },
                  }}
                  component={TextField}
                  className={classNames(this.props.classes.formField, this.props.classes.formFieldSmall)} />
                <FormControl
                  variant="outlined"
                  className={classNames(this.props.classes.formField, this.props.classes.formFieldSmall, this.props.classes.formFieldSelect)}>
                  <InputLabel htmlFor="status">
                    Type
                  </InputLabel>
                  <Field
                    disabled={!this.props.isNew}
                    name="productType"
                    label="Type"
                    variant="outlined"
                    autoComplete="off"
                    component={Select}>
                    {Object.values(sortedProductTypes).map(productType => (
                      <MenuItem
                        key={productType}
                        value={productType}>
                        {productType}
                      </MenuItem>
                    ))}
                  </Field>
                </FormControl>
                {[productTypes.PLAN, productTypes.ADDON].includes(this.props.productType) && (
                  <FormControl
                    variant="outlined"
                    className={classNames(this.props.classes.formField, this.props.classes.formFieldSmall, this.props.classes.formFieldSelect)}>
                    <InputLabel htmlFor="status">
                      Status
                    </InputLabel>
                    <Field
                      name="status"
                      label="Status"
                      variant="outlined"
                      autoComplete="off"
                      component={Select}>
                      {Object.values(grantStatuses).map(grantStatus => (
                        <MenuItem
                          key={grantStatus}
                          value={grantStatus}>
                          {grantStatus}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                )}
                <Autocomplete
                  disabled={!this.props.isNew}
                  open={this.state.autoCompleteOpen}
                  onOpen={() => this.setState({ autoCompleteOpen: true })}
                  onClose={() => this.setState({ autoCompleteOpen: false })}
                  defaultValue={this.state.user}
                  getOptionSelected={option => option.id === this.state.user.id}
                  getOptionLabel={(option) => `${option.name} (${option.phone || option.email})`}
                  options={searchResults}
                  loading={this.state.autoCompleteLoading}
                  className={classNames(this.props.classes.formField, this.props.classes.formFieldHorizontal)}
                  onChange={this.onChangeUser}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="User"
                      variant="outlined"
                      onChange={this.onChangeUserText}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <React.Fragment>
                            {params.InputProps.startAdornment}
                            {this.state.user ? <Avatar
                              src={getUrlForImage(this.state.user.photo.uuid)}
                              className={this.props.classes.resultAvatar} /> : null}
                          </React.Fragment>
                        ),
                        endAdornment: (
                          <React.Fragment>
                            {this.state.autoCompleteLoading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                  renderOption={option => (
                    <div className={this.props.classes.result}>
                      <Avatar
                        src={getUrlForImage(option.photo.uuid)}
                        className={this.props.classes.resultAvatar} />
                      <Typography
                        noWrap
                        className={this.props.classes.resultText}>
                        {option.name} ({option.phone || option.email})
                      </Typography>
                    </div>
                  )} />
              </div>
              {[productTypes.PLAN, productTypes.ADDON, productTypes.PROMO, productTypes.BUNDLE].includes(this.props.productType) && (
                <div className={this.props.classes.formFieldHorizontalContainer}>
                  <FormControl
                    variant="outlined"
                    className={classNames(this.props.classes.formField, this.props.classes.formFieldSelect, this.props.classes.formFieldHorizontal)}>
                    <InputLabel htmlFor="productCode">
                      Product
                    </InputLabel>
                    <Field
                      name="productCode"
                      label="Product"
                      variant="outlined"
                      fullWidth
                      component={Select}>
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {Object.keys(productsByType).map(productType => [(
                        <ListSubheader
                          key={`${productType}-header`}
                          className={this.props.classes.listSubheader}>
                          {productType}
                        </ListSubheader>
                      ), ...productsByType[productType].map(product => (
                        <MenuItem
                          key={product.code}
                          value={product.code}>
                          {product.code}
                        </MenuItem>
                      ))])}
                    </Field>
                  </FormControl>
                  <FormControl
                    variant="outlined"
                    className={classNames(this.props.classes.formField, this.props.classes.formFieldSelect, this.props.classes.formFieldHorizontal)}>
                    <InputLabel htmlFor="productCode">
                      Next product
                    </InputLabel>
                    <Field
                      name="nextProductCode"
                      label="Next product"
                      variant="outlined"
                      fullWidth
                      component={Select}>
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {Object.keys(productsByType).map(productType => [(
                        <ListSubheader
                          key={`${productType}-header`}
                          className={this.props.classes.listSubheader}>
                          {productType}
                        </ListSubheader>
                      ), ...productsByType[productType].map(product => (
                        <MenuItem
                          key={product.code}
                          value={product.code}>
                          {product.code}
                        </MenuItem>
                      ))])}
                    </Field>
                  </FormControl>
                </div>
              )}
              {[productTypes.PLAN, productTypes.ADDON, productTypes.PROMO].includes(this.props.productType) && (
                <div className={this.props.classes.formFieldHorizontalContainer}>
                  <Field
                    fullWidth
                    name="startsAt"
                    ampm={false}
                    label="Starts at"
                    className={classNames(this.props.classes.formField, this.props.classes.formFieldHorizontal)}
                    component={KeyboardDateTimePickerField} />
                  <Field
                    variant="inline"
                    fullWidth
                    name="endsAt"
                    ampm={false}
                    label="Ends at"
                    className={classNames(this.props.classes.formField, this.props.classes.formFieldHorizontal)}
                    component={KeyboardDateTimePickerField} />
                  {[productTypes.PLAN, productTypes.ADDON].includes(this.props.productType) && (
                    <Field
                      disabled
                      fullWidth
                      variant="inline"
                      name="pausedAt"
                      ampm={false}
                      label="Paused at"
                      className={classNames(this.props.classes.formField, this.props.classes.formFieldHorizontal)}
                      component={KeyboardDateTimePickerField} />
                  )}
                </div>
              )}
              {this.props.productType === productTypes.REWARD && (
                <div className={this.props.classes.formFieldHorizontalContainer}>
                  <Field
                    name="label"
                    label="Label"
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    component={TextField}
                    className={classNames(this.props.classes.formField, this.props.classes.formFieldHorizontal)} />
                  <Field
                    name="description"
                    label="Description"
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    component={TextField}
                    className={classNames(this.props.classes.formField, this.props.classes.formFieldHorizontal)} />
                </div>
              )}
            </form>
          </div>
        </Paper>
        {!this.props.isNew && this.props.productType !== productTypes.ADDON && (
          <ReservationTable
            readOnly
            reservations={reservations} />
        )}
      </div>
    )
  }
}

const selector = formValueSelector(FORM_NAME)

const mapStateToProps = (state, ownProps) => {
  let initialValues = {
    status: grantStatuses.NORMAL,
  }

  const grantId = ownProps.match && ownProps.match.params.grantId
  const grant = state.api.grantById[grantId]
  let user

  if (grant && grant.data) {
    initialValues = {
      userId: grant.data.userId,
      chargeId: grant.data.chargeId,
      productCode: grant.data.productCode,
      productType: grant.data.productType,
      nextProductCode: grant.data.nextProductCode,
      firstGrantId: grant.data.firstGrantId,
      ticketQuantity: grant.data.ticketQuantity,
      startsAt: grant.data.startsAt,
      endsAt: grant.data.endsAt,
      pausedAt: grant.data.pausedAt,
      status: grant.data.status
    }

    if (grant.data.productType === productTypes.REWARD && grant.data.data) {
      initialValues.label = grant.data.data.label
      initialValues.description = grant.data.data.description
    }

    user = state.api.user[grant.data.userId]
  } else {
    initialValues = {
      productType: productTypes.REWARD,
      ticketQuantity: 1,
      label: 'おめでとうございます！',
      description: '特典チケットが届いております♪',
    }
  }

  const isNew = ownProps.match.params.grantId === 'new'

  return {
    isNew,
    initialValues,
    grantId,
    grant,
    productType: selector(state, 'productType'),
    userId: selector(state, 'userId'),
    endsAt: selector(state, 'endsAt'),
    user,
    usersSearch: state.api.usersSearch[state.api.usersSearch.lastReceivedKey],
    products: state.api.products.default,
    reservations: isNew ? null : state.api.reservationsForGrant[ownProps.match.params.grantId],
  }
}

const mapDispatchToProps = {
  getGrantById,
  getGrantsForUser,
  createGrant,
  editGrant,
  deleteGrant,
  getUser,
  getProducts,
  searchUsers,
  getReservationsForGrant,
  change,
  initialize,
  resetForm: reset,
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: FORM_NAME,
  validate,
  enableReinitialize: true,
})(withRouter(withStyles(styles)(Grant))))


