const REQUIRED_VALUES = [
  'name',
  'email',
  'password',
]

export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i
const PASSWORD_MIN_LENGTH = 5

export default (values, props) => {
  const errors = {
    hours: {},
  }

  for (let requiredValue of REQUIRED_VALUES) {
    if (!values[requiredValue]) {
      errors[requiredValue] = 'Required'
    }
  }

  if (values.email && !EMAIL_REGEX.test(values.email)) {
    errors.email = 'Invalid email'
  }

  if (values.password && values.password.length < PASSWORD_MIN_LENGTH) {
    errors.password = `Password must be at least ${PASSWORD_MIN_LENGTH} characters long.`
  }

  return errors
}
