import React from 'react'
import classNames from 'classnames'
import './BigButton.css'

export default props => (
  <div
    className={classNames('big-button', {
      'big-button--full': props.full,
      'big-button--clear': props.clear,
      'big-button--inactive': props.inactive,
      'big-button--loading': props.loading,
    })}
    onClick={(props.inactive || props.loading) ? () => {} : props.onClick}>
    {props.text}
    {!props.inactive && !props.loading && !props.noCaret && (
      <div className="big-button__icon" />
    )}
    {props.noCaret && props.check && (
      <div className="big-button__icon big-button__icon--check" />
    )}
    {props.loading && (
      <div className="big-button__icon big-button__icon--loading" />
    )}
  </div>
)
