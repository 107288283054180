import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Field,
  reduxForm,
  reset,
} from 'redux-form'
import {
  Link,
  withRouter,
} from 'react-router-dom'
import {
  injectIntl,
  defineMessages,
  FormattedMessage,
} from 'react-intl'
import moment from 'moment-timezone'
import Autocomplete from 'react-autocomplete'
import classNames from 'classnames'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import {
  getProfile,
  getMeal,
  getMealOptions,
  getOpenRequests,
  getTags,
  tagMeal,
  untagMeal,
  editMeal,
  uploadImage,
  addMediaToMeal,
  removeMediaFromMeal,
  deleteRequest,
  acceptRequest,
} from '../../redux/api/actions'
import BigButton from '../../components/BigButton/BigButton'
import FormField from '../../components/FormField/FormField'
import FormFieldTextarea from '../../components/FormFieldTextarea/FormFieldTextarea'
import FormFieldCheckboxIOS from '../../components/FormFieldCheckboxIOS/FormFieldCheckboxIOS'
import validate from '../../utils/validators/mealValidator'
import getUrlForImage from '../../utils/getUrlForImage'
import './Meal.css'

const FORM_NAME = 'meal'

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  input: {
    display: 'none',
  },
})

const messages = defineMessages({
  changeName: {
    id: 'meal.change-name',
    defaultMessage: 'Change the meal name from "{previousValue}"" to "{newValue}"',
  },
  changePrice: {
    id: 'meal.change-price',
    defaultMessage: 'Change the price from {previousValue}円 to {newValue}円',
  },
  changeDailyReservationLimit: {
    id: 'meal.daily-reservation-limit',
    defaultMessage: 'Change the reservation limit from {previousValue} to {newValue}',
  },
  changeDetails: {
    id: 'meal.change-details',
    defaultMessage: 'Change the meal details from "{previousValue}"" to "{newValue}"',
  },
  changeIngredients: {
    id: 'meal.change-ingredients',
    defaultMessage: 'Change the ingredients from "{previousValue}"" to "{newValue}"',
  },
  changePickupInstructions: {
    id: 'meal.change-pickup-instructions',
    defaultMessage: 'Change the pickup instructions from "{previousValue}"" to "{newValue}"',
  },
  changeMedia: {
    id: 'meal.change-media',
    defaultMessage: 'Change the meal images to:',
  },
  changeOptions: {
    id: 'meal.change-media',
    defaultMessage: 'Change the meal options to:',
  },
  optionsRequired: {
    id: 'meal.option-required',
    defaultMessage: 'Required',
  },
  optionsAllowMultiple: {
    id: 'meal.option-allow-multiple',
    defaultMessage: 'Allows multiple selections',
  },
})

const OPERATIONS_TO_MESSAGES = {
  'CHANGE_MEAL_NAME': messages.changeName,
  'CHANGE_MEAL_PRICE': messages.changePrice,
  'CHANGE_MEAL_DAILY_RESERVATION_LIMIT': messages.changeDailyReservationLimit,
  'CHANGE_MEAL_DETAILS': messages.changeDetails,
  'CHANGE_MEAL_INGREDIENTS': messages.changeIngredients,
  'CHANGE_MEAL_PICKUP_INSTRUCTIONS': messages.changePickupInstructions,
  'CHANGE_MEAL_IMAGES': messages.changeMedia,
  'CHANGE_MEAL_OPTIONS': messages.changeOptions,
}

class Meal extends Component {
  state = {
    addTag: '',
    tags: [],
    mediaLoading: false,
  }

  hasLoaded = () => {
    return this.props.profile.loaded
      && this.props.meal && this.props.meal.loaded
      && this.props.mealOptions && this.props.mealOptions.loaded
      && this.props.tags.loaded
      && this.props.openRequests.loaded
  }

  onClickAcceptMealChangeRequest = async requestId => {
    await this.props.acceptRequest(requestId)
    await Promise.all([
      this.props.getOpenRequests(),
      this.props.getMeal(this.props.match.params.mealId),
    ])
    this.props.history.push('/shops')
  }

  onClickDenyMealChangeRequest = async requestId => {
    await this.props.deleteRequest(requestId)
    await Promise.all([
      this.props.getOpenRequests(),
      this.props.getMeal(this.props.match.params.mealId),
    ])
    this.props.history.push('/shops')
  }

  onUploadFile = async event => {
    this.setState({ mediaLoading: true })

    for (let file of event.target.files) {
      const upload = await this.props.uploadImage(file)

      for (let item of upload) {
        await this.props.addMediaToMeal({
          mealId: this.props.match.params.mealId,
          path: item.original,
          uuid: item.uuid,
        })
      }
    }

    await this.props.getMeal(this.props.match.params.mealId)

    this.setState({ mediaLoading: false })
  }

  onClickRemoveImage = async image => {
    this.setState({ mediaLoading: true })
    await this.props.removeMediaFromMeal({
      mealId: this.props.match.params.mealId,
      uuid: image,
    })
    await this.props.getMeal(this.props.match.params.mealId)
    this.setState({ mediaLoading: false })
  }

  onChangeAddTag = event => {
    this.setState({
      addTag: event.currentTarget.value,
    })
  }

  onSelectAddTag = name => {
    const newTag = this.props.tags.data.find(tag => tag.name === name)

    if (!this.state.tags.find(tag => tag.name === newTag.name)) {
      this.setState({
        tags: [...this.state.tags, newTag],
      })

      this.props.tagMeal({
        mealId: parseInt(this.props.match.params.mealId, 10),
        tagId: newTag.id,
      })
    }
  }

  onRemoveTag = oldTag => {
    this.setState({
      tags: this.state.tags.filter(tag => tag.name !== oldTag.name),
    })

    this.props.untagMeal({
      mealId: parseInt(this.props.match.params.mealId, 10),
      tagId: oldTag.id,
    })
  }

  onClickSubmit = async values => {
    await this.props.editMeal(this.props.match.params.mealId, values)
    await this.props.getMeal(this.props.match.params.mealId)
    this.props.dispatch(reset('meal'))
  }

  async componentWillMount() {
    let meal

    if (!this.hasLoaded()) {
      this.props.getProfile()
      this.props.getTags()
      this.props.getOpenRequests()
      this.props.getMealOptions(this.props.match.params.mealId)
      meal = await this.props.getMeal(this.props.match.params.mealId)
    } else {
      meal = this.props.meal.data
    }

    this.setState({
      tags: meal.tags,
    })
  }

  render() {
    if (!this.hasLoaded()) {
      return false
    }

    const {
      handleSubmit,
      pristine,
    } = this.props

    return (
      <div className="meal">
        <div className="meal__content">
          {this.props.openRequestForMeal && (
            <div
              key={this.props.openRequestForMeal.id}
              className="meal__change-request">
              <div className="meal__change-request-top">
                <div className="meal__change-request-icon" />
                <div className="meal__change-request-title">
                  Needs a review ({moment(this.props.openRequestForMeal.createdAt).format('MMMM Do')})
                </div>
              </div>
              <div className="meal__change-request-text">
                <FormattedMessage
                  id="meal.request-title"
                  defaultMessage="{requesterName} wants to edit this meal:"
                  values={{
                    requesterName: this.props.openRequestForMeal.requesterName,
                  }} />
              </div>
              <ul>
                {this.props.openRequestForMeal.operations.map(operation => (
                  <li key={operation.type}>
                    {this.props.intl.formatMessage(OPERATIONS_TO_MESSAGES[operation.type], {
                      previousValue: operation.previousValue,
                      newValue: operation.newValue,
                    })}
                    {operation.type === 'CHANGE_MEAL_IMAGES' && (
                      <div className="meal__change-request-images">
                        {operation.newValue.map(image => (
                          <div
                            key={image}
                            className="meal__change-request-image"
                            style={{
                              backgroundImage: `url(${getUrlForImage(image)})`,
                            }} />
                        ))}
                      </div>
                    )}
                    {operation.type === 'CHANGE_MEAL_OPTIONS' && (
                      <ul className="meal__change-request-options">
                        {operation.newValue.map(option => (
                          <li key={option.id}>
                            {option.name}
                            {option.required && ` (${this.props.intl.formatMessage(messages.optionsRequired)})`}
                            {option.allowMultiple && ` (${this.props.intl.formatMessage(messages.optionsAllowMultiple)})`}
                            <ul className="meal__change-request-option">
                              {option.values.map((value, index) => (
                                <li key={index}>
                                  {value.name} ({value.cost > 0 ? `￥${value.cost}` : 'free' })
                                </li>
                              ))}
                            </ul>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
              <Button
                variant="contained"
                color="primary"
                className={this.props.classes.button}
                onClick={() => this.onClickAcceptMealChangeRequest(this.props.openRequestForMeal.id)}>
                Accept
              </Button>
              <Button
                variant="contained"
                color="secondary"
                className={this.props.classes.button}
                onClick={() => this.onClickDenyMealChangeRequest(this.props.openRequestForMeal.id)}>
                Deny
              </Button>
            </div>
          )}
          <form>
            <div className="meal__form">
              <Field
                name="name"
                type="text"
                component={FormField}
                label="Name" />
              <div className="meal__item">
                <div className="meal__item-key">
                  Shop
                </div>
                <Link to={`/shops/${this.props.meal.data.shop.id}`}>
                  <div className="meal__item-value">
                    {this.props.meal.data.shop.name}
                  </div>
                </Link>
              </div>
              <div className="meal__horizontal-field">
                <Field
                  name="price"
                  type="number"
                  component={FormField}
                  label="Price (¥)" />
                <Field
                  name="ticketQuantity"
                  type="number"
                  component={FormField}
                  label="Ticket Amount" />
              </div>
              <Field
                name="dailyReservationLimit"
                type="text"
                component={FormField}
                placeholder="unlimited"
                label="Reservation Limit (daily)" />
              <div className="meal__item">
                <div className="meal__item-key">
                  Images
                </div>
                <div className="meal__item-value">
                  {this.state.mediaLoading && (
                    <div className="meal__item-loading">
                      <div className="meal__item-loading-icon" />
                    </div>
                  )}
                  <input
                    multiple
                    label='upload file'
                    type='file'
                    onChange={this.onUploadFile} />
                  {this.props.meal.data.images.map(image => (
                    <div
                      key={image}
                      className="meal__image"
                      style={{
                        backgroundImage: `url(${getUrlForImage(image)})`,
                      }}>
                      {this.props.meal.data.images.length > 1 && (
                        <div
                          className="meal__image-delete"
                          onClick={() => this.onClickRemoveImage(image)}>
                          <div className="meal__image-delete-icon" />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <div className="meal__item">
                <div className="meal__item-key">
                  Options
                </div>
                <div className="meal__item-value">
                  <ul className="meal__change-request-options">
                    {this.props.mealOptions.data.map(option => (
                      <li key={option.id}>
                        {option.name}
                        <ul className="meal__change-request-option">
                          {option.values.map((value, index) => (
                            <li key={index}>
                              {value.name} ({value.cost > 0 ? `￥${value.cost}` : 'free' })
                            </li>
                          ))}
                        </ul>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="meal__item">
                <div className="meal__item-key">
                  Tags
                </div>
                <div className="meal__item-value">
                  <div className="meal__tags-input-wrapper">
                    <Autocomplete
                      value={this.state.addTag}
                      inputProps={{
                        className: 'meal__tags-input',
                        placeholder: 'add tags',
                      }}
                      items={this.props.tags.data}
                      getItemValue={tag => tag.name}
                      shouldItemRender={(tag, value) => tag.name.toLowerCase().includes(value.toLowerCase())}
                      renderItem={(item, isHighlighted) => (
                        <div
                          key={item.name}
                          className={classNames('meal__tags-input-option', {
                          'meal__tags-input-option--highlighted': isHighlighted,
                        })}>
                          {item.name}
                        </div>
                      )}
                      onChange={this.onChangeAddTag}
                      onSelect={this.onSelectAddTag} />
                  </div>
                  <div className="meal__tags">
                    {this.state.tags.map(tag => (
                      <div
                        key={tag.name}
                        className="meal__tag">
                        {tag.name}
                        <div
                          className="meal__tag-delete"
                          onClick={() => this.onRemoveTag(tag)}>
                          <div className="meal__tag-delete-icon" />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <Field
                name="details"
                type="text"
                component={FormFieldTextarea}
                label="Details" />
              <Field
                name="ingredients"
                type="text"
                component={FormFieldTextarea}
                label="Ingredients" />
              <Field
                name="pickupInstructions"
                type="text"
                component={FormFieldTextarea}
                label="Pickup Instructions" />
              <Field
                name="deliveryEnabled"
                type="checkbox"
                component={FormFieldCheckboxIOS}
                label="Delivery enabled" />
              <Field
                name="showInShopsApp"
                type="checkbox"
                component={FormFieldCheckboxIOS}
                label="Show in Shops App" />
              <Field
                name="showInMarketplace"
                type="checkbox"
                component={FormFieldCheckboxIOS}
                label="Show in Potluck" />
              <Field
                name="deleted"
                type="checkbox"
                component={FormFieldCheckboxIOS}
                label="Deleted" />
              <BigButton
                text="登録する"
                inactive={pristine}
                loading={(this.props.editMealState && this.props.editMealState.loading) || this.props.meal.loading}
                onClick={handleSubmit(this.onClickSubmit)} />
            </div>
          </form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const props = {
    profile: state.api.profile.default,
    meal: state.api.meal[ownProps.match.params.mealId],
    mealOptions: state.api.mealOptions[ownProps.match.params.mealId],
    openRequests: state.api.openRequests.default,
    tags: state.api.tags.default,
    editMealState: state.api.editMeal[ownProps.match.params.mealId],
  }

  const mealId = parseInt(ownProps.match.params.mealId, 10)

  if (props.meal && props.meal.data) {
    props.initialValues = {
      name: props.meal.data.name,
      price: props.meal.data.price,
      ticketQuantity: props.meal.data.ticketQuantity,
      dailyReservationLimit: props.meal.data.dailyReservationLimit,
      details: props.meal.data.details,
      ingredients: props.meal.data.material,
      pickupInstructions: props.meal.data.pickupInstructions,
      deliveryEnabled: props.meal.data.deliveryEnabled,
      showInShopsApp: props.meal.data.showInShopsApp,
      showInMarketplace: props.meal.data.showInMarketplace,
      deleted: props.meal.data.deleted,
    }
  }

  if (state.api.openRequests.default.loaded) {
    props.openRequestForMeal = state.api.openRequests.default.data.find(openRequest => openRequest.operations.some(operation => operation.mealId === mealId))
  }

  return props
}

const mapDispatchToProps = {
  getProfile,
  getMeal,
  getMealOptions,
  getOpenRequests,
  getTags,
  tagMeal,
  untagMeal,
  editMeal,
  uploadImage,
  addMediaToMeal,
  removeMediaFromMeal,
  deleteRequest,
  acceptRequest,
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: FORM_NAME,
  validate,
  enableReinitialize: true,
})(withRouter(injectIntl(withStyles(styles)(Meal)))))
