import React from 'react'
import './FormFieldCheckboxIOS.css'

export default ({
  input,
  label,
  meta: {
    touched,
    error,
  },
}) => (
  <div className="form-field-checkbox-ios">
    <input
      {...input}
      checked={input.value}
      className="form-field-checkbox-ios-input"
      type="checkbox" />
    {label && (
      <div className="form-field-checkbox-ios-label">
        {label}
      </div>
    )}
    {touched && (error && (
      <span className="form-field-checkbox-ios-error">
        {error}
      </span>
    ))}
  </div>
)
