export default (values, props) => {
  const errors = {}

  if (!values.password) {
    errors.password = 'required'
  }

  if (!values.confirmPassword) {
    errors.confirmPassword = 'required'
  } else if (values.password !== values.confirmPassword) {
    errors.confirmPassword = 'Passwords must match'
  }

  return errors
}
