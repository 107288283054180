export default {
  white: '#FFFFFF',
  black: '#000000',
  primaryPink: '#FF6069',
  secondaryPink: '#FFA0A5',
  primaryCharcoal: '#232323',
  secondaryCharcoal: '#666666',
  primaryGray: '#B0B4BA',
  secondaryGray: '#979797',
  borderGray: '#E2E3E2',
  backgroundGray: '#F4F4F4',

  disabledGray: '#EEEEEE',
  mutedGray: '#C4C4C4',
  softGray: '#DADCDF',

  whitesmoke: '#F8F9FA',
  borderWhitesmoke: '#EBEBED',

  placeholderText: '#a3a3a3',

  alertRed: '#FF3B30', // iOS badge color
}
