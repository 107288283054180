import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Redirect,
  withRouter,
} from 'react-router-dom'
import moment from 'moment-timezone'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import AddIcon from '@material-ui/icons/Add'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import {
  getProfile,
  getShops,
  getOpenRequests,
} from '../../redux/api/actions'

const styles = theme => ({
  root: {
    flex: '1 0 auto',
  },
  content: {
    margin: theme.spacing.unit * 3,
  },
  toolbar: {
    display: 'flex',
  },
  actions: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  title: {
    flex: '1',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
})

const getRequestType = request => {
  if (request.operations[0].type === 'NEW_MEAL_OPERATION') {
    return `New Meal (${request.operations[0].values.name})`
  } else if (request.operations[0].type.includes('CHANGE_MEAL')) {
    return 'Edit Meal'
  } else if (request.operations[0].type === 'NEW_SIDE_OPERATION') {
    return `New Side (${request.operations[0].values.name})`
  } else if (request.operations[0].type.includes('CHANGE_SIDE')) {
    return 'Edit Side'
  } else {
    return 'Other'
  }
}

class Shops extends Component {
  state = {
    search: '',
  }

  hasLoaded = () => {
    return this.props.profile.loaded
      && this.props.shops.loaded
      && this.props.openRequests.loaded
  }

  onChangeSearch = event => {
    this.setState({
      search: event.currentTarget.value,
    })
  }

  onKeyPressSearch = event => {
    if (event.key === 'Enter') {
      // this.onClickCreateTag()
    }
  }

  onClickRequest = request => {
    if (request.operations[0].type === 'NEW_MEAL_OPERATION') {
      this.props.history.push(`/meal-requests/${request.id}`)
    } else if (request.operations[0].type.includes('CHANGE_MEAL')) {
      this.props.history.push(`/meals/${request.operations[0].mealId}`)
    } else if (request.operations[0].type === 'NEW_SIDE_OPERATION') {
      this.props.history.push(`/side-requests/${request.id}`)
    } else if (request.operations[0].type.includes('CHANGE_SIDE')) {
      this.props.history.push(`/sides/${request.operations[0].sideId}`)
    }
  }

  onClickShop = shop => {
    this.props.history.push(`/shops/${shop.id}`)
  }

  onClickAddShop = () => {
    this.props.history.push('/shops/new')
  }

  componentWillMount() {
    if (!this.hasLoaded()) {
      this.props.getProfile()
      this.props.getShops()
      this.props.getOpenRequests()
    }
  }

  render() {
    if (!this.hasLoaded()) {
      return false
    }

    if (!this.props.profile.data) {
      return (
        <Redirect to="/login" />
      )
    }

    const filteredShops = this.props.shops.data.filter(shop =>
      !this.state.search
      || shop.name.toLowerCase().includes(this.state.search.toLowerCase()))

    return (
      <div className={this.props.classes.root}>
        {this.props.openRequests.data.length > 0 && (
          <Paper className={this.props.classes.content}>
            <Toolbar className={this.props.classes.toolbar}>
              <div className={this.props.classes.title}>
                <Typography variant="h6">
                  Requests ({this.props.openRequests.data.length})
                </Typography>
              </div>
            </Toolbar>
            <div className={this.props.classes.tableWrapper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Shop</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Requester</TableCell>
                    <TableCell>Created</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.openRequests.data.map(openRequest => (
                    <TableRow
                      key={openRequest.id}
                      hover
                      onClick={() => this.onClickRequest(openRequest)}>
                      <TableCell component="th" scope="row">
                        {openRequest.shop.name}
                      </TableCell>
                      <TableCell>{getRequestType(openRequest)}</TableCell>
                      <TableCell>{openRequest.requesterName}</TableCell>
                      <TableCell>{moment(openRequest.createdAt).tz('Asia/Tokyo').format('kk:mm MMMM Do YYYY')}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </Paper>
        )}
        <Paper className={this.props.classes.content}>
          <Toolbar className={this.props.classes.toolbar}>
            <div className={this.props.classes.title}>
              <Typography variant="h6">
                Shops
              </Typography>
            </div>
            <div className={this.props.classes.actions}>
              <Tooltip title="Add">
                <IconButton
                  aria-label="Add"
                  color="primary"
                  onClick={this.onClickAddShop}>
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </div>
          </Toolbar>
          <div className={this.props.classes.tableWrapper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>id</TableCell>
                  <TableCell>name</TableCell>
                  <TableCell>status</TableCell>
                  <TableCell>area</TableCell>
                  <TableCell>email</TableCell>
                  <TableCell>Referral Code</TableCell>
                  <TableCell>created</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredShops.map(shop => (
                  <TableRow
                    key={shop.id}
                    hover
                    onClick={() => this.onClickShop(shop)}>
                    <TableCell>{shop.id}</TableCell>
                    <TableCell>{shop.name}</TableCell>
                    <TableCell>{shop.deleted ? 'Closed' : 'Open'}</TableCell>
                    <TableCell>{shop.neighborhood.name}</TableCell>
                    <TableCell>{shop.contact.email}</TableCell>
                    <TableCell>{shop.referralCode}</TableCell>
                    <TableCell>{moment.tz(shop.createdAt, 'Asia/Tokyo').format('MMMM Do YYYY')}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </Paper>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  profile: state.api.profile.default,
  shops: state.api.shops.default,
  openRequests: state.api.openRequests.default,
})

const mapDispatchToProps = {
  getProfile,
  getShops,
  getOpenRequests,
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(Shops)))
