const REQUIRED_VALUES = [
  'name',
  'neighborhoodId',
  'zip',
  'address',
  'contactName',
  'contactEmail',
]

const DAYS = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
]

export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i
export const PHONE_REGEX = /^[+][0-9]{11,13}$/i
export const DESCRIPTOR_REGEX = /^[a-zA-Z0-9-_]+$/
export const TIME_REGEX = /^((?:[01]\d:[0-5][0-9]|2[0-3]:[0-5][0-9])(?:\s?)-(?:\s?)(?:[01]\d:[0-5][0-9]|2[0-3]:[0-5][0-9])(?:\s??)?)+$/

export default (values, props) => {
  const errors = {
    hours: {},
  }

  for (let requiredValue of REQUIRED_VALUES) {
    if (!values[requiredValue]) {
      errors[requiredValue] = 'Required'
    }
  }

  for (let day of DAYS) {
    if (values.hours && values.hours[day] && !TIME_REGEX.test(values.hours[day])) {
      errors.hours[day] = 'Invalid range (must be like \'10:00-12:00\')'
    }
  }

  if (values.descriptor && !DESCRIPTOR_REGEX.test(values.descriptor)) {
    errors.descriptor = 'Invalid descriptor'
  }

  if (values.email && !EMAIL_REGEX.test(values.email)) {
    errors.email = 'Invalid email'
  }

  return errors
}
