import React, { Component } from 'react'
import {
  withRouter,
  matchPath,
} from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Hidden from '@material-ui/core/Hidden'
import Drawer from '@material-ui/core/Drawer'
import DashboardRoundedIcon from '@material-ui/icons/DashboardRounded'
import InsertChartOutlinedRoundedIcon from '@material-ui/icons/InsertChartOutlinedRounded'
import PersonRoundedIcon from '@material-ui/icons/PersonRounded'
import LocationCityIcon from '@material-ui/icons/LocationCityRounded'
import LocalOfferRoundedIcon from '@material-ui/icons/LocalOfferRounded'
import AttachMoneyRoundedIcon from '@material-ui/icons/AttachMoneyRounded'
import GroupRoundedIcon from '@material-ui/icons/GroupRounded'
import StoreRoundedIcon from '@material-ui/icons/StoreRounded'
import FastfoodRoundedIcon from '@material-ui/icons/FastfoodRounded'
import HowToVoteRoundedIcon from '@material-ui/icons/HowToVoteRounded'
import DirectionsBikeRoundedIcon from '@material-ui/icons/DirectionsBikeRounded'
import MapRoundedIcon from '@material-ui/icons/MapRounded'
import MonetizationOnRoundedIcon from '@material-ui/icons/MonetizationOnRounded'
import DateRangeRoundedIcon from '@material-ui/icons/DateRangeRounded'
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone'
import BusinessRoundedIcon from '@material-ui/icons/BusinessRounded'
import canUseDOM from '../../utils/canUseDOM'
import './Nav.css'

const SECTIONS = [{
  label: 'Overview',
  icon: ({ color = 'inherit' }) => (<DashboardRoundedIcon color={color} />),
  link: '/dashboard',
  path: '/dashboard',
}, {
  label: 'KPIs',
  icon: ({ color = 'inherit' }) => (<InsertChartOutlinedRoundedIcon color={color} />),
  link: '/kpis',
  path: '/kpis',
}, {
  label: 'Areas',
  icon: ({ color = 'inherit' }) => (<LocationCityIcon color={color} />),
  link: '/areas',
  path: '/areas',
}, {
  label: 'Users',
  icon: ({ color = 'inherit' }) => (<PersonRoundedIcon color={color} />),
  link: '/users',
  path: '/(users|grants)',
}, {
  label: 'Shop Users',
  icon: ({ color = 'inherit' }) => (<PersonRoundedIcon color={color} />),
  link: '/shop-users',
  path: '/shop-users',
}, {
  label: 'Organizations',
  icon: ({ color = 'inherit' }) => (<GroupRoundedIcon color={color} />),
  link: '/organizations',
  path: '/organizations',
}, {
  label: 'Offers & Products',
  icon: ({ color = 'inherit' }) => (<LocalOfferRoundedIcon color={color} />),
  link: '/offers-and-products',
  path: '/(offers-and-products|offers|products)',
}, {
  label: 'Subscriptions',
  icon: ({ color = 'inherit' }) => (<AttachMoneyRoundedIcon color={color} />),
  link: '/subscriptions',
  path: '/subscriptions',
}, {
  label: 'Shops',
  icon: ({ color = 'inherit' }) => (<StoreRoundedIcon color={color} />),
  link: '/shops',
  path: '/(shops|sides)',
}, {
  label: 'Meals',
  icon: ({ color = 'inherit' }) => (<FastfoodRoundedIcon color={color} />),
  link: '/meals',
  path: '/meals',
}, {
  label: 'Orders',
  icon: ({ color = 'inherit' }) => (<HowToVoteRoundedIcon color={color} />),
  link: '/orders',
  path: '/(order|orders)',
}, {
  label: 'Deliveries',
  icon: ({ color = 'inherit' }) => (<DirectionsBikeRoundedIcon color={color} />),
  link: '/deliveries',
  path: '/deliveries',
}, {
  label: 'Delivery zones',
  icon: ({ color = 'inherit' }) => (<MapRoundedIcon color={color} />),
  link: '/delivery-zones',
  path: '/delivery-zones',
}, {
  label: 'Payments (new)',
  icon: ({ color = 'inherit' }) => (<MonetizationOnRoundedIcon color={color} />),
  link: '/payments-new',
  path: '/payments-new',
}, {
  label: 'Payments',
  icon: ({ color = 'inherit' }) => (<MonetizationOnRoundedIcon color={color} />),
  link: '/payments',
  path: '/payments',
}, {
  label: 'Holidays',
  icon: ({ color = 'inherit' }) => (<DateRangeRoundedIcon color={color} />),
  link: '/holidays',
  path: '/holidays',
}, {
  label: 'App Versions',
  icon: ({ color = 'inherit' }) => (<PhoneIphoneIcon color={color} />),
  link: '/app-versions',
  path: '/app-versions',
}, {
  label: 'Companies',
  icon: ({ color = 'inherit' }) => (<BusinessRoundedIcon color={color} />),
  link: '/companies',
  path: '/companies',
}]

const styles = theme => ({
  drawer: {
    flexShrink: 0,
  },
  drawerPaper: {
    width: 240,
    [theme.breakpoints.up('md')]: {
      paddingTop: 64,
    },
  },
})

class DrawerUnwrapped extends Component {
  onClickItem = path => {
    this.props.history.push(path)

    if (this.props.mobileDrawerOpen) {
      this.props.onDrawerToggle()
    }
  }

  render() {
    let pathname

    if (canUseDOM()) {
      pathname = window.location.pathname
    } else {
      pathname = this.props.location.pathname
    }

    const drawer = (
      <List>
        {SECTIONS.map(item => {
          const selected = matchPath(pathname, item)

          return (
            <ListItem
              button
              key={item.link}
              onClick={() => this.onClickItem(item.link)}>
              <ListItemIcon>
                {item.icon({
                  color: selected ? 'primary' : 'inherit',
                })}
              </ListItemIcon>
              <ListItemText primary={item.label} />
            </ListItem>
          )
        })}
      </List>
    )

    return (
      <div>
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            container={this.props.container}
            open={this.props.mobileDrawerOpen}
            className={this.props.classes.drawer}
            classes={{
              paper: this.props.classes.drawerPaper,
            }}
            onClose={this.props.onDrawerToggle}>
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: this.props.classes.drawerPaper,
            }}
            variant="permanent"
            open>
            {drawer}
          </Drawer>
        </Hidden>
      </div>
    )
  }
}

export default withRouter(withStyles(styles)(DrawerUnwrapped))
