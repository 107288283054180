import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Field,
  reduxForm,
} from 'redux-form'
import {
  Link,
  Redirect,
  withRouter,
} from 'react-router-dom'
import {
  injectIntl,
  FormattedMessage,
} from 'react-intl'
import moment from 'moment-timezone'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import {
  getProfile,
  getOpenRequests,
  deleteRequest,
  acceptRequest,
} from '../redux/api/actions'
import getUrlForImage from '../utils/getUrlForImage'
import FormField from '../components/FormField/FormField'

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
    marginTop: theme.spacing.unit * 2,
  },
  input: {
    display: 'none',
  },

  side: {
    flex: 1,
  },
  sideContent: {
    padding: 20,
    width: 600,
  },
  sideChangeRequest: {
    padding: 20,
    backgroundColor: '#69bf07',
    color: 'white',
    fontSize: '16px',
  },
  sideChangeRequestTop: {
    display: 'flex',
    marginBottom: 14,
  },
  sideChangeRequestIcon: {
    height: 24,
    width: 24,
    marginRight: 10,
    backgroundImage: 'url(https://s3-ap-northeast-1.amazonaws.com/potluck-webapp/info.svg)',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  sideChangeRequestTitle: {
    flex: 1,
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 'bold',
  },
  sideChangeRequestText: {
    fontSize: '14px',
  },
  sideChangeRequestImages: {
    display: 'flex',
  },
  sideChangeRequestImage: {
    height: 50,
    width: 75,
    borderRadius: 2,
    margin: 5,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  sideForm: {
    fontSize: '14px',
    marginBottom: 20,
  },
  sideItem: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    margin: '12px 0 16px 0',
    fontSize: '16px',
    position: 'relative',
  },
  sideItemKey: {
    marginBottom: 10,
    color: 'rgba(51, 51, 51, 1)',
  },
  sideItemValue: {
    flex: 1,
  },
  sideItemLoading: {
    zIndex: 10,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  sideHorizontalField:{
    '& > div:not(:last-child)': {
      marginRight: 20,
    }
  },
  sideImage: {
    position: 'relative',
    height: 400,
    width: 600,
    borderRadius: 4,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    ':not(:first-child)': {
      marginTop: 20,
    },
  },
})

class SideRequest extends Component {
  state = {
    mediaLoading: false,
  }

  hasLoaded = () => {
    return this.props.profile.loaded
      && this.props.openRequests.loaded
  }

  onClickAcceptSideChangeRequest = async requestId => {
    const result = await this.props.acceptRequest(requestId)

    this.props.getOpenRequests()
    this.props.history.push(`/sides/${result.insertId}`)
  }

  onClickDenySideRequest = async requestId => {
    await this.props.deleteRequest(requestId)
    this.props.getOpenRequests()
    this.props.history.push('/shops')
  }

  async componentWillMount() {
    if (!this.hasLoaded()) {
      this.props.getProfile()
      this.props.getOpenRequests()
    }
  }

  render() {
    if (!this.hasLoaded()) {
      return false
    }

    if (!this.props.request) {
      return (<Redirect to="/shops" />)
    }

    return (
      <div className={this.props.classes.side}>
        <div className={this.props.classes.sideContent}>
          <div className={this.props.classes.sideChangeRequest}>
            <div className={this.props.classes.sideChangeRequestTop}>
              <div className={this.props.classes.sideChangeRequestIcon} />
              <div className={this.props.classes.sideChangeRequestTitle}>
                Needs a review ({moment(this.props.request.createdAt).format('MMMM Do')})
              </div>
            </div>
            <div className={this.props.classes.sideChangeRequestText}>
              <FormattedMessage
                id="side.request-title"
                defaultMessage="{requesterName} wants to add this new side to their shop:"
                values={{
                  requesterName: this.props.request.requesterName,
                }} />
            </div>
            <Button
              variant="contained"
              color="primary"
              className={this.props.classes.button}
              disabled={this.props.openRequests.loading || this.props.acceptRequestState.loading || this.props.deleteRequestState.loading}
              onClick={() => this.onClickAcceptSideChangeRequest(this.props.request.id)}>
              Accept
            </Button>
            <Button
              variant="contained"
              color="secondary"
              className={this.props.classes.button}
              disabled={this.props.openRequests.loading || this.props.acceptRequestState.loading || this.props.deleteRequestState.loading}
              onClick={() => this.onClickDenySideRequest(this.props.request.id)}>
              Deny
            </Button>
          </div>
          <form>
            <div className={this.props.classes.sideForm}>
              <Field
                disabled
                name="name"
                type="text"
                component={FormField}
                label="Name" />
              <div className={this.props.classes.sideItem}>
                <div className={this.props.classes.sideItemKey}>
                  Shop
                </div>
                <Link to={`/shops/${this.props.request.shop.id}`}>
                  <div className={this.props.classes.sideItemValue}>
                    {this.props.request.shop.name}
                  </div>
                </Link>
              </div>
              <Field
                disabled
                name="price"
                type="number"
                component={FormField}
                label="Price (¥)" />
              <Field
                disabled
                name="dailyReservationLimit"
                type="text"
                component={FormField}
                placeholder="unlimited"
                label="Reservation Limit (daily)" />
              <div className={this.props.classes.sideItem}>
                <div className={this.props.classes.sideItemKey}>
                  Images
                </div>
                <div className={this.props.classes.sideItemValue}>
                  {this.props.request.operations[0].values.images && this.props.request.operations[0].values.images.map(image => (
                    <div
                      key={image}
                      className={this.props.classes.sideImage}
                      style={{
                        backgroundImage: `url(${getUrlForImage(image)})`,
                      }} />
                  ))}
                </div>
              </div>
              <Field
                disabled
                name="category"
                type="text"
                component={FormField}
                label="Category" />
            </div>
          </form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const props = {
    profile: state.api.profile.default,
    openRequests: state.api.openRequests.default,
    acceptRequestState: state.api.acceptRequest.default,
    deleteRequestState: state.api.deleteRequest.default,
  }

  const requestId = parseInt(ownProps.match.params.requestId, 10)

  if (props.openRequests.data) {
    props.request = props.openRequests.data.find(openRequest => openRequest.id === requestId)

    if (props.request) {
      props.initialValues = {
        name: props.request.operations[0].values.name,
        price: props.request.operations[0].values.price,
        ticketQuantity: props.request.operations[0].values.ticketQuantity,
        dailyReservationLimit: props.request.operations[0].values.dailyReservationLimit,
        category: props.request.operations[0].values.category,
      }
    }
  }
  return props
}

const mapDispatchToProps = {
  getProfile,
  getOpenRequests,
  deleteRequest,
  acceptRequest,
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'side-request',
  enableReinitialize: true,
})(withRouter(injectIntl(withStyles(styles)(SideRequest)))))
