import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
// import logger from 'redux-logger'
import {
  createStore,
  combineReducers,
  applyMiddleware,
} from 'redux'
import thunk from 'redux-thunk'
import { reducer as formReducer } from 'redux-form'
import './index.css'
import App from './App'
import { unregister } from './registerServiceWorker'
import api from './redux/api/reducer'
import features from './redux/features/reducer'

const preloadedState = window.__PRELOADED_STATE__
delete window.__PRELOADED_STATE__

const store = createStore(combineReducers({
  api,
  features,
  form: formReducer,
}), preloadedState, applyMiddleware(thunk/*, logger */))

render((
  <Provider store={store}>
    <App />
  </Provider>
), document.getElementById('root'))

unregister()
