import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment-timezone'
import { withStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import AppleIcon from '@material-ui/icons/Apple'
import AdbIcon from '@material-ui/icons/Adb'
import { green } from '@material-ui/core/colors'
import {
  getAppVersionStatus,
  createAppVersionStatus,
} from '../redux/api/actions'

const OSNames = {
  IOS: 'ios',
  ANDROID: 'android',
}

const AppVersionStatuses = {
  GOOD: 'good',
  WARNING: 'warning',
  FORCE_UPDATE: 'force-update',
}

/*
 * These are the earliest versions of the apps which have forced app updates. Therefore
 * we should not allow setting app version rules on any versions earlier than these.
 */
const minIOSAppVerion = '1.49'
const minAndroidAppVersion = '65.0'

const getAppVersionNumber = version => parseInt(version.replace(/\./g, ''))

const getKeyForAppVersion = appVersion => `${appVersion.os}-${appVersion.version}`

const isAppVersionEditable = appVersion => {
  const appVersionNumber = getAppVersionNumber(appVersion.version)

  if (appVersion.os === OSNames.IOS) {
    return appVersionNumber >= getAppVersionNumber(minIOSAppVerion)
  } else if (appVersion.os === OSNames.ANDROID) {
    return appVersionNumber >= getAppVersionNumber(minAndroidAppVersion)
  }

  return false
}

const styles = theme => ({
  root: {
    flex: '1 0 auto',
  },
  content: {
    margin: theme.spacing.unit * 3,
  },
  cell: {
    textOverflow: 'ellipses',
  },
  cellMedium: {
    maxWidth: 120,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  },
  cellLarge: {
    maxWidth: 260,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  },
})

class AppVersions extends Component {
  constructor(props) {
    super(props)

    this.state = {
      tabIndex: 0,
      versions: [],
    }
  }

  async onChangeTab(tabIndex) {
    const appVersions = await this.props.getAppVersionStatus()
    const os = Object.values(OSNames)[tabIndex]

    this.setState({
      tabIndex,
      versions: appVersions.filter(version => version.os === os),
    })
  }

  async onChangeVersionStatus(status, version) {
    /* Update the data locally */
    this.setState({
      versions: this.state.versions.map(_version => ({
        ..._version,
        status: getKeyForAppVersion(_version) === getKeyForAppVersion(version) ? status : _version.status,
      }))
    })

    await this.props.createAppVersionStatus({
      ...version,
      status
    })
    // TODO: Call the API here to create a new version status
    this.props.getAppVersionStatus()
  }

  async componentWillMount() {
    const appVersions = await this.props.getAppVersionStatus()
      this.setState({
        versions: appVersions.filter(version => version.os === OSNames.IOS),
      })
    // TODO: Call the API here to get the data to display in the table
  }

  render() {
    return (
      <div className={this.props.classes.root}>
        <Paper className={this.props.classes.content}>
          <Tabs
            value={this.state.tabIndex}
            onChange={(event, tabIndex) => this.onChangeTab(tabIndex)}
            indicatorColor="primary">
            <Tab
              label="iOS"
              aria-label="ios"
              icon={<AppleIcon />} />
            <Tab
              label="Android"
              aria-label="android"
              icon={<AdbIcon />}
              style={{ color: green[500] }} />
          </Tabs>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell classes={{ root: this.props.classes.cellMedium }}>Version #</TableCell>
                <TableCell classes={{ root: this.props.classes.cellMedium }}>Number of Users</TableCell>
                <TableCell classes={{ root: this.props.classes.cellMedium }}>Status</TableCell>
                <TableCell classes={{ root: this.props.classes.cellLarge }}>Last used</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {this.state.versions.map(version => (
              <TableRow
                key={getKeyForAppVersion(version)}
                hover>
                <TableCell classes={{ root: this.props.classes.cellMedium }}>{version.version}</TableCell>
                <TableCell classes={{ root: this.props.classes.cellMedium }}>{version.activeUsers}</TableCell>
                <TableCell classes={{ root: this.props.classes.cellMedium }}>
                  <Select
                    value={version.status}
                    disabled={!isAppVersionEditable(version)}
                    onChange={event => this.onChangeVersionStatus(event.target.value, version)}>
                      {Object.values(AppVersionStatuses).map(status => (
                        <MenuItem
                          key={status}
                          value={status}>
                          {status}
                        </MenuItem>
                      ))}
                  </Select>
                </TableCell>
                <TableCell classes={{ root: this.props.classes.cellLarge }}>{moment.tz(version.lastActiveAt, 'Asia/Tokyo').format('MMMM Do YYYY')}</TableCell>
              </TableRow>
            ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
    )
  }
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = {
  getAppVersionStatus,
  createAppVersionStatus,
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(AppVersions)))
