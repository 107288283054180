import React from 'react'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import CircularProgress from '@material-ui/core/CircularProgress'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

const STATUSES = {
  NORMAL: {
    key: 'normal',
    label: 'Active',
  },
  CANCELED: {
    key: 'canceled',
    label: 'Canceled',
  },
  MISSED: {
    key: 'missed',
    label: 'User missed',
  },
  LATE: {
    key: 'late',
    label: 'User late',
  },
  DELETED: {
    key: 'deleted',
    label: 'Deleted',
  },
}

const getStatusForReservation = reservation => {
  if (reservation.deleted) {
    return STATUSES.DELETED
  } else if (reservation.canceled) {
    return STATUSES.CANCELED
  }

  return Object.values(STATUSES).find(status => status.key === reservation.status)
}

export default withStyles(theme => ({
  content: {
    margin: theme.spacing.unit * 3,
    position: 'relative',
  },
  toolbar: {
    display: 'flex',
  },
  actions: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  title: {
    flex: '1',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  progressRow: {
    height: 140,
  },
  progress: {
    position: 'absolute',
    left: 'calc(50% - 20px)',
    top: 'calc(50% + 40px)',
  },
  cellProgress: {
    opacity: 0.5,
  },
  statusProgress: {
    position: 'absolute',
    left: 'calc(50% - 20px)',
    top: 'calc(50% - 20px)',
  },
}))(props => (
  <Paper className={props.classes.content}>
    <Toolbar className={props.classes.toolbar}>
      <div className={props.classes.title}>
        <Typography variant="h6">
          Reservations
        </Typography>
      </div>
      <div className={props.classes.actions}>
      </div>
    </Toolbar>
    <div className={props.classes.tableWrapper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>Customer</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Shop</TableCell>
            <TableCell>Meal</TableCell>
            <TableCell>Time</TableCell>
            <TableCell>Date</TableCell>
            {!props.readOnly && (
              <TableCell align="right">Status</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.loading && (
            <TableRow
              colSpan={9}
              className={props.classes.progressRow}>
              <TableCell>
                <CircularProgress className={props.classes.progress} />
              </TableCell>
            </TableRow>
          )}
          {!props.loading && props.reservations.length <= 0 && (
            <TableRow colSpan={9}>
              <TableCell>None</TableCell>
            </TableRow>
          )}
          {!props.loading && props.reservations.length > 0 && props.reservations.map((reservation, index) => (
            <TableRow key={reservation.id}>
              <TableCell>{index + 1}</TableCell>
              <TableCell>{reservation.user}</TableCell>
              <TableCell>{reservation.email}</TableCell>
              <TableCell>{reservation.shop.name}</TableCell>
              <TableCell>{reservation.meal.name}</TableCell>
              <TableCell>{reservation.pickup.time}</TableCell>
              <TableCell>{reservation.pickup.date}</TableCell>
              {!props.readOnly && (
                <TableCell
                  align="right"
                  className={classNames({
                    [props.classes.cellProgress]: props.loadingStatuses.includes(reservation.id),
                  })}>
                  <Select
                    value={getStatusForReservation(reservation).key}
                    onChange={event => props.onReservationStatusChanged(reservation, event)}>
                    {Object.values(STATUSES).map(status => (
                      <MenuItem
                        key={status.key}
                        value={status.key}>
                        {status.label}
                        {props.loadingStatuses.includes(reservation.id) && (
                          <CircularProgress classes={{
                            root: props.classes.statusProgress,
                          }} />
                        )}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  </Paper>
))
