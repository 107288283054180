import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Redirect,
  withRouter,
} from 'react-router-dom'
import moment from 'moment-timezone'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import CircularProgress from '@material-ui/core/CircularProgress'
import Tooltip from '@material-ui/core/Tooltip'
import CloudDownloadRoundedIcon from '@material-ui/icons/CloudDownloadRounded'
import {
  getPaymentInfoForMonth,
} from '../../redux/api/actions'
import formatPrice from '../../utils/formatPrice'
import NewPaginationBar from '../../components/NewPaginationBar/NewPaginationBar'

const styles = theme => ({
  root: {
    flex: '1 0 auto',
  },
  pages: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  content: {
    margin: theme.spacing.unit * 3,
    position: 'relative',
  },
  toolbar: {
    display: 'flex',
  },
  actions: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  title: {
    flex: '1',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  progressRow: {
    height: 140,
  },
  progress: {
    position: 'absolute',
    left: 'calc(50% - 20px)',
    top: 'calc(50% + 40px)',
  },
  shopRow: {
    backgroundColor: theme.palette.background.default,
  },
  shopCell: {
    fontWeight: 'bold',
  },
})

const START_DATE = '20180801'

class Payments extends Component {
  state = {
    pages: [],
  }

  constructor(props) {
    super(props)

    const now = moment.tz('Asia/Tokyo')

    const pages = []
    let initialPage
    const startDate = moment.tz(START_DATE, 'YYYYMMDD', 'Asia/Tokyo')

    while (startDate.isSameOrBefore(now)) {
      pages.push({
        label: startDate.format('M/YY'),
        value: startDate.format('YYYYMM'),
      })

      startDate.add(1, 'month')
    }

    if (!props.match.params.date) {
      initialPage = pages[pages.length - 1]
    } else {
      initialPage = pages.find(page => page.value === props.match.params.date)
    }

    this.state = {
      pages,
      initialPage,
    }
  }

  hasLoaded = () => {
    return this.props.paymentInfoForMonth && this.props.paymentInfoForMonth.loaded
  }

  isLoading = () => {
    return this.props.paymentInfoForMonth && this.props.paymentInfoForMonth.loading
  }

  onChangePage = page => {
    this.props.history.push(`/payments/${page.value}`)
    this.props.getPaymentInfoForMonth(page.value)
  }

  onClickDownloadCSV = () => {
    const grandTotal = this.props.paymentInfoForMonth.data.grandTotal
    const shops = this.props.paymentInfoForMonth.data.shops

    let csv = 'Shop,Meal,Shop ID,Meal ID,Tickets,Price,Reservations,Total Tickets,Total Price\n'

    for (let shop of shops) {
      csv += `${shop.name},,${shop.id},,,,${shop.count},${shop.totalTickets},${shop.totalYen} 円\n`

      for (let meal of shop.meals) {
        csv += `,${meal.name},${shop.id},${meal.id},${meal.ticketQuantity},${meal.price} 円,${meal.count},${meal.totalTickets},${meal.totalYen} 円\n`
      }
    }

    csv += `Grand Total,,,,,,${grandTotal.count},${grandTotal.totalTickets},${grandTotal.totalYen} 円\n`

    const file = new Blob([csv], {
      type: 'text/csv;charset=utf-8;',
    })
    const filename = `payments-${this.props.paymentsLastRequestedKey}.csv`

    /* IE10+ */
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(file, )
    } else {
      const a = document.createElement('a')
      const url = URL.createObjectURL(file, filename)
      a.href = url
      a.download = filename
      document.body.appendChild(a)
      a.click()
      setTimeout(() => {
          document.body.removeChild(a)
          window.URL.revokeObjectURL(url)
      }, 0)
    }
  }

  componentDidMount() {
    this.props.getPaymentInfoForMonth(this.state.initialPage.value)

    if (!this.props.match.params.date) {
      this.props.history.push(`/payments/${this.state.initialPage.value}`)
    }
  }

  render() {
    if (!this.props.profile.data) {
      return (
        <Redirect to="/login" />
      )
    }

    let shops
    let grandTotal
    let rows

    if (this.hasLoaded()) {
      ({
        shops,
        grandTotal,
      } = this.props.paymentInfoForMonth.data)

      rows = shops.map(shop => {
        let items = [(
          <TableRow
            key={`${this.props.match.params.date}-${shop.id}-header`}
            className={this.props.classes.shopRow}>
            <TableCell
              colSpan="4"
              className={this.props.classes.shopCell}>
              {shop.name}
            </TableCell>
            <TableCell className={this.props.classes.shopCell}>{shop.count}</TableCell>
            <TableCell className={this.props.classes.shopCell}>{shop.totalTickets}</TableCell>
            <TableCell className={this.props.classes.shopCell}>{formatPrice(shop.totalYen)} 円</TableCell>
          </TableRow>
        )]

        items = items.concat(shop.meals.map(meal => (
          <TableRow key={`${this.props.match.params.date}-${shop.id}-${meal.id}`}>
            <TableCell />
            <TableCell>{meal.name}</TableCell>
            <TableCell>{meal.ticketQuantity}</TableCell>
            <TableCell>{formatPrice(meal.price)} 円</TableCell>
            <TableCell>{meal.count}</TableCell>
            <TableCell>{meal.totalTickets}</TableCell>
            <TableCell>{formatPrice(meal.totalYen)} 円</TableCell>
          </TableRow>
        )))

        return {
          key: shop.name,
          el: items,
        }
      })

      rows.sort((first, second) => first.key > second.key)
    }

    return (
      <div className={this.props.classes.root}>
        <div className={this.props.classes.pages}>
          <NewPaginationBar
            pages={this.state.pages}
            initialPage={this.state.initialPage}
            onChangePage={this.onChangePage} />
        </div>
        <Paper className={this.props.classes.content}>
          <Toolbar className={this.props.classes.toolbar}>
            <div className={this.props.classes.title}>
              <Typography variant="h6">
                Payments
              </Typography>
            </div>
            <div className={this.props.classes.actions}>
              <Tooltip title="Download CSV">
                <IconButton
                  aria-label="Download CSV"
                  color="primary"
                  onClick={this.onClickDownloadCSV}>
                  <CloudDownloadRoundedIcon />
                </IconButton>
              </Tooltip>
            </div>
          </Toolbar>
          <div className={this.props.classes.tableWrapper}>
            <div className="payments__list">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Shop</TableCell>
                    <TableCell>Meal</TableCell>
                    <TableCell>Tickets</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Reservations</TableCell>
                    <TableCell>Total Tickets</TableCell>
                    <TableCell>Total Price</TableCell>
                  </TableRow>
                </TableHead>
                {this.isLoading() && (
                  <TableBody>
                    <TableRow
                      colSpan={7}
                      className={this.props.classes.progressRow}>
                      <TableCell>
                        <CircularProgress className={this.props.classes.progress} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
                {this.hasLoaded() && this.props.paymentInfoForMonth.data <= 0 && (
                  <TableBody>
                    <TableRow colSpan={7}>
                      <TableCell>None</TableCell>
                    </TableRow>
                  </TableBody>
                )}
                {this.hasLoaded() && (
                  <TableBody>
                    {rows.map(row => row.el)}
                    <TableRow
                      key={`${this.props.match.params.date}-grand-total`}
                      className={this.props.classes.shopRow}>
                      <TableCell
                        colSpan="4"
                        align="right"
                        className={this.props.classes.shopCell}>
                        Grand Total
                      </TableCell>
                      <TableCell className={this.props.classes.shopCell}>{grandTotal.count}</TableCell>
                      <TableCell className={this.props.classes.shopCell}>{grandTotal.totalTickets}</TableCell>
                      <TableCell className={this.props.classes.shopCell}>{formatPrice(grandTotal.totalYen)} 円</TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </div>
          </div>
        </Paper>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  profile: state.api.profile.default,
  paymentsLastRequestedKey: state.api.paymentInfoForMonth.lastRequestedKey,
  paymentInfoForMonth: state.api.paymentInfoForMonth[state.api.paymentInfoForMonth.lastRequestedKey],
})

const mapDispatchToProps = {
  getPaymentInfoForMonth,
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(Payments)))
