export const mealStatuses = {
  UNAVAILABLE: 'unavailable',
  AVAILABLE: 'available',
}

export const shopStatuses = {
  OPEN: 'open',
  CLOSED: 'closed',
}

export const orderTypes = {
  REGULAR: 'regular',
  NOW: 'now',
  DELIVERY: 'delivery',
}

export const grantStatuses = {
  NORMAL: 'normal',
  PAUSED: 'paused',
  RESUMED: 'resumed',
  UNPAID: 'unpaid',
}

export const productTypes = {
  PLAN: 'plan',
  PROMO: 'promo',
  BUNDLE: 'bundle',
  REWARD: 'reward',
  ADDON: 'addon',
}

export const deliveryProviders = {
  TODOKU: 'Todocu',
  ANYCARRY: 'AnyCarry',
}

export const TAX_PERCENT = 8

export const DATE_FORMAT = 'YYYY-MM-DD hh:mm'

export default {
  REVIEW_BUFFER: 15, // mins

  LUNCH_FIRST_CALL: 17,
  LUNCH_LAST_CALL: 10,
  DINNER_FIRST_CALL: 10,
  DINNER_LAST_CALL: 17,

  PICKUP_WINDOW_LENGTH: 15,

  USER_AUTHORITY: 1,
  SHOP_AUTHORITY: 4,
  SALT_ROUNDS: 10,
}
