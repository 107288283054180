const REQUIRED_VALUES = [
  'code',
]

const CODE_REGEX = /^[a-zA-Z0-9\-_]*$/i

export default (values, props) => {
  const errors = {}

  for (let requiredValue of REQUIRED_VALUES) {
    if (!values[requiredValue]) {
      errors[requiredValue] = 'Required'
    }
  }

  if (values.code && !CODE_REGEX.test(values.code)) {
    errors.code = 'Invalid code'
  }

  return errors
}
