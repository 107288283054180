import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  withRouter,
  Link,
} from 'react-router-dom'
import moment from 'moment-timezone'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import {
  getOrganization,
  getOrganizationMembers,
  getOrganizationReservations,
} from '../../redux/api/actions'

const getStatusForReservation = reservation => {
  let status = 'active'

  if (reservation.deleted) {
    status = 'Deleted (not charged)'
  } else if (reservation.canceled) {
    status = 'Late canceled (charged)'
  }

  return status
}

const styles = theme => ({
  root: {
    flex: '1 0 auto',
  },
  info: {
    margin: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 3,
  },
  content: {
    margin: theme.spacing.unit * 3,
  },
  toolbar: {
    display: 'flex',
    justiftContent: 'space-between',
  },
  title: {
    flex: '1',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  searchBox: {
    flex: 1,
    boxSizing: 'border-box',
    width: '100%',
    height: 40,
    fontSize: '12px',
    lineHeight: '16px',
    border: '1px solid #e4e4e4',
    borderRadius: 4,
    outline: 'none',
    appearance: 'none',
    paddingLeft: theme.spacing.unit,
    marginLeft: theme.spacing.unit * 2,
  }
})

class Organization extends Component {
  state = {
    month: moment.tz('Asia/Tokyo').format('YYYYMM'),
  }

  hasLoaded = () => {
    return (this.props.organization && this.props.organization.loaded)
      && (this.props.organizationMembers && this.props.organizationMembers.loaded)
      && (this.props.organizationReservations && this.props.organizationReservations.loaded)
  }

  componentWillMount() {
    if (!this.hasLoaded()) {
      Promise.all([
        this.props.getOrganization(this.props.match.params.organizationId),
        this.props.getOrganizationMembers(this.props.match.params.organizationId),
        this.props.getOrganizationReservations({
          organizationId: this.props.match.params.organizationId,
          month: this.state.month,
        })
      ])
    }
  }

  render() {
    if (!this.hasLoaded()) {
      return false
    }

    return (
      <div className={this.props.classes.root}>
        <Paper className={this.props.classes.info}>
          <Typography variant="h6">
            {this.props.organization.data.name}
          </Typography>
          <Typography variant="body2">
            Owner: <Link to={`/users/${this.props.organization.data.owner.id}`}>{this.props.organization.data.owner.name}</Link>
          </Typography>
        </Paper>
        <Paper className={this.props.classes.content}>
          <Toolbar className={this.props.classes.toolbar}>
            <div className={this.props.classes.title}>
              <Typography variant="h6">
                Members
              </Typography>
            </div>
          </Toolbar>
          <div className={this.props.classes.tableWrapper}>
            <Table className={this.props.classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>id</TableCell>
                  <TableCell>name</TableCell>
                  <TableCell>email</TableCell>
                  <TableCell>status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.organizationMembers.data.map(organizationMember => (
                  <TableRow
                    key={organizationMember.id}
                    hover>
                    <TableCell>{organizationMember.id}</TableCell>
                    <TableCell>{organizationMember.name}</TableCell>
                    <TableCell>{organizationMember.email}</TableCell>
                    <TableCell>{organizationMember.status}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </Paper>
        <Paper className={this.props.classes.content}>
          <Toolbar className={this.props.classes.toolbar}>
            <div className={this.props.classes.title}>
              <Typography variant="h6">
                Reservations
              </Typography>
            </div>
          </Toolbar>
          <div className={this.props.classes.tableWrapper}>
            <Table className={this.props.classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Customer</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Shop</TableCell>
                  <TableCell>Meal</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.organizationReservations.data.map((organizationReservation, index) => (
                  <TableRow
                    key={organizationReservation.id}
                    hover>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{organizationReservation.user}</TableCell>
                    <TableCell>{organizationReservation.email}</TableCell>
                    <TableCell>{organizationReservation.shop.name}</TableCell>
                    <TableCell>{organizationReservation.meal.name}</TableCell>
                    <TableCell>{moment.tz(organizationReservation.pickup.date, 'Asia/Tokyo').format('MMMM Do')}</TableCell>
                    <TableCell>{getStatusForReservation(organizationReservation)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </Paper>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  organization: state.api.organization[ownProps.match.params.organizationId],
  organizationMembers: state.api.organizationMembers[ownProps.match.params.organizationId],
  organizationReservations: state.api.organizationReservations[state.api.organizationReservations.lastReceivedKey],
})

const mapDispatchToProps = {
  getOrganization,
  getOrganizationMembers,
  getOrganizationReservations,
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(Organization)))
