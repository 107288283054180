import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import Colors from '../utils/Colors'
import formatNumberWithCommas from '../utils/formatNumberWithCommas'

const styles = theme => ({
  container: {
    backgroundColor: Colors.backgroundGray,
    padding: theme.spacing.unit * 2,
  },
  title: {
    color: Colors.primaryCharcoal,
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '21px',
  },
  cost: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  costIcon: {
    marginRight: theme.spacing.unit,
  },
  costText: {
    color: Colors.primaryCharcoal,
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '21px',
  },
  description: {

  },
  descriptionText: {
    color: Colors.secondaryGray,
    fontSize: '12px',
    lineHeight: '18px',
  },
  incrementer: {
    marginTop: theme.spacing.unit * 2,
    justifyContent: 'center',
  },
})

const OrderItem = ({ item, className, classes }) => {
  const hasTicketCost = item.ticketsCost > 0
  const hasYenCost = item.yenCost > 0

  return (
    <div className={classNames([classes.container, className])}>
      <div className={classes.title}>
        {item.name}{item.quantity > 1 && `（x${formatNumberWithCommas(item.quantity)}）`}
      </div>
      <div className={classes.cost}>
        {hasTicketCost && (
          <div className={classes.costText}>
            チケット{item.ticketsCost}枚
          </div>
        )}
        {hasTicketCost && hasYenCost && (
          <div
            key="plus-sign"
            className={classes.costText}>
            +
          </div>
        )}
        {hasYenCost && (
          <div
            key="yen-cost"
            className={classes.costText}>
            ￥{formatNumberWithCommas(item.yenCost)}
          </div>
        )}
      </div>
      <div className={classes.description}>
        <div className={classes.descriptionText}>
          個数：x{item.quantity}
        </div>
        {item.options && item.options.map((option, index) => (
          <div
            key={index}
            className={classes.descriptionText}>
          {option.name}：{option.value}{option.yenCost > 0 && `（＋￥${formatNumberWithCommas(option.yenCost)}）`}
        </div>
        ))}
      </div>
    </div>
  )
}

export default withStyles(styles)(OrderItem)
