import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment-timezone'
import { debounce } from 'throttle-debounce'
import { withStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import {
  searchUsers,
} from '../../redux/api/actions'
import getUrlForImage from '../../utils/getUrlForImage'
import PaginatedTable from '../../components/PaginatedTable'

const styles = theme => ({
  root: {
    flex: '1 0 auto',
  },
  content: {
    margin: theme.spacing.unit * 3,
  },
  toolbar: {
    display: 'flex',
    justiftContent: 'space-between',
  },
  pagination: {
    borderBottom: 'none',
  },
  cell: {
    textOverflow: 'ellipses',
  },
  cellMedium: {
    maxWidth: 120,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  },
  cellLarge: {
    maxWidth: 260,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  },
  progress: {
    margin: `${theme.spacing.unit * 16}px auto`,
    display: 'flex',
    justifyContent: 'center',
  },
  avatarContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    width: theme.spacing.unit * 4,
    height: theme.spacing.unit * 4,
    marginRight: theme.spacing.unit,
  }
})

class Users extends Component {
  state = {
    text: '',
    page: 0,
  }

  refreshData = debounce(750, () => {
    this.props.searchUsers({
      text: this.state.text,
      page: this.state.page,
    })
  })

  onChangePage = (event, page) => {
    this.setState({
      page,
    })

    this.refreshData()
  }

  onChangeSearch = event => {
    let text = event.currentTarget.value

    if (text === '') {
      text = null
    }

    this.setState({
      text,
      page: 0,
    })

    this.refreshData()
  }

  onClickUser = user => {
    this.props.history.push(`/users/${user.id}`)
  }

  componentWillMount() {
    this.props.searchUsers({
      text: this.state.text,
      page: this.state.page,
    })
  }

  render() {
    let data = {
      users: [],
      page: 0,
      numUsers: 0,
      numPages: 1,
    }

    if (this.props.usersSearch && this.props.usersSearch.data) {
      data = this.props.usersSearch.data
    }

    const currentKey = `text:${this.state.text}-page:${this.state.page}`
    let loading = (this.props.usersSearch && this.props.usersSearch.loading) || currentKey !== this.props.usersSearchKey

    return (
      <div className={this.props.classes.root}>
        <PaginatedTable
          title="Users"
          loading={loading}
          data={data.users}
          page={this.state.page}
          rowsPerPage={100}
          count={data.numUsers}
          onChangePage={this.onChangePage}
          onChangeSearch={this.onChangeSearch}
          renderHeader={() => (
            <TableRow>
              <TableCell classes={{ root: this.props.classes.cellMedium }}>id</TableCell>
              <TableCell classes={{ root: this.props.classes.cellMedium }}>name</TableCell>
              <TableCell classes={{ root: this.props.classes.cellLarge }}>email / phone</TableCell>
              <TableCell classes={{ root: this.props.classes.cellMedium }}>neighborhood</TableCell>
              <TableCell>created</TableCell>
            </TableRow>
          )}
          renderRow={item => (
            <TableRow
              key={item.id}
              hover
              onClick={() => this.onClickUser(item)}>
              <TableCell classes={{ root: this.props.classes.cellMedium }}>{item.id}</TableCell>
              <TableCell classes={{ root: this.props.classes.cellMedium }}>
                <div className={this.props.classes.avatarContainer}>
                  <Avatar
                    src={getUrlForImage(item.photo.uuid)}
                    className={this.props.classes.avatar} />
                  {item.name}
                </div>
              </TableCell>
              <TableCell classes={{ root: this.props.classes.cellLarge }}>{item.email || item.phone}</TableCell>
              <TableCell classes={{ root: this.props.classes.cellMedium }}>{item.neighborhood.name}</TableCell>
              <TableCell>{moment.tz(item.createdAt, 'Asia/Tokyo').format('MMMM Do YYYY')}</TableCell>
            </TableRow>
          )} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  usersSearch: state.api.usersSearch[state.api.usersSearch.lastReceivedKey],
  usersSearchKey: state.api.usersSearch.lastReceivedKey,
})

const mapDispatchToProps = {
  searchUsers,
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(Users)))
