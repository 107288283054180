import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  Field,
  reduxForm,
  SubmissionError,
} from 'redux-form'
import {
  resetPassword,
} from '../../redux/api/actions'
import BigButton from '../../components/BigButton/BigButton'
import FormField from '../../components/FormField/FormField'
import validate from '../../utils/validators/resetPasswordValidator'
import './ResetPassword.css'

class ResetPassword extends Component {
  onClickSubmit = async values => {
    const reset = await this.props.resetPassword(this.props.match.params.userId, values.password)

    if (reset && reset.reset) {
      throw new SubmissionError({
        _error: 'Something went wrong',
      })
    } else {
      this.props.history.push(`/users/${this.props.match.params.userId}`)
    }
  }

  render() {
    const {
      handleSubmit,
      invalid,
      error,
      resetPasswordState,
    } = this.props

    return (
      <div className="reset-password">
        <div className="reset-password__content">
          <form>
            <div className="reset-password__error">
              {error}
            </div>
            <Field
              name="password"
              type="password"
              component={FormField}
              label="New password" />
            <Field
              name="confirmPassword"
              type="password"
              component={FormField}
              label="Confirm new password" />
            <BigButton
              text="更新する"
              loading={resetPasswordState && resetPasswordState.loading}
              inactive={invalid}
              onClick={handleSubmit(this.onClickSubmit)} />
          </form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  resetPasswordState: state.api.resetPassword[ownProps.match.params.userId],
})

const mapDispatchToProps = {
  resetPassword,
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'reset-password',
  validate,
})(withRouter(ResetPassword)))
