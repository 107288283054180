import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { debounce } from 'throttle-debounce'
import {
  Field,
  reduxForm,
  formValueSelector,
  SubmissionError,
  reset,
} from 'redux-form'
import {
  withRouter,
} from 'react-router-dom'
import {
  TextField,
  Switch,
} from 'redux-form-material-ui'
import Paper from '@material-ui/core/Paper'
import Toolbar from '@material-ui/core/Toolbar'
import FormControl from '@material-ui/core/FormControl'
import Button from '@material-ui/core/Button'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Checkbox from '@material-ui/core/Checkbox'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import LaunchRoundedIcon from '@material-ui/icons/LaunchRounded'
import CircularProgress from '@material-ui/core/CircularProgress'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import {
  createShopUser,
  getShopUser,
  editShopUser,
  searchShops,
  searchCompanies,
  createShopUserLoginLink,
  getShopsForShopUser,
  setShopsForShopUser,
} from '../redux/api/actions'
import validate from '../utils/validators/shopUserValidator'
import promiseMap from '../utils/promiseMap'

const FORM_NAME = 'shop-user'

const styles = theme => ({
  container: {
    margin: theme.spacing(3),
  },
  paper: {
    marginBottom: theme.spacing(3),
  },
  toolbar: {
    display: 'flex',
    justiftContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  title: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing.unit * 3,
  },
  actions: {

  },
  content: {
    padding: theme.spacing.unit * 3,
  },
  errorText: {
    color: 'red',
    marginBottom: theme.spacing.unit * 3,
  },
  formField: {
    marginBottom: theme.spacing.unit * 3,
  },
  formFieldSmall: {
    width: 110,
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  formFieldsHorizontal: {
    display: 'flex',
    flexDirection: 'row',
    '& > :not(:first-child)': {
      marginLeft: theme.spacing(3),
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      '& > :not(:first-child)': {
        marginLeft: 0,
      },
    },
  },
  formFieldsPassword: {
    display: 'flex',
    flexDirection: 'row',
    '& > :not(:first-child)': {
      marginLeft: theme.spacing(3),
    },
  },
  titleLink: {
    marginLeft: theme.spacing.unit,
    cursor: 'pointer',
  },
  button: {
    margin: theme.spacing.unit,
    marginTop: theme.spacing.unit * 2,
  },
  progress: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    zIndex: 10,
  },
  toggle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    marginLeft: theme.spacing(-1),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  noneText: {
    margin: theme.spacing(2, 0),
    textAlign: 'center',
  },
  greenText: {
    color: 'green',
  },
  redText: {
    color: 'red',
  },
  ctaSmall: {
    padding: 0,
  },
})

class User extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      shopResults: [],
      shopResultsLoading: false,
      text: '',
      textCompany: '',
      page: 0,
    }

    props.searchCompanies({
      text: this.state.textCompany,
      page: this.state.page,
    })
  }

  hasLoaded = () => {
    return this.props.shopUser && this.props.shopUser.loaded
  }

  searchShops = debounce(750, async text => {
    let results

    try {
      results = await this.props.searchShops({
        text,
        page: 0,
      })
    } catch (exception) {
      // swallow
    }

    this.setState({
      shopResultsLoading: false,
      shopResults: results.shops,
      company: this.props.shopUser.company,
    })
  })

  isShopsDirty = () => {
    return this.state.shops
      && this.props.shopsForShopUser
      && this.props.shopsForShopUser.data
      && (this.state.shops.some(shop => !this.props.shopsForShopUser.data.some(shopForShopUser => shopForShopUser.id === shop.id))
      || this.props.shopsForShopUser.data.some(shopForShopUser => !this.state.shops.some(shop => shop.id === shopForShopUser.id)))
  }

  isCompanyDirty = () => {
    return this.state.company && this.state.company.id !== this.props.shopUser.data.company.id
  }

  onClickLoginLink = () => {
    if (!this.props.createShopUserLoginLinkState.loading) {
      const windowReference = window.open()
      this.props.createShopUserLoginLink(this.props.shopUser.data.id).then(data => {
        windowReference.location = data.loginLink
      })
    }
  }

  onChangeShops = (event, shops) => {
    this.setState({
      shops,
    })
  }

  onChangeShopSearch = event => {
    this.setState({ shopResultsLoading: true })
    this.searchShops(event.target.value)
  }

  onChangeCompany = (event, company) => {
    this.setState({
      company,
    })
  }

  onChangeCompanySearch = event => {
    this.setState({ shopResultsLoading: true })
    this.searchCompanies(event.target.value)
  }

  onClickSubmit = async values => {
    this.setState({ loading: true })

    if (this.props.isNew) {
      const result = await this.props.createShopUser(values)

      if (result && result.id) {
        window.location = `/shop-users/${result.id}`
      } else if (result && result.error) {
        this.setState({ loading: false })

        throw new SubmissionError({
          _error: result.error,
        })
      }
    } else {
      const promises = [this.props.editShopUser(this.props.match.params.shopUserId, {
        ...values,
        companyId: this.state.company.id,
      })]

      if (this.isShopsDirty()) {
        promises.push(this.props.setShopsForShopUser(this.props.match.params.shopUserId, this.state.shops.map(shop => shop.id)))
      }

      await Promise.all(promises)

      await Promise.all([
        this.props.getShopUser(this.props.match.params.shopUserId),
        this.props.getShopsForShopUser(this.props.match.params.shopUserId),
      ])

      this.setState({ loading: false })
      this.props.dispatch(reset(FORM_NAME))
    }
  }

  onClickChangePassword = () => {
    this.props.history.push(`/shop-users/${this.props.shopUser.data.id}/reset-password`)
  }

  async componentWillMount() {
    const { shopUser, shopForShopUser, companies } = await promiseMap({
      shopUser: this.props.getShopUser(this.props.match.params.shopUserId),
      shopForShopUser: this.props.getShopsForShopUser(this.props.match.params.shopUserId),
      companies: this.props.searchCompanies(this.state.text, this.state.page),
    })

    this.setState({
      shopUser,
      shopForShopUser,
      companies,
      company: shopUser.company,
    })
  }

  render() {
    if (!this.hasLoaded()) {
      return false
    }

    const {
      handleSubmit,
      pristine,
      invalid,
      error,
    } = this.props

    return (
      <div className={this.props.classes.container}>
        <Paper className={this.props.classes.paper}>
          <Toolbar className={this.props.classes.toolbar}>
            <div className={this.props.classes.title}>
              <Typography variant="h4">
                {this.props.name}
                {!this.props.isNew && (
                  <IconButton
                    color="primary"
                    aria-label="Login"
                    className={this.props.classes.loginButton}
                    onClick={this.onClickLoginLink}>
                    <LaunchRoundedIcon />
                  </IconButton>
                )}
              </Typography>
            </div>
            <div className={this.props.classes.actions}>
              <Button
                variant="contained"
                color="primary"
                className={this.props.classes.button}
                disabled={this.state.loading || invalid || (pristine && !this.isShopsDirty() && !this.isCompanyDirty())}
                onClick={handleSubmit(this.onClickSubmit)}>
                登録する
              </Button>
            </div>
          </Toolbar>
          <form className={this.props.classes.content}>
            {error && (
              <Typography
                variant="body2"
                className={this.props.classes.errorText}>
                {error}
              </Typography>
            )}
            <Field
              name="name"
              label="Name"
              variant="outlined"
              autoComplete="off"
              fullWidth
              component={TextField}
              className={this.props.classes.formField} />
            <Field
              name="email"
              type="email"
              label="Email"
              variant="outlined"
              autoComplete="off"
              fullWidth
              component={TextField}
              className={this.props.classes.formField} />
            {!this.props.isNew && (
              <div className={this.props.classes.formFieldsPassword}>
                <Field
                  disabled
                  name="password"
                  type="password"
                  label="Password"
                  variant="outlined"
                  autoComplete="off"
                  fullWidth
                  component={TextField}
                  className={this.props.classes.formField} />
                <Button
                  color="primary"
                  variant="text"
                  className={this.props.classes.formField}
                  onClick={this.onClickChangePassword}>
                  Change
                </Button>
              </div>
            )}
            {this.props.isNew && (
              <div className={this.props.classes.formFieldsPassword}>
                <Field
                  name="password"
                  type="password"
                  label="Password"
                  variant="outlined"
                  autoComplete="off"
                  fullWidth
                  component={TextField}
                  className={this.props.classes.formField} />
              </div>
            )}
            {!this.props.isNew && this.props.shopsForShopUser && this.props.shopsForShopUser.data && (
              <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                ChipProps={{
                  color: 'primary',
                }}
                disableCloseOnSelect
                options={this.state.shopResults}
                defaultValue={this.props.shopsForShopUser.data}
                className={this.props.classes.formField}
                getOptionLabel={option => option.name}
                getOptionSelected={(option, value) => option.id === value.id}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      color="primary"
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Add shop"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {this.state.shopResultsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                      onChange: this.onChangeShopSearch,
                    }}
                  />
                )}
                onChange={this.onChangeShops} />
            )}
            <Autocomplete
              name="company"
              label="Company"
              fullWidth
              open={this.state.open}
              onOpen={() => {
                this.setState({
                  open: true,
                })
              }}
              onClose={() => {
                this.setState({
                  open: false,
                })
              }}
              isOptionEqualToValue={(option, value) => option.name === value.name}
              getOptionLabel={(option) => option.name}
              defaultValue={this.props.shopUser.data.company}
              options={(this.props.companiesSearch.data && this.props.companiesSearch.data.companies) || []}
              loading={this.state.loadingCompanies}
              className={this.props.classes.formField}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Company"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {this.state.loadingCompanies ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                    onChange: this.OnChangeCompanySearch,
                  }}
                />
              )}
              onChange={this.onChangeCompany}
            />
            {!this.props.isNew && (
              <div>
                <FormControl className={this.props.classes.toggle}>
                  <Field
                    name="deleted"
                    color="primary"
                    component={Switch} />
                  <Typography variant="caption">
                    Deleted
                  </Typography>
                </FormControl>
              </div>
            )}
          </form>
        </Paper>
      </div>
    )
  }
}

const selector = formValueSelector(FORM_NAME)

const mapStateToProps = (state, ownProps) => {
  const props = {
    isNew: ownProps.match.params.shopUserId === 'new',
    name: selector(state, 'name'),
    shopUser: state.api.shopUser[ownProps.match.params.shopUserId],
    companiesSearch: state.api.companiesSearch[state.api.companiesSearch.lastReceivedKey],
    shopsForShopUser: state.api.shopsForShopUser[ownProps.match.params.shopUserId],
    createShopUserLoginLinkState: state.api.createShopUserLoginLink.default,
  }

  if (props.shopUser && props.shopUser.data) {
    props.initialValues = {
      name: props.shopUser.data.name,
      email: props.shopUser.data.email,
      password: '••••••••••',
      company: props.shopUser.data.company,
      deleted: props.shopUser.data.deleted,
    }
  }

  return props
}

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    createShopUser,
    getShopUser,
    editShopUser,
    searchShops,
    searchCompanies,
    createShopUserLoginLink,
    getShopsForShopUser,
    setShopsForShopUser,
  }, dispatch),
  dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: FORM_NAME,
  validate,
  enableReinitialize: true,
})(withRouter(withStyles(styles)(User))))
