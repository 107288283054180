import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { Redirect } from 'react-router-dom'
import moment from 'moment-timezone'
import Paper from '@material-ui/core/Paper'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import {
  getProfile,
  getMeals,
  getOpenRequests,
} from '../../redux/api/actions'
import './Meals.css'

const styles = theme => ({
  root: {
    flex: '1 0 auto',
  },
  content: {
    margin: theme.spacing.unit * 3,
  },
  toolbar: {
    display: 'flex',
  },
  actions: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  title: {
    flex: '1',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  nameCell: {
    maxWidth: 120,
    textOverflow: 'ellipsis',
    overflow: 'auto',
  },
})

class Meals extends Component {
  state = {
    search: '',
  }

  hasLoaded = () => {
    return this.props.profile.loaded
      && this.props.meals.loaded
      && this.props.openRequests.loaded
  }

  onChangeSearch = event => {
    this.setState({
      search: event.currentTarget.value,
    })
  }

  onKeyPressSearch = event => {
    if (event.key === 'Enter') {
      // this.onClickCreateTag()
    }
  }

  onClickMeal = meal => {
    this.props.history.push(`/meals/${meal.id}`)
  }

  componentWillMount() {
    if (!this.hasLoaded()) {
      this.props.getProfile()
      this.props.getMeals()
      this.props.getOpenRequests()
    }
  }

  render() {
    if (!this.hasLoaded()) {
      return false
    }

    if (!this.props.profile.data) {
      return (
        <Redirect to="/login" />
      )
    }

    const filteredMeals = this.props.meals.data.filter(meal =>
      !this.state.search
      || meal.name.toLowerCase().includes(this.state.search.toLowerCase())
      || meal.shop.name.toLowerCase().includes(this.state.search.toLowerCase()))

    return (
      <div className={this.props.classes.root}>
        <Paper className={this.props.classes.content}>
          <Toolbar className={this.props.classes.toolbar}>
            <div className={this.props.classes.title}>
              <Typography variant="h6">
                Meals
              </Typography>
            </div>
          </Toolbar>
          <div className={this.props.classes.tableWrapper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>id</TableCell>
                  <TableCell classes={{ root: this.props.classes.nameCell }}>name</TableCell>
                  <TableCell classes={{ root: this.props.classes.nameCell }}>shop</TableCell>
                  <TableCell>created</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredMeals.map(meal => (
                  <TableRow
                    key={meal.id}
                    hover
                    onClick={() => this.onClickMeal(meal)}>
                    <TableCell>{meal.id}</TableCell>
                    <TableCell classes={{ root: this.props.classes.nameCell }}>{meal.name}</TableCell>
                    <TableCell classes={{ root: this.props.classes.nameCell }}>{meal.shop.name}</TableCell>
                    <TableCell>{moment.tz(meal.createdAt, 'Asia/Tokyo').format('MMMM Do YYYY')}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </Paper>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  profile: state.api.profile.default,
  meals: state.api.meals.default,
  openRequests: state.api.openRequests.default,
})

const mapDispatchToProps = {
  getProfile,
  getMeals,
  getOpenRequests,
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(Meals)))
