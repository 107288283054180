import React, { Component } from 'react'
import classNames from 'classnames'
import ReactResizeDetector from 'react-resize-detector'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Fade from '@material-ui/core/Fade'
import IconButton from '@material-ui/core/IconButton'
import NavigateBeforeRoundedIcon from '@material-ui/icons/NavigateBeforeRounded'
import NavigateNextRoundedIcon from '@material-ui/icons/NavigateNextRounded'

const styles = theme => ({
  root: {
    display: 'flex',
    maxWidth: '100%',
  },
  arrow: {
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
  },
  pages: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    overflowX: 'hidden',
  },
  page: {
    height: 40,
    minWidth: 40,
    borderRadius: 24,
    border: `1px solid ${theme.palette.primary.main}`,
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    cursor: 'pointer',
    textAlign: 'center',
  },
  pageSelected: {
    backgroundColor: theme.palette.primary.main,
  },
  label: {
    lineHeight: '40px',
    color: theme.palette.primary.main,
  },
  labelSelected: {
    color: 'white',
  }
})

class NewPaginationBar extends Component {
  pageRefs = {}
  state = {
    index: 0,
  }

  constructor(props) {
    super(props)

    if (props.pages.length > 0) {
      if (props.initialPage) {
        this.state = {
          index: props.pages.map(page => page.value).indexOf(props.initialPage.value),
        }
      } else {
        this.state = {
          index: props.pages.length - 1
        }
      }
    }
  }

  onResize = () => {
    this.pageRefs[this.state.index].scrollIntoView({ behavior: 'smooth' })
  }

  onClickPrevious = () => {
    if (this.state.index > 0) {
      this.setState({
        index: this.state.index - 1,
      })

      this.pageRefs[this.state.index - 1].scrollIntoView({ behavior: 'smooth' })
      this.props.onChangePage && this.props.onChangePage(this.props.pages[this.state.index - 1])
    }
  }

  onClickNext = () => {
    if (this.state.index < this.props.pages.length - 1) {
      this.setState({
        index: this.state.index + 1,
      })

      this.pageRefs[this.state.index + 1].scrollIntoView({ behavior: 'smooth' })
      this.props.onChangePage && this.props.onChangePage(this.props.pages[this.state.index + 1])
    }
  }

  onClickPage = (page, index) => {
    if (index !== this.state.index) {
      this.setState({ index })
      this.pageRefs[index].scrollIntoView({ behavior: 'smooth' })
      this.props.onChangePage && this.props.onChangePage(this.props.pages[index])
    }
  }

  componentDidMount() {
    if (this.pageRefs[this.state.index]) {
      this.pageRefs[this.state.index].scrollIntoView()
    }
  }

  render() {
    return (
      <div className={this.props.classes.root}>
        <ReactResizeDetector
          handleWidth
          onResize={this.onResize} />
        <Fade in={this.state.index > 0}>
          <IconButton
            className={this.props.classes.arrow}
            disabled={this.state.index <= 0}
            onClick={this.onClickPrevious}>
            <NavigateBeforeRoundedIcon color="primary" />
          </IconButton>
        </Fade>
        <div className={this.props.classes.pages}>
          {this.props.pages.map((page, index) => (
            <div
              key={page.value}
              ref={ref => this.pageRefs[index] = ref}
              className={classNames(this.props.classes.page, {
                [this.props.classes.pageSelected]: this.state.index === index,
              })}
              onClick={() => this.onClickPage(page, index)}>
              <Typography
                variant="caption"
                align="center"
                className={classNames(this.props.classes.label, {
                  [this.props.classes.labelSelected]: this.state.index === index,
                })}>
                {page.label}
              </Typography>
            </div>
          ))}
        </div>
        <Fade in={this.state.index < this.props.pages.length - 1}>
          <IconButton
            className={this.props.classes.arrow}
            disabled={this.state.index >= this.props.pages.length - 1}
            onClick={this.onClickNext}>
            <NavigateNextRoundedIcon color="primary" />
          </IconButton>
        </Fade>
      </div>
    )
  }
}

export default withStyles(styles)(NewPaginationBar)
