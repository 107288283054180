export default async promiseObj => {
  const result = {}

  await Promise.all(Object.keys(promiseObj).map(async key => {
    const promiseResult = await promiseObj[key]
    result[key] = promiseResult
  }))

  return result
}
