import axios from 'axios'
import apiAction from '../tools/apiAction'
import canUseDOM from '../../utils/canUseDOM'

let BASE_URL = '/_api'

/* for server-side render */
if (!canUseDOM()) {
  BASE_URL = process.env.API_BASE_URL
}

export const getShopPaymentsKey = ({ startsAt, endsAt }) => `${startsAt}-${endsAt}`

export const ACTIONS = {
  LOGIN: 'LOGIN',
  AREAS: 'AREAS',
  CREATE_AREA: 'CREATE_AREA',
  EDIT_AREA: 'EDIT_AREA',
  USERS_SEARCH: 'USERS_SEARCH',
  USER: 'USER',
  USER_SUBSCRIPTION: 'USER_SUBSCRIPTION',
  SUBSCRIPTIONS: 'SUBSCRIPTIONS',
  USER_CHARGES: 'USER_CHARGES',
  NOTIFICATION_LOGS_FOR_USER: 'NOTIFICATION_LOGS_FOR_USER',
  EDIT_USER: 'EDIT_USER',
  RESET_PASSWORD: 'RESET_PASSWORD',
  DELETE_USER_FOREVER: 'DELETE_USER_FOREVER',
  SHOP_USER: 'SHOP_USER',
  SHOP_USERS_SEARCH: 'SHOP_USERS_SEARCH',
  CREATE_SHOP_USER: 'CREATE_SHOP_USER',
  EDIT_SHOP_USER: 'EDIT_SHOP_USER',
  SET_SHOPS_FOR_SHOP_USER: 'SET_SHOPS_FOR_SHOP_USER',
  CREATE_SHOP_USER_LOGIN_LINK: 'CREATE_SHOP_USER_LOGIN_LINK',
  RESET_PASSWORD_SHOP_USER: 'RESET_PASSWORD_SHOP_USER',
  SHOPS_FOR_SHOP_USER: 'SHOPS_FOR_SHOP_USER',
  DASHBOARD_DATA: 'DASHBOARD_DATA',
  DASHBOARD_SUBSCRIBER_DATA: 'DASHBOARD_SUBSCRIBER_DATA',
  JOINS_AND_CHURNS: 'JOINS_AND_CHURNS',
  CHURN_RATES: 'CHURN_RATES',
  GM_BY_PLAN: 'GM_BY_PLAN',
  PROFILE: 'PROFILE',
  MEALS: 'MEALS',
  MEAL: 'MEAL',
  MEAL_OPTIONS: 'MEAL_OPTIONS',
  CREATE_MEAL: 'CREATE_MEAL',
  EDIT_MEAL: 'EDIT_MEAL',
  TAGS: 'TAGS',
  TAG_MEAL: 'TAG_MEAL',
  UNTAG_MEAL: 'UNTAG_MEAL',
  CREATE_TAG: 'CREATE_TAG',
  SIDE: 'SIDE',
  SIDES_FOR_SHOP: 'SIDES_FOR_SHOP',
  EDIT_SIDE: 'EDIT_SIDE',
  RESERVATIONS_FOR_DATE: 'RESERVATIONS_FOR_DATE',
  RESERVATIONS_FOR_USER: 'RESERVATIONS_FOR_USER',
  UPDATE_RESERVATION_STATUS: 'UPDATE_RESERVATION_STATUS',
  DELIVERY_RESERVATIONS_FOR_DATE: 'DELIVERY_RESERVATIONS_FOR_DATE',
  ORDER: 'ORDER',
  ORDERS_FOR_DATE: 'ORDERS_FOR_DATE',
  EDIT_ORDER: 'EDIT_ORDER',
  CREATE_ORDER: 'CREATE_ORDER',
  DELETE_ORDER: 'DELETE_ORDER',
  SEARCH_SHOPS: 'SEARCH_SHOPS',
  SHOPS: 'SHOPS',
  SHOP: 'SHOP',
  SHOP_USERS_FOR_SHOP: 'SHOP_USERS_FOR_SHOP',
  SET_SHOP_USERS_FOR_SHOP: 'SET_SHOP_USERS_FOR_SHOP',
  MEALS_FOR_SHOP: 'MEALS_FOR_SHOP',
  CREATE_SHOP: 'CREATE_SHOP',
  EDIT_SHOP: 'EDIT_SHOP',
  CREATE_SHOP_LOGIN_LINK: 'CREATE_SHOP_LOGIN_LINK',
  NEIGHBORHOODS: 'NEIGHBORHOODS',
  UPLOAD_IMAGE: 'UPLOAD_IMAGE',
  ADD_MEDIA_TO_MEAL: 'ADD_MEDIA_TO_MEAL',
  REMOVE_MEDIA_FROM_MEAL: 'REMOVE_MEDIA_FROM_MEAL',
  CREATE_LOGIN_LINK: 'CREATE_LOGIN_LINK',
  SHOP_PAYMENTS: 'SHOP_PAYMENTS',
  PAYMENT_INFO_FOR_MONTH: 'PAYMENT_INFO_FOR_MONTH',
  OPEN_REQUESTS: 'OPEN_REQUESTS',
  ACCEPT_REQUEST: 'ACCEPT_REQUEST',
  DELETE_REQUEST: 'DELETE_REQUEST',
  ORGANIZATIONS: 'ORGANIZATIONS',
  ORGANIZATION: 'ORGANIZATION',
  ORGANIZATION_MEMBERS: 'ORGANIZATION_MEMBERS',
  ORGANIZATION_RESERVATIONS: 'ORGANIZATION_RESERVATIONS',
  REWARDS_FOR_USER: 'REWARDS_FOR_USER',
  EDIT_REWARDS_FOR_USER: 'EDIT_REWARDS_FOR_USER',
  OFFER: 'OFFER',
  OFFERS: 'OFFERS',
  OFFERS_SEARCH: 'OFFERS_SEARCH',
  CREATE_OFFER: 'CREATE_OFFER',
  EDIT_OFFER: 'EDIT_OFFER',
  DELETE_OFFER: 'DELETE_OFFER',
  PRODUCT: 'PRODUCT',
  PRODUCTS: 'PRODUCTS',
  PRODUCTS_SEARCH: 'PRODUCTS_SEARCH',
  CREATE_PRODUCT: 'CREATE_PRODUCT',
  EDIT_PRODUCT: 'EDIT_PRODUCT',
  DELIVERY_ZONES: 'DELIVERY_ZONES',
  CREATE_DELIVERY_ZONE: 'CREATE_DELIVERY_ZONE',
  EDIT_DELIVERY_ZONE: 'EDIT_DELIVERY_ZONE',
  DELETE_DELIVERY_ZONE: 'DELETE_DELIVERY_ZONE',
  DELIVERY_ZONES_FOR_SHOP: 'DELIVERY_ZONES_FOR_SHOP',
  ADD_SHOP_TO_DELIVERY_ZONE: 'ADD_SHOP_TO_DELIVERY_ZONE',
  REMOVE_SHOP_TO_DELIVERY_ZONE: 'REMOVE_SHOP_TO_DELIVERY_ZONE',
  HOLIDAYS: 'HOLIDAYS',
  CREATE_HOLIDAY: 'CREATE_HOLIDAY',
  EDIT_HOLIDAY: 'EDIT_HOLIDAY',
  DELETE_HOLIDAY: 'DELETE_HOLIDAY',
  HOLIDAY_STATUS: 'HOLIDAY_STATUS',
  HOLIDAY_DIALOGS: 'HOLIDAY_DIALOGS',
  CREATE_HOLIDAY_DIALOG: 'CREATE_HOLIDAY_DIALOG',
  EDIT_HOLIDAY_DIALOG: 'EDIT_HOLIDAY_DIALOG',
  DELETE_HOLIDAY_DIALOG: 'DELETE_HOLIDAY_DIALOG',
  GRANT_BY_ID: 'GRANT_BY_ID',
  RESERVATIONS_FOR_GRANT: 'RESERVATIONS_FOR_GRANT',
  GRANTS_FOR_USER: 'GRANTS_FOR_USER',
  CREATE_GRANT: 'CREATE_GRANT',
  EDIT_GRANT: 'EDIT_GRANT',
  DELETE_GRANT: 'DELETE_GRANT',
  APP_VERSION_STATUS: 'APP_VERSION_STATUS',
  CREATE_APP_VERSION_STATUS: 'CREATE_APP_VERSION_STATUS',
  COMPANIES_SEARCH: 'COMPANIES_SEARCH',
  COMPANY: 'COMPANY',
  CREATE_COMPANY: 'CREATE_COMPANY',
  EDIT_COMPANY: 'EDIT_COMPANY',
  SHOPS_FOR_COMPANY: 'SHOPS_FOR_COMPANY',
  SHOP_USERS_FOR_COMPANY: 'SHOP_USERS_FOR_COMPANY',
}

export const login = accessToken => apiAction(ACTIONS.LOGIN, () => axios.post(`${BASE_URL}/admin/auth`, { accessToken }, { withCredentials: true }))

export const getAreas = () => apiAction(ACTIONS.AREAS, () => axios.get(`${BASE_URL}/admin/areas`, { withCredentials: true }))

export const createArea = area => apiAction(ACTIONS.CREATE_AREA, () => axios.post(`${BASE_URL}/admin/areas`, area, { withCredentials: true }))

export const editArea = (id, area) => apiAction(ACTIONS.EDIT_AREA, () => axios.post(`${BASE_URL}/admin/areas/${id}`, area, { withCredentials: true }))

export const searchUsers = ({ text, page }) => apiAction(ACTIONS.USERS_SEARCH, () => axios.post(`${BASE_URL}/admin/users/search`, { text, page }, { withCredentials: true }), { key: `text:${text}-page:${page}` })

export const getUser = userId => apiAction(ACTIONS.USER, () => axios.get(`${BASE_URL}/admin/users/${userId}`, { withCredentials: true }), { key: userId })

export const getUserSubscription = userId => apiAction(ACTIONS.USER_SUBSCRIPTION, () => axios.get(`${BASE_URL}/admin/users/${userId}/subscription`, { withCredentials: true }), { key: userId })

export const getSubscriptions = () => apiAction(ACTIONS.SUBSCRIPTIONS, () => axios.get(`${BASE_URL}/admin/subscriptions`, { withCredentials: true }))

export const getUserCharges = userId => apiAction(ACTIONS.USER_CHARGES, () => axios.get(`${BASE_URL}/admin/users/${userId}/charges`, { withCredentials: true }), { key: userId })

export const getNotificationLogsForUser = userId => apiAction(ACTIONS.NOTIFICATION_LOGS_FOR_USER, () => axios.get(`${BASE_URL}/admin/user-notification-logs/${userId}`, { withCredentials: true }), { key: userId })

export const editUser = (userId, user) => apiAction(ACTIONS.EDIT_USER, () => axios.post(`${BASE_URL}/admin/users/${userId}`, user, { withCredentials: true }), { key: userId })

export const resetPassword = (userId, password) => apiAction(ACTIONS.RESET_PASSWORD, () => axios.post(`${BASE_URL}/admin/users/${userId}/password`, { password }, { withCredentials: true }), { key: userId })

export const deleteUserForever = userId => apiAction(ACTIONS.DELETE_USER_FOREVER, () => axios.delete(`${BASE_URL}/admin/users/${userId}/forever`, { withCredentials: true }))

export const getShopUser = shopUserId => apiAction(ACTIONS.SHOP_USER, () => axios.get(`${BASE_URL}/admin/shop-users/${shopUserId}`, { withCredentials: true }), { key: shopUserId })

export const searchShopUsers = ({ text, page }) => apiAction(ACTIONS.SHOP_USERS_SEARCH, () => axios.post(`${BASE_URL}/admin/shop-users/search`, { text, page }, { withCredentials: true }), { key: `text:${text}-page:${page}` })

export const getCompany = companyId => apiAction(ACTIONS.COMPANY, () => axios.get(`${BASE_URL}/admin/companies/${companyId}`, { withCredentials: true }), { key: companyId })

export const searchCompanies = ({ text, page }) => apiAction(ACTIONS.COMPANIES_SEARCH, () => axios.post(`${BASE_URL}/admin/companies/search`, { text, page }, { withCredentials: true }), { key: `text:${text}-page:${page}` })

export const createCompany = company => apiAction(ACTIONS.CREATE_COMPANY, () => axios.post(`${BASE_URL}/admin/companies/create`, company, { withCredentials: true }))

export const editCompany = (companyId, company) => apiAction(ACTIONS.EDIT_COMPANY, () => axios.post(`${BASE_URL}/admin/companies/${companyId}`, company, { withCredentials: true }), { key: companyId })

export const createShopUser = shopUser => apiAction(ACTIONS.CREATE_SHOP_USER, () => axios.post(`${BASE_URL}/admin/shop-users/create`, shopUser, { withCredentials: true }))

export const editShopUser = (shopUserId, shopUser) => apiAction(ACTIONS.EDIT_SHOP_USER, () => axios.post(`${BASE_URL}/admin/shop-users/${shopUserId}`, shopUser, { withCredentials: true }), { key: shopUserId })

export const setShopsForShopUser = (shopUserId, shopIds) => apiAction(ACTIONS.SET_SHOPS_FOR_SHOP_USER, () => axios.post(`${BASE_URL}/admin/shop-users/${shopUserId}/shops`, { shopIds }, { withCredentials: true }), { key: shopUserId })

export const resetPasswordShopUser = (shopUserId, password) => apiAction(ACTIONS.RESET_PASSWORD_SHOP_USER, () => axios.post(`${BASE_URL}/admin/shop-users/${shopUserId}/password`, { password }, { withCredentials: true }), { key: shopUserId })

export const createShopUserLoginLink = shopUserId => apiAction(ACTIONS.CREATE_SHOP_USER_LOGIN_LINK, () => axios.post(`${BASE_URL}/admin/shop-users/${shopUserId}/login-link`, {}, { withCredentials: true }))

export const getShopsForShopUser = shopUserId => apiAction(ACTIONS.SHOPS_FOR_SHOP_USER, () => axios.get(`${BASE_URL}/admin/shop-users/${shopUserId}/shops`, { withCredentials: true }), { key: shopUserId })

export const getDashboardData = () => apiAction(ACTIONS.DASHBOARD_DATA, () => axios.get(`${BASE_URL}/admin/dashboard`, { withCredentials: true }))

export const getDashboardSubscriberData = () => apiAction(ACTIONS.DASHBOARD_SUBSCRIBER_DATA, () => axios.get(`${BASE_URL}/admin/dashboard-subscriber`, { withCredentials: true }))

export const getJoinsAndChurns = ({ since }) => apiAction(ACTIONS.JOINS_AND_CHURNS, () => axios.post(`${BASE_URL}/admin/joins-and-churns`, { since }, { withCredentials: true }), { key: since })

export const getChurnRates = ({ since }) => apiAction(ACTIONS.CHURN_RATES, () => axios.post(`${BASE_URL}/admin/churn-rates`, { since }, { withCredentials: true }), { key: since })

export const getGMByPlan = ({ since }) => apiAction(ACTIONS.GM_BY_PLAN, () => axios.post(`${BASE_URL}/admin/gm-by-plan`, { since }, { withCredentials: true }), { key: since })

export const getProfile = () => apiAction(ACTIONS.PROFILE, () => axios.get(`${BASE_URL}/admin/profile`, { withCredentials: true }))

export const getMeals = () => apiAction(ACTIONS.MEALS, () => axios.get(`${BASE_URL}/admin/meals`, { withCredentials: true }))

export const getMeal = mealId => apiAction(ACTIONS.MEAL, () => axios.get(`${BASE_URL}/admin/meals/${mealId}`, { withCredentials: true }), { key: mealId })

export const getMealOptions = mealId => apiAction(ACTIONS.MEAL_OPTIONS, () => axios.get(`${BASE_URL}/admin/meals/${mealId}/options`, { withCredentials: true }), { key: mealId })

export const createMeal = meal => apiAction(ACTIONS.CREATE_MEAL, () => axios.post(`${BASE_URL}/admin/meals/new`, meal, { withCredentials: true }))

export const editMeal = (mealId, meal) => apiAction(ACTIONS.EDIT_MEAL, () => axios.post(`${BASE_URL}/admin/meals/${mealId}`, meal, { withCredentials: true }), { key: mealId })

export const getTags = () => apiAction(ACTIONS.TAGS, () => axios.get(`${BASE_URL}/admin/tags`, { withCredentials: true }))

export const tagMeal = ({ mealId, tagId }) => apiAction(ACTIONS.TAG_MEAL, () => axios.post(`${BASE_URL}/admin/meals/${mealId}/tags`, { mealId, tagId }, { withCredentials: true }), { key: `${mealId}-${tagId}` })

export const untagMeal = ({ mealId, tagId }) => apiAction(ACTIONS.UNTAG_MEAL, () => axios.delete(`${BASE_URL}/admin/meals/${mealId}/tags`, { data: { mealId, tagId } }, { withCredentials: true }), { key: `${mealId}-${tagId}` })

export const createTag = ({ name, category }) => apiAction(ACTIONS.CREATE_TAG, () => axios.post(`${BASE_URL}/admin/tags`, { name, category }, { withCredentials: true }))

export const getSideById = sideId => apiAction(ACTIONS.SIDE, () => axios.get(`${BASE_URL}/admin/sides/${sideId}`, { withCredentials: true }), { key: sideId })

export const getSidesForShop = shopId => apiAction(ACTIONS.SIDES_FOR_SHOP, () => axios.get(`${BASE_URL}/admin/shops/${shopId}/sides`, { withCredentials: true }), { key: shopId })

export const editSide = (sideId, side) => apiAction(ACTIONS.EDIT_SIDE, () => axios.post(`${BASE_URL}/admin/sides/${sideId}`, side, { withCredentials: true }), { key: sideId })

export const getReservationsForDate = date => apiAction(ACTIONS.RESERVATIONS_FOR_DATE, () => axios.get(`${BASE_URL}/admin/reservations/${date}`, { withCredentials: true }), { key: date })

export const getReservationsForUser = userId => apiAction(ACTIONS.RESERVATIONS_FOR_USER, () => axios.get(`${BASE_URL}/admin/users/${userId}/reservations`, { withCredentials: true }), { key: userId })

export const updateReservationStatus = ({ reservationId, status }) => apiAction(ACTIONS.UPDATE_RESERVATION_STATUS, () => axios.post(`${BASE_URL}/admin/reservations/statuses`, { statuses: { [reservationId]: status } }, { withCredentials: true }), { key: reservationId })

export const getDeliveryReservationsForDate = date => apiAction(ACTIONS.DELIVERY_RESERVATIONS_FOR_DATE, () => axios.get(`${BASE_URL}/admin/delivery-reservations/${date}`, { withCredentials: true }), { key: date })

export const getOrder = id => apiAction(ACTIONS.ORDER, () => axios.get(`${BASE_URL}/admin/orders/${id}`, { withCredentials: true }), { key: id })

export const getOrdersForDate = date => apiAction(ACTIONS.ORDERS_FOR_DATE, () => axios.get(`${BASE_URL}/admin/orders-by-date/${date}`, { withCredentials: true }), { key: date })

export const editOrder = (id, order) => apiAction(ACTIONS.EDIT_ORDER, () => axios.post(`${BASE_URL}/admin/orders/${id}`, order, { withCredentials: true }))

export const createOrder = order => apiAction(ACTIONS.CREATE_ORDER, () => axios.post(`${BASE_URL}/admin/orders`, order, { withCredentials: true }))

export const deleteOrder = orderId => apiAction(ACTIONS.DELETE_ORDER, () => axios.delete(`${BASE_URL}/admin/orders/${orderId}`, { withCredentials: true }))

export const searchShops = search => apiAction(ACTIONS.SEARCH_SHOPS, () => axios.post(`${BASE_URL}/admin/shops/search`, search, { withCredentials: true }))

export const getShops = () => apiAction(ACTIONS.SHOPS, () => axios.get(`${BASE_URL}/admin/shops`, { withCredentials: true }))

export const getShop = shopId => apiAction(ACTIONS.SHOP, () => axios.get(`${BASE_URL}/admin/shops/${shopId}`, { withCredentials: true }), { key: shopId })

export const getShopUsersForCompany = companyId => apiAction(ACTIONS.SHOP_USERS_FOR_COMPANY, () => axios.get(`${BASE_URL}/admin/shop-users/companies/${companyId}`, { withCredentials: true }), { key: companyId })

export const getShopsForCompany = companyId => apiAction(ACTIONS.SHOPS_FOR_COMPANY, () => axios.get(`${BASE_URL}/admin/shops/companies/${companyId}`, { withCredentials: true }), { key: companyId })

export const getShopUsersForShop = shopId => apiAction(ACTIONS.SHOP_USERS_FOR_SHOP, () => axios.get(`${BASE_URL}/admin/shops/${shopId}/shop-users`, { withCredentials: true }), { key: shopId })

export const setShopUsersForShop = ({ shopId, shopUserIds }) => apiAction(ACTIONS.SET_SHOP_USERS_FOR_SHOP, () => axios.post(`${BASE_URL}/admin/shops/${shopId}/shop-users`, { shopUserIds }, { withCredentials: true }))

export const getMealsForShop = shopId => apiAction(ACTIONS.MEALS_FOR_SHOP, () => axios.get(`${BASE_URL}/admin/shops/${shopId}/meals`, { withCredentials: true }), { key: shopId })

export const createShop = shop => apiAction(ACTIONS.CREATE_SHOP, () => axios.post(`${BASE_URL}/admin/shops/new`, shop, { withCredentials: true }))

export const editShop = (shopId, shop) => apiAction(ACTIONS.EDIT_SHOP, () => axios.post(`${BASE_URL}/admin/shops/${shopId}`, shop, { withCredentials: true }), { key: shopId })

export const createShopLoginLink = shopId => apiAction(ACTIONS.CREATE_SHOP_LOGIN_LINK, () => axios.post(`${BASE_URL}/admin/shops/${shopId}/login-link`, {}, { withCredentials: true }))

export const getNeighborhoods = () => apiAction(ACTIONS.NEIGHBORHOODS, () => axios.get(`${BASE_URL}/admin/neighborhoods`, { withCredentials: true }))

export const uploadImage = file => apiAction(ACTIONS.UPLOAD_IMAGE, () => {
  const formData = new FormData()
  formData.append('file', file)

  return axios.post(`${BASE_URL}/admin/media`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    withCredentials: true,
  })
})

export const addMediaToMeal = ({ mealId, path, uuid }) => apiAction(ACTIONS.ADD_MEDIA_TO_MEAL, () => axios.post(`${BASE_URL}/admin/meals/${mealId}/media`, { path, uuid }, { withCredentials: true }))

export const removeMediaFromMeal = ({ mealId, uuid, path }) => apiAction(ACTIONS.REMOVE_MEDIA_FROM_MEAL, () => axios.post(`${BASE_URL}/admin/meals/${mealId}/delete-media`, { uuid, path }, { withCredentials: true }))

export const createLoginLink = userId => apiAction(ACTIONS.CREATE_LOGIN_LINK, () => axios.post(`${BASE_URL}/admin/users/${userId}/login-link`, {}, { withCredentials: true }))

export const getShopPayments = ({ startsAt, endsAt }) => apiAction(ACTIONS.SHOP_PAYMENTS, () => axios.post(`${BASE_URL}/admin/shop-payments`, { startsAt, endsAt }, { withCredentials: true }), { key: getShopPaymentsKey({ startsAt, endsAt }), useCache: true })

export const getPaymentInfoForMonth = month => apiAction(ACTIONS.PAYMENT_INFO_FOR_MONTH, () => axios.get(`${BASE_URL}/admin/payments/${month}`, { withCredentials: true }), { key: month })

export const getOpenRequests = () => apiAction(ACTIONS.OPEN_REQUESTS, () => axios.get(`${BASE_URL}/admin/requests`, { withCredentials: true }))

export const acceptRequest = requestId => apiAction(ACTIONS.ACCEPT_REQUEST, () => axios.post(`${BASE_URL}/admin/requests/${requestId}/accept`, { withCredentials: true }))

export const deleteRequest = requestId => apiAction(ACTIONS.DELETE_REQUEST, () => axios.delete(`${BASE_URL}/admin/requests/${requestId}`, { withCredentials: true }))

export const getOrganizations = () => apiAction(ACTIONS.ORGANIZATIONS, () => axios.get(`${BASE_URL}/admin/organizations`, { withCredentials: true }))

export const getOrganization = organizationId => apiAction(ACTIONS.ORGANIZATION, () => axios.get(`${BASE_URL}/admin/organizations/${organizationId}`, { withCredentials: true }), { key: organizationId })

export const getOrganizationMembers = organizationId => apiAction(ACTIONS.ORGANIZATION_MEMBERS, () => axios.get(`${BASE_URL}/admin/organizations/${organizationId}/members`, { withCredentials: true }), { key: organizationId })

export const getOrganizationReservations = ({ organizationId, month }) => apiAction(ACTIONS.ORGANIZATION_RESERVATIONS, () => axios.get(`${BASE_URL}/admin/organizations/${organizationId}/reservations/${month}`, { withCredentials: true }), { key: `${organizationId}-${month}` })

export const getRewardsForUser = userId => apiAction(ACTIONS.REWARDS_FOR_USER, () => axios.get(`${BASE_URL}/admin/rewards/${userId}`, { withCredentials: true }), { key: userId })

export const editRewardsForUser = ({ userId, rewards }) => apiAction(ACTIONS.REWARDS_FOR_USER, () => axios.post(`${BASE_URL}/admin/rewards/${userId}`, { userId, rewards }, { withCredentials: true }), { key: userId })

export const getOffer = offerId => apiAction(ACTIONS.OFFER, () => axios.get(`${BASE_URL}/admin/offers/${offerId}`, { withCredentials: true }), { key: offerId })

export const getOffers = () => apiAction(ACTIONS.OFFERS, () => axios.get(`${BASE_URL}/admin/offers`, { withCredentials: true }))

export const searchOffers = ({ text, page }) => apiAction(ACTIONS.OFFERS_SEARCH, () => axios.post(`${BASE_URL}/admin/offers/search`, { text, page }, { withCredentials: true }), { key: `text:${text}-page:${page}` })

export const createOffer = offer => apiAction(ACTIONS.CREATE_OFFER, () => axios.post(`${BASE_URL}/admin/offers/new`, offer, { withCredentials: true }))

export const editOffer = (offerId, values) => apiAction(ACTIONS.EDIT_OFFER, () => axios.post(`${BASE_URL}/admin/offers/${offerId}`, values, { withCredentials: true }))

export const deleteOffer = offerId => apiAction(ACTIONS.DELETE_OFFER, () => axios.delete(`${BASE_URL}/admin/offers/${offerId}`, { withCredentials: true }))

export const getProduct = productId => apiAction(ACTIONS.PRODUCT, () => axios.get(`${BASE_URL}/admin/products/${productId}`, { withCredentials: true }), { key: productId })

export const getProducts = () => apiAction(ACTIONS.PRODUCTS, () => axios.get(`${BASE_URL}/admin/products`, { withCredentials: true }))

export const searchProducts = ({ text, page }) => apiAction(ACTIONS.PRODUCTS_SEARCH, () => axios.post(`${BASE_URL}/admin/products/search`, { text, page }, { withCredentials: true }), { key: `text:${text}-page:${page}` })

export const createProduct = product => apiAction(ACTIONS.CREATE_PRODUCT, () => axios.post(`${BASE_URL}/admin/products`, product, { withCredentials: true }))

export const editProduct = (productId, values) => apiAction(ACTIONS.EDIT_PRODUCT, () => axios.post(`${BASE_URL}/admin/products/${productId}`, values, { withCredentials: true }))

export const getDeliveryZones = () => apiAction(ACTIONS.DELIVERY_ZONES, () => axios.get(`${BASE_URL}/admin/delivery-zones`, { withCredentials: true }))

export const createDeliveryZone = zone => apiAction(ACTIONS.CREATE_DELIVERY_ZONE, () => axios.post(`${BASE_URL}/admin/delivery-zones`, zone, { withCredentials: true }))

export const editDeliveryZone = ({ id, ...rest }) => apiAction(ACTIONS.EDIT_DELIVERY_ZONE, () => axios.post(`${BASE_URL}/admin/delivery-zones/${id}`, { id, ...rest }, { withCredentials: true }))

export const deleteDeliveryZone = id => apiAction(ACTIONS.DELETE_DELIVERY_ZONE, () => axios.delete(`${BASE_URL}/admin/delivery-zones/${id}`, { withCredentials: true }))

export const getDeliveryZonesForShop = shopId => apiAction(ACTIONS.DELIVERY_ZONES_FOR_SHOP, () => axios.get(`${BASE_URL}/admin/shops/${shopId}/delivery-zones`, { withCredentials: true }), { key: shopId })

export const addShopToDeliveryZone = ({ shopId, deliveryZoneId }) => apiAction(ACTIONS.ADD_SHOP_TO_DELIVERY_ZONE, () => axios.post(`${BASE_URL}/admin/shops/${shopId}/delivery-zones`, { deliveryZoneId }, { withCredentials: true }))

export const removeShopToDeliveryZone = ({ shopId, deliveryZoneId }) => apiAction(ACTIONS.REMOVE_SHOP_TO_DELIVERY_ZONE, () => axios.post(`${BASE_URL}/admin/shops/${shopId}/delivery-zones`, { deliveryZoneId }, { withCredentials: true }))

export const getHolidays = () => apiAction(ACTIONS.HOLIDAYS, () => axios.get(`${BASE_URL}/admin/holidays`, { withCredentials: true }))

export const createHoliday = holiday => apiAction(ACTIONS.CREATE_HOLIDAY, () => axios.post(`${BASE_URL}/admin/holidays`, holiday, { withCredentials: true }))

export const editHoliday = (id, holiday) => apiAction(ACTIONS.EDIT_HOLIDAY, () => axios.post(`${BASE_URL}/admin/holidays/${id}`, holiday, { withCredentials: true }))

export const deleteHoliday = id => apiAction(ACTIONS.DELETE_HOLIDAY, () => axios.delete(`${BASE_URL}/admin/holidays/${id}`, { withCredentials: true }))

export const getHolidayStatus = id => apiAction(ACTIONS.HOLIDAY_STATUS, () => axios.get(`${BASE_URL}/admin/holidays/${id}/status`, { withCredentials: true }), { key: id })

export const getHolidayDialogs = () => apiAction(ACTIONS.HOLIDAY_DIALOGS, () => axios.get(`${BASE_URL}/admin/shop-holiday-dialogs`, { withCredentials: true }))

export const createHolidayDialog = holidayDialog => apiAction(ACTIONS.CREATE_HOLIDAY_DIALOG, () => axios.post(`${BASE_URL}/admin/shop-holiday-dialogs`, holidayDialog, { withCredentials: true }))

export const editHolidayDialog = (id, holidayDialog) => apiAction(ACTIONS.EDIT_HOLIDAY_DIALOG, () => axios.post(`${BASE_URL}/admin/shop-holiday-dialogs/${id}`, holidayDialog, { withCredentials: true }))

export const deleteHolidayDialog = id => apiAction(ACTIONS.DELETE_HOLIDAY_DIALOG, () => axios.delete(`${BASE_URL}/admin/shop-holiday-dialogs/${id}`, { withCredentials: true }))

export const getGrantById = grantId => apiAction(ACTIONS.GRANT_BY_ID, () => axios.get(`${BASE_URL}/admin/grants/${grantId}`, { withCredentials: true }), { key: grantId })
export const getReservationsForGrant = grantId => apiAction(ACTIONS.RESERVATIONS_FOR_GRANT, () => axios.get(`${BASE_URL}/admin/grants/${grantId}/reservations`, { withCredentials: true }), { key: grantId })
export const getGrantsForUser = userId => apiAction(ACTIONS.GRANTS_FOR_USER, () => axios.get(`${BASE_URL}/admin/grants/user/${userId}`, { withCredentials: true }), { key: userId })
export const createGrant = grant => apiAction(ACTIONS.CREATE_GRANT, () => axios.post(`${BASE_URL}/admin/grants`, grant, { withCredentials: true }))
export const editGrant = (grantId, data) => apiAction(ACTIONS.EDIT_GRANT, () => axios.post(`${BASE_URL}/admin/grants/${grantId}`, data, { withCredentials: true }))
export const deleteGrant = grantId => apiAction(ACTIONS.DELETE_GRANT, () => axios.delete(`${BASE_URL}/admin/grants/${grantId}`, { withCredentials: true }))

export const getAppVersionStatus = () => apiAction(ACTIONS.APP_VERSION_STATUS, () => axios.get(`${BASE_URL}/admin/app-versions`, { withCredentials: true }))

export const createAppVersionStatus = version => apiAction(ACTIONS.CREATE_APP_VERSION_STATUS, () => axios.post(`${BASE_URL}/admin/app-versions`, version, { withCredentials: true }))
