import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import Fade from '@material-ui/core/Fade'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import {
  DragDropContext,
  Droppable,
  Draggable,
} from 'react-beautiful-dnd'
import ContentEditableWithRef from '../components/ContentEditableWithRef'
import PlanCard from '../components/PlanCard'

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  boxShadow: isDragging ? '5px 5px 11px -4px rgba(0, 0, 0, 0.57)' : null,
  transition: 'box-shadow 0.5s linear',
  ...draggableStyle
})

const styles = theme => ({
  package: {
    border: '3px solid #000000',
    borderRadius: 5,
    maxWidth: 340,
    margin: '0 auto',
    padding: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit * 8,
  },
  packageTitle: {
    fontSize: 20,
    fontWeight: 800,
    lineHeight: '28px',
    textAlign: 'center',
    color: '#232323',
  },
  packageSubtitle: {
    fontSize: 14,
    fontWeight: 800,
    lineHeight: '18px',
    textAlign: 'center',
    color: '#666666',
    marginTop: theme.spacing.unit * 2,
  },
  products: {
    marginTop: theme.spacing.unit * 2,
  },
  product: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  productSeparator: {
    borderTop: '1px solid #D6D6D8',
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  draggable: {
    backgroundColor: '#ffffff',
  },
  cta: {
    borderRadius: 22,
    width: '100%',
    marginTop: theme.spacing.unit * 4,
  },
  disclaimerTitle: {
    fontSize: 14,
    lineHeight: '16px',
    color: '#666666',
    fontWeight: 'bold',
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginTop: theme.spacing.unit * 6,
  },
  disclaimerSeparator: {
    borderTop: '1px solid #D6D6D8',
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  disclaimer: {
    fontSize: 12,
    lineHeight: '16px',
    color: '#666666',
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  elementDeleteContainer: {
    position: 'relative',
  },
  elementDeleteButton: {
    position: 'absolute',
    top: 'calc(50% - 24px)',
    right: -78,
  },
  addDisclaimerButton: {
    width: '100%',
    marginTop: theme.spacing.unit * 2,
  },
})

export default withStyles(styles)(({ offerPackage, classes, onEdit, onClickAddProduct }) => {
  const onClickDeleteProduct = index => {
    offerPackage.products.splice(index, 1)
    return onEdit(offerPackage)
  }

  const onClickDeleteDisclaimer = index => {
    offerPackage.disclaimers.splice(index, 1)
    return onEdit(offerPackage)
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  const onDragEnd = result => {
    if (!result.destination) {
      return
    }

    const products = reorder(
      offerPackage.products,
      result.source.index,
      result.destination.index
    )

    return onEdit({
      ...offerPackage,
      products,
    })
  }

  const onChangeDisclaimer = (value, index) => {
    offerPackage.disclaimers.splice(index, 1, value)
    return onEdit(offerPackage)
  }

  const onClickAddDisclaimer = () => onEdit({
    ...offerPackage,
    disclaimers: [...(offerPackage.disclaimers || []), '※ ']
  })
  
  return (
    <div className={classes.package}>
      <ContentEditableWithRef
        className={classes.packageTitle}
        value={offerPackage.copy && offerPackage.copy.title}
        onInput={value => onEdit({
          ...offerPackage,
          copy: {
            ...offerPackage.copy,
            title: value,
          },
        })} />
      <ContentEditableWithRef
        className={classes.packageSubtitle}
        value={offerPackage.copy && offerPackage.copy.subtitle}
        onInput={value => onEdit({
          ...offerPackage,
          copy: {
            ...offerPackage.copy,
            subtitle: value,
          },
        })} />
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="package">
          {(droppableProvided, droppableSnapshot) => (
            <div
              ref={droppableProvided.innerRef}
              className={classes.products}
              {...droppableProvided.droppableProps}>
              {offerPackage.products && offerPackage.products.map((product, index) => (
                 <Draggable
                  key={`item-${index}`}
                  draggableId={`item-${index}`}
                  index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={classNames([classes.draggable, classes.elementDeleteContainer])}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}>
                      <PlanCard
                        key={product.id}
                        plan={product}
                        className={classes.product} />
                      {(!snapshot.isDragging && index < offerPackage.products.length - 1) && (
                        <div
                          key={`separator-${index}`}
                          className={classes.productSeparator} />
                      )}
                      <Fade in={!droppableSnapshot.draggingFromThisWith}>
                        <IconButton
                          color="primary"
                          size="small"
                          className={classes.elementDeleteButton}
                          disabled={droppableSnapshot.draggingFromThisWith}
                          onClick={() => onClickDeleteProduct(index)}>
                          <DeleteIcon />
                        </IconButton>
                      </Fade>
                    </div>
                  )}
                </Draggable>
              ))}
              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Button
        color="primary"
        variant="contained"
        className={classes.addDisclaimerButton}
        onClick={onClickAddProduct}>
        <AddIcon />
      </Button>
      <Button
        variant="contained"
        color="primary"
        size="large"
        disabled
        className={classes.cta}>
        {offerPackage.copy && offerPackage.copy.cta}
      </Button>
      {offerPackage.disclaimers && offerPackage.disclaimers.length > 0 && (
        <div>
          <Typography
            variant="h6"
            className={classes.disclaimerTitle}>
            注意事項
          </Typography>
          <div className={classes.disclaimerSeparator} />
          {offerPackage.disclaimers.map((disclaimer, index) => (
            <div className={classes.elementDeleteContainer}>
              <ContentEditableWithRef
                key={index}
                variant="h6"
                className={classes.disclaimer}
                value={disclaimer}
                onInput={value => onChangeDisclaimer(value, index)} />
              <IconButton
                color="primary"
                size="small"
                className={classes.elementDeleteButton}
                onClick={() => onClickDeleteDisclaimer(index)}>
                <DeleteIcon />
              </IconButton>
            </div>
          ))}
        </div>
      )}
      <Button
        color="primary"
        variant="contained"
        className={classes.addDisclaimerButton}
        onClick={onClickAddDisclaimer}>
        <AddIcon />
      </Button>
    </div>
  )
})
