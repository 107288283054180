import React, { Component } from 'react'
import {
  StaticRouter,
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  withRouter,
} from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  injectIntl,
  IntlProvider,
  addLocaleData,
} from 'react-intl'
import queryString from 'query-string'
import { FormattedMessage } from 'react-intl'
import jaLocaleData from 'react-intl/locale-data/ja'
import esLocaleData from 'react-intl/locale-data/es'
import moment from 'moment-timezone'
import 'moment/locale/ja'
import {
  MuiThemeProvider,
  createMuiTheme,
} from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import CssBaseline from '@material-ui/core/CssBaseline'
import blue from '@material-ui/core/colors/blue'
import red from '@material-ui/core/colors/red'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Hidden from '@material-ui/core/Hidden'
import MenuIcon from '@material-ui/icons/Menu'
import locales from './i18n/locales'
import routes from './routes'
import {
  getProfile,
} from './redux/api/actions'
import {
  setFlag,
  setRedirectTo,
} from './redux/features/actions'
import Login from './containers/Login/Login'
import Nav from './components/Nav/Nav'

addLocaleData(jaLocaleData)
addLocaleData(esLocaleData)

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: blue,
    secondary: red,
  },
})

class LoggedInRouteUnwrapped extends Component {
  hasLoaded = () => this.props.profile.loaded

  shouldComponentUpdate(nextProps) {
    return nextProps.profile.receivedAt !== this.props.profile.receivedAt
      || nextProps.component !== this.props.component
  }

  componentWillMount() {
    const search = queryString.parse(this.props.location.search)
    this.props.setRedirectTo(search.redirectTo)
  }

  render() {
    if (!this.hasLoaded()) {
      return false
    }

    const {
      component: Component,
      ...rest
    } = this.props

    if (!this.props.profile.data) {
      this.props.setRedirectTo(this.props.path)
      return (
        <Redirect to="/login" />
      )
    }

    return (
      <Route {...rest} render={(props) => (<Component {...props} />)} />
    )
  }
}

const routeMapStateToProps = state => ({
  profile: state.api.profile.default
})

const routeMapDispatchToProps = {
  setRedirectTo,
}

const LoggedInRoute = connect(routeMapStateToProps, routeMapDispatchToProps)(withRouter(LoggedInRouteUnwrapped))

const drawerWidth = 240

const styles = theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    '@media print': {
      display: 'none',
    },
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  logo: {
    height: 34,
    width: 34,
    backgroundImage: 'url(https://s3-ap-northeast-1.amazonaws.com/potluck-webapp/logo--white.png)',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    marginRight: theme.spacing.unit * 2,
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    paddingTop: 64,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 240,
    },
  },
})

class App extends Component {
  state = {
    mobileDrawerOpen: false,
  }

  onDrawerToggle = () => {
    this.setState({
      mobileDrawerOpen: !this.state.mobileDrawerOpen,
    })
  }

  render() {
    if (!this.props.profile.loaded) {
      return false
    }

    const content = (
      <div className="app">
        <MuiThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Switch>
              <Route
                path="/login"
                exact
                component={Login} />
              <Route path="*">
                <div>
                  <CssBaseline />
                  <AppBar
                    position="fixed"
                    className={this.props.classes.appBar}>
                    <Toolbar>
                      <IconButton
                        color="inherit"
                        aria-label="Open drawer"
                        onClick={this.onDrawerToggle}
                        className={this.props.classes.menuButton}>
                        <MenuIcon />
                      </IconButton>
                      <Hidden smDown implementation="css">
                        <div className={this.props.classes.logo} />
                      </Hidden>
                      <Typography variant="h6" color="inherit" noWrap>
                        <FormattedMessage
                          id="app.name"
                          defaultMessage="Potluck Admin" />
                      </Typography>
                    </Toolbar>
                  </AppBar>
                  <div className={this.props.classes.content}>
                    <Nav
                      location={this.props.location}
                      mobileDrawerOpen={this.state.mobileDrawerOpen}
                      onDrawerToggle={this.onDrawerToggle} />
                    <Switch>
                      <Route
                        path="/"
                        exact>
                        <div>
                          {this.props.profile && this.props.profile.data && (
                            <Redirect to="/dashboard" />
                          )}
                          {!(this.props.profile && this.props.profile.data) && (
                            <Redirect to="/login" />
                          )}
                        </div>
                      </Route>
                      {routes.map(route => {
                        if (route.requireLoggedIn) {
                          return (<LoggedInRoute key={route.path} {...route} />)
                        }

                        return (<Route key={route.path} {...route} />)
                      })}
                    </Switch>
                  </div>
                </div>
              </Route>
            </Switch>
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </div>
    )

    if (this.props.server) {
      return (
        <StaticRouter
          location={this.props.location}
          context={this.props.context}>
          {content}
        </StaticRouter>
      )
    }

    return (
      <BrowserRouter>
        {content}
      </BrowserRouter>
    )
  }
}

const AppIntl = injectIntl(withStyles(styles)(App))

export const fetchData = dispatch => Promise.all([
  dispatch(getProfile()),
])

class AppWrapper extends Component {
  hasLoaded = () => {
    return this.props.profile.loaded
  }

  componentWillMount() {
    if (!this.hasLoaded()) {
      fetchData(this.props.dispatch)
    }
  }

  render() {
    if (!this.hasLoaded()) {
      return false
    }

    let locale = 'en'

    moment.locale(locale)
    const messages = locales[locale]

    return (
      <IntlProvider
        locale={locale}
        key={locale}
        messages={messages}>
        <AppIntl {...this.props}/>
      </IntlProvider>
    )
  }
}

const mapStateToProps = state => ({
  profileDropdownOpen: state.features.flags.profileDropdownOpen,
  profile: state.api.profile.default,
})

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    setFlag,
  }, dispatch),
  dispatch,
})

AppWrapper.fetchData = fetchData

export default connect(mapStateToProps, mapDispatchToProps)(AppWrapper)
