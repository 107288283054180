import React, { useRef } from 'react'

export default ({ value, onInput, onPaste, ...rest }) => {
  const defaultValue = useRef(value)

  const handleInput = (event) => {
    if (onInput && event.type === 'input' && event.nativeEvent.inputType !== 'insertFromPaste') {
        onInput(event.target.innerHTML)
    }
  }

  const handlePaste = (event) => {
    if (onPaste && event.type === 'paste') {
    }
    onPaste(event)
  }

  return (
    <div
      {...rest}
      contentEditable
      onInput={handleInput}
      onPaste={handlePaste}
      dangerouslySetInnerHTML={{ __html: defaultValue.current }} />
  )
}
