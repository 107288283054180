import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import formatNumberWithCommas from '../utils/formatNumberWithCommas'
import ContentEditableWithRef from '../components/ContentEditableWithRef'

const PER_TYPE_TO_TEXT = {
  ticket: '1食あたり（税抜）',
  'month': '月',
  '3month': '3月',
}

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    border: '3px solid #000000',
    borderRadius: 5,
    maxWidth: 340,
    margin: '0 auto',
    padding: theme.spacing.unit * 2,
  },
  radioButtonContainer: {
    height: 24,
    width: 24,
    border: '2px solid #A3A3A3',
    borderRadius: 14,
    marginTop: 2,
    marginRight: 4,
  },
  radioButtonInside: {
    height: 16,
    width: 16,
    borderRadius: 10,
    margin: 4,
    backgroundColor: '#FF6069',
  },
  plan: {
    flex: 1,
    marginLeft: 8,
    paddingTop: 3,
  },
  offsetVerticalContainer: {
    paddingTop: 18,
  },
  offsetVertical: {
    marginTop: -18,
  },
  promotionalText: {
    fontWeight: '800',
    fontSize: 12,
    color: '#FF6069',
  },
  title: {
    fontSize: 16,
    fontWeight: '800',
    color: '#232323',
  },
  description: {
    fontSize: 12,
    color: "#666666"
  },
  price: {
    textAlign: 'right',
  },
  strikethroughPriceText: {
    fontSize: 12,
    fontWeight: '800',
    textAlign: 'right',
    textDecoration: 'line-through',
    color: '#FF6069',
    opacity: 0.7,
  },
  priceText: {
    fontSize: 24,
    fontWeight: '800',
    textAlign: 'right',
    color: '#FF6069',
  },
  priceTextSmall: {
    fontSize: 10,
    fontWeight: '500',
    textAlign: 'right',
    color: 'color: rgba(0, 0, 0, 0.3)',
    marginTop: -6,
  }
})

export default withStyles(styles)(({ plan, amount=0, selected, className, classes, onEditCopy }) => {
  return (
    <div
      className={classNames(classes.container, {
        [classes.offsetVerticalContainer]: plan.copy.promotionalText || plan.copy.strikethroughPrice,
      }, className)}>
      <div className={classes.radioButtonContainer}>
        {selected && (<div className={classes.radioButtonInside} />)}
      </div>
      <div className={classNames(classes.plan, {
        [classes.offsetVertical]: plan.copy.promotionalText,
      })}>
        {plan.copy.promotionalText && (
          <div className={classes.promotionalText}>
            {plan.copy.promotionalText}
          </div>
        )}
        <ContentEditableWithRef
          className={classes.title}
          value={plan.copy.title}
          onPaste={e => onEditCopy({
            ...plan.copy,
            title: e.clipboardData.getData('Text'),
          })}
          onInput={value => onEditCopy({
            ...plan.copy,
            title: value,
          })} />
        <ContentEditableWithRef
          className={classes.description}
          value={plan.copy.description}
          onInput={value => onEditCopy({
            ...plan.copy,
            description: value,
          })} />
        <div className={classes.description}>
          {plan.periodType === 'month' ? '月々' : ''}¥{formatNumberWithCommas(amount)} (税抜)
        </div>
      </div>
      <div className={classNames(classes.price, {
        [classes.offsetVertical]: plan.copy.strikethroughPrice,
      })}>
        {plan.copy.strikethroughPrice && (
          <div className={classes.strikethroughPriceText}>
            ¥{plan.copy.strikethroughPrice}
          </div>
        )}
        <div className={classes.priceText}>
          ¥{plan.copy.price}
        </div>
        <div className={classes.priceTextSmall}>
          {PER_TYPE_TO_TEXT[plan.copy.perType]}
        </div>
      </div>
    </div>
  )
})
