import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { debounce } from 'throttle-debounce'
import clone from 'clone'
import {
  Field,
  reduxForm,
  formValueSelector,
  SubmissionError,
  reset,
  change,
} from 'redux-form'
import {
  withRouter,
} from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import {
  TextField,
  Switch,
  Select,
} from 'redux-form-material-ui'
import Paper from '@material-ui/core/Paper'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Autocomplete from '@material-ui/lab/Autocomplete'
import InputLabel from '@material-ui/core/InputLabel'
import MuiSelect from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import CircularProgress from '@material-ui/core/CircularProgress'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import {
  getProducts,
  getProduct,
  createProduct,
  editProduct,
  getShop,
  searchShops,
} from '../redux/api/actions'
import {
  TAX_PERCENT,
  productTypes,
} from '../utils/Constants'
import validate from '../utils/validators/productValidator'
import EditablePlanCard from '../components/EditablePlanCard'

const FORM_NAME = 'product'

const DEFAULT_NEXT_PLAN = 'potluck_12_monthly_7646_with_tax'

const PERMISSIONS = [{
  code: 'rollover',
  label: 'Rollover',
  description: 'Allow tickets from one cycle to be carried over to the next.',
}, {
  code: 'multipleReservations',
  label: 'Multiple reservations',
  description: 'Allow users with this product to reserve more than one meal at a time.',
}, {
  code: 'delivery',
  label: 'Free delivery',
  description: 'Allow users with this product to order delivery for free.',
}, {
  code: 'weekdayOnly',
  label: 'Weekday only',
  description: 'Makes this plan only available Monday-Friday.'
}]

const sortedProductTypes = [
  productTypes.PLAN,
  productTypes.PROMO,
  productTypes.BUNDLE,
  productTypes.ADDON,
]

const PLAN_TYPES = [
  'daily',
  'ticket',
]

const getAmountWithTax = amount => Math.round(amount * (1 + (TAX_PERCENT / 100)))
const getAmountWithoutTax = amount => Math.round(amount / (1 + (TAX_PERCENT / 100)))

const getPerAmount = ({ type, planType, periodType, amount, ticketQuantity, dailyTicketLimit, weekdayOnly }) => {
  let tickets = ticketQuantity

  if (type === productTypes.PLAN && planType === 'daily') {
    if (weekdayOnly) {
      tickets = dailyTicketLimit * 20
    } else {
      tickets = dailyTicketLimit * 30
    }
  } else if (type === productTypes.PROMO) {
    if (weekdayOnly) {
      tickets = 5
    } else {
      tickets = 7
    }
  }

  return Math.round(amount / tickets)
}

const PERIOD_TYPES = [
  'day',
  'week',
  'month',
  'forever',
]

const PERIODS_MAP = {
  day: 'daily',
  week: 'weekly',
  month: 'monthly',
  forever: ''
}

const generateProductCode = ({ name = "", shop, type, planType, ticketQuantity, dailyTicketLimit=0, periodType, amount, weekdayOnly }) => {
  if (type === productTypes.PROMO) {
    return name.toLowerCase().replace(/\W/g, '')
  }

  dailyTicketLimit = parseInt(dailyTicketLimit, 10)
  const ticketQuantityText = ticketQuantity === 999 ? 'tabeho' : ticketQuantity

  const parts = []

  /*
   * Is this product for all of Potluck, or for a specific shop?
   */
  if (shop) {
    parts.push(`s${shop.id}`)
  } else {
    parts.push('potluck')
  }

  if (type === productTypes.PLAN && planType === 'daily') {
    if (weekdayOnly) {
      parts.push('weekday')
    } else if (dailyTicketLimit === 1) {
      parts.push('daily')
    } else if (dailyTicketLimit === 2) {
      parts.push('allday')
    } else {
      parts.push(`${dailyTicketLimit}perday`)
    }
  } else {
    parts.push(ticketQuantityText)
    parts.push(PERIODS_MAP[periodType])
  }

  if ([productTypes.ADDON, productTypes.BUNDLE].includes(type)) {
    parts.push(type)
  }

  parts.push(getAmountWithTax(amount))
  parts.push('with_tax')

  return parts.join('_')
}

const styles = theme => ({
  container: {
    margin: theme.spacing(3),
  },
  paper: {
    marginBottom: theme.spacing(3),
  },
  toolbar: {
    display: 'flex',
    justiftContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  title: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing.unit * 3,
  },
  titleLink: {
    textDecoration: 'none',
    marginLeft: theme.spacing(3),
  },
  titleLinkText: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  actions: {

  },
  content: {
    padding: theme.spacing.unit * 3,
  },
  sectionTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  errorText: {
    color: 'red',
    marginBottom: theme.spacing.unit * 3,
  },
  formField: {
    marginBottom: theme.spacing.unit * 3,
  },
  formFieldSmall: {
    width: 170,
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  formFieldsHorizontal: {
    display: 'flex',
    flexDirection: 'row',
    '& > :not(:first-child)': {
      marginLeft: theme.spacing(3),
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      '& > :not(:first-child)': {
        marginLeft: 0,
      },
    },
  },
  formFieldsPassword: {
    display: 'flex',
    flexDirection: 'row',
    '& > :not(:first-child)': {
      marginLeft: theme.spacing(3),
    },
  },
  button: {
    margin: theme.spacing.unit,
    marginTop: theme.spacing.unit * 2,
  },
  progress: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    zIndex: 10,
  },
  permission: {
    marginTop: theme.spacing(1),
  },
  toggle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    marginLeft: theme.spacing(-1),
  },
})

class Product extends Component {
  constructor(props) {
    super(props)

    const state = {
      loading: false,
      plans: null,
      planType: 'ticket',
      shopResults: [],
      shopResultsLoading: false,
    }

    if (props.isNew) {
      state.copy = {
        title: 'Title',
        description: 'description',
        perType: 'ticket',
        price: 0,
      }
    }

    this.state = state
  }

  loadProductsIfNeeded = async () => {
    let products = this.props.products.data

    if (!products) {
      products = await this.props.getProducts()
    }

    const plans = products.filter(product => product.type === productTypes.PLAN)

    this.setState({
      plans,
    })
  }

  searchShops = debounce(750, async text => {
    let results

    try {
      results = await this.props.searchShops({
        text,
        page: 0,
      })
    } catch (exception) {
      // swallow
      console.log(exception)
    }

    this.setState({
      shopResultsLoading: false,
      shopResults: results.shops,
    })
  })

  hasLoaded = () => {
    let loaded = this.props.isNew || (this.props.product && this.props.product.loaded)

    if (this.props.product
      && this.props.product.data
      && this.props.product.data.restrictions
      && this.props.product.data.restrictions.shopIds
      && this.props.product.data.restrictions.shopIds.length > 0) {
      loaded = loaded && !!this.state.shop
    }

    return loaded
  }

  onChangePlanType = event => {
    this.setState({
      planType: event.target.value,
    })
  }

  onChangeShopSearch = event => {
    this.setState({ shopResultsLoading: true })
    this.searchShops(event.target.value)
  }

  onChangeShop = (event, shop) => {
    this.setState({
      shop,
    })
  }

  onChangeNextProductCode = event => {
    this.setState({
      nextProductCode: event.target.value,
    })
  }

  onEditCopy = copy => {
    this.setState({ copy })
  }

  onClickSubmit = async values => {
    this.setState({ loading: true })

    if (this.props.type !== productTypes.PLAN || this.state.planType !== 'daily') {
      delete values.dailyTicketLimit
    }

    let restrictions = {}

    if (this.props.shop) {
      restrictions = this.props.shop.restrictions || {}
    }

    if (this.state.shop) {
      restrictions.shopIds = [this.state.shop.id]
    }

    if (this.props.isNew) {
      const amount = getAmountWithTax(values.amount)
      const code = generateProductCode({
        name: this.props.name,
        shop: this.state.shop,
        type: this.props.type,
        planType: this.state.planType,
        ticketQuantity: this.props.ticketQuantity,
        dailyTicketLimit: this.props.dailyTicketLimit,
        periodType: this.props.periodType,
        amount: this.props.amount,
        weekdayOnly: this.props.weekdayOnly,
      })

      const result = await this.props.createProduct({
        ...values,
        permissions: {
          ...values.permissions,
          nextProductCode: this.props.type === productTypes.PROMO ? this.state.nextProductCode : null,
        },
        ticketQuantity: this.state.planType === 'daily' ? 999 : values.ticketQuantity,
        amount,
        code,
        copy: this.state.copy,
        restrictions,
      })

      if (result && result.id) {
        window.location = `/products/${result.id}`
      } else if (result && result.error) {
        this.setState({ loading: false })

        throw new SubmissionError({
          _error: result.error,
        })
      }
    } else {
      await this.props.editProduct(this.props.match.params.productId, {
        ...values,
        permissions: {
          ...values.permissions,
          nextProductCode: this.props.type === productTypes.PROMO ? this.state.nextProductCode : null,
        },
        ticketQuantity: this.state.planType === 'daily' ? 999 : values.ticketQuantity,
        amount: getAmountWithTax(values.amount),
        copy: this.state.copy,
        restrictions,
      })

      const product = await this.props.getProduct(this.props.match.params.productId)

      this.setState({
        loading: false,
        copy: clone(product.copy),
      })

      this.props.dispatch(reset(FORM_NAME))
    }
  }

  async componentWillMount() {
    this.loadProductsIfNeeded()

    if (!this.props.isNew) {
      const product = await this.props.getProduct(this.props.match.params.productId)

      if (product) {
        const state = {
          planType: product.dailyTicketLimit > 0 ? 'daily' : 'ticket',
          copy: clone(product.copy),
        }

        if (product.permissions) {
          state.nextProductCode = product.permissions.nextProductCode || DEFAULT_NEXT_PLAN
        }

        if (product.restrictions
          && product.restrictions.shopIds
          && product.restrictions.shopIds.length > 0) {
          const shopId = product.restrictions.shopIds[0]

          const shop = await this.props.getShop(shopId)

          state.shop = shop
        }

        this.setState(state)
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.type !== prevProps.type) {
      if ([productTypes.PLAN, productTypes.ADDON].includes(this.props.type)) {
        this.props.dispatch(change(FORM_NAME, 'periodAmount', 1))
        this.props.dispatch(change(FORM_NAME, 'periodType', 'month'))
      } else if (this.props.type === productTypes.PROMO && this.props.isNew) {
        this.setState({
          nextProductCode: DEFAULT_NEXT_PLAN,
        })

        this.props.dispatch(change(FORM_NAME, 'periodAmount', 1))
        this.props.dispatch(change(FORM_NAME, 'periodType', 'week'))
      } else if (this.props.type === productTypes.BUNDLE) {
        this.props.dispatch(change(FORM_NAME, 'periodAmount', 1))
        this.props.dispatch(change(FORM_NAME, 'periodType', 'forever'))
      }
    }

    if (this.hasLoaded()
      && this.state.copy
      && (this.props.type !== prevProps.type
      || this.props.planType !== prevProps.planType
      || this.props.amount !== prevProps.amount
      || this.props.ticketQuantity !== prevProps.ticketQuantity
      || this.props.dailyTicketLimit !== prevProps.dailyTicketLimit
      || this.props.weekdayOnly !== prevProps.weekdayOnly)) {
      this.setState({
        copy: {
          ...this.state.copy,
          price: getPerAmount({
            type: this.props.type,
            planType: this.state.planType,
            periodType: this.props.periodType,
            amount: this.props.amount,
            ticketQuantity: this.props.ticketQuantity,
            dailyTicketLimit: this.props.dailyTicketLimit,
            weekdayOnly: this.props.weekdayOnly,
          })
        }
      })
    }
  }

  render() {
    if (!this.hasLoaded()) {
      return false
    }
    
    const {
      handleSubmit,
      pristine,
      invalid,
      error,
    } = this.props

    let code = false
    let isCopyDirty = this.props.isNew || (this.props.product.data && JSON.stringify(this.state.copy) !== JSON.stringify(this.props.product.data.copy))
    let isNextProductCodeDirty = this.props.isNew || (this.props.type === productTypes.PROMO && this.state.nextProductCode !== this.props.product.data.permissions.nextProductCode)

    if (this.props.isNew) {
      code = generateProductCode({
        name: this.props.name,
        shop: this.state.shop,
        type: this.props.type,
        planType: this.state.planType,
        ticketQuantity: this.props.ticketQuantity,
        dailyTicketLimit: this.props.dailyTicketLimit,
        periodType: this.props.periodType,
        amount: this.props.amount,
        weekdayOnly: this.props.weekdayOnly,
      })
    } else {
      code = this.props.product.data.code
    }

    const plans = this.state.plans || []
    const isDailyPlan = this.props.type === productTypes.PLAN && this.state.planType === 'daily'

    return (
      <div className={this.props.classes.container}>
        <Paper className={this.props.classes.paper}>
          <Toolbar className={this.props.classes.toolbar}>
            <div className={this.props.classes.title}>
              <Typography variant="h4">
                {this.props.isNew ? 'New Product' : this.props.name}
              </Typography>
            </div>
            <div className={this.props.classes.actions}>
              <Button
                variant="contained"
                color="primary"
                className={this.props.classes.button}
                disabled={invalid || this.state.loading || (pristine && !isCopyDirty && !isNextProductCodeDirty)}
                onClick={handleSubmit(this.onClickSubmit)}>
                登録する
              </Button>
            </div>
          </Toolbar>
          {!this.props.isNew && this.props.type === productTypes.PLAN && (
            <a
              href={`https://pay.jp/d/plans/${code}`}
              className={this.props.classes.titleLink}>
              <Typography
                variant="caption"
                className={this.props.classes.titleLinkText}>
                View in Pay.jp
              </Typography>
            </a>
          )}
          <form className={this.props.classes.content}>
            {error && (
              <Typography
                variant="body2"
                className={this.props.classes.errorText}>
                {error}
              </Typography>
            )}
            <Field
              name="name"
              label="Name"
              variant="outlined"
              autoComplete="off"
              fullWidth
              component={TextField}
              className={this.props.classes.formField} />
            <div className={this.props.classes.formFieldsHorizontal}>
              <FormControl
                variant="outlined"
                fullWidth
                className={classNames(this.props.classes.formField, this.props.classes.formFieldSelect)}>
                <InputLabel htmlFor="status">
                  Type
                </InputLabel>
                <Field
                  disabled={!this.props.isNew}
                  name="type"
                  label="Type"
                  variant="outlined"
                  autoComplete="off"
                  fullWidth
                  component={Select}>
                  {Object.values(sortedProductTypes).map(productType => (
                    <MenuItem
                      key={productType}
                      value={productType}>
                      {productType}
                    </MenuItem>
                  ))}
                </Field>
              </FormControl>
              {this.props.type === productTypes.PLAN && (
                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classNames(this.props.classes.formField, this.props.classes.formFieldSelect)}>
                  <InputLabel htmlFor="status">
                    Plan type
                  </InputLabel>
                  <MuiSelect
                    disabled={!this.props.isNew}
                    name="planType"
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    value={this.state.planType}
                    onChange={this.onChangePlanType}>
                    {PLAN_TYPES.map(planType => (
                      <MenuItem
                        key={planType}
                        value={planType}>
                        {planType}
                      </MenuItem>
                    ))}
                  </MuiSelect>
                </FormControl>
              )}
              <Field
                disabled={!this.props.isNew}
                name="amount"
                label="Price (no tax)"
                variant="outlined"
                autoComplete="off"
                fullWidth
                type="number"
                InputProps={{
                  inputProps: {
                    min: 0,
                  },
                }}
                component={TextField}
                className={this.props.classes.formField} />
              {isDailyPlan && (
                <Field
                  disabled={!this.props.isNew}
                  name="dailyTicketLimit"
                  label="Daily ticket limit"
                  variant="outlined"
                  autoComplete="off"
                  fullWidth
                  type="number"
                  InputProps={{
                    inputProps: {
                      min: 0,
                    },
                  }}
                  component={TextField}
                  className={this.props.classes.formField} />
              )}
              {!isDailyPlan && (
                <Field
                  disabled={!this.props.isNew}
                  name="ticketQuantity"
                  label="Tickets"
                  variant="outlined"
                  autoComplete="off"
                  fullWidth
                  type="number"
                  InputProps={{
                    inputProps: {
                      min: 0,
                    },
                  }}
                  component={TextField}
                  className={this.props.classes.formField} />
              )}
            </div>
            <div className={this.props.classes.formFieldsHorizontal}>
              <Field
                disabled={!this.props.isNew || this.props.type !== productTypes.PROMO}
                name="periodAmount"
                label="Period amount"
                variant="outlined"
                autoComplete="off"
                fullWidth
                type="number"
                InputProps={{
                  inputProps: {
                    min: 0,
                  },
                }}
                component={TextField}
                className={this.props.classes.formField} />
              <FormControl
                variant="outlined"
                fullWidth
                className={classNames(this.props.classes.formField, this.props.classes.formFieldSelect)}>
                <InputLabel htmlFor="periodType">
                  Period type
                </InputLabel>
                <Field
                  disabled={!this.props.isNew || this.props.type !== productTypes.PROMO}
                  name="periodType"
                  variant="outlined"
                  autoComplete="off"
                  fullWidth
                  component={Select}>
                  {PERIOD_TYPES.map(periodType => (
                    <MenuItem
                      key={periodType}
                      value={periodType}>
                      {periodType}
                    </MenuItem>
                  ))}
                </Field>
              </FormControl>
              {(this.props.type !== productTypes.PROMO || this.state.shop) && (
                <Autocomplete
                  id="shop"
                  ChipProps={{
                    color: 'primary',
                  }}
                  fullWidth
                  disableCloseOnSelect
                  disabled={!this.props.isNew}
                  options={this.state.shopResults}
                  defaultValue={this.state.shop}
                  className={classNames(this.props.classes.formField, this.props.classes.formFieldSelect)}
                  getOptionLabel={option => option.name}
                  getOptionSelected={(option, value) => option.id === value.id}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        color="primary"
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Shop"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {this.state.shopResultsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                        onChange: this.onChangeShopSearch,
                      }}
                    />
                  )}
                  onChange={this.onChangeShop} />
              )}
            </div>
            {this.props.isNew && (
              <FormControl
                variant="outlined"
                fullWidth
                className={classNames(this.props.classes.formField, this.props.classes.formFieldSelect)}>
                <TextField
                  name="code"
                  label="Code"
                  disabled
                  fullWidth
                  variant="outlined"
                  value={code} />
              </FormControl>
            )}
            {!this.props.isNew && (
              <Field
                disabled
                name="code"
                label="Code"
                variant="outlined"
                fullWidth
                component={TextField}
                className={this.props.classes.formField} />
            )}
            {this.props.type === productTypes.PROMO && this.state.nextProductCode && (
              <FormControl
                fullWidth
                variant="outlined"
                className={this.props.classes.filterSelect}>
                <InputLabel htmlFor="nextProductCode">
                  Converts to
                </InputLabel>
                <MuiSelect
                  name="nextProductCode"
                  value={this.state.nextProductCode}
                  onChange={this.onChangeNextProductCode}>
                  {plans.map(plan => (
                    <MenuItem
                      key={plan.code}
                      value={plan.code}>
                      {plan.code}
                    </MenuItem>
                  ))}
                </MuiSelect>
              </FormControl>
            )}
            <Typography
              variant="h6"
              className={this.props.classes.sectionTitle}>
              Appearance
            </Typography>
            {this.state.copy && (
              <EditablePlanCard
                plan={{
                  amount: this.props.amount,
                  periodAmount: this.props.periodAmount,
                  periodType: this.props.periodType,
                  copy: this.state.copy,
                }}
                amount={this.props.amount}
                className={this.props.classes.product}
                onEditCopy={this.onEditCopy} />
            )}
            <Typography
              variant="h6"
              className={this.props.classes.sectionTitle}>
              Permissions
            </Typography>
            {PERMISSIONS.map(permission => (
              <div className={this.props.classes.permission}>
                <FormControl
                  key={permission.code}
                  className={this.props.classes.toggle}>
                  <Field
                    name={`permissions.${permission.code}`}
                    color="primary"
                    component={Switch} />
                  <Typography variant="body2">
                    {permission.label}
                  </Typography>
                </FormControl>
                <Typography variant="caption">
                  {permission.description}
                </Typography>
              </div>
            ))}
          </form>
        </Paper>
      </div>
    )
  }
}

const selector = formValueSelector(FORM_NAME)

const mapStateToProps = (state, ownProps) => {
  const props = {
    isNew: ownProps.match.params.productId === 'new',
    name: selector(state, 'name'),
    type: selector(state, 'type'),
    ticketQuantity: selector(state, 'ticketQuantity'),
    dailyTicketLimit: selector(state, 'dailyTicketLimit'),
    amount: selector(state, 'amount'),
    periodAmount: selector(state, 'periodAmount'),
    periodType: selector(state, 'periodType'),
    weekdayOnly: selector(state, 'permissions.weekdayOnly'),
    product: state.api.product[ownProps.match.params.productId],
    products: state.api.products.default,
    usersSearch: state.api.usersSearch[state.api.usersSearch.lastReceivedKey],
  }

  if (props.isNew) {
    props.initialValues = {
      type: productTypes.PLAN,
      periodAmount: 1,
      periodType: 'month',
      amount: 0,
      ticketQuantity: 0,
      permissions: {
        rollover: true,
        multipleReservations: true,
      },
    }
  } else if (props.product && props.product.data) {
    props.initialValues = {
      ticketQuantity: props.product.data.ticketQuantity,
      dailyTicketLimit: props.product.data.dailyTicketLimit,
      type: props.product.data.type,
      name: props.product.data.name,
      amount: getAmountWithoutTax(props.product.data.amount),
      periodAmount: props.product.data.periodAmount,
      periodType: props.product.data.periodType,
      code: props.product.data.code,
      permissions: props.product.data.permissions,
    }
  }

  return props
}

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    getProducts,
    getProduct,
    createProduct,
    editProduct,
    getShop,
    searchShops,
  }, dispatch),
  dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: FORM_NAME,
  validate,
  enableReinitialize: true,
})(withRouter(withStyles(styles)(Product))))
