import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import {
  getDashboardSubscriberData,
} from '../../redux/api/actions'

export const PLANS_RANKING = [
  'potluck_6',
  'potluck_6_monthly',
  'potluck_6_monthly_with_tax',
  'potluck_6_monthly_10_percent_off_with_tax',
  'potluck_6_monthly_20_percent_off_with_tax',
  'potluck_6_monthly_30_percent_off_with_tax',
  'potluck_6_monthly_50_percent_off_with_tax',
  'potluck_6_monthly_88_percent_off_with_tax',

  'potluck_12',
  'potluck_12_monthly',
  'potluck_12_monthly_with_tax',
  'potluck_12_monthly_rollover_with_tax',
  'potluck_12_monthly_6739_with_tax',
  'potluck_12_monthly_6240_with_tax',
  'potluck_12_monthly_rollover_10_percent_off_with_tax',
  'potluck_12_monthly_rollover_30_percent_off_with_tax',
  'potluck_12_monthly_20_percent_off_with_tax',
  'potluck_12_monthly_50_percent_off_with_tax',
  'potluck_12_monthly_88_percent_off_with_tax',

  'potluck_20',
  'potluck_20_monthly',
  'potluck_20_monthly_with_tax',
  'potluck_20_monthly_rollover_with_tax',
  'potluck_20_monthly_11016_with_tax',
  'potluck_20_monthly_rollover_10_percent_off_with_tax',
  'potluck_20_monthly_20_percent_off_with_tax',
  'potluck_20_monthly_rollover_30_percent_off_with_tax',
  'potluck_20_monthly_50_percent_off_with_tax',
  'potluck_20_monthly_88_percent_off_with_tax',

  'potluck_tabeho',
  'potluck_tabeho_12960_with_tax',
  'potluck_tabeho_12960_single_ticket_with_tax',
  'potluck_tabeho_10799_single_ticket_with_tax',
  'potluck_tabeho_12960_10_percent_off_single_ticket_with_tax',
  'potluck_tabeho_12960_30_percent_off_single_ticket_with_tax',
  'potluck_tabeho_12960_20_percent_off_single_ticket_with_tax',
  'potluck_tabeho_12960_10_percent_off_with_tax',
  'potluck_tabeho_12960_20_percent_off_with_tax',
  'potluck_tabeho_12960_30_percent_off_with_tax',

  'potluck_tabeho_with_tax',
  'potluck_tabeho_monthly_25920_with_tax',
  'potluck_tabeho_monthly_25920_single_ticket_with_tax',
  'potluck_tabeho_monthly_25920_10_percent_off_with_tax',
  'potluck_tabeho_monthly_25920_20_percent_off_with_tax',
  'potluck_tabeho_monthly_25920_30_percent_off_with_tax',
]

const styles = theme => ({
  root: {
    flex: '1 0 auto',
  },
  content: {
    margin: theme.spacing.unit * 3,
  },
  toolbar: {
    display: 'flex',
    justiftContent: 'space-between',
  },
  title: {
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  darkRow: {
    backgroundColor: theme.palette.background.default,
  },
  darkRowText: {
    fontWeight: 'bold',
  },
})

class Subscriptions extends Component {
  state = {
    search: '',
  }

  hasLoaded = () => {
    return this.props.dashboardSubscriberData.loaded
  }

  onChangeSearch = event => {
    this.setState({
      search: event.currentTarget.value,
    })
  }

  onClickSubscriber = subscriber => {
    window.open(`/users/${subscriber.userId}`)
  }

  componentWillMount() {
    if (!this.hasLoaded()) {
      Promise.all([
        this.props.getDashboardSubscriberData(),
      ])
    }
  }

  render() {
    if (!this.hasLoaded()) {
      return false
    }

    const plans = Object.keys(this.props.dashboardSubscriberData.data)
    plans.sort((first, second) => PLANS_RANKING.indexOf(first) < PLANS_RANKING.indexOf(second) ? -1 : 1)

    return (
      <div className={this.props.classes.root}>
        <Paper className={this.props.classes.content}>
          <Toolbar className={this.props.classes.toolbar}>
            <div className={this.props.classes.title}>
              <Typography variant="h6">
                Subscribers
              </Typography>
            </div>
          </Toolbar>
          <div className={this.props.classes.tableWrapper}>
            <Table className={this.props.classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>plan</TableCell>
                  <TableCell>id</TableCell>
                  <TableCell>name</TableCell>
                  <TableCell>email</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {plans.map(plan => [(
                  <TableRow
                    key={plan}
                    className={this.props.classes.darkRow}>
                    <TableCell
                      colSpan="5"
                      className={this.props.classes.darkRowText}>
                      {plan} ({this.props.dashboardSubscriberData.data[plan].length})
                    </TableCell>
                  </TableRow>
                ), ...this.props.dashboardSubscriberData.data[plan].map(subscriber => (
                  <TableRow
                    key={subscriber.id}
                    hover
                    onClick={() => this.onClickSubscriber(subscriber)}>
                    <TableCell />
                    <TableCell>{subscriber.userId}</TableCell>
                    <TableCell>{subscriber.userName}</TableCell>
                    <TableCell>{subscriber.userEmail}</TableCell>
                  </TableRow>
                ))])}
              </TableBody>
            </Table>
          </div>
        </Paper>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  dashboardSubscriberData: state.api.dashboardSubscriberData.default,
})

const mapDispatchToProps = {
  getDashboardSubscriberData,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Subscriptions))
