import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment-timezone'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import {
  getOrganizations,
} from '../../redux/api/actions'

const styles = theme => ({
  root: {
    flex: '1 0 auto',
  },
  content: {
    margin: theme.spacing.unit * 3,
  },
  toolbar: {
    display: 'flex',
    justiftContent: 'space-between',
  },
  title: {
    flex: '1',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  searchBox: {
    flex: 1,
    boxSizing: 'border-box',
    width: '100%',
    height: 40,
    fontSize: '12px',
    lineHeight: '16px',
    border: '1px solid #e4e4e4',
    borderRadius: 4,
    outline: 'none',
    appearance: 'none',
    paddingLeft: theme.spacing.unit,
    marginLeft: theme.spacing.unit * 2,
  }
})

class Organizations extends Component {
  state = {
    search: '',
  }

  hasLoaded = () => {
    return this.props.organizations.loaded
  }

  onChangeSearch = event => {
    this.setState({
      search: event.currentTarget.value,
    })
  }

  onClickOrganization = organization => {
    this.props.history.push(`/organizations/${organization.id}`)
  }

  componentWillMount() {
    if (!this.hasLoaded()) {
      Promise.all([
        this.props.getOrganizations(),
      ])
    }
  }

  render() {
    if (!this.hasLoaded()) {
      return false
    }

    return (
      <div className={this.props.classes.root}>
        <Paper className={this.props.classes.content}>
          <Toolbar className={this.props.classes.toolbar}>
            <div className={this.props.classes.title}>
              <Typography variant="h6">
                Organizations
              </Typography>
            </div>
          </Toolbar>
          <div className={this.props.classes.tableWrapper}>
            <Table className={this.props.classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>id</TableCell>
                  <TableCell>name</TableCell>
                  <TableCell>owner</TableCell>
                  <TableCell>created</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.organizations.data.map(organization => (
                  <TableRow
                    key={organization.id}
                    hover
                    onClick={() => this.onClickOrganization(organization)}>
                    <TableCell>{organization.id}</TableCell>
                    <TableCell>{organization.name}</TableCell>
                    <TableCell>{organization.owner.email}</TableCell>
                    <TableCell>{moment.tz(organization.createdAt, 'Asia/Tokyo').format('MMMM Do YYYY')}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </Paper>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  organizations: state.api.organizations.default,
})

const mapDispatchToProps = {
  getOrganizations,
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(Organizations)))
