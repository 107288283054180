const REQUIRED_VALUES = [
  'userId',
  'ticketQuantity',
  'status',
]

const REQUIRED_VALUES_REWARD = [
  'label',
  'description',
]

export default (values, props) => {
  const errors = {
    hours: {},
  }

  for (let requiredValue of REQUIRED_VALUES) {
    if (!values[requiredValue]) {
      errors[requiredValue] = 'Required'
    }
  }

  if (!values.endsAt) {
    for (let requiredValue of REQUIRED_VALUES_REWARD) {
      if (!values[requiredValue]) {
        errors[requiredValue] = 'Required'
      }
    }
  }

  return errors
}
