/*
 * Mapbox operates at zoom levels [0, 20] but we really don't want to use that whole spectrum
 */
const MIN_ZOOM = 10
const MAX_ZOOM = 17
const DEGRESS_EARTH = 360

/*
 * Given a delta of either latitudes or longitudes, return the zoom level which will be of equal size
 */
const deltaToZoomLevel = delta => Math.max(MIN_ZOOM, Math.min(Math.floor(Math.log2(DEGRESS_EARTH/delta)), MAX_ZOOM))

/*
 * Given a delta of either latitudes or longitudes, return the zoom level which will be of equal size
 */
const zoomLevelToDelta = zoom => DEGRESS_EARTH / Math.pow(2, zoom)

const MIN_DELTA = zoomLevelToDelta(MAX_ZOOM)

/*
 * Given an array of coordinates and a pixel size of map, return a mapbox viewport which
 * contains all of the coordinates.
 */
export default ({ coordinates, paddingBottomPx, height }) => {
  const longitudes = coordinates.map(coordinate => coordinate.longitude).sort()
  const latitudes = coordinates.map(coordinate => coordinate.latitude).sort()

  const centerLongitude = (longitudes[0] + longitudes[longitudes.length - 1]) / 2
  let centerLatitude = (latitudes[0] + latitudes[latitudes.length - 1]) / 2

  const diffLongitude = Math.max(longitudes[longitudes.length - 1] - longitudes[0], MIN_DELTA)
  let diffLatitude = Math.max(latitudes[latitudes.length - 1] - latitudes[0], MIN_DELTA)

  if (Number.isFinite(paddingBottomPx) && Number.isFinite(height) && height > 0) {
    const latitudeShift = Math.min(1, paddingBottomPx / height) * diffLatitude

    diffLatitude += latitudeShift
    centerLatitude -= latitudeShift
  }

  const zoom = deltaToZoomLevel(Math.max(diffLongitude, diffLatitude))

  return {
    zoom,
    longitude: centerLongitude,
    latitude: centerLatitude,
  }
}
