import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Redirect,
  withRouter,
} from 'react-router-dom'
import moment from 'moment-timezone'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import AddIcon from '@material-ui/icons/Add'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import {
  getProfile,
  getAreas,
} from '../redux/api/actions'

const styles = theme => ({
  root: {
    flex: '1 0 auto',
  },
  content: {
    margin: theme.spacing.unit * 3,
  },
  toolbar: {
    display: 'flex',
  },
  actions: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  title: {
    flex: '1',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
})

class Areas extends Component {
  onClickArea = shop => {
    this.props.history.push(`/areas/${shop.id}`)
  }

  onClickAddArea = () => {
    this.props.history.push('/areas/new')
  }

  componentWillMount() {
    this.props.getProfile()
    this.props.getAreas()
  }

  render() {
    if (this.props.profile.loaded && !this.props.profile.data) {
      return (
        <Redirect to="/login" />
      )
    }

    let areas = []

    if (this.props.areas.loaded) {
      areas = this.props.areas.data
    }

    return (
      <div className={this.props.classes.root}>
        <Paper className={this.props.classes.content}>
          <Toolbar className={this.props.classes.toolbar}>
            <div className={this.props.classes.title}>
              <Typography variant="h6">
                Areas
              </Typography>
            </div>
            <div className={this.props.classes.actions}>
              <Tooltip title="Add">
                <IconButton
                  aria-label="Add"
                  color="primary"
                  onClick={this.onClickAddArea}>
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </div>
          </Toolbar>
          <div className={this.props.classes.tableWrapper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>name</TableCell>
                  <TableCell>city</TableCell>
                  <TableCell>created</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {areas.map((area, index) => (
                  <TableRow
                    key={area.id}
                    hover
                    onClick={() => this.onClickArea(area)}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{area.name}</TableCell>
                    <TableCell>{area.city}</TableCell>
                    <TableCell>{moment.tz(area.createdAt, 'Asia/Tokyo').format('MMMM Do YYYY')}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </Paper>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  profile: state.api.profile.default,
  areas: state.api.areas.default,
})

const mapDispatchToProps = {
  getProfile,
  getAreas,
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(Areas)))
