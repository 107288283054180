import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Toolbar from '@material-ui/core/Toolbar'
import InputBase from '@material-ui/core/InputBase'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import Tooltip from '@material-ui/core/Tooltip'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'
import SearchIcon from '@material-ui/icons/Search'
import AddIcon from '@material-ui/icons/Add'

const TablePaginationActions = withStyles(theme => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing.unit * 2.5,
  },
}))(props => {
  const classes = props.classes
  const { count, page, rowsPerPage, onChangePage } = props

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0)
  }

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1)
  }

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1)
  }

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page">
        <FirstPageIcon />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page">
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page">
        <LastPageIcon />
      </IconButton>
    </div>
  )
})

export default withStyles(theme => ({
  content: {
    margin: theme.spacing.unit * 3,
  },
  toolbar: {
    display: 'flex',
    justiftContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      paddingTop: theme.spacing.unit,
    },
  },
  toolbarLeft: {
    display: 'flex',
    width: '100%',
  },
  title: {
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing.unit * 2,
    },
  },
  search: {
    flex: 1,
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[200],
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing.unit * 3,
      marginRight: theme.spacing.unit * 2,
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing.unit * 6,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 6,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  table: {
  },
  searchBox: {
    flex: 1,
    boxSizing: 'border-box',
    width: '100%',
    height: 40,
    fontSize: '12px',
    lineHeight: '16px',
    border: '1px solid #e4e4e4',
    borderRadius: 4,
    outline: 'none',
    appearance: 'none',
    paddingLeft: theme.spacing.unit,
    marginLeft: theme.spacing.unit * 2,
  },
  pagination: {
    borderBottom: 'none',
  },
  progress: {
    margin: `${theme.spacing.unit * 16}px auto`,
    display: 'flex',
    justifyContent: 'center',
  },
}))(({ title, loading, data, page, rowsPerPage, count, classes, renderHeader, renderRow, onChangePage, onChangeSearch, onClickAdd }) => (
  <Paper className={classes.content}>
    <Toolbar className={classes.toolbar}>
      <div className={classes.toolbarLeft}>
        <div className={classes.title}>
          <Typography variant="h6">
            {title}
          </Typography>
        </div>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon size={20} />
          </div>
          <InputBase
            fullWidth
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            onChange={onChangeSearch} />
        </div>
      </div>
      <TablePagination
        count={count}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[]}
        ActionsComponent={TablePaginationActions}
        classes={{ root: classes.pagination }}
        onChangePage={onChangePage} />
      {onClickAdd && (
        <Tooltip title="Add">
          <IconButton
            aria-label="Add"
            color="primary"
            onClick={onClickAdd}>
            <AddIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
    <div className={classes.tableWrapper}>
      <Table className={classes.table}>
        <TableHead>
          {renderHeader()}
        </TableHead>
        <TableBody>
          {!loading && data.map(item => renderRow(item))}
        </TableBody>
      </Table>
      {loading && (
        <div className={classes.progress}>
          <CircularProgress />
        </div>
      )}
    </div>
  </Paper>
))
