import React, { Component } from 'react'
import moment from 'moment-timezone'
import { connect } from 'react-redux'
import {
  withRouter,
} from 'react-router-dom'
import {
  Field,
  formValueSelector,
  reduxForm,
} from 'redux-form'
import {
  TextField,
} from 'redux-form-material-ui'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import CircularProgress from '@material-ui/core/CircularProgress'
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded'
import {
  KeyboardDatePicker,
} from '@material-ui/pickers'
import {
  getProfile,
  getHolidays,
  getHolidayDialogs,
  createHolidayDialog,
  editHolidayDialog,
  deleteHolidayDialog,
} from '../redux/api/actions'
import validate from '../utils/validators/holidayDialogValidator'
import promiseMap from '../utils/promiseMap'

const FORM_NAME = 'holiday-dialog'

const DATE_FIELDS = [
  'periodStart',
  'periodEnd',
  'startsAt',
  'endsAt',
]

const styles = theme => ({
  root: {
    flex: '1 0 auto',
  },
  container: {
    margin: theme.spacing.unit * 3,
  },
  content: {
    padding: theme.spacing.unit * 3,
  },
  toolbar: {
    display: 'flex',
    justiftContent: 'space-between',
  },
  title: {
    flex: 1,
  },
  cta: {
    marginLeft: theme.spacing(2),
  },
  ctaProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  ctaIcon: {
    marginRight: theme.spacing(),
  },
  formField: {
    flex: 1,
    marginBottom: theme.spacing.unit * 3,
  },
  formFieldDate: {
    flex: 1,
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit * 3,
  },
  formFieldsHorizontal: {
    display: 'flex',
    flexDirection: 'row',
    '& > :not(:first-child)': {
      marginLeft: theme.spacing(3),
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      '& > :not(:first-child)': {
        marginLeft: 0,
      },
    },
  },
  formFieldSelect: {
    minWidth: 180,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
})

class HolidayDialog extends Component {
  constructor(props) {
    super(props)

    const state = {
      loading: false,
      deleteLoading: false,
    }

    if (props.holidayDialog) {
      for (let dateField of DATE_FIELDS) {
        state[dateField] = moment.tz(props.holidayDialog[dateField], 'Asia/Tokyo')
      }
    }

    this.state = state
  }

  hasLoaded = () => this.props.holidays.loaded && this.props.holidayDialogs.loaded

  isDateDirty = () => this.props.isNew || DATE_FIELDS.some(dateField => !(this.state[dateField] && this.props.holidayDialog && this.state[dateField].isSame(moment(this.props.holidayDialog[dateField]))))

  onDateChange = (name, date) => {
    date = moment(date)

    this.setState({
      [name]: date,
    })

    if (name === 'periodStart') {
      if (date.isAfter(this.state.periodEnd)) {
        this.setState({
          periodEnd: date.clone().add(1, 'day')
        })
      }
    }

    if (name === 'startsAt') {
      if (date.isAfter(this.state.endsAt)) {
        this.setState({
          endsAt: date.clone().add(1, 'day')
        })
      }
    }

    if (name === 'periodEnd') {
      if (date.isBefore(this.state.periodStart)) {
        this.setState({
          periodStart: date.clone().subtract(1, 'day')
        })
      }
    }

    if (name === 'endsAt') {
      if (date.isBefore(this.state.startsAt)) {
        this.setState({
          startsAt: date.clone().subtract(1, 'day')
        })
      }
    }
  }

  onPressDelete = async () => {
    this.setState({ deleteLoading: true })

    await this.props.deleteHolidayDialog(this.props.match.params.holidayDialogId)
    await this.props.getHolidays()

    this.props.history.push('/holidays')
  }

  onPressSave = async values => {
    this.setState({ loading: true })

    if (this.props.isNew) {
      const newHolidayDialog = await this.props.createHolidayDialog({
        ...values,
        ...DATE_FIELDS.reduce((memo, dateName) => ({
          ...memo,
          [dateName]: this.state[dateName].format('YYYYMMDD'),
        }), {}),
      })

      await this.props.getHolidayDialogs()

      window.location = `/holidays/dialogs/${newHolidayDialog.id}`
    } else {
      await this.props.editHolidayDialog(this.props.holidayDialogId, {
        ...values,
        ...DATE_FIELDS.reduce((memo, dateName) => ({
          ...memo,
          [dateName]: this.state[dateName].format('YYYYMMDD'),
        }), {}),
      })

      await this.props.getHolidayDialogs()
      this.setState({ loading: false })
    }
  }

  onClickHoliday = holiday => {
    this.props.history.push(`/holidays/${holiday.id}`)
  }

  async componentWillMount() {
    this.props.getProfile()

    if (!this.hasLoaded()) {
      const {
        holidayDialogs,
      } = await promiseMap({
        holidays: this.props.getHolidays(),
        holidayDialogs: this.props.getHolidayDialogs(),
      })

      if (!this.props.isNew) {
        const holidayDialog = holidayDialogs.find(({ id }) => id === parseInt(this.props.holidayDialogId, 10))

        const state = this.state

        for (let dateField of DATE_FIELDS) {
          state[dateField] = moment.tz(holidayDialog[dateField], 'Asia/Tokyo')
        }

        this.setState(state)
      }
    }
  }

  render() {
    let holidaysInRange = []

    if (this.props.holidays.data) {
      holidaysInRange = this.props.holidays.data.filter(holiday => moment(holiday.startDate).isBetween(this.state.periodStart, this.state.periodEnd, null, '[)'))
    }

    return (
      <div className={this.props.classes.root}>
        <Paper className={this.props.classes.container}>
          <Toolbar className={this.props.classes.toolbar}>
            <div className={this.props.classes.title}>
              <Typography variant="h4">
                {this.props.name}
              </Typography>
            </div>
            {!this.props.isNew && (
              <Button
                variant="contained"
                color="secondary"
                disabled={this.state.loading || this.state.deleteLoading}
                onClick={this.onPressDelete}>
                {this.state.deleteLoading && (
                  <CircularProgress
                    size={24}
                    color="secondary"
                    className={this.props.classes.ctaProgress} />
                )}
                <DeleteRoundedIcon className={this.props.classes.ctaIcon} />
                Delete
              </Button>
            )}
            <Button
              variant="contained"
              color="primary"
              disabled={(this.props.pristine && !this.isDateDirty()) || this.props.invalid || this.state.loading || this.state.deleteLoading}
              className={this.props.classes.cta}
              onClick={this.props.handleSubmit(this.onPressSave)}>
              {this.state.loading && (
                <CircularProgress
                  size={24}
                  color="primary"
                  className={this.props.classes.ctaProgress} />
              )}
              Save
            </Button>
          </Toolbar>
          <div className={this.props.classes.content}>
            <form>
              <Field
                name="name"
                label="Name"
                variant="outlined"
                fullWidth
                autoComplete="off"
                component={TextField}
                className={this.props.classes.formField} />
              <Typography variant="h6">
                Dialog display dates
              </Typography>
              <Typography variant="caption">
                The dialog will appear in shop admin between these dates.
              </Typography>
              <div className={this.props.classes.formFieldsHorizontal}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  label="Date"
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  value={this.state.startsAt}
                  className={this.props.classes.formFieldDate}
                  onChange={date => this.onDateChange('startsAt', date)} />
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  label="Date"
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  value={this.state.endsAt}
                  className={this.props.classes.formFieldDate}
                  onChange={date => this.onDateChange('endsAt', date)} />
              </div>
              <Typography variant="h6">
                Holiday dates
              </Typography>
              <Typography variant="caption">
                Holidays which are between these two dates will appear in the dialog in shop admin.
              </Typography>
              <div className={this.props.classes.formFieldsHorizontal}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  label="Start"
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  value={this.state.periodStart}
                  className={this.props.classes.formFieldDate}
                  onChange={date => this.onDateChange('periodStart', date)} />
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  label="End"
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  value={this.state.periodEnd}
                  className={this.props.classes.formFieldDate}
                  onChange={date => this.onDateChange('periodEnd', date)} />
              </div>
            </form>
          </div>
        </Paper>
        <Paper className={this.props.classes.container}>
          <Toolbar className={this.props.classes.toolbar}>
            <div className={this.props.classes.title}>
              <Typography variant="h4">
                Holidays in this range
              </Typography>
            </div>
          </Toolbar>
          <div className={this.props.classes.tableWrapper}>
            <Table className={this.props.classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!this.state.loading && holidaysInRange.map(holiday => (
                  <TableRow
                    key={holiday.id}
                    hover
                    onClick={() => this.onClickHoliday(holiday)}>
                    <TableCell>{holiday.id}</TableCell>
                    <TableCell>{holiday.name}</TableCell>
                    <TableCell>{moment.tz(holiday.startDate, 'Asia/Tokyo').format('MMMM Do YYYY')}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </Paper>
      </div>
    )
  }
}

const selector = formValueSelector(FORM_NAME)

const mapStateToProps = (state, ownProps) => {
  let initialValues
  let holidayDialog
  const holidayDialogId = ownProps.match && ownProps.match.params.holidayDialogId

  if (holidayDialogId && state.api.holidayDialogs.default.data) {
    holidayDialog = state.api.holidayDialogs.default.data.find(({ id }) => id === parseInt(holidayDialogId, 10))

    if (holidayDialog) {
      initialValues = {
        name: holidayDialog.name,
      }
    }
  }

  return {
    isNew: ownProps.match && holidayDialogId === 'new',
    holidays: state.api.holidays.default,
    holidayDialogs: state.api.holidayDialogs.default,
    holidayDialog,
    holidayDialogId,
    initialValues,
    name: selector(state, 'name'),
  }
}

const mapDispatchToProps = {
  getProfile,
  getHolidays,
  getHolidayDialogs,
  createHolidayDialog,
  editHolidayDialog,
  deleteHolidayDialog,
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: FORM_NAME,
  validate,
  enableReinitialize: true,
})(withRouter(withStyles(styles)(HolidayDialog))))


