import React, {
  Component,
  PureComponent,
} from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import moment from 'moment-timezone'
import { Link } from 'react-router-dom'
import 'moment/locale/ja'
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  LineChart,
  Line,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts'
import { withStyles } from '@material-ui/core/styles'
import Fade from '@material-ui/core/Fade'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import ArrowUpwardRoundedIcon from '@material-ui/icons/ArrowUpwardRounded'
import ArrowDownwardRoundedIcon from '@material-ui/icons/ArrowDownwardRounded'
import {
  red,
  green,
  blue,
  orange,
} from '@material-ui/core/colors'
import {
  getDashboardData,
  getJoinsAndChurns,
} from '../../redux/api/actions'

moment.locale('ja')

export const PLAN_COLORS = {
  'Potluck 3': '#ffa500',
  'Potluck 6': '#86DAF4',
  'Potluck 9': '#f6546a',
  'Potluck 12': '#EA89F4',
  'Potluck Delivery 12': '#daa520',
  'Potluck 16': '#ccff00',
  'Potluck 20': '#B4E372',
  'Potluck 30': '#c39797',
  'Potluck Tabeho': '#F29988',
  'Potluck Tabeho Lunch': '#fa8072',
  'Potluck Tabeho Daily': '#dcedc1',
  'Potluck Delivery Daily': '#000000',
  'Potluck All Day': '#5ac18e',
  'Potluck Weekday': '#78A300',
  '100 Tickets': '#c996d0',
  '200 Tickets': '#5fc5e8',
  'Potluck Delivery Daily (4100 yen off)': '#811172',
}

export const SOURCE_COLORS = {
  'iOS App': '#39CCCC',
  'Android App': '#2ECC40',
  web: '#FF4136',
  total: '#111111',
}

export const USAGE_COLORS = {
  'Potluck 3': '#ffa500',
  'Potluck 6': '#86DAF4',
  'Potluck 9': '#f6546a',
  'Potluck 12': '#EA89F4',
  'Potluck Delivery 12': '#daa520',
  'Potluck 16': '#ccff00',
  'Potluck 20': '#B4E372',
  'Potluck 30': '#c39797',
  'Potluck Tabeho Lunch': '#fa8072',
  'Potluck Tabeho Daily': '#dcedc1',
  'Potluck Delivery Daily': '#000000',
  'Potluck All Day': '#5ac18e',
  'Potluck Delivery Daily (4100 yen off)': '#811172',
}

const PRODUCTS_RANKING = [
  'Potluck 3',
  'Potluck 6',
  'Potluck 9',
  'Potluck 12',
  'Potluck Delivery 12',
  'Potluck 16',
  'Potluck 20',
  'Potluck 30',
  'Potluck Tabeho',
  'Potluck Tabeho Lunch',
  'Potluck Tabeho Daily',
  'Potluck Delivery Daily',
  'Potluck All Day',
]

const UpDownCounter = withStyles(theme => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    border: '1 solid red',
  },
  up: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing.unit / 2,
  },
  upIcon: {
    color: green[600],
  },
  upText: {
    fontWeight: 'bold',
    color: green[600],
  },
  down: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing.unit / 2,
  },
  downIcon: {
    color: red[600],
  },
  downText: {
    fontWeight: 'bold',
    color: red[600],
  },
}))(({ up, down, classes, className }) => (
  <div className={classNames(classes.container, className)}>
    <div className={classes.up}>
      <ArrowUpwardRoundedIcon className={classes.upIcon} />
      <Typography
        variant="caption"
        className={classes.upText}>
        {up}
      </Typography>
    </div>
    <div className={classes.down}>
      <ArrowDownwardRoundedIcon className={classes.downIcon} />
      <Typography
        variant="caption"
        className={classes.downText}>
        {down}
      </Typography>
    </div>
  </div>
))

export class CustomizedAxisTick extends PureComponent {
  render() {
    const {
      x, y, payload,
    } = this.props

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          fontSize="9px"
          transform="rotate(-55)">
          {payload.value}
        </text>
      </g>
    )
  }
}

const styles = theme => ({
  container: {
    margin: theme.spacing.unit * 2,
  },
  section: {
    marginBottom: theme.spacing.unit * 4,
  },
  title: {
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: theme.spacing.unit * 2,
  },
  titleLink: {
    marginLeft: theme.spacing.unit,
  },
  chart: {
    width: '100%',
    height: 500,
    paddingTop: theme.spacing.unit * 3,
    paddingBottom: theme.spacing.unit * 3,
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
  },
  counts: {
    display: 'flex',
    marginLeft: theme.spacing.unit * -1,
    marginRight: theme.spacing.unit * -1,
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  count: {
    position: 'relative',
    height: 170,
    width: 120,
    margin: theme.spacing.unit,
    padding: theme.spacing.unit,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      padding: theme.spacing.unit * 2,
      marginTop: theme.spacing.unit,
      marginBottom: theme.spacing.unit,
    },
  },
  countFlex: {
    flex: 1,
  },
  countRed: {
    backgroundColor: red[600],
  },
  countGreen: {
    backgroundColor: green[600],
  },
  countBlue: {
    backgroundColor: blue[600],
  },
  countTitle: {
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: theme.spacing.unit * 2,
    minHeight: 40,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing.unit,
    },
  },
  countTitleColors: {
    color: '#ffffff',
  },
  countValueColors: {
    color: '#ffffff',
  },
  planCountUpDown: {
  },
})

class Dashboard extends Component {
  state = {
    width: 800,
  }

  hasLoaded = () => {
    return this.props.dashboardData.loaded
  }

  handleResize = ({ width }) => this.setState({ width })

  componentWillMount() {
    const beginningOfMonth = moment.tz('Asia/Tokyo').startOf('month').format('YYYY-MM-DD')

    if (!this.hasLoaded()) {
      this.props.getDashboardData()
      this.props.getJoinsAndChurns({ since: beginningOfMonth })
    }
  }

  render() {
    if (!this.hasLoaded()) {
      return false
    }

    let plans = []

    if (this.props.dashboardData.loaded) {
      plans = Object.keys(this.props.dashboardData.data.todayPlanCounts)

      plans.sort((first, second) => PRODUCTS_RANKING.indexOf(first) < PRODUCTS_RANKING.indexOf(second) ? -1 : 1)
    }

    return (
      <div className={this.props.classes.container}>
        <div className={this.props.classes.section}>
          <Typography
            variant="h5"
            className={this.props.classes.title}>
            All-time totals
          </Typography>
          <div className={this.props.classes.counts}>
            <Paper className={classNames(this.props.classes.count, this.props.classes.countFlex, this.props.classes.countBlue)}>
              <Typography
                variant="body1"
                className={classNames(this.props.classes.countTitle, this.props.classes.countTitleColors)}>
                Users
              </Typography>
              <Typography
                variant="h3"
                className={this.props.classes.countValueColors}>
                {this.props.dashboardData.data.users.total}
              </Typography>
            </Paper>
            <Paper className={classNames(this.props.classes.count, this.props.classes.countFlex, this.props.classes.countRed)}>
              <Typography
                variant="body1"
                className={classNames(this.props.classes.countTitle, this.props.classes.countTitleColors)}>
                Reservations
              </Typography>
              <Typography
                variant="h3"
                className={this.props.classes.countValueColors}>
                {this.props.dashboardData.data.reservations.total}
              </Typography>
            </Paper>
            <Paper className={classNames(this.props.classes.count, this.props.classes.countFlex, this.props.classes.countGreen)}>
              <Typography
                variant="body1"
                className={classNames(this.props.classes.countTitle, this.props.classes.countTitleColors)}>
                Tickets
              </Typography>
              <Typography
                variant="h3"
                className={this.props.classes.countValueColors}>
                {this.props.dashboardData.data.tickets.total}
              </Typography>
            </Paper>
          </div>
        </div>
        <div className={this.props.classes.section}>
          <Typography
            variant="h5"
            className={this.props.classes.title}>
            Subscribers
            <Typography
              variant="body1"
              className={this.props.classes.titleLink}>
              (<Link to="/subscriptions">See all</Link>)
            </Typography>
          </Typography>
          <div className={this.props.classes.counts}>
            {plans.map(plan => (
              <Paper
                key={plan}
                className={this.props.classes.count}>
                <Typography
                  variant="body1"
                  className={this.props.classes.countTitle}>
                  {plan}
                </Typography>
                <Typography variant="h3">
                  {this.props.dashboardData.data.todayPlanCounts[plan].value}
                </Typography>
                {this.props.joinsAndChurns && this.props.joinsAndChurns.loaded && (
                  <Fade in>
                    <UpDownCounter
                      up={(this.props.joinsAndChurns.data[plan] && this.props.joinsAndChurns.data[plan].joins) || 0}
                      down={(this.props.joinsAndChurns.data[plan] && this.props.joinsAndChurns.data[plan].churns) || 0}
                      className={this.props.classes.planCountUpDown} />
                  </Fade>
                )}
              </Paper>
            ))}
          </div>
        </div>
        <div className={this.props.classes.section}>
          <Typography
            variant="h5"
            className={this.props.classes.title}>
            Subscribers (Historical)
          </Typography>
          <Paper className={this.props.classes.chart}>
            <ResponsiveContainer>
              <AreaChart
                height={400}
                data={this.props.dashboardData.data.subscribers}
                margin={{
                  top: 10,
                  right: 20,
                  left: 0,
                  bottom: 30,
                }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="name"
                  interval={0}
                  tick={(<CustomizedAxisTick />)} />
                <YAxis />
                <Tooltip />
                {Object.keys(PLAN_COLORS).map(plan => (
                  <Area
                    key={plan}
                    type="monotone"
                    dataKey={plan}
                    stackId="1"
                    stroke="none"
                    fill={PLAN_COLORS[plan]} />
                ))}
              </AreaChart>
            </ResponsiveContainer>
          </Paper>
        </div>
        <div className={this.props.classes.section}>
          <Typography
            variant="h5"
            className={this.props.classes.title}>
            Customer Satisfaction
          </Typography>
          <Paper className={this.props.classes.chart}>
            <ResponsiveContainer>
              <LineChart
                height={400}
                data={this.props.dashboardData.data.surveysAverage}
                margin={{
                  top: 10,
                  right: 20,
                  left: 0,
                  bottom: 30,
                }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="name"
                  interval={0}
                  tick={(<CustomizedAxisTick />)} />
                <YAxis />
                <Tooltip />
                {Object.keys(SOURCE_COLORS).map(source => (
                  <Line
                    key={source}
                    type="monotone"
                    dot={false}
                    dataKey={source}
                    stroke={SOURCE_COLORS[source]} />
                ))}
              </LineChart>
            </ResponsiveContainer>
          </Paper>
        </div>
        <div className={this.props.classes.section}>
          <Typography
            variant="h5"
            className={this.props.classes.title}>
            Usage
          </Typography>
          <Paper className={this.props.classes.chart}>
            <ResponsiveContainer>
              <LineChart
                height={400}
                data={this.props.dashboardData.data.planUsage}
                margin={{
                  top: 10,
                  right: 20,
                  left: 0,
                  bottom: 30,
                }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="name"
                  interval={0}
                  tick={(<CustomizedAxisTick />)} />
                <YAxis />
                <Tooltip />
                {Object.keys(USAGE_COLORS).map(plan => (
                  <Line
                    key={plan}
                    type="monotone"
                    dot={false}
                    dataKey={plan}
                    stroke={USAGE_COLORS[plan]} />
                ))}
              </LineChart>
            </ResponsiveContainer>
          </Paper>
        </div>
        <div className={this.props.classes.section}>
          <Typography
            variant="h5"
            className={this.props.classes.title}>
            Users
          </Typography>
          <Paper className={this.props.classes.chart}>
            <ResponsiveContainer>
              <BarChart
                height={400}
                data={this.props.dashboardData.data.users.points}
                margin={{
                  top: 10,
                  right: 20,
                  left: 0,
                  bottom: 30,
                }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="name"
                  interval={0}
                  tick={(<CustomizedAxisTick />)} />
                <YAxis />
                <Tooltip />
                <Bar dataKey="users" stackId="a" fill={blue[600]} />
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </div>
        <div className={this.props.classes.section}>
          <Typography
            variant="h5"
            className={this.props.classes.title}>
            Reservations
          </Typography>
          <Paper className={this.props.classes.chart}>
            <ResponsiveContainer>
              <BarChart
                height={400}
                data={this.props.dashboardData.data.reservations.points}
                margin={{
                  top: 10,
                  right: 20,
                  left: 0,
                  bottom: 30,
                }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="name"
                  interval={0}
                  tick={(<CustomizedAxisTick />)} />
                <YAxis />
                <Tooltip />
                <Bar dataKey="lunch" stackId="a" fill={red[600]} />
                <Bar dataKey="dinner" stackId="a" fill={orange[600]} />
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </div>
        <div className={this.props.classes.section}>
          <Typography
            variant="h5"
            className={this.props.classes.title}>
            Tickets
          </Typography>
          <Paper className={this.props.classes.chart}>
            <ResponsiveContainer>
              <BarChart
                height={400}
                data={this.props.dashboardData.data.tickets.points}
                margin={{
                  top: 10,
                  right: 20,
                  left: 0,
                  bottom: 30,
                }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="name"
                  interval={0}
                  tick={(<CustomizedAxisTick />)} />
                <YAxis />
                <Tooltip />
                <Bar dataKey="tickets" stackId="a" fill={green[600]} />
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  dashboardData: state.api.dashboardData.default,
  joinsAndChurns: state.api.joinsAndChurns[state.api.joinsAndChurns.lastRequestedKey],
})

const mapDispatchToProps = {
  getDashboardData,
  getJoinsAndChurns,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Dashboard))
