import React, { Component } from 'react'
import moment from 'moment-timezone'
import { withStyles } from '@material-ui/core/styles'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { debounce } from 'throttle-debounce'
import classNames from 'classnames'
import {
  Field,
  reduxForm,
  change,
  reset,
  formValueSelector,
} from 'redux-form'
import {
  withRouter,
} from 'react-router-dom'
import Script from 'react-load-script'
import Dropzone from 'react-dropzone'
import Geosuggest from 'react-geosuggest'
import Paper from '@material-ui/core/Paper'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import Chip from '@material-ui/core/Chip'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Checkbox from '@material-ui/core/Checkbox'
import AddIcon from '@material-ui/icons/Add'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'
import LaunchRoundedIcon from '@material-ui/icons/LaunchRounded'
import CloudUploadIcon from '@material-ui/icons/CloudUploadRounded'
import DeleteIcon from '@material-ui/icons/Delete'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import {
  TextField,
  Select,
  Switch,
} from 'redux-form-material-ui'
import {
  getProfile,
  createShop,
  getShop,
  getShops,
  editShop,
  searchShopUsers,
  searchCompanies,
  getNeighborhoods,
  getDeliveryZones,
  getShopUsersForShop,
  setShopUsersForShop,
  getMealsForShop,
  getSidesForShop,
  createShopLoginLink,
  uploadImage,
  getDeliveryZonesForShop,
  addShopToDeliveryZone,
  removeShopToDeliveryZone,
} from '../../redux/api/actions'
import {
  setFlag,
} from '../../redux/features/actions'
import getUrlForImage from '../../utils/getUrlForImage'
import validate, {
  EMAIL_REGEX,
  PHONE_REGEX,
} from '../../utils/validators/shopValidator'
import promiseMap from '../../utils/promiseMap'
import canUseDOM from '../../utils/canUseDOM'
import './Shop.css'

let ReactMapboxGl
let Layer
let Feature

let MapBoxMap

if (canUseDOM()) {
  const MapBox = require('react-mapbox-gl')

  ReactMapboxGl = MapBox.default
  Layer = MapBox.Layer
  Feature = MapBox.Feature

  MapBoxMap = ReactMapboxGl({
    accessToken: 'pk.eyJ1IjoicG90bHVja2hxIiwiYSI6ImNqeW03OHc3bjBnNTkzbHJvNHVtemhjNW4ifQ._9iIJ5jy_RzZTeks86lQBw',
    injectCSS: false,
  })
}

const GOOGLE_API_KEY = 'AIzaSyCe1Sgvl0fg7BMtiQ8vMAF7oxyDEClUUTU'

const PHOTO_CODE_LENGTH = 7

// https://stackoverflow.com/questions/1349404/generate-random-string-characters-in-javascript
const generatePhotoCode = () => {
  let text = ''
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  for (let i = 0; i < PHOTO_CODE_LENGTH; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length))
  }

  return text
}

const DEFAULT_MAP_CENTER = {
  lat: 35.656869,
  lng: 139.696711,
}

const FORM_NAME = 'shop'

const LUNCH_SHOP_OPEN = 11
const LUNCH_SHOP_CLOSE = 15

const DINNER_SHOP_OPEN = 18
const DINNER_SHOP_CLOSE = 24

const lunchOpen = moment.tz('Asia/Tokyo').startOf('day').set('h', LUNCH_SHOP_OPEN)
const lunchClose = moment.tz('Asia/Tokyo').startOf('day').set('h', LUNCH_SHOP_CLOSE)

const dinnerOpen = moment.tz('Asia/Tokyo').startOf('day').set('h', DINNER_SHOP_OPEN)
const dinnerClose = moment.tz('Asia/Tokyo').startOf('day').set('h', DINNER_SHOP_CLOSE)

const LUNCH_TIMES = []
const DINNER_TIMES = []

while (!lunchOpen.isAfter(lunchClose)) {
  LUNCH_TIMES.push(lunchOpen.clone())
  lunchOpen.add(15, 'm')
}

while (!dinnerOpen.isAfter(dinnerClose)) {
  DINNER_TIMES.push(dinnerOpen.clone())
  dinnerOpen.add(15, 'm')
}

const styles = theme => ({
  container: {
    margin: theme.spacing(3),
  },
  paper: {
    marginBottom: theme.spacing(3),
  },
  title: {
    flex: 1,
  },
  actions: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  ctaProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  content: {
    padding: theme.spacing(3),
  },
  formField: {
    marginBottom: theme.spacing.unit * 3,
  },
  formFieldMarginTop: {
    marginTop: theme.spacing.unit * 3,
  },
  formFieldSmall: {
    width: 110,
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  formFieldMedium: {
    width: 180,
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  formFieldLarge: {
    width: 300,
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  formFieldGrow: {
    flex: 1,
  },
  formFieldBlue: {
    '& input': {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
    },
  },
  formFieldsHorizontal: {
    display: 'flex',
    flexDirection: 'row',
    '& > :not(:first-child)': {
      marginLeft: theme.spacing(3),
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      '& > :not(:first-child)': {
        marginLeft: 0,
      },
    },
  },
  geosuggestContainer: {
    position: 'relative',
  },
  geosuggest: {
    zIndex: 10,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  geosuggestDelete: {
    position: 'absolute',
    top: 4,
    right: 4,
  },
  map: {
    position: 'relative',
    marginBottom: theme.spacing(3),
  },
  bannerImage: {
    position: 'relative',
    paddingBottom: '42.8%',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: 4,
    cursor: 'pointer',
    marginBottom: theme.spacing.unit * 3,
    '&:focus': {
      outline: 0,
    },
  },
  bannerImageDrag: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: '#E0E0E0',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  imageCentered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageLoading: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageEmpty: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  deleteImageCta: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  ownerImageContainer: {
    display: 'flex',
    marginBottom: theme.spacing(3),
  },
  ownerImage: {
    position: 'relative',
    height: 100,
    width: 100,
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: 50,
    cursor: 'pointer',
    marginBottom: theme.spacing.unit * 3,
    '&:focus': {
      outline: 0,
    },
    overflow: 'hidden',
  },
  ownerImageDrag: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  ownerDeleteImageCta: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
  ownerQuote: {
    flex: 1,
    marginLeft: theme.spacing(3),
  },
  notificationItems: {
    marginBottom: theme.spacing(1),
  },
  notificationItem: {
    margin: theme.spacing(1, 1, 1, 0),
  },
  deliveryZones: {
    marginBottom: theme.spacing(1),
  },
  deliveryZone: {
    margin: theme.spacing(1, 1, 1, 0),
  },
  holidays: {
    marginBottom: theme.spacing(3),
  },
  toggle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    marginLeft: theme.spacing(-1),
  },
  meals: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: theme.spacing(0, -1),
  },
  meal: {
    minWidth: 220,
    maxWidth: 220,
    margin: theme.spacing(0, 1, 1, 1),
  },
  sides: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: theme.spacing(0, -1),
  },
  side: {
    minWidth: 220,
    maxWidth: 220,
    margin: theme.spacing(0, 1, 1, 1),
  },
})

class Shop extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      shopUsers: [],
      shopUserResults: [],
      shopUserResultsLoading: false,
      company: null,
      companyResults: [],
      companyResultsLoading: false,
      media: {},
      mediaLoading: {},
      emails: [],
      phones: [],
      newPhone: null,
      text: '',
      textCompany: '',
      page: 0,
    }
  }

  searchShopUsers = debounce(750, async text => {
    let results

    try {
      results = await this.props.searchShopUsers({
        text,
        page: 0,
      })
    } catch (exception) {
      // swallow
    }

    this.setState({
      shopUserResults: results.shopUsers,
      shopUserResultsLoading: false,
    })
  })

  hasLoaded = () => {
    if (this.props.isNew) {
      return this.props.profile.loaded
        && this.props.neighborhoods.loaded
    }

    return this.props.profile.loaded
      && this.props.shop && this.props.shop.loaded
      && this.props.shopUsersForShop && this.props.shopUsersForShop.loaded
      && this.props.mealsForShop && this.props.mealsForShop.loaded
      && this.props.sidesForShop && this.props.sidesForShop.loaded
      && this.props.neighborhoods.loaded
  }

  isShopUsersDirty = () => {
    return this.state.shopUsers
      && this.props.shopUsersForShop
      && this.props.shopUsersForShop.data
      && (this.state.shopUsers.some(shopUser => !this.props.shopUsersForShop.data.some(shopForShopUser => shopForShopUser.id === shopUser.id))
      || this.props.shopUsersForShop.data.some(shopForShopUser => !this.state.shopUsers.some(shopUser => shopUser.id === shopForShopUser.id)))
  }

  isCompanyDirty = () => {
    return this.state.company && this.state.company.id !== this.props.shop.data.company.id
  }

  isMediaDirty = () => {
    return this.props.shop && this.props.shop.data && this.props.shop.data.media && ['bannerImage', 'ownerImage', 'ownerQuote'].reduce((memo, key) => memo || this.props.shop.data.media[key] !== this.state.media[key], false)
  }

  isEmailsDirty = () => {
    return (this.props.shop && this.props.shop.data.emails.find(email => !this.state.emails.includes(email)))
      || this.state.emails.find(email => !this.props.shop.data.emails.includes(email))
  }

  isPhonesDirty = () => {
    return (this.props.shop && this.props.shop.data.phones.find(phone => !this.state.phones.includes(phone)))
      || this.state.phones.find(phone => !this.props.shop.data.phones.includes(phone))
  }

  isDeliveryZonesDirty = () => {
    return this.state.deliveryZonesForShop
      && this.props.deliveryZonesForShop
      && this.props.deliveryZonesForShop.data
      && (this.state.deliveryZonesForShop.some(deliveryZoneForShop => !this.props.deliveryZonesForShop.data.some(deliveryZone => deliveryZone.id === deliveryZoneForShop.id))
      || this.props.deliveryZonesForShop.data.some(deliveryZoneForShop => !this.state.deliveryZonesForShop.some(deliveryZone => deliveryZone.id === deliveryZoneForShop.id)))
  }

  onClickLoginLink = async () => {
    if (!this.props.createShopLoginLinkState.loading) {
      const data = await this.props.createShopLoginLink(this.props.shop.data.id)
      window.open(data.loginLink)
    }
  }

  onLoadGooglePlaces = () => {
    this.props.setFlag('googlePlacesLoaded', true)
  }

  onClickMap = (event, data) => {
    if (!this.props.lat && !this.props.lon) {
      this.props.change('googlePlaceId', null)
      this.props.change('lat', data.lngLat.lat)
      this.props.change('lon', data.lngLat.lng)
    }
  }

  onMarkerDragEnd = data => {
    this.props.change('googlePlaceId', null)
    this.props.change('lat', data.lngLat.lat)
    this.props.change('lon', data.lngLat.lng)
  }

  onClickSubmit = async values => {
    this.setState({ loading: true })

    if (this.props.isNew) {
      const newShop = await this.props.createShop({
        ...values,
        companyId: this.state.companyId,
      })
      await this.props.getShops()
      window.location = `/shops/${newShop.id}`
    } else {
      const promises = [this.props.editShop(this.props.match.params.shopId, {
        ...values,
        companyId: this.state.company.id,
        media: this.state.media,
        emails: this.state.emails,
        phones: this.state.phones,
        deliveryZones: this.state.deliveryZonesForShop.map(({ id }) => id),
      })]

      if (this.isShopUsersDirty()) {
        promises.push(this.props.setShopUsersForShop({
          shopId: this.props.match.params.shopId,
          shopUserIds: this.state.shopUsers.map(shopUser => shopUser.id),
        }))
      }

      await Promise.all(promises)

      const {
        shop,
        deliveryZonesForShop,
      } = await promiseMap({
        shop: this.props.getShop(this.props.match.params.shopId),
        shopUsersForShop: this.props.getShopUsersForShop(this.props.match.params.shopId),
        deliveryZonesForShop: this.props.getDeliveryZonesForShop(this.props.match.params.shopId),
      })

      this.setState({
        emails: shop.emails,
        phones: shop.phones,
        media: shop.media,
        deliveryZonesForShop,
      })

      this.props.dispatch(reset('shop'))
      this.setState({ loading: false })
    }
  }

  onReady = (props, map) => {
    this.setState({
      map,
    })
  }

  onClickGooglePlace = googlePlace => {
    if (googlePlace) {
      this.props.dispatch(change('shop', 'googlePlaceId', googlePlace.placeId))
      this.props.dispatch(change('shop', 'lat', parseFloat(googlePlace.location.lat)))
      this.props.dispatch(change('shop', 'lon', parseFloat(googlePlace.location.lng)))
    }
  }

  onClickRemoveGooglePlace = () => {
    this.props.dispatch(change('shop', 'googlePlaceId', null))
  }

  onEditMedia = (event, name, value) => {
    event.stopPropagation()

    this.setState({
      media: {
        ...this.state.media,
        [name]: value,
      },
    })
  }

  onDropImage = async (files, name) => {
    this.setState({
      mediaLoading: {
        ...this.state.mediaLoading,
        [name]: true,
      },
    })

    const file = files[0]

    const code = generatePhotoCode()
    const filename = `/potluck-webapp/shop-photos/${code}-${file.name}`

    const upload = await this.props.uploadImage(file, filename)

    this.setState({
      media: {
        ...this.state.media,
        [name]: upload[0].uuid,
      },
      mediaLoading: {
        ...this.state.mediaLoading,
        [name]: false,
      },
    })
  }

  onChangeOwnerQuote = event => {
    this.setState({
      media: {
        ...this.state.media,
        ownerQuote: event.target.value,
      },
    })
  }

  onChangeShopUsers = (event, shopUsers) => {
    this.setState({
      shopUsers,
    })
  }

  onChangeShopUserSearch = event => {
    this.setState({ shopUserResultsLoading: true })
    this.searchShopUsers(event.target.value)
  }

  onChangeCompany = (event, company) => {
    this.setState({
      company,
    })
  }

  onChangeCompanySearch = async value => {
    this.setState({ companyResultsLoading: true })

    const results = await this.props.searchCompanies(value)

    this.setState({
      companyResultsLoading: false,
      companyResults: results.companies,
    })
  }

  onChangeDeliveryZones = (event, deliveryZonesForShop) => {
    this.setState({
      deliveryZonesForShop,
    })
  }

  onKeyPressNewEmail = event => {
    if (event.key === 'Enter') {
      const email = event.target.value

      if (EMAIL_REGEX.test(email) && !this.state.emails.includes(email)) {
        this.setState({
          emails: [...this.state.emails, email],
        })

        event.target.value = ''
      }
    }
  }

  onClickRemoveEmail = email => {
    this.setState({
      emails: this.state.emails.filter(oldEmail => oldEmail !== email)
    })
  }

  onKeyPressNewPhone = event => {
    if (event.key === 'Enter') {
      event.preventDefault()

      const phone = event.target.value

      if (PHONE_REGEX.test(phone) && !this.state.phones.includes(phone)) {
        this.setState({
          phones: [...this.state.phones, phone],
        })

        event.target.value = ''
      }
    }
  }

  onClickRemovePhone = phone => {
    this.setState({
      phones: this.state.phones.filter(oldPhone => oldPhone !== phone)
    })
  }

  onClickAddMeal = () => {
    this.props.history.push(`/shops/${this.props.match.params.shopId}/meals/new`)
  }

  onClickAddSide = () => {
    this.props.history.push(`/shops/${this.props.match.params.shopId}/sides/new`)
  }

  onClickMeal = meal => {
    this.props.history.push(`/meals/${meal.id}`)
  }

  onClickSide = side => {
    this.props.history.push(`/sides/${side.id}`)
  }

  async componentWillMount() {
    const {
      deliveryZones,
    } = await promiseMap({
      profile: this.props.getProfile(),
      neighborhoods: this.props.getNeighborhoods(),
      deliveryZones: this.props.getDeliveryZones(),
    })

    if (this.props.isNew) {
      const company = {
        id: 'create-new',
        name: 'Create new',
      }

      this.setState({
        company,
        companyResults: [company],
        shopUsersForShop: [],
        deliveryZones,
        deliveryZonesForShop: [],
      })
    } else {
      this.props.getShopUsersForShop(this.props.match.params.shopId)
      this.props.getMealsForShop(this.props.match.params.shopId)
      this.props.getSidesForShop(this.props.match.params.shopId)

      const {
        shop,
        shopUsersForShop,
        deliveryZonesForShop,
      } = await promiseMap({
        shop: this.props.getShop(this.props.match.params.shopId),
        shopUsersForShop: this.props.getShopUsersForShop(this.props.match.params.shopId),
        deliveryZonesForShop: this.props.getDeliveryZonesForShop(this.props.match.params.shopId),
      })

      this.setState({
        media: shop.media || {},
        mediaLoading: {},
        emails: shop.emails,
        phones: shop.phones,
        company: shop.company,
        companyResults: [shop.company],
        shopUsers: shopUsersForShop,
        shopUsersForShop,
        deliveryZones,
        deliveryZonesForShop,
      })
    }
  }

  render() {
    if (!this.hasLoaded()) {
      return false
    }

    console.log(this.state.companyResults)

    const {
      handleSubmit,
      pristine,
      invalid,
    } = this.props

    let mapLocation

    if (this.props.lat && this.props.lon) {
      mapLocation = [this.props.lon, this.props.lat]
    }

    return (
      <div className={this.props.classes.container}>
        <Paper className={this.props.classes.paper}>
          {!this.props.googlePlacesLoaded && (
            <Script
              url={`https://maps.googleapis.com/maps/api/js?v=weekly&key=${GOOGLE_API_KEY}&libraries=places,drawing`}
              onLoad={this.onLoadGooglePlaces} />
          )}
          <form>
            <Toolbar>
              <Typography
                variant="h4"
                className={this.props.classes.title}>
                  {this.props.name}
                {!this.props.isNew && (
                  <IconButton
                    color="primary"
                    aria-label="Login"
                    className={this.props.classes.loginButton}
                    onClick={this.onClickLoginLink}>
                    <LaunchRoundedIcon />
                  </IconButton>
                )}
              </Typography>
              <Button
                color="primary"
                variant="contained"
                disabled={this.state.loading || invalid || (pristine && !this.isShopUsersDirty() && !this.isMediaDirty() && !this.isEmailsDirty() && !this.isPhonesDirty() && !this.isDeliveryZonesDirty() && !this.isCompanyDirty())}
                onClick={handleSubmit(this.onClickSubmit)}>
                {this.state.loading && (
                  <CircularProgress
                    size={24}
                    color="primary"
                    className={this.props.classes.ctaProgress} />
                )}
                Save
              </Button>
            </Toolbar>
            <div className={this.props.classes.content}>
              <div className={this.props.classes.formFieldsHorizontal}>
                <Field
                  name="name"
                  label="Name"
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                  component={TextField}
                  className={this.props.classes.formField} />
                <Autocomplete
                  name="company"
                  label="Company"
                  fullWidth
                  isOptionEqualToValue={(option, value) => option.name === value.name}
                  getOptionLabel={(option) => option.name}
                  value={this.state.company}
                  options={this.state.companyResults}
                  loading={this.state.companyResultsLoading}
                  className={this.props.classes.formField}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Company"
                      InputProps={{
                        ...params.InputProps,
                        autoComplete: 'off',
                        endAdornment: (
                          <React.Fragment>
                            {this.state.companyResultsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                  onInputChange={(event, value) => this.onChangeCompanySearch(value)}
                  onChange={this.onChangeCompany}
                />
                <Field
                  name="descriptor"
                  label="Descriptor"
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                  component={TextField}
                  className={this.props.classes.formField} />
                <Field
                  disabled
                  name="referralCode"
                  label="Referral code"
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                  component={TextField}
                  className={this.props.classes.formField} />
              </div>
              <div className={this.props.classes.formFieldsHorizontal}>
                <FormControl variant="outlined">
                  <InputLabel htmlFor="city">
                    City
                  </InputLabel>
                  <Field
                    name="neighborhoodId"
                    label="City"
                    variant="outlined"
                    fullWidth
                    component={Select}
                    className={classNames(this.props.classes.formField, this.props.classes.formFieldSmall)}>
                    {this.props.neighborhoods.data.map(neighborhood => (
                      <MenuItem
                        key={neighborhood.id}
                        value={neighborhood.id}>
                        {neighborhood.name}
                      </MenuItem>
                    ))}
                  </Field>
                </FormControl>
                <Field
                  name="zip"
                  label="Zip"
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                  component={TextField}
                  className={classNames(this.props.classes.formField, this.props.classes.formFieldSmall)} />
                <Field
                  name="address"
                  label="Address"
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                  component={TextField}
                  className={classNames(this.props.classes.formField, this.props.classes.formFieldGrow)} />
              </div>
              <div className={this.props.classes.geosuggestContainer}>
                <Field
                  disabled
                  name="googlePlaceId"
                  label="Google place ID"
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                  component={TextField}
                  className={classNames(this.props.classes.formField, this.props.classes.formFieldBlue)} />
                {this.props.googlePlaceId && (
                  <IconButton
                    aria-label="Delete"
                    onClick={this.onClickRemoveGooglePlace}
                    className={this.props.classes.geosuggestDelete}>
                    <CloseRoundedIcon />
                  </IconButton>
                )}
                {this.props.googlePlacesLoaded && !this.props.googlePlaceId && (
                  <div className={this.props.classes.geosuggest}>
                    <Geosuggest
                      country="jp"
                      className="shop__geo-suggest"
                      onFocus={() => {}}
                      onBlur={() => {}}
                      onSuggestSelect={this.onClickGooglePlace} />
                  </div>
                )}
              </div>
              <div className={this.props.classes.formFieldsHorizontal}>
                <Field
                  disabled={this.props.googlePlaceId}
                  name="lat"
                  label="Latitude"
                  variant="outlined"
                  fullWidth
                  type="number"
                  component={TextField}
                  className={this.props.classes.formField} />
                <Field
                  disabled={this.props.googlePlaceId}
                  name="lon"
                  label="Longitude"
                  variant="outlined"
                  fullWidth
                  type="number"
                  component={TextField}
                  className={this.props.classes.formField} />
              </div>
              <div className={this.props.classes.map}>
                {canUseDOM() && (
                  <MapBoxMap
                    ref={ref => this.mapRef = ref}
                    // eslint-disable-next-line
                    style="mapbox://styles/potluckhq/cjym7i4e00vom1clbjz7at3an"
                    center={mapLocation || DEFAULT_MAP_CENTER}
                    zoom={[14]}
                    containerStyle={{
                      height: 400,
                      width: '100%',
                    }}
                    onClick={this.onClickMap}>
                    {mapLocation && (
                      <Layer
                        id="shops"
                        type="symbol"
                        layout={{
                          'icon-image': 'shop-marker-red',
                          'icon-anchor': 'bottom',
                        }}>
                        <Feature
                          id="shop-location"
                          draggable
                          coordinates={mapLocation}
                          onDragEnd={this.onMarkerDragEnd} />
                      </Layer>
                    )}
                  </MapBoxMap>
                )}
              </div>
              <Field
                name="details"
                label="Details"
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                className={this.props.classes.formField}
                component={TextField} />
              <Field
                name="contactName"
                label="Contact Name"
                variant="outlined"
                fullWidth
                autoComplete="off"
                component={TextField}
                className={this.props.classes.formField} />
              <Field
                name="contactPhone"
                label="Contact Phone"
                variant="outlined"
                fullWidth
                autoComplete="off"
                component={TextField}
                className={this.props.classes.formField} />
              <Field
                name="contactEmail"
                label="Contact Email"
                variant="outlined"
                fullWidth
                autoComplete="off"
                component={TextField}
                className={this.props.classes.formField} />
              {!this.props.isNew && this.props.shopUsersForShop && this.props.shopUsersForShop.data && this.state.shopUsersForShop && (
                <Autocomplete
                  multiple
                  ChipProps={{
                    color: 'primary',
                  }}
                  disableCloseOnSelect
                  options={this.state.shopUserResults}
                  defaultValue={this.props.shopUsersForShop.data}
                  className={this.props.classes.formField}
                  getOptionLabel={option => option.email}
                  getOptionSelected={(option, value) => option.id === value.id}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        color="primary"
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.email}
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Add shop user"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {this.state.shopResultsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                        onChange: this.onChangeShopUserSearch,
                      }}
                    />
                  )}
                  onChange={this.onChangeShopUsers} />
              )}
              {!this.props.isNew && (
                <div>
                  <Typography variant="body1">
                    Media
                  </Typography>
                  <Dropzone onDrop={files => this.onDropImage(files, 'bannerImage')}>
                    {({ getRootProps, getInputProps, isDragActive }) => (
                      <div
                        {...getRootProps()}
                        className={classNames(this.props.classes.bannerImage, {
                          [this.props.classes.bannerImageDrag]: isDragActive,
                        })}>
                        <input {...getInputProps()} />
                        <div className={this.props.classes.imageEmpty}>
                          <CloudUploadIcon color={isDragActive ? 'primary' : 'disabled'} />
                          <Typography
                            variant="caption"
                            color={isDragActive ? 'primary' : 'disabled'}>
                            21:9
                          </Typography>
                        </div>
                        {this.state.media.bannerImage && (
                          <div
                            className={this.props.classes.image}
                            style={{
                              backgroundImage: `url(${getUrlForImage(this.state.media.bannerImage)})`,
                            }}>
                            <IconButton
                              size="small"
                              className={this.props.classes.deleteImageCta}
                              onClick={event => this.onEditMedia(event, 'bannerImage', null)}>
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        )}
                        {this.state.mediaLoading['bannerImage'] && (
                          <div className={this.props.classes.imageLoading}>
                            <CircularProgress />
                          </div>
                        )}
                      </div>
                    )}
                  </Dropzone>
                  <div className={this.props.classes.ownerImageContainer}>
                    <Dropzone onDrop={files => this.onDropImage(files, 'ownerImage')}>
                      {({ getRootProps, getInputProps, isDragActive }) => (
                        <div
                          {...getRootProps()}
                          className={classNames(this.props.classes.ownerImage, {
                            [this.props.classes.ownerImageDrag]: isDragActive,
                          })}>
                          <input {...getInputProps()} />
                          <div className={this.props.classes.imageEmpty}>
                            <CloudUploadIcon color={isDragActive ? 'primary' : 'disabled'} />
                            <Typography
                              variant="caption"
                              color={isDragActive ? 'primary' : 'disabled'}>
                              1:1
                            </Typography>
                          </div>
                          {this.state.media.ownerImage && (
                            <div
                              className={classNames([this.props.classes.image, this.props.classes.imageCentered])}
                              style={{
                                backgroundImage: `url(${getUrlForImage(this.state.media.ownerImage)})`,
                              }}>
                              <IconButton
                                size="small"
                                className={this.props.classes.ownerDeleteImageCta}
                                onClick={event => this.onEditMedia(event, 'ownerImage', null)}>
                                <DeleteIcon />
                              </IconButton>
                            </div>
                          )}
                          {this.state.mediaLoading['ownerImage'] && (
                            <div className={this.props.classes.imageLoading}>
                              <CircularProgress />
                            </div>
                          )}
                        </div>
                      )}
                    </Dropzone>
                    <FormControl
                      variant="outlined"
                      className={this.props.classes.ownerQuote}>
                      <TextField
                        name="ownerQuote"
                        label="Owner quote"
                        variant="outlined"
                        value={this.state.media.ownerQuote}
                        onChange={this.onChangeOwnerQuote}
                        multiline
                        rows={4} />
                    </FormControl>
                  </div>
                  <Typography variant="body1">
                    Notification emails
                  </Typography>
                  <div className={this.props.classes.notificationItems}>
                    {this.state.emails.map(email => (
                      <Chip
                        key={email}
                        label={email}
                        color="primary"
                        className={this.props.classes.notificationItem}
                        onDelete={() => this.onClickRemoveEmail(email)} />
                    ))}
                    {this.state.emails.length === 0 && (
                      <Typography variant="caption">
                        None
                      </Typography>
                    )}
                  </div>
                  <Typography variant="body1">
                    Notification phones
                  </Typography>
                  <div className={this.props.classes.notificationItems}>
                    {this.state.phones.map(phone => (
                      <Chip
                        key={phone}
                        label={phone}
                        color="primary"
                        className={this.props.classes.notificationItem}
                        onDelete={() => this.onClickRemovePhone(phone)} />
                    ))}
                    {this.state.phones.length === 0 && (
                      <Typography variant="caption">
                        None
                      </Typography>
                    )}
                  </div>
                  <TextField
                    fullWidth
                    type="phone"
                    label="Add phone"
                    value={this.state.newPhone}
                    className={this.props.classes.newPhone}
                    onKeyPress={this.onKeyPressNewPhone} />
                  {this.props.deliveryZones.data && this.state.deliveryZonesForShop && (
                    <Autocomplete
                      multiple
                      id="checkboxes-tags-demo"
                      ChipProps={{
                        color: 'primary',
                      }}
                      disableCloseOnSelect
                      options={this.state.deliveryZones}
                      defaultValue={this.state.deliveryZonesForShop}
                      className={classNames([this.props.classes.formField, this.props.classes.formFieldMarginTop])}
                      getOptionLabel={option => option.name}
                      getOptionSelected={(option, value) => option.id === value.id}
                      renderOption={(option, { selected }) => (
                        <React.Fragment>
                          <Checkbox
                            color="primary"
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.name}
                        </React.Fragment>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Delivery zones"
                          placeholder="Add a zone" />
                      )}
                      onChange={this.onChangeDeliveryZones} />
                  )}
                  <Typography variant="body1">
                    Holidays
                  </Typography>
                  <div className={this.props.classes.holidays}>
                    {!this.props.isNew && this.props.shop.data.holidays.map(holiday => (
                      <div key={`${holiday.code}-${holiday.id}`}>
                        <Typography variant="caption">
                          {holiday.name} ({holiday.startDate === holiday.endDate ? moment.tz(holiday.startDate, 'Asia/Tokyo').format('LL') : `${moment.tz(holiday.startDate, 'Asia/Tokyo').format('LL')} - ${moment.tz(holiday.endDate, 'Asia/Tokyo').format('LL')}`})
                        </Typography>
                      </div>
                    ))}
                    {!this.props.isNew && this.props.shop.data.holidays.length === 0 && (
                      <Typography variant="caption">
                        None
                      </Typography>
                    )}
                  </div>
                  <Typography variant="body1">
                    Settings
                  </Typography>
                  <FormControl className={this.props.classes.toggle}>
                    <Field
                      name="canControlPrice"
                      color="primary"
                      component={Switch} />
                    <Typography variant="caption">
                      Allow shop to set their own prices
                    </Typography>
                  </FormControl>
                  <FormControl className={this.props.classes.toggle}>
                    <Field
                      name="shopsAppEnabled"
                      color="primary"
                      component={Switch} />
                    <Typography variant="caption">
                      Enable shops app (/s/{this.props.descriptor})
                    </Typography>
                  </FormControl>
                  <div>
                    <FormControl className={this.props.classes.toggle}>
                      <Field
                        name="freeDrinkCampaign"
                        color="primary"
                        component={Switch} />
                      <Typography variant="caption">
                        Free drink campaign
                        <span
                          role="img"
                          aria-label="beer">
                          🍺
                        </span>
                        <span
                          role="img"
                          aria-label="cocktail">
                          🍸
                        </span>
                        <span
                          role="img"
                          aria-label="whiskey">
                          🥃
                        </span>
                      </Typography>
                    </FormControl>
                  </div>
                  <div>
                    <FormControl className={this.props.classes.toggle}>
                      <Field
                        name="comingSoon"
                        color="primary"
                        component={Switch} />
                      <Typography variant="caption">
                        Coming soon
                      </Typography>
                    </FormControl>
                  </div>
                  <div>
                    <FormControl className={this.props.classes.toggle}>
                      <Field
                        name="deleted"
                        color="primary"
                        component={Switch} />
                      <Typography variant="caption">
                        Deleted
                      </Typography>
                    </FormControl>
                  </div>
                </div>
              )}
            </div>
          </form>
        </Paper>
        {!this.props.isNew && (
          <Paper className={this.props.classes.paper}>
            <Toolbar>
              <Typography
                variant="h4"
                className={this.props.classes.title}>
                Meals
              </Typography>
              <div className={this.props.classes.actions}>
                <Tooltip title="Add">
                  <IconButton
                    aria-label="Add"
                    color="primary"
                    onClick={this.onClickAddMeal}>
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </Toolbar>
            <div className={this.props.classes.content}>
              <div className={this.props.classes.meals}>
                {this.props.mealsForShop.data.map(meal => (
                  <Card
                    key={meal.id}
                    className={this.props.classes.meal}>
                    <CardActionArea onClick={() => this.onClickMeal(meal)}>
                      <CardMedia
                        component="img"
                        alt="Meal image"
                        height="140"
                        image={meal.images && getUrlForImage(meal.images[0])} />
                      <CardContent>
                        <Typography
                          noWrap
                          variant="body1"
                          className={this.props.classes.mealName}>
                          {meal.name}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                ))}
              </div>
            </div>
          </Paper>
        )}
        {!this.props.isNew && (
          <Paper className={this.props.classes.paper}>
            <Toolbar>
              <Typography
                variant="h4"
                className={this.props.classes.title}>
                Sides
              </Typography>
              <div className={this.props.classes.actions}>
                <Tooltip title="Add">
                  <IconButton
                    aria-label="Add"
                    color="primary"
                    onClick={this.onClickAddSide}>
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </Toolbar>
            <div className={this.props.classes.content}>
              <div className={this.props.classes.sides}>
                {this.props.sidesForShop.data.map(side => (
                  <Card
                    key={side.id}
                    className={this.props.classes.side}>
                    <CardActionArea onClick={() => this.onClickSide(side)}>
                      <CardMedia
                        component="img"
                        alt="Side image"
                        height="140"
                        image={side.images && getUrlForImage(side.images[0])} />
                      <CardContent>
                        <Typography
                          noWrap
                          variant="body1"
                          className={this.props.classes.sideName}>
                          {side.name}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                ))}
              </div>
            </div>
          </Paper>
        )}
      </div>
    )
  }
}

const selector = formValueSelector(FORM_NAME)

const mapStateToProps = (state, ownProps) => {
  let shop = state.api.shop[ownProps.match.params.shopId]
  let initialValues = {}

  if (shop && shop.data) {
    initialValues = {
      name: shop.data.name,
      descriptor: shop.data.descriptor,
      neighborhoodId: shop.data.neighborhood.id,
      details: shop.data.details,
      zip: shop.data.zip,
      address: shop.data.address,
      referralCode: shop.data.referralCode,
      googlePlaceId: shop.data.googlePlaceId,
      lat: shop.data.location.lat,
      lon: shop.data.location.lon,
      contactName: shop.data.contact.name,
      contactPhone: shop.data.contact.phone,
      contactEmail: shop.data.contact.email,
      canControlPrice: shop.data.canControlPrice,
      shopsAppEnabled: shop.data.shopsAppEnabled,
      freeDrinkCampaign: shop.data.freeDrinkCampaign,
      comingSoon: shop.data.comingSoon,
      deleted: shop.data.deleted,
    }
  }

  return {
    initialValues,
    isNew: ownProps.match.params.shopId === 'new',
    profile: state.api.profile.default,
    shop: state.api.shop[ownProps.match.params.shopId],
    shopUsersForShop: state.api.shopUsersForShop[ownProps.match.params.shopId],
    mealsForShop: state.api.mealsForShop[ownProps.match.params.shopId],
    sidesForShop: state.api.sidesForShop[ownProps.match.params.shopId],
    editShopState: state.api.editShop[ownProps.match.params.shopId],
    deliveryZonesForShop: state.api.deliveryZonesForShop[ownProps.match.params.shopId],
    deliveryZones: state.api.deliveryZones.default,
    neighborhoods: state.api.neighborhoods.default,
    createShopLoginLinkState: state.api.createShopLoginLink.default,
    name: selector(state, 'name'),
    googlePlaceId: selector(state, 'googlePlaceId'),
    lat: selector(state, 'lat'),
    lon: selector(state, 'lon'),
    descriptor: selector(state, 'descriptor'),
    googlePlacesLoaded: state.features.flags.googlePlacesLoaded,
  }
}

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    getProfile,
    getShop,
    getShops,
    createShop,
    editShop,
    searchShopUsers,
    searchCompanies,
    getNeighborhoods,
    getDeliveryZones,
    getShopUsersForShop,
    setShopUsersForShop,
    getMealsForShop,
    getSidesForShop,
    createShopLoginLink,
    getDeliveryZonesForShop,
    addShopToDeliveryZone,
    removeShopToDeliveryZone,
    uploadImage,
    setFlag,
  }, dispatch),
  dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: FORM_NAME,
  validate,
  enableReinitialize: true,
})(withRouter(withStyles(styles)(Shop))))
