import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Redirect,
  withRouter,
} from 'react-router-dom'
import moment from 'moment-timezone'
import { CSVLink } from 'react-csv'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import CircularProgress from '@material-ui/core/CircularProgress'
import Tooltip from '@material-ui/core/Tooltip'
import CloudDownloadRoundedIcon from '@material-ui/icons/CloudDownloadRounded'
import {
  getShopPayments,
} from '../redux/api/actions'
import formatPrice from '../utils/formatPrice'
import formatNumberWithCommas from '../utils/formatNumberWithCommas'
import NewPaginationBar from '../components/NewPaginationBar/NewPaginationBar'

const styles = theme => ({
  root: {
    flex: '1 0 auto',
  },
  pages: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  content: {
    margin: theme.spacing.unit * 3,
    position: 'relative',
  },
  toolbar: {
    display: 'flex',
  },
  actions: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  title: {
    flex: '1',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  progressRow: {
    height: 140,
  },
  progress: {
    position: 'absolute',
    left: 'calc(50% - 20px)',
    top: 'calc(50% + 40px)',
  },
  shopRow: {
    backgroundColor: theme.palette.background.default,
  },
  shopCell: {
    fontWeight: 'bold',
  },
})

const START_DATE = '20180801'
const URL_FORMAT = 'YYYYMM'

class Payments extends Component {
  constructor(props) {
    super(props)

    const now = moment.tz('Asia/Tokyo')

    const pages = []
    let page
    const startDate = moment.tz(START_DATE, 'YYYYMMDD', 'Asia/Tokyo').startOf('month')

    while (startDate.isSameOrBefore(now)) {
      pages.push({
        label: startDate.format('M/YY'),
        value: startDate.format(), // TODO: remove custom pagination bar and remove this property
        startsAt: startDate.clone(),
        endsAt: startDate.clone().add(1, 'month'),
      })

      startDate.add(1, 'month')
    }

    if (!props.match.params.date) {
      page = pages[pages.length - 1]
    } else {
      const initialDate = moment.tz(props.match.params.date, URL_FORMAT, 'Asia/Tokyo').startOf('month')
      page = pages.find(page => page.startsAt.isSame(initialDate)) || pages[pages.length - 1]
    }

    this.state = {
      pages,
      page,
    }
  }

  hasLoaded = () => {
    return this.props.paymentInfoForMonth && this.props.paymentInfoForMonth.loaded
  }

  isLoading = () => {
    return this.props.paymentInfoForMonth && this.props.paymentInfoForMonth.loading
  }

  onChangePage = page => {
    this.setState({
      page,
    })

    this.props.history.push(`/payments-new/${page.startsAt.format(URL_FORMAT)}`)
    this.props.getShopPayments({
      startsAt: page.startsAt,
      endsAt: page.endsAt,
    })
  }

  getCsvData = () => {
    const data = []
    let filename

    if (this.props.locale === 'ja') {
      filename = `売上-${this.state.page.startsAt.format('LL')}-${this.state.page.endsAt.format('LL')}.csv`
      data.push(['店舗名', 'メニュー名', 'ユーザー', '注文日', 'サイドメニュー/オプション', '総注文数', '売上'])
    } else {
      filename = `sales-${this.state.page.startsAt.format('LL')}-${this.state.page.endsAt.format('LL')}.csv`
      data.push(['Shop', 'Item', 'User', 'Pickup', 'Reservations', 'Revenue'])
    }

    if (this.props.shopPayments && this.props.shopPayments.data && this.props.shopPayments.data.items) {
      for (let shop of this.props.shopPayments.data.items) {
        data.push([shop.name, '', '', '', shop.count, shop.revenue])

        if (shop.items) {
          for (let meal of shop.items) {
            data.push(['', meal.name, '', '', meal.count, meal.revenue])

            if (meal.items) {
              for (let item of meal.items) {
                data.push(['', '', item.user, moment(item.date).format('M/D kk:mm'), '', item.revenue])
              }
            }
          }
        }
      }

      data.push(['', '', '', '', this.props.shopPayments.data.totalOrders, formatNumberWithCommas(this.props.shopPayments.data.totalRevenue)])
    }

    return {
      data,
      filename,
    }
  }

  componentDidMount() {
    this.props.getShopPayments({
      startsAt: this.state.page.startsAt,
      endsAt: this.state.page.endsAt,
    })

    if (!this.props.match.params.date) {
      this.props.history.push(`/payments-new/${this.state.page.startsAt.format(URL_FORMAT)}`)
    }
  }

  render() {
    if (!this.props.profile.data) {
      return (
        <Redirect to="/login" />
      )
    }

    const csvData = this.getCsvData()

    return (
      <div className={this.props.classes.root}>
        <div className={this.props.classes.pages}>
          <NewPaginationBar
            pages={this.state.pages}
            initialPage={this.state.page}
            onChangePage={this.onChangePage} />
        </div>
        <Paper className={this.props.classes.content}>
          <Toolbar className={this.props.classes.toolbar}>
            <div className={this.props.classes.title}>
              <Typography variant="h6">
                Payments (new)
              </Typography>
            </div>
            <div className={this.props.classes.actions}>
              <CSVLink {...csvData}>
                <Tooltip title="Download CSV">
                  <IconButton
                    aria-label="Download CSV"
                    color="primary">
                    <CloudDownloadRoundedIcon />
                  </IconButton>
                </Tooltip>
              </CSVLink>
            </div>
          </Toolbar>
          <div className={this.props.classes.tableWrapper}>
            <div className="payments__list">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Shop</TableCell>
                    <TableCell>Meal</TableCell>
                    <TableCell align="right">Count</TableCell>
                    <TableCell align="right">Total Price</TableCell>
                  </TableRow>
                </TableHead>
                {!(this.props.shopPayments && this.props.shopPayments.data) && (
                  <TableBody>
                    <TableRow
                      colSpan={4}
                      className={this.props.classes.progressRow}>
                      <TableCell>
                        <CircularProgress className={this.props.classes.progress} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
                {(this.props.shopPayments && this.props.shopPayments.data) && (
                  <TableBody>
                    {this.props.shopPayments.data.items.map(shop => {
                      let items = [(
                        <TableRow
                          key={`${this.props.match.params.date}-${shop.id}-header`}
                          className={this.props.classes.shopRow}>
                          <TableCell
                            colSpan={2}
                            className={this.props.classes.shopCell}>
                            {shop.name}
                          </TableCell>
                          <TableCell
                            align="right"
                            className={this.props.classes.shopCell}>
                            {shop.count}
                          </TableCell>
                          <TableCell
                            align="right"
                            className={this.props.classes.shopCell}>
                            {formatPrice(shop.revenue)} 円
                          </TableCell>
                        </TableRow>
                      )]

                      items = items.concat(shop.items.map(meal => (
                        <TableRow key={`${this.props.match.params.date}-${shop.id}-${meal.id}`}>
                          <TableCell />
                          <TableCell>{meal.name}</TableCell>
                          <TableCell align="right">{meal.count}</TableCell>
                          <TableCell align="right">{formatPrice(meal.revenue)} 円</TableCell>
                        </TableRow>
                      )))

                      return items
                    })}
                    <TableRow
                      key={`${this.props.match.params.date}-grand-total`}
                      className={this.props.classes.shopRow}>
                      <TableCell
                        colSpan={2}
                        align="right"
                        className={this.props.classes.shopCell}>
                        Grand Total
                      </TableCell>
                      <TableCell
                        align="right"
                        className={this.props.classes.shopCell}>
                        {(this.props.shopPayments && this.props.shopPayments.data) ? this.props.shopPayments.data.totalOrders : '----'}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={this.props.classes.shopCell}>
                        {(this.props.shopPayments && this.props.shopPayments.data) ? `${formatPrice(this.props.shopPayments.data.totalRevenue)} 円` : '----'}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </div>
          </div>
        </Paper>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  profile: state.api.profile.default,
  shopPaymentsLastRequestedKey: state.api.shopPayments.lastRequestedKey,
  shopPayments: state.api.shopPayments[state.api.shopPayments.lastRequestedKey],
})

const mapDispatchToProps = {
  getShopPayments,
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(Payments)))
