import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment-timezone'
import { debounce } from 'throttle-debounce'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Toolbar from '@material-ui/core/Toolbar'
import InputBase from '@material-ui/core/InputBase'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TablePagination from '@material-ui/core/TablePagination'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'
import AddIcon from '@material-ui/icons/Add'
import SearchIcon from '@material-ui/icons/Search'
import {
  searchCompanies,
} from '../redux/api/actions'

const TablePaginationActions = withStyles(theme => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing.unit * 2.5,
  },
}))(props => {
  const { classes } = props
  const {
    count,
    page,
    rowsPerPage,
    onChangePage,
  } = props

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0)
  }

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1)
  }

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1)
  }

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page">
        <FirstPageIcon />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page">
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page">
        <LastPageIcon />
      </IconButton>
    </div>
  )
})

const styles = theme => ({
  root: {
    flex: '1 0 auto',
  },
  content: {
    margin: theme.spacing.unit * 3,
  },
  toolbar: {
    display: 'flex',
    justiftContent: 'space-between',
  },
  title: {
  },
  search: {
    flex: 1,
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[200],
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
    marginRight: theme.spacing.unit * 2,
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing.unit * 3,
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  table: {
  },
  searchBox: {
    flex: 1,
    boxSizing: 'border-box',
    width: '100%',
    height: 40,
    fontSize: '12px',
    lineHeight: '16px',
    border: '1px solid #e4e4e4',
    borderRadius: 4,
    outline: 'none',
    appearance: 'none',
    paddingLeft: theme.spacing.unit,
    marginLeft: theme.spacing.unit * 2,
  },
  pagination: {
    borderBottom: 'none',
  },
  cell: {
    textOverflow: 'ellipses',
  },
  cellMedium: {
    maxWidth: 120,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  },
  cellLarge: {
    maxWidth: 260,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  },
  progress: {
    margin: `${theme.spacing.unit * 16}px auto`,
    display: 'flex',
    justifyContent: 'center',
  },
})

class Companies extends Component {
  constructor(props) {
    super(props)

    const state = {
      text: '',
      page: 0,
    }

    props.searchCompanies({
      text: state.text,
      page: state.page,
    })

    this.state = state
  }

  refreshData = debounce(750, () => {
    this.props.searchCompanies({
      text: this.state.text,
      page: this.state.page,
    })
  })

  onChangePage = (event, page) => {
    this.setState({
      page,
    })

    this.refreshData()
  }

  onClickAddCompany = () => {
    this.props.history.push('/companies/new')
  }

  onChangeSearch = event => {
    let text = event.currentTarget.value

    if (text === '') {
      text = null
    }

    this.setState({
      text,
      page: 0,
    })

    this.refreshData()
  }

  onClickCompany = company => {
    this.props.history.push(`/companies/${company.id}`)
  }

  render() {
    let data = {
      companies: [],
      page: 0,
      numCompanies: 0,
      numPages: 1,
    }

    if (this.props.companiesSearch && this.props.companiesSearch.data) {
      data = this.props.companiesSearch.data
    }

    const currentKey = `text:${this.state.text}-page:${this.state.page}`
    const loading = (this.props.companiesSearch && this.props.companiesSearch.loading)
        || currentKey !== this.props.companiesSearchKey

    return (
      <div className={this.props.classes.root}>
        <Paper className={this.props.classes.content}>
          <Toolbar className={this.props.classes.toolbar}>
            <div className={this.props.classes.title}>
              <Typography variant="h6">
                Companies
              </Typography>
            </div>
            <div className={this.props.classes.search}>
              <div className={this.props.classes.searchIcon}>
                <SearchIcon size={20} />
              </div>
              <InputBase
                fullWidth
                placeholder="Search…"
                classes={{
                  root: this.props.classes.inputRoot,
                  input: this.props.classes.inputInput,
                }}
                onChange={this.onChangeSearch} />
            </div>
            <TablePagination
              count={data.numCompanies}
              page={this.state.page}
              rowsPerPage={100}
              rowsPerPageOptions={[]}
              ActionsComponent={TablePaginationActions}
              classes={{ root: this.props.classes.pagination }}
              onChangePage={this.onChangePage} />
            <Tooltip title="Add">
              <IconButton
                aria-label="Add"
                color="primary"
                onClick={this.onClickAddCompany}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          </Toolbar>
          <div className={this.props.classes.tableWrapper}>
            <Table className={this.props.classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell classes={{ root: this.props.classes.cellMedium }}>id</TableCell>
                  <TableCell classes={{ root: this.props.classes.cellMedium }}>name</TableCell>
                  <TableCell>created</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!loading && data.companies.map(company => (
                  <TableRow
                    key={company.id}
                    hover
                    onClick={() => this.onClickCompany(company)}>
                    <TableCell
                      classes={{ root: this.props.classes.cellMedium }}>
                      {company.id}
                    </TableCell>
                    <TableCell
                      classes={{ root: this.props.classes.cellMedium }}>
                      {company.name}
                    </TableCell>
                    <TableCell>
                      {moment.tz(company.createdAt, 'Asia/Tokyo').format('MMMM Do YYYY')}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {loading && (
              <div className={this.props.classes.progress}>
                <CircularProgress />
              </div>
            )}
          </div>
        </Paper>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  companiesSearch: state.api.companiesSearch[state.api.companiesSearch.lastReceivedKey],
  companiesSearchKey: state.api.companiesSearch.lastReceivedKey,
})

const mapDispatchToProps = {
  searchCompanies,
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(Companies)))
