import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import './Home.css'

class Home extends Component {
  render() {
    return (
      <Redirect to="/dashboard" />
    )
  }
}

const mapStateToProps = state => ({})
const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
