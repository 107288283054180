import React from 'react'
import './FormField.css'

export default ({
  input,
  disabled,
  placeholder,
  label,
  type,
  loading,
  meta: {
    touched,
    error,
  },
}) => (
  <div className="form-field__item">
    <div className="form-field__item-key">
      {label}
    </div>
    <div className="form-field__item-value">
      <input
      {...input}
      disabled={disabled}
      placeholder={placeholder}
      type={type} />
      {loading && (<div className="form-field__loading" />)}
      {touched && (error && (
        <span className="form-field__item-error">
          {error}
        </span>
      ))}
    </div>
  </div>
)
