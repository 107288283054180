import React, {
  Component,
} from 'react'
import { connect } from 'react-redux'
import moment from 'moment-timezone'
import { debounce } from 'throttle-debounce'
import {
  withRouter,
} from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import {
  searchOffers,
  searchProducts,
} from '../redux/api/actions'
import PaginatedTable from '../components/PaginatedTable'

const styles = theme => ({
  container: {
    margin: theme.spacing.unit * 2,
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
  },
  root: {
    flex: '1 0 auto',
  },
  toolbar: {
    display: 'flex',
  },
  actions: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  title: {
    flex: '1',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
})

class OffersAndProducts extends Component {
  state = {
    offersText: '',
    offersPage: 0,
    productsText: '',
    productsPage: 0,
  }

  refreshOfferData = debounce(750, () => {
    this.props.searchOffers({
      text: this.state.offersText,
      page: this.state.offersPage,
    })
  })

  refreshProductData = debounce(750, () => {
    this.props.searchProducts({
      text: this.state.productsText,
      page: this.state.productsPage,
    })
  })

  onClickAddOffer = () => {
    this.props.history.push('/offers/new')
  }

  onClickAddProduct = () => {
    this.props.history.push('/products/new')
  }

  onChangeOfferPage = (event, offersPage) => {
    this.setState({
      offersPage,
    })

    this.refreshOfferData()
  }

  onChangeProductPage = (event, productsPage) => {
    this.setState({
      productsPage,
    })

    this.refreshProductData()
  }

  onChangeOfferSearch = event => {
    let offersText = event.currentTarget.value

    if (offersText === '') {
      offersText = null
    }

    this.setState({
      offersText,
      offersPage: 0,
    })

    this.refreshOfferData()
  }

  onChangeProductSearch = event => {
    let productsText = event.currentTarget.value

    if (productsText === '') {
      productsText = null
    }

    this.setState({
      productsText,
      productsPage: 0,
    })

    this.refreshProductData()
  }

  onClickOffer = offer => {
    this.props.history.push(`/offers/${offer.id}`)
  }

  onClickProduct = product => {
    this.props.history.push(`/products/${product.id}`)
  }

  componentWillMount() {
    console.log('OffersAndProducts -- componentWillMount')
    this.props.searchOffers({
      text: this.state.offersText,
      page: this.state.offersPage,
    })

    this.props.searchProducts({
      text: this.state.productsText,
      page: this.state.productsPage,
    })
  }

  render() {
    let offersData = {
      offers: [],
      page: 0,
      numOffers: 0,
      numPages: 1,
    }

    if (this.props.offersSearch && this.props.offersSearch.data) {
      offersData = this.props.offersSearch.data
    }

    const currentOffersKey = `text:${this.state.offersText}-page:${this.state.offersPage}`
    let offersLoading = (this.props.offersSearch && this.props.offersSearch.loading) || currentOffersKey !== this.props.offersSearchKey

    let productsData = {
      products: [],
      page: 0,
      numProducts: 0,
      numPages: 1,
    }

    if (this.props.productsSearch && this.props.productsSearch.data) {
      productsData = this.props.productsSearch.data
    }

    const currentProductsKey = `text:${this.state.productsText}-page:${this.state.productsPage}`
    let productsLoading = (this.props.productsSearch && this.props.productsSearch.loading) || currentProductsKey !== this.props.productsSearchKey

    return (
      <div className={this.props.classes.container}>
        <PaginatedTable
          title="Offers"
          loading={offersLoading}
          data={offersData.offers}
          page={this.state.offersPage}
          rowsPerPage={10}
          count={offersData.numOffers}
          onClickAdd={this.onClickAddOffer}
          onChangePage={this.onChangeOfferPage}
          onChangeSearch={this.onChangeOfferSearch}
          renderHeader={() => (
            <TableRow>
              <TableCell classes={{ root: this.props.classes.cellMedium }}>id</TableCell>
              <TableCell classes={{ root: this.props.classes.cellLarge }}>code</TableCell>
              <TableCell>created</TableCell>
            </TableRow>
          )}
          renderRow={item => (
            <TableRow
              key={item.id}
              hover
              onClick={() => this.onClickOffer(item)}>
              <TableCell classes={{ root: this.props.classes.cellMedium }}>{item.id}</TableCell>
              <TableCell classes={{ root: this.props.classes.cellLarge }}>{item.code}</TableCell>
              <TableCell>{moment.tz(item.createdAt, 'Asia/Tokyo').format('MMMM Do YYYY')}</TableCell>
            </TableRow>
          )} />
        <PaginatedTable
          title="Products"
          loading={productsLoading}
          data={productsData.products}
          page={this.state.productsPage}
          rowsPerPage={10}
          count={productsData.numProducts}
          onClickAdd={this.onClickAddProduct}
          onChangePage={this.onChangeProductPage}
          onChangeSearch={this.onChangeProductSearch}
          renderHeader={() => (
            <TableRow>
              <TableCell classes={{ root: this.props.classes.cellMedium }}>id</TableCell>
              <TableCell classes={{ root: this.props.classes.cellLarge }}>code</TableCell>
              <TableCell>created</TableCell>
            </TableRow>
          )}
          renderRow={item => (
            <TableRow
              key={item.id}
              hover
              onClick={() => this.onClickProduct(item)}>
              <TableCell classes={{ root: this.props.classes.cellMedium }}>{item.id}</TableCell>
              <TableCell classes={{ root: this.props.classes.cellLarge }}>{item.code}</TableCell>
              <TableCell>{moment.tz(item.createdAt, 'Asia/Tokyo').format('MMMM Do YYYY')}</TableCell>
            </TableRow>
          )} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  offersSearch: state.api.offersSearch[state.api.offersSearch.lastReceivedKey],
  offersSearchKey: state.api.offersSearch.lastReceivedKey,
  productsSearch: state.api.productsSearch[state.api.productsSearch.lastReceivedKey],
  productsSearchKey: state.api.productsSearch.lastReceivedKey,
})

const mapDispatchToProps = {
  searchOffers,
  searchProducts,
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(OffersAndProducts)))
