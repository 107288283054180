export const camelCase = snake => snake.toLowerCase().replace(/(_\w)/g, k => k[1].toUpperCase())
export const getRequestType = name => `REQUEST_${name}`
export const getReceiveType = name => `RECEIVE_${name}`

const DEFAULT_OPTIONS = {
  key: 'default',
  useCache: false,
}

export default (name, api, options) => {
  const {
    key,
    useCache,
  } = {
    ...DEFAULT_OPTIONS,
    ...options,
  }

  const requestType = getRequestType(name)
  const receiveType = getReceiveType(name)

  const reducerKey = camelCase(name)

  const selector = state => state.api[reducerKey][key]

  const request = (...args) => ({ ...args, type: requestType, key })
  const recieve = (data, error) => ({
    type: receiveType,
    data,
    error,
    key,
  })

  return (dispatch, getState) => {
    dispatch(request())

    if (useCache) {
      const apiState = selector(getState())

      if (apiState && apiState.loaded) {
        return apiState.data
      }
    }

    return api()
      .then(json => {
        dispatch(recieve(json.data, null))
        return json.data
      })
      .catch(error => {
        let data = {}

        if (error.response && error.response.status) {
          data = {
            status: error.response.status,
          }
        }

        if (error.response && error.response.data) {
          data = {
            ...error.response.data,
            ...data,
          }
        }

        dispatch(recieve(null, data))
        return data
      })
  }
}
