import React, {
  Component,
  PureComponent,
} from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  Redirect,
  withRouter,
} from 'react-router-dom'
import classNames from 'classnames'
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LineChart,
  Line,
} from 'recharts'
import moment from 'moment-timezone'
import {
  red,
  green,
} from '@material-ui/core/colors'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import {
  getProfile,
  getJoinsAndChurns,
  getChurnRates,
  getGMByPlan,
} from '../redux/api/actions'
import { PLANS_RANKING } from './Subscriptions/Subscriptions'
import {
  PLAN_COLORS,
  CustomizedAxisTick,
} from './Dashboard/Dashboard'

const data = [
  {
    name: 'Page A', uv: 4000, pv: 2400, amt: 2400,
  },
  {
    name: 'Page B', uv: 3000, pv: 1398, amt: 2210,
  },
  {
    name: 'Page C', uv: 2000, pv: 9800, amt: 2290,
  },
  {
    name: 'Page D', uv: 2780, pv: 3908, amt: 2000,
  },
  {
    name: 'Page E', uv: 1890, pv: 4800, amt: 2181,
  },
  {
    name: 'Page F', uv: 2390, pv: 3800, amt: 2500,
  },
  {
    name: 'Page G', uv: 3490, pv: 4300, amt: 2100,
  },
]

const MONTHS = [
  '8 / 2018',
  '9 / 2018',
  '10 / 2018',
  '11 / 2018',
  '12 / 2018',
  '1 / 2019',
  '2 / 2019',
  '3 / 2019',
  '4 / 2019',
  '5 / 2019',
  '6 / 2019',
  '7 / 2019',
  '8 / 2019',
]

const COHORT_AGES = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
]

const PLANS_NAMES = {
  potluck_6: 'Potluck 6',
  potluck_12: 'Potluck 12',
  potluck_20: 'Potluck 20',
  potluck_tabeho: 'Potluck Tabeho',
}

const PLANS_FILTERS = {
  ALL_PLANS: 'all',
  NORMAL_PLANS: 'normal',
  DISCOUNT_PLANS: 'discount',
}

const FILTER_OPTIONS = {
  growth: {
    plans: [{
      label: 'All',
      value: PLANS_FILTERS.ALL_PLANS,
    }, {
      label: 'Normal',
      value: PLANS_FILTERS.NORMAL_PLANS,
    }, {
      label: 'Discount',
      value: PLANS_FILTERS.DISCOUNT_PLANS,
    }],
  },
  churn: {
    plans: [{
      label: 'All',
      value: PLANS_FILTERS.ALL_PLANS,
    }, {
      label: 'Normal',
      value: PLANS_FILTERS.NORMAL_PLANS,
    }, {
      label: 'Discount',
      value: PLANS_FILTERS.DISCOUNT_PLANS,
    }],
  },
}

const GROWTH_TYPES = [{
  label: 'Existing',
  key: 'existing',
}, {
  label: 'Joins',
  key: 'joins',
}, {
  label: 'Churns',
  key: 'churns',
}]

const DATA_START_DATE = moment.tz('2018-08-01', 'YYYY-MM-DD', 'Asia/Tokyo')

const isDiscountPlan = name => name.includes('percent_off')

export class CustomizedAxisPercentTick extends PureComponent {
  render() {
    const {
      x, y, payload,
    } = this.props

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          fontSize="9px"
          transform="rotate(-20)">
          {payload.value}%
        </text>
      </g>
    )
  }
}

const fetchData = dispatch => Promise.all([
  dispatch(getProfile()),
  dispatch(getJoinsAndChurns({ since: DATA_START_DATE.format('YYYY-MM-DD') })),
  dispatch(getChurnRates({ since: DATA_START_DATE.format() })),
  dispatch(getGMByPlan({ since: DATA_START_DATE.format() })),
])

const styles = theme => ({
  container: {
    margin: theme.spacing.unit * 2,
  },
  section: {
    marginBottom: theme.spacing.unit * 4,
  },
  filterSelect: {
    minWidth: 120,
    backgroundColor: '#ffffff',
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit * 2,
  },
  title: {
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: theme.spacing.unit * 2,
  },
  subtitle: {
    marginBottom: theme.spacing.unit,
    textAlign: 'center',
  },
  paper: {
    marginBottom: theme.spacing.unit * 3,
    paddingTop: theme.spacing.unit * 3,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    position: 'relative',
  },
  chart: {
    width: '100%',
    height: 500,
    paddingTop: theme.spacing.unit * 3,
    paddingBottom: theme.spacing.unit * 3,
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  titleCell: {
    [theme.breakpoints.down('lg')]: {
      width: '1%',
      whiteSpace: 'nowrap',
    },
  },
  net: {
    fontWeight: 'bold',
  },
  positiveNet: {
    color: green[600],
  },
  negativeNet: {
    color: red[600],
  },
})

class KPIs extends Component {
  constructor(props) {
    super(props)

    this.state = {
      filters: {
        growth: {
          plans: FILTER_OPTIONS.growth.plans[0].value,
        },
        churn: {
          plans: FILTER_OPTIONS.growth.plans[0].value,
        },
      },
    }
  }

  hasLoaded = () => {
    return this.props.profile.loaded
      && this.props.joinsAndChurns &&this.props.joinsAndChurns.loaded
      && this.props.churnRates &&this.props.churnRates.loaded
      && this.props.gmByPlan &&this.props.gmByPlan.loaded
  }

  componentWillMount() {
    fetchData(this.props.dispatch)
  }

  onChangeGrowthPlansFilter = event => {
    this.setState({
      filters: {
        ...this.state.filters,
        growth: {
          ...this.state.filters.growth,
          plans: event.target.value,
        },
      },
    })
  }

  onChangeChurnPlansFilter = event => {
    this.setState({
      filters: {
        ...this.state.filters,
        churn: {
          ...this.state.filters.churn,
          plans: event.target.value,
        },
      },
    })
  }

  render() {
    if (!this.hasLoaded()) {
      return false
    }

    if (!this.props.profile.data) {
      return (
        <Redirect to="/login" />
      )
    }

    let growthTotals = []
    let growthPlanNames = []
    let growthPlanTotals = []

    if (this.props.joinsAndChurns && this.props.joinsAndChurns.data) {
      const months = Object.keys(this.props.joinsAndChurns.data)

      months.sort((first, second) => moment(first).isBefore(moment(second)) ? -1 : 1)

      growthTotals = months.map(month => {
        const plans = Object.keys(this.props.joinsAndChurns.data[month][this.state.filters.growth.plans].bySimplePlanName)

        for (let plan of plans) {
          if (!growthPlanNames.includes(plan)) {
            growthPlanNames.push(plan)
          }
        }

        return {
          name: month,
          ...this.props.joinsAndChurns.data[month][this.state.filters.growth.plans].total,
        }
      })

      growthPlanNames.sort((first, second) => PLANS_RANKING.indexOf(first) < PLANS_RANKING.indexOf(second) ? -1 : 1)

      growthPlanTotals = growthPlanNames.map(plan => {
        const data = months.reduce((memo, month) => {
          if (this.props.joinsAndChurns.loaded) {
            memo[month] = this.props.joinsAndChurns.data[month][this.state.filters.growth.plans].bySimplePlanName[plan]

            if (this.props.joinsAndChurns.data[month][this.state.filters.growth.plans].bySimplePlanName[plan] &&
              typeof this.props.joinsAndChurns.data[month][this.state.filters.growth.plans].bySimplePlanName[plan].joins === 'number'
              && typeof this.props.joinsAndChurns.data[month][this.state.filters.growth.plans].bySimplePlanName[plan].churns === 'number') {
              memo[month].net = this.props.joinsAndChurns.data[month][this.state.filters.growth.plans].bySimplePlanName[plan].joins - this.props.joinsAndChurns.data[month][this.state.filters.growth.plans].bySimplePlanName[plan].churns
            }
          } else {
            memo[month] = {
              existing: 0,
              joins: 0,
              churns: 0,
              net: 0,
            }
          }

          return memo
        }, {})

        return {
          plan,
          data,
        }
      })
    }

    let churnData = []
    let churnPlanNames = []

    if (this.props.churnRates && this.props.churnRates.data) {
      if (this.state.filters.churn.plans === PLANS_FILTERS.ALL_PLANS) {
        churnData = this.props.churnRates.data.bySimplePlanName.points

        for (let point of churnData) {
          for (let plan of Object.keys(point)) {
            if (!churnPlanNames.includes(plan) && plan.includes('potluck')) {
              churnPlanNames.push(plan)
            }
          }
        }
      } else if (this.state.filters.churn.plans === PLANS_FILTERS.NORMAL_PLANS) {
        churnData = this.props.churnRates.data.byPlanName.points.filter(point => {
          const result = {}

          for (let plan of Object.keys(point)) {
            if (!isDiscountPlan(plan) && plan.includes('potluck')) {
              result[plan] = point[plan]

              if (!churnPlanNames.includes(plan)) {
                churnPlanNames.push(plan)
              }
            }
          }

          return result
        })
      } else if (this.state.filters.churn.plans === PLANS_FILTERS.DISCOUNT_PLANS) {
        churnData = this.props.churnRates.data.byPlanName.points.filter(point => {
          const result = {}

          for (let plan of Object.keys(point)) {
            if (isDiscountPlan(plan) && plan.includes('potluck')) {
              result[plan] = point[plan]

              if (!churnPlanNames.includes(plan)) {
                churnPlanNames.push(plan)
              }
            }
          }

          return result
        })
      }
    }

    churnPlanNames.sort((first, second) => PLANS_RANKING.indexOf(first) < PLANS_RANKING.indexOf(second) ? -1 : 1)

    return (
      <div className={this.props.classes.container}>
        <div className={this.props.classes.section}>
          <Typography
            variant="h5"
            className={this.props.classes.title}>
            Growth
          </Typography>
          <FormControl
            variant="outlined"
            className={this.props.classes.filterSelect}>
            <InputLabel>
              Plans
            </InputLabel>
            <Select
              value={this.state.filters.growth.plans}
              onChange={this.onChangeGrowthPlansFilter}
              input={(
                <OutlinedInput
                  labelWidth={39}
                  name="age" />
              )}>
              {FILTER_OPTIONS.growth.plans.map(plan => (
                <MenuItem
                  key={plan.value}
                  value={plan.value}>
                  {plan.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Paper className={this.props.classes.paper}>
            <div className={this.props.classes.chart}>
              <ResponsiveContainer>
                <BarChart
                  height={300}
                  data={growthTotals}
                  margin={{
                    top: 10,
                    right: 20,
                    left: 0,
                    bottom: 30,
                  }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="name"
                    interval={0}
                    tick={(<CustomizedAxisTick />)} />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="joins" stackId="a" fill={green[900]} />
                  <Bar dataKey="existing" stackId="a" fill={green[600]} />
                  <Bar dataKey="churns" fill={red[600]} />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Paper>
          {growthPlanNames.map(plan => {
            const planTotal = growthPlanTotals.find(growthPlanTotal => growthPlanTotal.plan === plan)

            return (
              <Paper
                key={planTotal.plan}
                className={this.props.classes.paper}>
                <Typography
                  variant="caption"
                  className={this.props.classes.subtitle}>
                  {PLANS_NAMES[plan]}
                </Typography>
                <div className={this.props.classes.tableWrapper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        {Object.keys(planTotal.data).map(month => (
                          <TableCell
                            key={month}
                            variant="head"
                            className={this.props.classes.titleCell}>
                            {month}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                      <TableBody>
                        {GROWTH_TYPES.map(({ label, key }) => (
                          <TableRow key={key}>
                            <TableCell>
                              {label}
                            </TableCell>
                            {Object.keys(planTotal.data).map(month => (
                              <TableCell key={month}>
                                {planTotal.data[month] ? planTotal.data[month][key] : '-'}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                        <TableRow>
                          <TableCell>
                            Net
                          </TableCell>
                          {Object.keys(planTotal.data).map(month => (
                            <TableCell
                              key={month}
                              className={classNames(this.props.classes.net, {
                                [this.props.classes.positiveNet]: planTotal.data[month] && planTotal.data[month].net > 0,
                                [this.props.classes.negativeNet]: planTotal.data[month] && planTotal.data[month].net < 0,
                              })}>
                              {(planTotal.data[month] === undefined || typeof planTotal.data[month].net !== 'number') && '-'}
                              {(planTotal.data[month] !== undefined && planTotal.data[month].net > 0) && `+${planTotal.data[month].net}`}
                              {(planTotal.data[month] !== undefined && planTotal.data[month].net <= 0) && planTotal.data[month].net}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableBody>
                  </Table>
                </div>
              </Paper>
            )
          })}
        </div>
        {false && (
          <div className={this.props.classes.section}>
            <Typography
              variant="h5"
              className={this.props.classes.title}>
              LTV
            </Typography>
            <Paper className={this.props.classes.paper}>
              <div className={this.props.classes.chart}>
                <ResponsiveContainer>
                  <LineChart
                    height={300}
                    data={data}
                    margin={{
                      top: 5, right: 30, left: 20, bottom: 5,
                    }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="name"
                      interval={0}
                      tick={(<CustomizedAxisTick />)} />
                    <YAxis />
                    <Tooltip />
                    <Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{ r: 8 }} />
                    <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </Paper>
          </div>
        )}
        {false && (
          <div className={this.props.classes.section}>
            <Paper className={this.props.classes.paper}>
              <div className={this.props.classes.tableWrapper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Month</TableCell>
                      {MONTHS.map(month => (
                        <TableCell key={month}>{month}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                    <TableBody>
                      {COHORT_AGES.map(age => (
                        <TableRow key={age}>
                          <TableCell>
                            {age}
                          </TableCell>
                          {MONTHS.map(month => (
                            <TableCell key={month}>1.23</TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                </Table>
              </div>
            </Paper>
          </div>
        )}
        <div className={this.props.classes.section}>
          <Typography
            variant="h5"
            className={this.props.classes.title}>
            Churn
          </Typography>
          <FormControl
            variant="outlined"
            className={this.props.classes.filterSelect}>
            <InputLabel>
              Plans
            </InputLabel>
            <Select
              value={this.state.filters.churn.plans}
              onChange={this.onChangeChurnPlansFilter}
              input={(
                <OutlinedInput
                  labelWidth={39}
                  name="age" />
              )}>
              {FILTER_OPTIONS.churn.plans.map(plan => (
                <MenuItem
                  key={plan.value}
                  value={plan.value}>
                  {plan.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Paper className={this.props.classes.paper}>
            <div className={this.props.classes.chart}>
              <ResponsiveContainer>
                <LineChart
                  height={300}
                  data={churnData}
                  margin={{
                    top: 10,
                    right: 20,
                    left: 0,
                    bottom: 30,
                  }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="name"
                    interval={0}
                    tick={(<CustomizedAxisTick />)} />
                  <YAxis
                    ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                    tick={(<CustomizedAxisPercentTick />)} />
                  <Tooltip formatter={(value, name, props) => `${Math.round(value)}%`} />
                  {churnPlanNames.map(plan => (
                    <Line
                      key={plan}
                      type="monotone"
                      dataKey={plan}
                      stroke={PLAN_COLORS[plan]} />
                  ))}
                </LineChart>
              </ResponsiveContainer>
            </div>
          </Paper>
        </div>
        {false && (
          <div className={this.props.classes.section}>
            <Paper className={this.props.classes.paper}>
              <div className={this.props.classes.tableWrapper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Month</TableCell>
                      {MONTHS.map(month => (
                        <TableCell key={month}>{month}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                    <TableBody>
                      {COHORT_AGES.map(age => (
                        <TableRow key={age}>
                          <TableCell>
                            {age}
                          </TableCell>
                          {MONTHS.map(month => (
                            <TableCell key={month}>xx%</TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                </Table>
              </div>
            </Paper>
          </div>
        )}
        <div className={this.props.classes.section}>
          <Typography
            variant="h5"
            className={this.props.classes.title}>
            GM
          </Typography>
          <Paper className={this.props.classes.paper}>
            <div className={this.props.classes.chart}>
              <ResponsiveContainer>
                <LineChart
                  height={300}
                  data={this.props.gmByPlan.data.points}
                  margin={{
                    top: 10,
                    right: 20,
                    left: 0,
                    bottom: 30,
                  }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="name"
                    interval={0}
                    tick={(<CustomizedAxisTick />)} />
                  <YAxis
                    ticks={[-100, -80, -60, -40, -20, 0, 20, 40, 60, 80, 100]}
                    tick={(<CustomizedAxisPercentTick />)} />
                  <Tooltip formatter={(value, name, props) => `${Math.round(value)}%`} />
                  <Tooltip />
                  {['potluck_6', 'potluck_12', 'potluck_20', 'potluck_tabeho'].map(plan => (
                    <Line
                      key={plan}
                      type="monotone"
                      dataKey={plan}
                      stroke={PLAN_COLORS[plan]} />
                  ))}
                </LineChart>
              </ResponsiveContainer>
            </div>
          </Paper>
          {false && (
            <Paper className={this.props.classes.paper}>
              <div className={this.props.classes.tableWrapper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Month</TableCell>
                      {MONTHS.map(month => (
                        <TableCell key={month}>{month}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                    <TableBody>
                      {COHORT_AGES.map(age => (
                        <TableRow key={age}>
                          <TableCell>
                            {age}
                          </TableCell>
                          {MONTHS.map(month => (
                            <TableCell key={month}>xx%</TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                </Table>
              </div>
            </Paper>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  profile: state.api.profile.default,
  joinsAndChurns: state.api.joinsAndChurns[state.api.joinsAndChurns.lastRequestedKey],
  churnRates: state.api.churnRates[state.api.churnRates.lastRequestedKey],
  gmByPlan: state.api.gmByPlan[state.api.gmByPlan.lastRequestedKey],
})

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    getProfile,
    getJoinsAndChurns,
    getChurnRates,
    getGMByPlan,
  }),
  dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(KPIs)))
