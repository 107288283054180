import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Redirect,
  withRouter,
} from 'react-router-dom'
import moment from 'moment-timezone'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import CircularProgress from '@material-ui/core/CircularProgress'
import CloudDownloadRoundedIcon from '@material-ui/icons/CloudDownloadRounded'
import {
  getDeliveryReservationsForDate,
  updateReservationStatus,
} from '../redux/api/actions'
import NewPaginationBar from '../components/NewPaginationBar/NewPaginationBar'
import Constants from '../utils/Constants'

const groupAndSortReservations = reservations => {
  const results = reservations.reduce((memo, reservation) => {
    const existingReservation = memo.find(({ userId, meal, pickup }) => reservation.userId === userId
      && reservation.meal.id === meal.id
      && reservation.pickup.date === pickup.date
      && reservation.pickup.time === pickup.time)

    if (existingReservation) {
      existingReservation.amount++
    } else {
      memo.push({
        ...reservation,
        amount: 1,
      })
    }

    return memo
  }, [])

  results.sort((first, second) => {
    const firstMoment = moment(`${first.pickup.date} ${first.pickup.time}`)
    const secondMoment = moment(`${second.pickup.date} ${second.pickup.time}`)

    if (firstMoment.isBefore(secondMoment)) {
      return -1
    } else if (secondMoment.isBefore(firstMoment)) {
      return 1
    } else if (first.meal.id > second.meal.id) {
      return -1
    } else if (first.meal.id < second.meal.id) {
      return 1
    } else {
      return first.id > second.id
    }
  })

  return results
}

const styles = theme => ({
  root: {
    flex: '1 0 auto',
  },
  pages: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  content: {
    margin: theme.spacing.unit * 3,
    position: 'relative',
  },
  toolbar: {
    display: 'flex',
  },
  actions: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  title: {
    flex: '1',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  progressRow: {
    height: 140,
  },
  progress: {
    position: 'absolute',
    left: 'calc(50% - 20px)',
    top: 'calc(50% + 40px)',
  },
  cellProgress: {
    opacity: 0.5,
  },
  statusProgress: {
    position: 'absolute',
    left: 'calc(50% - 20px)',
    top: 'calc(50% - 20px)',
  },
})

const DELIVERY_START = '20200509'

class Delivery extends Component {
  constructor(props) {
    super(props)

    const now = moment.tz('Asia/Tokyo')

    let page
    const pages = []
    const startDate = moment.tz(DELIVERY_START, 'YYYYMMDD', 'Asia/Tokyo')

    while (startDate.isSameOrBefore(now)) {
      pages.push({
        label: startDate.format('M/D'),
        value: startDate.format('YYYYMMDD'),
      })

      startDate.add(1, 'day')
    }

    const shouldShowReservationsForTomorrow = now.hours() >= Constants.LUNCH_FIRST_CALL

    if (shouldShowReservationsForTomorrow) {
      pages.push({
        label: startDate.format('M/D'),
        value: startDate.format('YYYYMMDD'),
      })
    }

    if (!props.match.params.date) {
      page = pages[pages.length - 1]
    } else {
      page = pages.find(page => page.value === props.match.params.date)
    }

    this.state = {
      page,
      pages,
      loadingStatuses: [],
    }
  }

  hasLoaded = () => {
    return this.props.deliveryReservations && this.props.deliveryReservations.loaded
  }

  isLoading = () => {
    return this.props.deliveryReservations && this.props.deliveryReservations.loading
  }

  onChangePage = page => {
    this.setState({
      page,
    })

    this.props.history.push(`/deliveries/${page.value}`)
    this.props.getDeliveryReservationsForDate(page.value)
  }

  onClickDownloadCSV = () => {
    let csv = 'data:text/csv;charset=utf-8,\nNo.,Pickup time,ユーザー名 Account name,配送先住所 Delivery address,建物名・部屋番号 Delivery building,緊急連絡先 User phone,配達員へのコメント Note,予約店舗 Shop,メニュー名 Meal,個数 Quantitiy,店舗住所 Shop address\n'

    const reservations = groupAndSortReservations(this.props.deliveryReservations.data)

    for (let index = 0; index < reservations.length; index++) {
      const reservation = reservations[index]
      csv += `${index + 1},"${reservation.pickup.time}","${reservation.user}","${reservation.deliveryDetails && reservation.deliveryDetails.deliveryLocation && reservation.deliveryDetails.deliveryLocation.address}","${(reservation.deliveryDetails && reservation.deliveryDetails.building) || ''}","${reservation.deliveryDetails && reservation.deliveryDetails.phone}","${(reservation.deliveryDetails && reservation.deliveryDetails.note) || ''}","${reservation.shop.name}","${reservation.meal.name}","${reservation.amount}","${reservation.shop.address}"\n`
    }

    const encodedUri = encodeURI(csv)
    var link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute('download', `deliveries-${this.state.page.value}.csv`)
    document.body.appendChild(link)
    link.click()
  }

  componentWillMount() {
    this.props.getDeliveryReservationsForDate(this.state.page.value)

    if (!this.props.match.params.date) {
      this.props.history.push(`/deliveries/${this.state.page.value}`)
    }
  }

  render() {
    let reservations = []
    if (!this.props.profile.data) {
      return (
        <Redirect to="/login" />
      )
    }

    if (this.props.deliveryReservations.data) {
      reservations = groupAndSortReservations(this.props.deliveryReservations.data)
    }

    return (
      <div className={this.props.classes.root}>
        <div className={this.props.classes.pages}>
          <NewPaginationBar
            pages={this.state.pages}
            initialPage={this.state.page}
            onChangePage={this.onChangePage} />
        </div>
        <Paper className={this.props.classes.content}>
          <Toolbar className={this.props.classes.toolbar}>
            <div className={this.props.classes.title}>
              <Typography variant="h6">
                Deliveries
              </Typography>
            </div>
            <div className={this.props.classes.actions}>
              <Tooltip title="Download CSV">
                <IconButton
                  aria-label="Download CSV"
                  color="primary"
                  onClick={this.onClickDownloadCSV}>
                  <CloudDownloadRoundedIcon />
                </IconButton>
              </Tooltip>
            </div>
          </Toolbar>
          <div className={this.props.classes.tableWrapper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>No.</TableCell>
                  <TableCell>Pickup time</TableCell>
                  <TableCell>ユーザー名<br />Account name</TableCell>
                  <TableCell>配送先住所<br />Delivery address</TableCell>
                  <TableCell>建物名・部屋番号<br />Delivery building</TableCell>
                  <TableCell>緊急連絡先<br />User phone</TableCell>
                  <TableCell>配達員へのコメント<br />Note</TableCell>
                  <TableCell>予約店舗<br />Shop</TableCell>
                  <TableCell>メニュー名<br />Meal</TableCell>
                  <TableCell>個数<br />Quantitiy</TableCell>
                  <TableCell>店舗住所<br />Shop address</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!this.hasLoaded() && this.isLoading() && (
                  <TableRow
                    colSpan={9}
                    className={this.props.classes.progressRow}>
                    <TableCell>
                      <CircularProgress className={this.props.classes.progress} />
                    </TableCell>
                  </TableRow>
                )}
                {this.hasLoaded() && reservations.length <= 0 && (
                  <TableRow colSpan={9}>
                    <TableCell>None</TableCell>
                  </TableRow>
                )}
                {this.hasLoaded() && reservations.length > 0 && reservations.map((reservation, index) => (
                  <TableRow key={reservation.id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{reservation.pickup.time}</TableCell>
                    <TableCell>{reservation.user}</TableCell>
                    <TableCell>{reservation.deliveryDetails && reservation.deliveryDetails.deliveryLocation && reservation.deliveryDetails.deliveryLocation.address}</TableCell>
                    <TableCell>{reservation.deliveryDetails && reservation.deliveryDetails.building}</TableCell>
                    <TableCell>{reservation.deliveryDetails && reservation.deliveryDetails.phone}</TableCell>
                    <TableCell>{reservation.deliveryDetails && reservation.deliveryDetails.note}</TableCell>
                    <TableCell>{reservation.shop.name}</TableCell>
                    <TableCell>{reservation.meal.name}</TableCell>
                    <TableCell>{reservation.amount}</TableCell>
                    <TableCell>{reservation.shop.address}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </Paper>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  profile: state.api.profile.default,
  deliveryReservations: state.api.deliveryReservationsForDate[state.api.deliveryReservationsForDate.lastRequestedKey],
  updateReservationStatusState: state.api.updateReservationStatus,
})

const mapDispatchToProps = {
  getDeliveryReservationsForDate,
  updateReservationStatus,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(Delivery)))
