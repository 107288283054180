import React, { Component } from 'react'
import {
  Map as GoogleMap,
  Marker,
  GoogleApiWrapper
} from 'google-maps-react'

const API_KEY = 'AIzaSyCe1Sgvl0fg7BMtiQ8vMAF7oxyDEClUUTU'

class PotluckMap extends Component {
  render() {
    const {
      markers,
      onClickMap,
      ...rest
    } = this.props

    return (
      <GoogleMap
        {...rest}
        onClick={onClickMap}>
        {markers.map((marker, index) => (
          <Marker
            key={index}
            icon={{
              url: 'https://s3-ap-northeast-1.amazonaws.com/potluck-webapp/marker.svg',
              size: new this.props.google.maps.Size(45, 45, 'px', 'px'),
              scaledSize: new this.props.google.maps.Size(45, 45, 'px', 'px'),
            }}
            {...marker} />
        ))}
      </GoogleMap>
    )
  }
}

PotluckMap.defaultProps = {
  zoom: 14,
  markers: [],
  initialCenter: {
    lat: 35.6580340,
    lng: 139.7016360,
  },
  streetViewControl: false,
  mapTypeControl: false,
  fullscreenControl: false,
}

export default GoogleApiWrapper({
  apiKey: (API_KEY)
})(PotluckMap)
